import {
  DynamicColumnFormat,
  IIntegrationTypeField,
  IntegrationTypeFieldListResponse,
} from '../IntegrationRules.i';

export const parseFileContentToObject = (
  content: string,
): Record<string, string> => {
  const lines = content.split('\n');
  const object: Record<string, string> = {};

  lines.forEach(line => {
    const parts = line.split('=');
    if (parts.length >= 2) {
      const key = parts[0].trim();
      let value = parts.slice(1).join('=').trim(); // Joining parts in case there are equal signs in the value
      const match = value.match(/"(.*)";$/); // Match the value within quotes followed by a semicolon at the end
      if (match) {
        // eslint-disable-next-line prefer-destructuring
        value = match[1]; // Get the matched value without the quotes and semicolon
      }
      value = value.replace(/"/g, ''); // Remove double quotes
      value = value.replace(/;/g, ''); // Remove semicolons
      // value = decodeURIComponent(value); // Decode the value
      object[key] = value;
    }
  });

  return object;
};

export const generateDynamicFormat = (
  headerRow: string[],
): Record<string, DynamicColumnFormat> => {
  const dynamicFormat: Record<string, DynamicColumnFormat> = {};
  headerRow.forEach((columnName, index) => {
    dynamicFormat[columnName] = {
      name: columnName,
      align: 'left',
      order: (index + 1).toString(),
      isDisplayColumn: false,
      isPrimaryKeyColumn: false,
      isHidden: false,
    };
  });
  return dynamicFormat;
};

export function orderFields(
  fields: IntegrationTypeFieldListResponse,
): IntegrationTypeFieldListResponse {
  const fieldMap = new Map<string, IIntegrationTypeField>();
  const orderedFields: IIntegrationTypeField[] = [];

  // Map each field by its ID for easy lookup
  fields.forEach(field => fieldMap.set(field.id, field));

  // Recursive function to add children to a parent field
  function addChildren(parent: IIntegrationTypeField) {
    const children = fields
      .filter(field => field.parent_id === parent.id)
      .sort((a, b) => {
        // Sort by type with FIELD and INTEGRATED first, then TABLE
        if (a.fieldType === 'FIELD' && b.fieldType !== 'FIELD') return -1;
        if (a.fieldType === 'INTEGRATED' && b.fieldType === 'TABLE') return -1;
        return 1;
      });

    children.forEach(child => {
      orderedFields.push(child);
      if (child.fieldType === 'INTEGRATED' || child.fieldType === 'TABLE') {
        addChildren(child); // Recursively add children for INTEGRATED or TABLE types
      }
    });
  }

  // Add top-level items with no parent_id in FIELD, INTEGRATED, TABLE order
  fields
    .filter(field => field.parent_id === null)
    .sort((a, b) => {
      if (a.fieldType === 'FIELD' && b.fieldType !== 'FIELD') return -1;
      if (a.fieldType === 'INTEGRATED' && b.fieldType === 'TABLE') return -1;
      return 1;
    })
    .forEach(topLevelField => {
      orderedFields.push(topLevelField);
      if (
        topLevelField.fieldType === 'INTEGRATED' ||
        topLevelField.fieldType === 'TABLE'
      ) {
        addChildren(topLevelField); // Add children for INTEGRATED and TABLE items
      }
    });

  return orderedFields;
}

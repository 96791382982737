import React from 'react';

import { TextField, CircularProgress } from '@material-ui/core';
import PermissionComponent from 'src/components/PermissionComponent';
import { Autocomplete } from '@material-ui/lab';
import { UserRestrictDataProps } from '../User.i';

export const UserRestrictData: React.FC<UserRestrictDataProps> = ({
  formik,
  businesses,
  loadingBusiness,
  loadingArea,
  areas,
}) => {
  return (
    <div className="w-full flex flex-row gap-2 max-md:flex-wrap">
      <PermissionComponent permissionName="BUSINESS">
        <Autocomplete
          id="business"
          getOptionLabel={business => business?.name}
          getOptionSelected={(business, value) => business.id === value.id}
          options={businesses}
          loading={loadingBusiness}
          value={formik.values.business || null}
          onChange={(e, value) => {
            formik.setFieldValue(
              'business',
              value !== null ? value : formik.initialValues.business,
            );
          }}
          loadingText="Carregando"
          fullWidth
          renderInput={rest => (
            <TextField
              {...rest}
              id="business"
              label="Unidade de negócio"
              margin="dense"
              variant="outlined"
              error={formik.touched.business && Boolean(formik.errors.business)}
              helperText={formik.touched.business && formik.errors.business}
              InputProps={{
                ...rest.InputProps,
                endAdornment: (
                  <>
                    {loadingArea ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {rest.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          id="area"
          getOptionLabel={area => area.name}
          getOptionSelected={(area, value) => area.id === value.id}
          options={areas}
          loading={loadingArea}
          value={formik.values.area || null}
          onChange={(e, value) => {
            formik.setFieldValue(
              'area',
              value !== null ? value : formik.initialValues.area,
            );
          }}
          loadingText="Carregando"
          fullWidth
          renderInput={rest => (
            <TextField
              {...rest}
              id="area"
              label="Área"
              margin="dense"
              variant="outlined"
              error={formik.touched.area && Boolean(formik.errors.area)}
              helperText={formik.touched.area && formik.errors.area}
              InputProps={{
                ...rest.InputProps,
                endAdornment: (
                  <>
                    {loadingArea ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {rest.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </PermissionComponent>
    </div>
  );
};

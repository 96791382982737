'use client';

import React, { useCallback, useEffect } from 'react';
import { Table } from '@tanstack/react-table';

import { FilterIcon, LucideIcon, PlusCircleIcon, X } from 'lucide-react';
import { DateRange } from 'react-day-picker';
import { DataTableViewOptions } from './data-table-view-options';
import { Button } from './button';

import { DataTableFacetedFilter } from './data-table-faceted-filter';
import { DebouncedInput } from './debounce-input';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { TooltipWrapper } from './tooltip';
import { DatePickerWithRange } from '../DateRangePicker';

interface FilterOptions {
  value: string;
  label: string;
  icon?: React.ComponentType<{ className?: string }> | LucideIcon;
}

export interface ColumnsToFilterType {
  columnId: string;
  title: string;
  options: FilterOptions[];
}

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  globalFilter:
    | string
    | number
    | (string & readonly string[])
    | (number & readonly string[]);
  setGlobalFilter: (value: string) => void;
  showColumnVisibility?: boolean;
  showGlobalSearchInput?: boolean;
  handleClickAddNewButton?: () => void;
  initiallyInvisibleColumns?: string[];
  localStorageKey?: string;
  showFilters?: boolean;
  columnsToFilter?: ColumnsToFilterType[];
  firstDateColumnId?: string;
  secondDateColumnId?: string;
  thirdDateColumnId?: string;
  includeButtonText?: string;
}

const renderDateFilterOption = (
  columnId: string,
  date: DateRange | undefined,
  setDate: (value: DateRange | undefined) => void,
) => {
  return (
    <div className="flex flex-row" key={columnId}>
      <DatePickerWithRange
        placeholder={columnId}
        date={date}
        setDate={setDate}
      />
    </div>
  );
};

export function DataTableToolbar<TData>({
  table,
  globalFilter,
  setGlobalFilter,
  showColumnVisibility = false,
  showGlobalSearchInput = false,
  showFilters = false,
  handleClickAddNewButton,
  initiallyInvisibleColumns,
  localStorageKey,
  columnsToFilter = [],
  firstDateColumnId,
  secondDateColumnId,
  thirdDateColumnId,
  includeButtonText,
}: DataTableToolbarProps<TData>) {
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [firstDate, setFirstDate] = React.useState<DateRange | undefined>(
    undefined,
  );
  const [secondDate, setSecondDate] = React.useState<DateRange | undefined>(
    undefined,
  );
  const [thirdDate, setThirdDate] = React.useState<DateRange | undefined>(
    undefined,
  );

  const handleFilterModalState = useCallback(() => {
    setIsFilterOpen(oldValue => !oldValue);
  }, []);

  const resetDateFilter = useCallback(
    (columnId: string, setState: (value: DateRange | undefined) => void) => {
      const removeFilter = table
        .getState()
        .columnFilters.filter(f => f.id !== columnId);
      table.setColumnFilters(removeFilter);
      setState(undefined);
    },
    [table],
  );

  const renderDateFilters = useCallback(() => {
    const dateFilters = [];
    if (firstDateColumnId) {
      dateFilters.push(
        renderDateFilterOption(firstDateColumnId, firstDate, setFirstDate),
      );
    }
    if (secondDateColumnId) {
      dateFilters.push(
        renderDateFilterOption(secondDateColumnId, secondDate, setSecondDate),
      );
    }
    if (thirdDateColumnId) {
      dateFilters.push(
        renderDateFilterOption(thirdDateColumnId, thirdDate, setThirdDate),
      );
    }
    return dateFilters;
  }, [
    firstDateColumnId,
    secondDateColumnId,
    thirdDateColumnId,
    firstDate,
    secondDate,
    thirdDate,
  ]);

  useEffect(() => {
    if ((firstDate?.from || firstDate?.to) && firstDateColumnId) {
      const dateColumn = table.getColumn(firstDateColumnId);
      if (dateColumn?.id === firstDateColumnId)
        dateColumn.setFilterValue(firstDate);
    }
    if (!firstDate?.from && !firstDate?.to && firstDateColumnId) {
      resetDateFilter(firstDateColumnId, setFirstDate);
    }
  }, [firstDate, firstDateColumnId]);

  useEffect(() => {
    if ((secondDate?.from || secondDate?.to) && secondDateColumnId) {
      const dateColumn = table.getColumn(secondDateColumnId);
      if (dateColumn?.id === secondDateColumnId)
        dateColumn.setFilterValue(secondDate);
    }
    if (!secondDate?.from && !secondDate?.to && secondDateColumnId) {
      resetDateFilter(secondDateColumnId, setSecondDate);
    }
  }, [secondDate, secondDateColumnId]);

  useEffect(() => {
    if ((thirdDate?.from || thirdDate?.to) && thirdDateColumnId) {
      const dateColumn = table.getColumn(thirdDateColumnId);
      if (dateColumn?.id === thirdDateColumnId)
        dateColumn.setFilterValue(thirdDate);
    }
    if (!thirdDate?.from && !thirdDate?.to && thirdDateColumnId) {
      resetDateFilter(thirdDateColumnId, setThirdDate);
    }
  }, [thirdDate, thirdDateColumnId]);

  const resetAllColumnFilters = useCallback(() => {
    const filteredColumns = table.getState().columnFilters;
    if (filteredColumns && filteredColumns.length > 0) {
      table.setColumnFilters([]);
    }
    setFirstDate(undefined);
    setSecondDate(undefined);
    setThirdDate(undefined);
  }, [table]);

  const renderColumnFilters = useCallback(() => {
    return columnsToFilter.map(columnFilter => {
      const currentColumn = table.getColumn(columnFilter.columnId);

      if (!currentColumn) return null;

      return (
        <DataTableFacetedFilter
          key={columnFilter.columnId}
          column={currentColumn}
          title={columnFilter.title}
          options={columnFilter.options}
        />
      );
    });
  }, [columnsToFilter, table]);

  const isFiltered = table.getState().columnFilters.length > 0;
  const includeText = includeButtonText || 'Adicionar';

  return (
    <div className="flex items-center justify-between max-sm:flex-col max-sm:gap-2">
      <div className="flex flex-1 items-center space-x-1 max-sm:w-full max-sm:justify-between">
        {showGlobalSearchInput && (
          <DebouncedInput
            value={globalFilter}
            onChange={value => setGlobalFilter(String(value))}
            className="p-2 mr-1 h-8 font-lg border w-[150px] lg:w-[300px]"
            placeholder="Busca geral..."
          />
        )}
        <div className="flex flex-nowrap">
          {showFilters &&
            ((columnsToFilter && columnsToFilter.length > 0) ||
              firstDateColumnId ||
              secondDateColumnId) && (
              <div className="flex flex-nowrap items-center space-x-2 ">
                <Popover open={isFilterOpen} onOpenChange={undefined}>
                  <TooltipWrapper value="Filtros" className="font-medium">
                    <PopoverTrigger asChild onClick={handleFilterModalState}>
                      <Button
                        variant={isFiltered ? 'default' : 'outline'}
                        size="default"
                        className="h-8"
                      >
                        <FilterIcon className="h-4 w-4 font-bold" />
                      </Button>
                    </PopoverTrigger>
                  </TooltipWrapper>
                  <PopoverContent
                    className="z-[50] w-fit min-w-[150px] h-fit p-2 flex flex-col gap-2"
                    align="center"
                    side="bottom"
                    onOpenAutoFocus={e => e.stopPropagation()}
                    onCloseAutoFocus={e => e.stopPropagation()}
                    onFocusOutside={e => e.stopPropagation()}
                    onInteractOutside={e => e.stopPropagation()}
                    onPointerDownOutside={() => setIsFilterOpen(false)}
                  >
                    {columnsToFilter && renderColumnFilters()}
                    {(firstDateColumnId || secondDateColumnId) &&
                      renderDateFilters()}
                  </PopoverContent>
                </Popover>
              </div>
            )}
          {isFiltered && (
            <Button
              variant="ghost"
              onClick={resetAllColumnFilters}
              className="h-8 px-1 lg:px-3"
            >
              <span className="max-sm:hidden">Limpar</span>
              <X className="ml-1 h-4 w-4" />
            </Button>
          )}
        </div>
      </div>

      <div className="flex flex-nowrap items-center space-x-2 max-sm:w-full max-sm:justify-end">
        {showColumnVisibility && (
          <DataTableViewOptions
            table={table}
            initiallyInvisibleColumns={initiallyInvisibleColumns || []}
            localStorageKey={localStorageKey || ''}
          />
        )}
        {handleClickAddNewButton && (
          <Button onClick={handleClickAddNewButton} size="sm" className="h-8">
            <PlusCircleIcon className="mr-1 h-4 w-4" /> {includeText}
          </Button>
        )}
      </div>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseTextFieldProps, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

interface CustomTextFieldProps extends BaseTextFieldProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  error: boolean;
  helperText: React.ReactNode;
}

export const CustomTextField: React.FC<CustomTextFieldProps> = ({
  name,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  ...rest
}) => {
  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    if (value) {
      setLocalValue(value);
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e); // Trigger Formik's blur handler
    onChange(e); // Sync local state with Formik state
  };

  return (
    <TextField
      {...rest}
      name={name}
      value={localValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
      helperText={helperText}
    />
  );
};

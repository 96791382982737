// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAccounts } from 'src/hooks/useAccounts';
import { GetAccountsApi } from 'src/services/accountApi';
import api from 'src/services/api';
import { brazilianStates } from 'src/utils/brazilianStates';

interface HookReturn {
  handleAddAccount: () => void;
  handleCloseModal: () => void;
  handleEdit: (account_id: string) => void;
  handleDelete: (account_id: string) => void;
  handleOpenDeleteAccountModal: (id: string) => void;
  handleDeleteAlertState: (id: boolean) => void;
  accounts: GetAccountsApi[];
  columnsToFilter: {
    columnId: string;
    title: string;
    options: {
      value: string;
      label: string;
    }[];
  }[];
  deleteAlertState: {
    isOpen: boolean;
    accountId: string;
  };
  openModal: boolean;
}

type objectWithLabelAndValue = { value: string; label: string; id: string };

export function useAccountList(): HookReturn {
  const history = useHistory();
  const { accounts, setRefreshAccount } = useAccounts();
  const [openModal, setOpenModal] = useState(false);
  const [deleteAlertState, setDeleteAlertState] = useState<{
    isOpen: boolean;
    accountId: string;
  }>({
    isOpen: false,
    accountId: '',
  });

  const handleOpenDeleteAccountModal = useCallback(
    (id: string) => {
      setDeleteAlertState({
        isOpen: true,
        accountId: id,
      });
    },
    [accounts],
  );

  const handleDeleteAlertState = (state: boolean) => {
    setDeleteAlertState(oldValue => ({
      ...oldValue,
      isOpen: state,
    }));
  };

  const handleAddAccount = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
    setRefreshAccount(oldValue => !oldValue);
  };

  const handleEdit = useCallback(
    (account_id: string) => {
      history.push(`/accounts/${account_id}`);
    },
    [history],
  );

  const handleDelete = (account_id: string) => {
    const deleteRequest = `/accounts/${account_id}`;
    api
      .delete(deleteRequest)
      .then(() => {
        toast.success(`Conta excluída com sucesso`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
        setRefreshAccount(oldValue => !oldValue);
      })
      .catch(error => {
        const errorMessage = error.response?.data.message || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      });
  };

  const { uniqueCities, uniqueDistricts } = accounts.reduce(
    (
      acc: {
        uniqueCities: objectWithLabelAndValue[];
        uniqueDistricts: objectWithLabelAndValue[];
      },
      currentAccount,
    ) => {
      if (!acc.uniqueCities.some(item => item.id === currentAccount.city)) {
        acc.uniqueCities.push({
          value: currentAccount.city as string,
          label: currentAccount.city as string,
          id: currentAccount.city as string,
        });
      }

      if (
        !acc.uniqueDistricts.some(item => item.id === currentAccount.district)
      ) {
        acc.uniqueDistricts.push({
          value: currentAccount.district as string,
          label: currentAccount.district as string,
          id: currentAccount.district as string,
        });
      }

      return acc;
    },
    { uniqueCities: [], uniqueDistricts: [] },
  );

  const columnsToFilter = [
    {
      columnId: 'Bairro',
      title: 'Bairro',
      options: uniqueDistricts,
    },
    {
      columnId: 'Cidade',
      title: 'Cidade',
      options: uniqueCities,
    },
    {
      columnId: 'Estado',
      title: 'Estado',
      options: brazilianStates,
    },
  ];

  return {
    accounts,
    handleAddAccount,
    handleCloseModal,
    handleEdit,
    handleDelete,
    handleOpenDeleteAccountModal,
    handleDeleteAlertState,
    columnsToFilter,
    openModal,
    deleteAlertState,
  } as HookReturn;
}

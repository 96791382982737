/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  Chip,
  Popper,
  TextField,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { predefinedStyles } from './predefinedStyles';
import { useCardDataContext } from '../../../../../context/CardDataContext';
import { IDefaultFieldProps } from '../../../Card.i';

export const checkBoxOutline = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => createStyles(predefinedStyles()));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PopperMy = (props: any) => {
  return (
    <Popper
      {...props}
      style={{ maxWidth: 'fit-content' }}
      placement="bottom-start"
    />
  );
};

export const PredefinedField: React.FC<IDefaultFieldProps> = ({
  customField,
  customFieldIndex,
  cardCustomFieldPhaseId,
  phaseIndex,
  handleFillTableValue,
  tableColumn,
  isInCurrentCardPhase,
}: IDefaultFieldProps) => {
  const classes = useStyles();
  const { cardCurrentPhase, handleFillCustomField } = useCardDataContext();
  const [inputValue, setInputValue] = useState<string[]>([]);

  useEffect(() => {
    if (customField.valueJSON && !tableColumn)
      setInputValue(customField.valueJSON);
    if (tableColumn && tableColumn.value && Array.isArray(tableColumn.value))
      setInputValue(tableColumn.value as string[]);
  }, [
    customField.valueJSON,
    tableColumn?.value,
    customField?.predefinedValues,
  ]);

  const handleInputChange = (value: string[]) => {
    setInputValue(value);
  };

  const handleBlur = () => {
    handleFillCustomField({
      phaseIndex,
      customFieldIndex,
      valueJSON: inputValue as any,
      customFieldId: customField.id,
    });
  };

  const handleBlurTableColumn = () => {
    if (handleFillTableValue && tableColumn)
      handleFillTableValue(
        customField.id,
        tableColumn.tableRowIndex,
        undefined,
        inputValue,
      );
  };

  if (handleFillTableValue && tableColumn) {
    return (
      <Autocomplete
        multiple
        className={classes.predefinedInput}
        disableClearable
        disableCloseOnSelect
        PopperComponent={PopperMy}
        disabled={customField.isInactive || customField.isDisabled}
        fullWidth
        options={customField.predefinedValues as string[]}
        getOptionDisabled={option =>
          !customField.predefinedActiveOptions.includes(option)
        }
        getOptionLabel={predefinedValues => predefinedValues as string}
        getOptionSelected={(predefinedValues, value) =>
          predefinedValues === value
        }
        value={inputValue}
        onChange={(e, value) => handleInputChange(value)}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={checkBoxOutline}
              checkedIcon={checkedIcon}
              className={classes.predefinedFieldOptionsCheckBox}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </>
        )}
        renderTags={(value: string[], getTagProps) => {
          const numTags = value.length;
          const limitTags = 1;

          return (
            <>
              {value
                .slice(0, limitTags)
                .map((option: string, index: number) => (
                  <Chip
                    {...getTagProps({ index })}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${index}${option}`}
                    label={option}
                    size="small"
                  />
                ))}

              {numTags > limitTags && ` +${numTags - limitTags}`}
            </>
          );
        }}
        loadingText="Carregando"
        renderInput={params => (
          <TextField
            {...params}
            name={customField.name}
            onBlur={handleBlurTableColumn}
            required={
              !!(
                inputValue.length === 0 &&
                customField.isRequired &&
                isInCurrentCardPhase
              )
            }
            disabled={customField.isInactive || customField.isDisabled}
            InputProps={{
              ...params.InputProps,
              // readOnly: true,
              disableUnderline: true,
              margin: 'none',
              style: {
                display: 'flex',
                flexWrap: 'nowrap',
                padding: 0,
                margin: 0,
                fontSize: '14px',
              },
            }}
          />
        )}
      />
    );
  }

  return (
    <Autocomplete
      key={customField.id}
      id={customField.name}
      disableClearable
      multiple
      disableCloseOnSelect
      limitTags={2}
      className={classes.predefinedFieldContent}
      options={customField?.predefinedValues || []}
      disabled={
        customField?.predefinedValues?.length === 0 ||
        customField.isInactive ||
        customField.isDisabled
      }
      getOptionDisabled={option =>
        !customField.predefinedActiveOptions.includes(option)
      }
      getOptionLabel={predefinedValues => predefinedValues}
      getOptionSelected={(predefinedValues, value) =>
        predefinedValues === value
      }
      value={inputValue}
      onChange={(e, value) => {
        handleInputChange(value);
      }}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={checkBoxOutline}
            checkedIcon={checkedIcon}
            className={classes.predefinedFieldOptionsCheckBox}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </>
      )}
      loadingText="Carregando"
      fullWidth
      renderInput={params => (
        <TextField
          {...params}
          autoComplete="off"
          required={
            !!(
              Object.values(customField?.valueJSON || {}).length === 0 &&
              customField.isRequired &&
              cardCurrentPhase?.id === cardCustomFieldPhaseId
            )
          }
          disabled={customField.isInactive || customField.isDisabled}
          name={customField.name}
          label={customField.name}
          margin="normal"
          InputProps={{
            ...params.InputProps,
          }}
          onBlur={() => handleBlur()}
        />
      )}
    />
  );
};

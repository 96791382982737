import React, { useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from 'src/components/ui/popover';
import { cn } from 'src/lib/utils';
import { CheckIcon, LucideIcon } from 'lucide-react';
import clsx from 'clsx';
import { Button } from '../ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../ui/command';

export interface OptionType {
  id: string;
  name: string;
  options?: {
    icon?: React.ComponentType<{ className?: string }> | LucideIcon;
    fillColor?: string;
    color?: string;
  };
}

interface SelectProps {
  readonly items: OptionType[];
  readonly selectedItems: OptionType[] | undefined;
  readonly setSelectedItems: (selectedItems: OptionType[]) => void;
  readonly maxItems?: number;
  readonly placeholder?: string;
  readonly disabled?: boolean;
  readonly closeOnSelect?: boolean;
  readonly btnClassName?: clsx.ClassValue;
  readonly disabledSearch?: boolean;
}

const Select: React.FC<SelectProps> = ({
  items,
  maxItems = 1,
  selectedItems,
  setSelectedItems,
  placeholder = 'Selecionar items',
  disabled = false,
  closeOnSelect = false,
  btnClassName = '',
  disabledSearch = false,
}) => {
  const [selectOpen, setSelectOpen] = useState(false);

  const handleSelectItem = (option: OptionType) => {
    const isSelected = selectedItems?.find(item => item.id === option.id);
    let newSelection: OptionType[] = [];
    if (isSelected) {
      newSelection = selectedItems?.filter(item => item.id !== option.id) || [];
    } else if (maxItems === 1) {
      newSelection = [option];
      if (closeOnSelect && option?.id) {
        setSelectOpen(false);
      }
    } else {
      if (selectedItems?.length === maxItems) {
        return;
      }
      newSelection = [...(selectedItems || []), option];
    }
    setSelectedItems(newSelection);
  };

  return (
    <Popover open={selectOpen} onOpenChange={setSelectOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn('align-center justify-center gap-1', btnClassName)}
          disabled={disabled}
        >
          {selectedItems?.length
            ? selectedItems.length === 1 && selectedItems[0]
              ? selectedItems[0].name
              : `${selectedItems.length} selecionados`
            : placeholder}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col w-full h-full gap-3 p-1">
        <Command>
          {!disabledSearch && <CommandInput placeholder="Buscar" />}
          <CommandList>
            <CommandEmpty>Sem resultados.</CommandEmpty>
            <CommandGroup>
              {items.map(option => {
                return (
                  <CommandItem
                    key={option.id}
                    onSelect={() => handleSelectItem(option)}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        selectedItems !== undefined &&
                          selectedItems.length > 0 &&
                          selectedItems?.find(item => item?.id === option.id)
                          ? 'bg-primary text-primary-foreground'
                          : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    {option.options && option.options?.icon && (
                      <option.options.icon
                        className="mr-2 h-5 w-5 text-muted-foreground"
                        fill={option?.options?.fillColor}
                        color={option?.options?.color}
                      />
                    )}
                    <span>{option.name}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default Select;

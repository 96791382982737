import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { dataSourceApi, DataSourceListType } from 'src/services/dataSourceApi';

interface HookReturn {
  dataSourceList: DataSourceListType[];
  loadingDataSources: boolean;
  setRefreshDataSources: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useDataSourceList(): HookReturn {
  const [dataSourceList, setDataSourceList] = useState<DataSourceListType[]>(
    [],
  );
  const [loadingDataSources, setLoadingDataSources] = useState<boolean>(false);
  const [refreshDataSources, setRefreshDataSources] = useState<boolean>(false);

  useEffect(() => {
    setLoadingDataSources(true);
    dataSourceApi
      .getDataSourceList()
      .then(
        (response: { data: { visionDataSources: DataSourceListType[] } }) => {
          const { visionDataSources } = response.data;
          setDataSourceList(visionDataSources);
        },
      )
      .catch(error => {
        const errMessage = error.response?.data?.message || error.message;
        toast.error(
          `Falha ao buscar lista de Mineração de Dados. ${errMessage || ''}`,
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          },
        );
      })
      .finally(() => {
        setLoadingDataSources(false);
      });
  }, [refreshDataSources]);

  return {
    dataSourceList,
    loadingDataSources,
    setRefreshDataSources,
  } as HookReturn;
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { cn } from 'src/lib/utils';
import { Checkbox } from '../ui/checkbox';

('use client');

interface CheckboxWithTextProps {
  id: string;
  labelText: string;
  infoText?: string;
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
}

export function CheckboxWithText({
  id,
  labelText,
  infoText,
  checked,
  onCheckedChange,
  className,
  disabled = false,
}: CheckboxWithTextProps) {
  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <Checkbox
        id={id}
        checked={checked}
        onCheckedChange={onCheckedChange}
        disabled={disabled}
      />
      <div className="grid gap-1.5 leading-none">
        <label
          htmlFor={id}
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {labelText}
        </label>
        {infoText && (
          <span className="text-sm text-muted-foreground">{infoText}</span>
        )}
      </div>
    </div>
  );
}

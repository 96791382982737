import api from './api';

export interface IUser {
  id: string;
  name: string;
  email: string;
  is_supervisor: boolean;
  business: { id: string; name: string };
  area: { id: string; name: string };
  emails: {
    id: string;
    email: string;
    isDefault: boolean;
    isAssociated: boolean;
  }[];
  profile: {
    id: string;
    name: string;
    category: {
      name: string;
      description: string;
    };
  };
}

export interface IUserAll {
  id: string;
  name: string;
  is_supervisor: boolean;
  area: {
    id: string;
    name: string;
  };
}

export const userApi = {
  getUsers: () => api.get<IUser[]>(`/users`),
  getUsersAll: () => api.get<IUserAll[]>(`/users/all`),
};

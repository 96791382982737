/* eslint-disable react-hooks/rules-of-hooks */
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { GridAlignment, GridCellParams } from '@material-ui/data-grid';
import { TableCell } from '@material-ui/core';
import React from 'react';
import { History } from 'history';
import { useAuth } from 'src/context/AuthContext';
import { ICustomField } from 'src/interface/ICardFields';
import { EllipsisOptionsDropdown } from '../../../components/EllipsisOptionsDropdown/EllipsisOptionsDropdown';
import api from '../../../services/api';
import { TaskWithPhase } from '.';
import { IAreaWithPhases } from '../ListAreasAndPhases';
import { IPhase } from '../../Phase/Phase.i';

export const fieldsTableColumns = (
  setAreas: (areas: IAreaWithPhases[]) => void,
  selectedPhases: IPhase[],
  setSelectedPhases: (phases: React.SetStateAction<IPhase[]>) => void,
  history: History,
  areaId: string,
  handleEditField: (
    phase_id: string,
    customField_id: string,
    history: History,
    areaId: string,
  ) => void,
) => {
  return [
    {
      field: 'name',
      headerName: 'Nome',
      width: 250,
      renderCell: (params: GridCellParams) => {
        return <TableCell>{params.row.name}</TableCell>;
      },
    },
    {
      field: 'phase',
      headerName: 'Fase',
      align: 'left' as GridAlignment,
      width: 130,
      renderCell: (params: GridCellParams) => {
        return (
          <TableCell padding="none" className="truncate" style={{ border: 0 }}>
            {params.row.phase.name}
          </TableCell>
        );
      },
    },
    {
      field: 'order',
      align: 'left' as GridAlignment,
      filterable: false,
      headerName: 'Ordem',
      width: 120,
      renderCell: (params: GridCellParams) => {
        return (
          <TableCell>
            {params.row.phaseOrder === -1
              ? `T.${params.row.tableOrder}`
              : params.row.phaseOrder}
          </TableCell>
        );
      },
    },
    {
      field: 'options',
      align: 'center' as GridAlignment,
      sortable: false,
      filterable: false,
      headerName: 'Opções',
      width: 50,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: any) => {
        const { user } = useAuth();
        const userCantEditArea = user?.permissions.AREA !== 'READ_AND_WRITE';

        return (
          <TableCell>
            <EllipsisOptionsDropdown
              refreshDataAfterAction={() => {
                api.get('/areas').then(response => setAreas(response.data));
              }}
              options={{
                'Editar campo': () => {
                  handleEditField(
                    params.row.phase.id,
                    params.id,
                    history,
                    areaId,
                  );
                },
                ...(userCantEditArea
                  ? {}
                  : {
                      'Mover para cima': () => {
                        handleChangeCustomFieldUp(params, setAreas);
                      },
                      'Mover para baixo': () => {
                        handleChangeCustomFieldDown(params, setAreas);
                      },
                      'Excluir campo': () => {
                        handleDeleteField(
                          params.row.phase.id,
                          params.id,
                          selectedPhases,
                          setSelectedPhases,
                          setAreas,
                        );
                      },
                    }),
              }}
            />
          </TableCell>
        );
      },
    },
  ];
};

export const tasksTableColumns = (
  setAreas: (areas: IAreaWithPhases[]) => void,
  selectedPhases: IPhase[],
  setSelectedPhases: (phases: React.SetStateAction<IPhase[]>) => void,
  tasks: Task[],
  history: History,
  areaId: string,
  handleEditTask: (
    phase_id: string,
    task_id: string,
    history: History,
    areaId: string,
  ) => void,
) => {
  return [
    {
      field: 'name',
      headerName: 'Nome',
      width: 250,
      renderCell: (params: GridCellParams) => {
        return <TableCell>{params.row.name}</TableCell>;
      },
    },
    {
      field: 'phase',
      headerName: 'Fase',
      align: 'left' as GridAlignment,
      width: 130,
      renderCell: (params: GridCellParams) => {
        return (
          <TableCell
            padding="none"
            size="small"
            variant="body"
            style={{
              border: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {params.row.phase.name}
          </TableCell>
        );
      },
    },
    {
      field: 'order',
      align: 'left' as GridAlignment,
      filterable: false,
      headerName: 'Ordem',
      width: 120,
      renderCell: (params: GridCellParams) => {
        return <TableCell>{params.row.order}</TableCell>;
      },
    },
    {
      field: 'options',
      align: 'center' as GridAlignment,
      sortable: false,
      filterable: false,
      headerName: 'Opções',
      width: 50,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: any) => {
        const { user } = useAuth();
        const userCantEditArea = user?.permissions.AREA !== 'READ_AND_WRITE';

        return (
          <TableCell>
            <EllipsisOptionsDropdown
              refreshDataAfterAction={() => {
                api.get('/areas').then(response => setAreas(response.data));
              }}
              options={{
                'Editar tarefa': () => {
                  handleEditTask(
                    params.row.phase.id,
                    params.id,
                    history,
                    areaId,
                  );
                },
                ...(userCantEditArea
                  ? {}
                  : {
                      'Mover para cima': () => {
                        handleChangeTaskUp(params.row, tasks, setAreas);
                      },
                      'Mover para baixo': () => {
                        handleChangeTaskDown(params.row, tasks, setAreas);
                      },
                      'Excluir tarefa': () => {
                        handleDeleteTask(
                          params.row.phase.id,
                          params.id,
                          selectedPhases,
                          setSelectedPhases,
                          setAreas,
                        );
                      },
                    }),
              }}
            />
          </TableCell>
        );
      },
    },
  ];
};

const handleRefreshArea = (setAreas: (areas: IAreaWithPhases[]) => void) => {
  api.get('/areas').then(response => setAreas(response.data));
};

const handleDeleteField = (
  phase_id: string,
  customField_id: string,
  selectedPhases: IPhase[],
  setSelectedPhases: (phases: React.SetStateAction<IPhase[]>) => void,
  setAreas: (areas: IAreaWithPhases[]) => void,
) => {
  Swal.fire({
    title: 'Deseja excluir?',
    text: 'Essa opção não poderá ser revertida',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sim!',
    cancelButtonText: 'Não',
  }).then(result => {
    if (result.isConfirmed) {
      api
        .delete(`/customFields/${customField_id}`)
        .then(() => {
          if (selectedPhases && selectedPhases.length > 0) {
            setSelectedPhases(oldSelectedPhases =>
              oldSelectedPhases.map(phase =>
                phase.id === phase_id
                  ? {
                      ...phase,
                      customFields: phase.customFields.filter(
                        field => field.id !== customField_id,
                      ),
                    }
                  : phase,
              ),
            );
          } else {
            handleRefreshArea(setAreas);
          }
        })
        .catch(err => {
          toast.error(`Erro ao deletar campo: ${err.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        });
    }
  });
};

const handleDeleteTask = (
  phase_id: string,
  task_id: string,
  selectedPhases: IPhase[],
  setSelectedPhases: (phases: React.SetStateAction<IPhase[]>) => void,
  setAreas: (areas: IAreaWithPhases[]) => void,
) => {
  Swal.fire({
    title: 'Deseja excluir?',
    text: 'Essa opção não poderá ser revertida',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sim!',
    cancelButtonText: 'Não',
  }).then(result => {
    if (result.isConfirmed) {
      api
        .delete(`/cardTasks/${task_id}`)
        .then(() => {
          if (selectedPhases && selectedPhases.length > 0) {
            setSelectedPhases(oldSelectedPhases =>
              oldSelectedPhases.map(phase =>
                phase.id === phase_id
                  ? {
                      ...phase,
                      tasks: phase.cardTasks.filter(
                        task => task.id !== task_id,
                      ),
                    }
                  : phase,
              ),
            );
          } else {
            handleRefreshArea(setAreas);
          }
        })
        .catch(err => {
          toast.error(`Erro ao deletar tarefa: ${err.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        });
    }
  });
};

interface Task {
  phase: {
    id: string;
    name: string;
  };
  id: string;
  name: string;
  description: string;
  deadline: number;
  isInactive: boolean;
  isRequired: boolean;
  weight: number;
  order: number;
  instructions: string;
}

const handleChangeTaskUp = (
  taskSelected: TaskWithPhase,
  tasks: Task[],
  setAreas: (areas: IAreaWithPhases[]) => void,
) => {
  const findTask = tasks.find(task => task.order < taskSelected.order);

  if (findTask) {
    api
      .patch(`/cardTasks/${taskSelected.id}/up`)
      .then(() => handleRefreshArea(setAreas));
  }
};

const handleChangeTaskDown = (
  taskSelected: TaskWithPhase,
  tasks: Task[],
  setAreas: (areas: IAreaWithPhases[]) => void,
) => {
  const findTask = tasks.find(task => task.order > taskSelected.order);

  if (findTask) {
    api
      .patch(`/cardTasks/${taskSelected.id}/down`)
      .then(() => handleRefreshArea(setAreas));
  }
};

const handleChangeCustomFieldUp = (
  customFieldSelected: ICustomField,
  setAreas: (areas: IAreaWithPhases[]) => void,
) => {
  api
    .patch(`/customFields/${customFieldSelected.id}/up`)
    .then(() => handleRefreshArea(setAreas))
    .catch(error => {
      const errorMessage = error.response?.data?.message || 'An error occurred';
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        autoClose: 5000,
      });
    });
};

const handleChangeCustomFieldDown = (
  customFieldSelected: ICustomField,
  setAreas: (areas: IAreaWithPhases[]) => void,
) => {
  api
    .patch(`/customFields/${customFieldSelected.id}/down`)
    .then(() => handleRefreshArea(setAreas))
    .catch(error => {
      const errorMessage = error.response?.data?.message || 'An error occurred';
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        autoClose: 5000,
      });
    });
};

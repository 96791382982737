/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { ColumnDef } from '@tanstack/react-table';
import { returnColorByTaskStatus } from 'src/utils/taskUtils';
import { Button } from 'src/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { LucideIcon, MoreHorizontalIcon } from 'lucide-react';
import { useTaskModalContext } from 'src/context/TaskModalContext';
import { DataTableColumnHeader } from 'src/components/ui/data-table-column-header';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { statuses } from 'src/utils/taskFilterColumnsUtils';
import { useAuth } from 'src/context/AuthContext';
import { ICardTask, ISingleTask } from 'src/interface/ICardData';

export const columns: ColumnDef<ICardTask | ISingleTask>[] = [
  {
    accessorKey: 'origin',
    id: 'Origem',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Origem"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
        className="justify-center"
      />
    ),
    cell: ({ row }) => {
      const formatted = row.original.origin;
      return (
        <TooltipWrapper
          value={row.original.origin === 'P' ? 'Processo' : 'Avulsa'}
          className="font-medium text-xs"
        >
          <div className="text-center text-nowrap">{formatted}</div>
        </TooltipWrapper>
      );
    },
  },
  {
    accessorKey: 'startsAt',
    id: 'Inicio',
    enableGlobalFilter: true,
    filterFn: 'dateBetweenFilterFn',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Inicio Agenda"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
        // className="justify-left"
      />
    ),
    cell: ({ row }) => {
      const value: string | Date = row.original.startsAt || '';
      return <div className="text-left text-nowrap p-1">{value}</div>;
    },
  },
  {
    accessorKey: 'endsAt',
    id: 'Fim',
    enableGlobalFilter: true,
    filterFn: 'dateBetweenFilterFn',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Fim Agenda"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string | Date = row.original.endsAt || '';
      return <div className="text-left text-nowrap p-1">{value}</div>;
    },
  },
  {
    accessorKey: 'name',
    id: 'Nome',
    enableGlobalFilter: true,
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Nome"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original.name;
      return (
        <TooltipWrapper value={value} className="font-medium text-xs">
          <div className="text-left text-nowrap max-w-[130px] truncate">
            {value}
          </div>
        </TooltipWrapper>
      );
    },
  },
  {
    accessorKey: 'phase.name',
    id: 'Fase',
    enableGlobalFilter: true,
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Fase"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const formatted = row.original.phase.name;
      return (
        <TooltipWrapper value={formatted} className="font-medium text-xs">
          <div className="text-left text-nowrap max-w-[100px] truncate">
            {formatted}
          </div>
        </TooltipWrapper>
      );
    },
  },
  {
    accessorKey: 'type',
    id: 'Tipo',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const typeValue = row.original.type;
      return value.includes(typeValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Tipo"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const formatted = row.original.type;
      return (
        <div className="text-left text-nowrap max-w-[100px] truncate">
          {formatted}
        </div>
      );
    },
  },
  {
    accessorKey: 'user.name',
    id: 'Usuário',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const rowFormattedValue = row.original.user.name;
      return value.includes(rowFormattedValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Usuário"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original.user.name;
      return (
        <div className="text-left text-nowrap max-w-[100px] truncate">
          {value}
        </div>
      );
    },
  },
  {
    accessorKey: 'dateDeadlineFormat',
    id: 'Prazo',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Prazo"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const formatted =
        'dateDeadlineFormat' in row.original
          ? row.original?.dateDeadlineFormat
          : '';
      return <div className="text-left text-nowrap">{formatted}</div>;
    },
  },
  {
    accessorKey: 'status',
    id: 'Situação',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title=""
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original.status;
      const statusIcon: LucideIcon | undefined = statuses.find(
        st => st.value === value,
      )?.icon;
      const statusColor = returnColorByTaskStatus(value);

      if (!statusIcon) {
        return null;
      }

      const IconComponent = statusIcon as React.FC<
        React.SVGProps<SVGSVGElement>
      >;

      return (
        <TooltipWrapper value={value} className="font-medium text-xs">
          <IconComponent
            className="h-[22px] w-[22px] ml-0.5 text-muted-foreground"
            fill={statusColor}
            strokeWidth={1.8}
            color="#fff"
          />
        </TooltipWrapper>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      const { handleOpenModal, handleDeleteTask } = useTaskModalContext();
      const { user } = useAuth();
      const editPermissionDisabledForUser =
        row.original.user.id !== user.id &&
        user?.profile.categoryName !== 'ADMINISTRATOR';
      const task = row.original;
      const handleDeleteTaskClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        event.stopPropagation();
        handleDeleteTask(task.id);
      };

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontalIcon className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Ações</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="hover:bg-gray-200 cursor-pointer"
              onClick={() => handleOpenModal(task.id)}
            >
              Alterar
            </DropdownMenuItem>
            <DropdownMenuItem
              className="hover:bg-gray-200 cursor-pointer"
              onClick={e => handleDeleteTaskClick(e)}
              disabled={
                task.isProcessTask ||
                (!task.isProcessTask && task.status === 'Concluída') ||
                editPermissionDisabledForUser
              }
            >
              Excluir
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];

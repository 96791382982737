import React from 'react';
import {
  Dialog,
  DialogOverlay,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import { AlertCircle } from 'lucide-react';

interface ConfirmDialogIAProps {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
  hideCloseButton?: boolean;
}

const ConfirmDialogIA: React.FC<ConfirmDialogIAProps> = ({
  title,
  message,
  onConfirm,
  onCancel,
  confirmText = 'Confirmar',
  cancelText = 'Cancelar',
  hideCloseButton = false,
}) => {
  return (
    <Dialog open>
      <DialogOverlay className="z-[1600]" />
      <DialogContent
        hideCloseButton={hideCloseButton}
        className="z-[1700] max-w-md p-6"
        onInteractOutside={event => event.preventDefault()}
        onEscapeKeyDown={event => event.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold flex items-center">
            <h1 className="mr-2">{title}</h1>
            <AlertCircle className="text-red-500" />
          </DialogTitle>
        </DialogHeader>
        <div className="mt-4">
          <p>{message}</p>
        </div>
        <DialogFooter className="flex justify-end mt-4">
          {onCancel && (
            <Button
              onClick={onCancel}
              className="bg-gray-300 text-black hover:bg-gray-400"
            >
              {cancelText}
            </Button>
          )}
          <Button
            onClick={onConfirm}
            className="ml-2 text-white hover:bg-blue-900"
          >
            {confirmText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialogIA;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { accountApi, GetAccountsApi } from 'src/services/accountApi';

interface HookReturn {
  accounts: GetAccountsApi[];
  setAccounts: React.Dispatch<React.SetStateAction<GetAccountsApi[]>>;
  loadingAccounts: boolean;
  setRefreshAccount: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useAccounts(): HookReturn {
  const [accounts, setAccounts] = useState<GetAccountsApi[]>([]);
  const [loadingAccounts, setLoadingAccounts] = useState<boolean>(false);
  const [refreshAccount, setRefreshAccount] = useState<boolean>(false);

  useEffect(() => {
    setLoadingAccounts(true);
    accountApi
      .getAccounts()
      .then((response: { data: GetAccountsApi[] }) => {
        const sorted =
          response.data?.length > 0
            ? response.data.sort(
                (a: GetAccountsApi, b: GetAccountsApi) =>
                  -b.name.localeCompare(a.name),
              )
            : [];
        setAccounts(sorted);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        const errMessage = error.response?.data?.message || error.message;
        toast.error(`Falha ao buscar lista de contas. ${errMessage || ''}`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      })
      .finally(() => {
        setLoadingAccounts(false);
      });
  }, [refreshAccount]);

  return {
    accounts,
    setAccounts,
    loadingAccounts,
    setRefreshAccount,
  } as HookReturn;
}

import React from 'react';
import {
  PermissionProperties,
  PermissionsByCategories,
  PermissionValues,
  ProfileCategory,
} from 'src/services/permissionsApi';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { Checkbox } from 'src/components/ui/checkbox';
import { cn } from 'src/lib/utils';

export interface ProfilePermissionsProps {
  permissionProperties: PermissionProperties;
  permissionsByCategories: PermissionsByCategories;
  customPermissions?: {
    name: string;
    status: PermissionValues;
  }[];
  activePermissions: {
    name: string;
    status: PermissionValues;
  }[];
  currentCategory: ProfileCategory;
  handleChangePermission: (
    permissionName: string,
    status: PermissionValues,
    isExecutableOnly: boolean,
  ) => void;
  disableEdit?: boolean;
  className?: string;
}

export const ProfilePermissionTable: React.FC<ProfilePermissionsProps> = ({
  permissionProperties,
  permissionsByCategories,
  activePermissions,
  currentCategory,
  handleChangePermission,
  customPermissions = [],
  disableEdit = false,
  className = '',
}) => {
  return (
    <div
      className={cn(
        'flex flex-wrap w-full overflow-auto border-2 box-border rounded-md',
        className,
      )}
    >
      <Table className="w-full overflow-clip">
        <TableHeader className="z-10">
          <TableRow>
            <TableCell align="left" className="p-2 font-medium">
              Função
            </TableCell>
            <TableCell align="center" className="p-2 font-medium">
              Visualiza
            </TableCell>
            <TableCell align="center" className="p-2 font-medium">
              Edita/Executa
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {permissionsByCategories[currentCategory] &&
            Object.entries(permissionsByCategories[currentCategory]).map(
              ([permissionKey, permissionByCategory]) => {
                const activePermission =
                  customPermissions.find(
                    permission => permission.name === permissionKey,
                  )?.status ||
                  activePermissions.find(
                    permission => permission.name === permissionKey,
                  )?.status;
                const { isExecutableOnly, isReadOnly } =
                  permissionProperties[permissionKey];

                return (
                  <TableRow key={permissionKey}>
                    <TableCell align="left" className="p-2">
                      {permissionProperties[permissionKey].description}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      <Checkbox
                        id={`${permissionKey}-READ_ONLY`}
                        disabled={
                          isExecutableOnly ||
                          disableEdit ||
                          permissionByCategory === 'DISABLED' ||
                          permissionByCategory === 'TOTAL'
                        }
                        checked={
                          (activePermission === 'READ_ONLY' &&
                            !isExecutableOnly) ||
                          activePermission === 'TOTAL' ||
                          activePermission === 'READ_AND_WRITE'
                        }
                        onCheckedChange={checked => {
                          handleChangePermission(
                            permissionKey,
                            checked && !isExecutableOnly
                              ? 'READ_ONLY'
                              : 'DISABLED',
                            isExecutableOnly,
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      <Checkbox
                        id={`${permissionKey}-READ_AND_WRITE`}
                        disabled={
                          isReadOnly ||
                          disableEdit ||
                          permissionByCategory === 'DISABLED' ||
                          permissionByCategory === 'TOTAL' ||
                          permissionByCategory === 'READ_ONLY'
                        }
                        checked={
                          activePermission === 'TOTAL' ||
                          activePermission === 'READ_AND_WRITE'
                        }
                        onCheckedChange={checked => {
                          handleChangePermission(
                            permissionKey,
                            checked
                              ? 'READ_AND_WRITE'
                              : (activePermission === 'TOTAL' ||
                                  activePermission === 'READ_AND_WRITE') &&
                                !checked &&
                                !isExecutableOnly
                              ? 'READ_ONLY'
                              : 'DISABLED',
                            isExecutableOnly,
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              },
            )}
        </TableBody>
      </Table>
    </div>
  );
};

import React from 'react';

import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';
import { IntegratedItemFormat } from 'src/components/ModalIntegratedCustomFields';
import { ICustomField } from 'src/interface/ICardFields';
import { integratedStyles } from './integratedStyle';

const useStyles = makeStyles(() => createStyles(integratedStyles()));

type IntegratedInputProps = {
  customField: ICustomField;
  handleOpenModal: (
    cardField: ICustomField,
    table?: {
      id: string;
      tableRowIndex: number;
    },
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleFillIntegratedField?: (value: any, fieldId?: string) => void;
  handleFillTableValue?: (
    fieldId: string,
    tableRowIndex: number,
    value: string | string[] | null | object | object[],
    valueProperty: 'value' | 'valueJSON',
  ) => void;
  isDisabled: boolean;
  isRequired: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedIntegratedItem: Record<string, any>;
  table?: {
    id: string;
    tableRowIndex: number;
  };
};

export const IntegratedField = ({
  customField,
  handleOpenModal,
  handleFillIntegratedField = () => {},
  isDisabled,
  isRequired,
  table,
  handleFillTableValue,
  selectedIntegratedItem,
}: IntegratedInputProps) => {
  const classes = useStyles();
  const { primaryKeyColumnName } = customField;
  const { displayColumnName } = customField;
  const hasFormat =
    customField?.format !== null && customField?.format !== undefined;
  const hasDynamicFormat =
    customField?.dynamicFormat !== null &&
    customField?.dynamicFormat !== undefined;
  const currentFormat = hasFormat
    ? customField?.format
    : customField?.dynamicFormat;
  const primaryKeyDisplayName: string = hasDynamicFormat
    ? (currentFormat?.[primaryKeyColumnName] as IntegratedItemFormat)?.name ||
      ''
    : primaryKeyColumnName;
  const formattedDisplayName: string = hasDynamicFormat
    ? (currentFormat?.[displayColumnName] as IntegratedItemFormat)?.name || ''
    : displayColumnName;
  const isTableColumn = table !== undefined;

  return (
    <Box className={!isTableColumn ? classes.outerDiv : classes.tableOuterDiv}>
      <Box className={!isTableColumn ? classes.integratedDataDiv : undefined}>
        {selectedIntegratedItem &&
        Object.keys(selectedIntegratedItem).length !== 0 ? (
          <Box>
            {!isTableColumn && (
              <Box className={classes.integratedLabel}>{customField.name}</Box>
            )}
            <Typography
              component="span"
              className={
                !isTableColumn
                  ? classes.integratedFieldPrimaryKey
                  : classes.tablePrimaryKey
              }
            >
              {selectedIntegratedItem[primaryKeyDisplayName]}
            </Typography>
            <Typography
              align="left"
              component="span"
              noWrap
              className={
                !isTableColumn
                  ? classes.integratedFieldDisplayColumnName
                  : classes.tableDisplayColumnName
              }
            >
              {`|
                ${selectedIntegratedItem[formattedDisplayName]}`}
            </Typography>
          </Box>
        ) : (
          <TextField
            className={classes.integratedFieldDummyTextField}
            contentEditable={false}
            label={isTableColumn ? undefined : customField.name}
            autoComplete="off"
            disabled={isDisabled}
            required={isRequired}
            InputLabelProps={{ shrink: false, style: { width: '100%' } }}
            InputProps={{
              disableUnderline: true,
              onKeyDown: (e: { preventDefault: () => void }) => {
                e.preventDefault();
                return false;
              },
            }}
          />
        )}
      </Box>
      <Box className={classes.integratedFieldButtons}>
        {selectedIntegratedItem &&
          Object.keys(selectedIntegratedItem).length !== 0 && (
            <IconButton
              aria-label="Excluir"
              size="small"
              className={
                isTableColumn ? undefined : classes.typeIntegratedButton
              }
              disabled={isDisabled}
              onClick={() => {
                if (
                  handleFillTableValue &&
                  table?.tableRowIndex !== undefined
                ) {
                  handleFillTableValue(
                    customField.id,
                    table?.tableRowIndex,
                    {},
                    'valueJSON',
                  );
                } else {
                  handleFillIntegratedField({}, customField.id);
                }
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          )}
        <IconButton
          aria-label="Procurar campo integrado"
          size="small"
          className={isTableColumn ? undefined : classes.typeIntegratedButton}
          disabled={isDisabled}
          onClick={() => handleOpenModal(customField, table)}
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ConditionsExpression } from 'src/components/Conditions/Condition.i';
import { IPhaseCards } from 'src/interface/IPhaseCards';
import { toast } from 'react-toastify';
import api from '../services/api';
import { useAuth } from './AuthContext';

interface IFilter {
  area_id?: string;
  account_ids?: string[];
  contact_ids?: string[];
  user_ids?: string[];
  card_key?: string;
  refreshFilter?: boolean;
  filterTotalizer?: boolean;
  conditionsExpressionInput?: ConditionsExpression;
  filters?: any[];
}

interface FilterContextData {
  handleFilters(filterData: IFilter): Promise<void>;
  phases: IPhaseCards[];
  filterArea: string;
  storedAccountIds: string[];
  storedContactIds: string[];
  storedUserIds: string[];
  storedCardKey: string;
  filterTotalizer: boolean;
  storedConditionsExpressionInput: ConditionsExpression;
  setStoredConditionsExpressionInput: React.Dispatch<
    React.SetStateAction<ConditionsExpression>
  >;
}

const FilterContext = createContext<FilterContextData>({} as FilterContextData);

const FilterProvider: React.FC = ({ children }) => {
  const [phases, setPhases] = useState<IPhaseCards[]>([]);
  const [storedAreaId, setStoredAreaId] = useState(() => {
    return localStorage.getItem('@Brasao:area') || '';
  });
  const [storedAccountIds, setStoredAccountIds] = useState<string[]>([]);
  const [storedContactIds, setStoredContactIds] = useState<string[]>([]);
  const [storedUserIds, setStoredUserIds] = useState<string[]>([]);
  const [storedCardKey, setStoredCardKey] = useState('');
  const [filterTotalizer, setFilterTotalizer] = useState(false);
  const [storedConditionsExpressionInput, setStoredConditionsExpressionInput] =
    useState<ConditionsExpression>([[{ name: '', type: '' }]]);
  const [storedFilters, setStoredFilters] = useState<any[]>([]);
  const { user } = useAuth();

  const handleFilters = useCallback(
    async ({
      area_id,
      account_ids = [],
      contact_ids = [],
      user_ids = [],
      card_key = '',
      filters,
      refreshFilter,
      conditionsExpressionInput = [[{ name: '', type: '' }]],
    }: IFilter) => {
      const areaIdToUse = area_id || storedAreaId || user.areaId;

      const appliedFilters = filters !== undefined ? filters : storedFilters;

      let accountsToInclude: string[] = [];
      let contactsToInclude: string[] = [];
      let usersToInclude: string[] = [];
      let cardToInclude = '';
      let fieldsConditionsToFilter: string[] = [];

      if (!refreshFilter) {
        setStoredAreaId(areaIdToUse);
        setStoredAccountIds(account_ids);
        setStoredContactIds(contact_ids);
        setStoredUserIds(user_ids);
        setStoredCardKey(card_key);
        setStoredConditionsExpressionInput(conditionsExpressionInput);
        setStoredFilters(filters || []);

        accountsToInclude = account_ids;
        contactsToInclude = contact_ids;
        usersToInclude = user_ids;
        cardToInclude = card_key;
        fieldsConditionsToFilter = filters || [];
      } else {
        accountsToInclude = storedAccountIds;
        contactsToInclude = storedContactIds;
        usersToInclude = storedUserIds;
        cardToInclude = storedCardKey;
        fieldsConditionsToFilter = storedFilters;
      }

      try {
        const response = await api.post('/kanbanBoard', {
          area_id: areaIdToUse,
          filters: fieldsConditionsToFilter,
        });

        const phasesData: IPhaseCards[] = response.data.map(
          (phase: IPhaseCards) => {
            return {
              ...phase,
              cards: phase.cards.filter(card => {
                let shouldIncludeCard = true;

                if (cardToInclude.length > 0) {
                  shouldIncludeCard =
                    shouldIncludeCard &&
                    cardToInclude === card.keyCard.toString();
                  return shouldIncludeCard;
                }
                if (accountsToInclude.length > 0) {
                  shouldIncludeCard =
                    shouldIncludeCard &&
                    accountsToInclude.includes(card.account.id);
                }
                if (contactsToInclude.length > 0) {
                  shouldIncludeCard =
                    shouldIncludeCard &&
                    contactsToInclude.includes(card.account.contact.id);
                }
                if (usersToInclude.length > 0) {
                  shouldIncludeCard =
                    shouldIncludeCard && usersToInclude.includes(card.user.id);
                }
                return shouldIncludeCard;
              }),
            };
          },
        );

        const cardIds = phasesData.reduce((ids: string[], phase: any) => {
          const cardIdsInPhase = phase.cards.map((card: any) => card.id);
          return ids.concat(cardIdsInPhase);
        }, []);

        if (cardIds.length > 0) {
          const fieldValuesResponse = await api.post('/cards/fieldValues', {
            card_ids: cardIds,
          });

          const cardsWithFieldValues = fieldValuesResponse.data;
          const fieldValuesMap: { [key: string]: any } = {};
          cardsWithFieldValues.forEach((card: any) => {
            fieldValuesMap[card.id] = card.fieldValues;
          });

          const updatedPhases = phasesData.map((phase: any) => {
            const updatedCards = phase.cards.map((card: any) => ({
              ...card,
              fieldValues: fieldValuesMap[card.id] || [],
            }));
            return { ...phase, cards: updatedCards };
          });

          setPhases(updatedPhases);
        } else {
          setPhases(phasesData);
        }

        setFilterTotalizer(appliedFilters.length > 0);
      } catch (error: any) {
        const errorMessage =
          error?.response?.data?.message ||
          error?.message ||
          'Erro desconhecido.';
        toast.error(`Erro ao buscar dados: ${errorMessage}`, {
          autoClose: 5000,
          closeOnClick: true,
          theme: 'colored',
        });
      }
    },
    [
      storedAccountIds,
      storedContactIds,
      storedUserIds,
      storedCardKey,
      storedAreaId,
      storedFilters,
      user.areaId,
    ],
  );

  useEffect(() => {
    if (storedAreaId) {
      localStorage.setItem('@Brasao:area', storedAreaId);
    }
  }, [storedAreaId]);

  return (
    <FilterContext.Provider
      value={{
        handleFilters,
        phases,
        filterArea: storedAreaId,
        storedAccountIds,
        filterTotalizer,
        storedContactIds,
        storedUserIds,
        storedCardKey,
        storedConditionsExpressionInput,
        setStoredConditionsExpressionInput,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

function useFilters(): FilterContextData {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilters must be used within a FilterProvider');
  }
  return context;
}

export { FilterProvider, useFilters };

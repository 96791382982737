/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import { FilterIcon, Loader2 } from 'lucide-react';
import { useWorkspaceFilters } from 'src/context/WorkspaceFiltersContext';
import Select, { OptionType } from 'src/components/Select';
import { DateRange } from 'react-day-picker';
import { DatePickerWithRange } from 'src/components/DateRangePicker';
import { Input } from 'src/components/ui/input';
import { Button } from '../../../components/ui/button';
import { useAuth } from '../../../context/AuthContext';

interface FilterButtonProps {
  readonly users?: { id: string; name: string }[];
  readonly phaseOptions: {
    areaId: string;
    phases: { id: string; name: string }[];
  }[];
  readonly accountOptions?: { id: string; name: string }[];
}

const SituationOptions = [
  { id: 'active', name: 'Ativo' },
  { id: 'suspended', name: 'Suspenso' },
  { id: 'finished', name: 'Finalizado' },
];

export const CardsFiltersButton: React.FC<FilterButtonProps> = ({
  users,
  phaseOptions,
  accountOptions,
}) => {
  const {
    filtersData,
    setFiltersData,
    areas,
    setPhaseIds,
    setAccountIds,
    loading,
    keycard,
    setKeycard,
  } = useWorkspaceFilters();
  const { user } = useAuth();
  const [selectedUser, setSelectedUser] = useState<OptionType[]>([user]);
  const [open, setOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const [selectedAreas, setSelectedAreas] = useState<OptionType[]>(
    areas?.filter(area => area.id === user.areaId),
  );
  const [selectedSituations, setSelectedSituations] = useState<OptionType[]>([
    SituationOptions[0],
  ]);
  const [selectedPhases, setSelectedPhases] = useState<OptionType[]>();
  const [selectedAccounts, setSelectedAccounts] = useState<OptionType[]>();

  const handleApplyFilter = () => {
    setFiltersData({
      ...filtersData,
      cards: {
        startDate: dateRange?.from?.toISOString() || undefined,
        endDate: dateRange?.to?.toISOString() || undefined,
        user_id: selectedUser?.[0].id || user.id || undefined,
        area_ids:
          selectedAreas && selectedAreas.length > 0
            ? selectedAreas.map(area => area.id)
            : areas
                ?.filter(area => area.id === user.areaId)
                ?.map(area => area.id),
        situations: selectedSituations?.map(situation => situation.id) || [
          'active',
        ],
      },
    });
    setPhaseIds(selectedPhases?.map(phase => phase.id) || []);
    setAccountIds(selectedAccounts?.map(account => account.id) || []);
  };

  const handleClearFilter = () => {
    setFiltersData({
      ...filtersData,
      cards: {},
    });
    setDateRange(undefined);
    setSelectedUser([user]);
    setSelectedAreas([]);
    setSelectedSituations([SituationOptions[0]]);
    setSelectedPhases([]);
    setSelectedAccounts([]);
    setKeycard('');
    setPhaseIds([]);
    setAccountIds([]);
    setOpen(false);
  };

  useEffect(() => {
    if (filtersData && filtersData.cards) {
      if (filtersData.cards?.startDate && filtersData.cards?.endDate) {
        setDateRange({
          from: new Date(filtersData.cards.startDate),
          to: new Date(filtersData.cards.endDate),
        });
      }

      if ((filtersData.cards?.user_id !== undefined || user.id) && users) {
        const newUser =
          filtersData.cards?.user_id !== undefined
            ? users.find(user => user.id === filtersData.cards?.user_id)
            : users.find(option => option.id === user.id);
        setSelectedUser(newUser ? [newUser] : []);
      }

      if (areas && (filtersData.cards?.area_ids !== undefined || user.areaId)) {
        const selectedAreaOptions =
          filtersData.cards?.area_ids !== undefined &&
          filtersData.cards?.area_ids.length > 0
            ? areas.filter(area =>
                filtersData.cards?.area_ids?.includes(area.id),
              )
            : areas.filter(area => area.id === user.areaId);
        setSelectedAreas(selectedAreaOptions);
      }

      if (
        filtersData.cards?.situations &&
        filtersData.cards?.situations !== undefined
      ) {
        const selectSituationOptions = filtersData.cards?.situations.map(
          situation =>
            SituationOptions.find(
              situationOption => situationOption.id === situation,
            ) || { id: 'active', name: 'Ativo' },
        );
        setSelectedSituations(selectSituationOptions);
      }
    }
  }, [filtersData.cards, users, areas]);

  const filteredPhases = useMemo(() => {
    const filteredAreaIds =
      selectedAreas.map(area => area.id) || filtersData.cards?.area_ids || [];

    const userDefaultAreaPhases = phaseOptions
      ?.filter(phase => {
        return user.areaId.includes(phase.areaId);
      })
      .map(phase => phase.phases)
      .flat();

    const filterPhasesByAreaIds =
      filteredAreaIds.length > 0
        ? phaseOptions
            ?.filter(phase => {
              return filteredAreaIds?.includes(phase.areaId);
            })
            .map(phase => phase.phases)
            .flat()
        : [];

    const result =
      filterPhasesByAreaIds.length > 0
        ? filterPhasesByAreaIds
        : userDefaultAreaPhases;

    return result;
  }, [phaseOptions, user.areaId, selectedAreas, filtersData.cards?.area_ids]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {loading ? (
        <Button
          size="icon"
          variant="default"
          className="h-[32px] w-[50px]"
          disabled
        >
          <Loader2 className="h-5 w-5 animate-spin" />
        </Button>
      ) : (
        <PopoverTrigger>
          <Button
            size="icon"
            variant={
              filtersData.column &&
              (filtersData.column.startDate ||
                filtersData.column.endDate ||
                filtersData.column.user_id ||
                filtersData.column.field_ids.length > 0)
                ? 'default'
                : 'outline'
            }
            className="h-[32px] w-[50px]"
          >
            <FilterIcon size={18} />
          </Button>
        </PopoverTrigger>
      )}
      <PopoverContent className="flex flex-col w-full h-full gap-3">
        <DatePickerWithRange
          date={dateRange}
          setDate={setDateRange}
          placeholder="Período"
        />
        {user?.profile.categoryName === 'ADMINISTRATOR' && (
          <Select
            items={areas || []}
            selectedItems={selectedAreas}
            setSelectedItems={setSelectedAreas}
            placeholder="Área"
            maxItems={areas?.length}
          />
        )}
        <Select
          items={filteredPhases || []}
          selectedItems={selectedPhases}
          setSelectedItems={setSelectedPhases}
          placeholder="Fase"
          maxItems={filteredPhases?.length || 0}
        />
        <Select
          items={accountOptions || []}
          selectedItems={selectedAccounts}
          setSelectedItems={setSelectedAccounts}
          placeholder="Conta"
          maxItems={accountOptions?.length || 0}
        />
        {user?.profile.categoryName === 'ADMINISTRATOR' && (
          <Select
            items={users || []}
            selectedItems={selectedUser}
            setSelectedItems={setSelectedUser}
            placeholder="Usuário"
          />
        )}
        <Select
          items={SituationOptions || []}
          selectedItems={selectedSituations}
          setSelectedItems={setSelectedSituations}
          placeholder="Situação"
          maxItems={3}
        />
        <Input
          placeholder="Chave"
          onChange={e =>
            setKeycard(e.target.value === '' ? '' : e.target.value)
          }
          value={keycard}
        />
        <Button
          onClick={() => {
            handleApplyFilter();
            setOpen(false);
          }}
        >
          Aplicar
        </Button>
        <Button type="reset" variant="secondary" onClick={handleClearFilter}>
          Limpar
        </Button>
      </PopoverContent>
    </Popover>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { ColumnDef } from '@tanstack/react-table';
import { Button } from 'src/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { MoreHorizontalIcon } from 'lucide-react';
import { DataTableColumnHeader } from 'src/components/ui/data-table-column-header';
import { useAuth } from 'src/context/AuthContext';
import { useDataTableContext } from 'src/context/DataTableContext';
import { GetAccountsApi } from 'src/services/accountApi';

export const AccountColumns: ColumnDef<GetAccountsApi>[] = [
  {
    accessorKey: 'name',
    id: 'Conta',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const rowFormattedValue = row.original.name;
      return value.includes(rowFormattedValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Conta"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original.name;
      return (
        <div className="text-left px-2 text-nowrap max-w-[300px] truncate">
          {value}
        </div>
      );
    },
  },
  {
    accessorKey: 'cpfCnpj',
    id: 'CPF/CNPJ',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const rowFormattedValue = row.original.cpfCnpj;
      return value.includes(rowFormattedValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="CPF/CNPJ"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original.cpfCnpj;
      return (
        <div className="text-left px-2 text-nowrap max-w-[200px] truncate">
          {value}
        </div>
      );
    },
  },
  {
    accessorKey: 'district',
    id: 'Bairro',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const rowFormattedValue = row.original?.district || '';
      return value.includes(rowFormattedValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Bairro"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original?.district || '';
      return (
        <div className="text-left px-2 text-nowrap max-w-[200px] truncate">
          {value}
        </div>
      );
    },
  },
  {
    accessorKey: 'city',
    id: 'Cidade',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const rowFormattedValue = row.original?.city || '';
      return value.includes(rowFormattedValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Cidade"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original?.city || '';
      return (
        <div className="text-left px-2 text-nowrap max-w-[200px] truncate">
          {value}
        </div>
      );
    },
  },
  {
    accessorKey: 'state',
    id: 'Estado',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const rowFormattedValue = row.original?.state || '';
      return value.includes(rowFormattedValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Estado"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original?.state || '';
      return (
        <div className="text-left px-2 text-nowrap max-w-[100px] truncate">
          {value}
        </div>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Ações"
        className="text-center"
      />
    ),
    cell: ({ row }) => {
      const { handleOpenModal, handleDeleteItem } = useDataTableContext();
      const { user } = useAuth();
      const editPermissionDisabledForUser =
        user?.permissions.ACCOUNT !== 'READ_AND_WRITE';
      const task = row.original;
      const handleDeleteTaskClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        event.stopPropagation();
        handleDeleteItem(task.id);
      };

      return (
        <div className="flex w-full place-content-center">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontalIcon className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Ações</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="hover:bg-gray-200 cursor-pointer"
                onClick={() => handleOpenModal(task.id)}
              >
                Alterar
              </DropdownMenuItem>
              <DropdownMenuItem
                className="hover:bg-gray-200 cursor-pointer"
                onClick={e => handleDeleteTaskClick(e)}
                disabled={editPermissionDisabledForUser}
              >
                Excluir
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row } from '@tanstack/react-table';
import {
  format,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  isValid,
  parse,
  parseISO,
  startOfDay,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

/**
 * This function is intended to be used within a data table.
 * It checks if the column date value in the given row is within the specified date range.
 * @param {Row} row - the row of a table, should be of the same tape as a Row from tanstack/react-table
 * @param {string} columnId - the column ID defined at the columnsDef array
 * @param {{ from: Date | undefined; to: Date | undefined; }} value - the date range specified as an object with `from` and `to` properties
 * @returns {boolean} Returns true if the column date value is within the specified date range, false otherwise.
 */
export const isWithinRange = (
  row: Row<any>,
  columnId: string,
  value:
    | {
        from: Date | undefined;
        to: Date | undefined;
      }
    | undefined,
) => {
  let columnDate: Date | undefined;

  if (row && columnId && row.getValue(columnId)) {
    const rowValue = row.getValue(columnId) as string;
    const removeTime = rowValue.split(' ')[0];
    columnDate = parse(removeTime.toString(), 'dd/MM/yy', new Date(), {
      locale: ptBR,
    });
  }
  const from = value?.from ? new Date(value?.from) : undefined;
  const to = value?.to ? new Date(value?.to) : undefined;

  if (columnDate) columnDate.setHours(0, 0, 0, 0);
  if (from) from.setHours(0, 0, 0, 0);
  if (to) to.setHours(0, 0, 0, 0);

  if ((from || to) && !columnDate) return false;

  if (columnDate && from && to) {
    return columnDate >= from && columnDate <= to;
  }

  if (columnDate && from && !to) {
    return columnDate >= from;
  }

  if (columnDate && !from && to) {
    return columnDate <= to;
  }

  return true;
};

/**
 * It checks if a date value is within the specified date range.
 * @param {Date} dateToCompare - the date to compare
 * @param {{ from: Date | undefined; to: Date | undefined; }} value - the date range specified as an object with `from` and `to` properties
 * @returns {boolean} Returns true if the date value is within the specified date range, false otherwise.
 */
export const isDateWithinDateRange = (
  dateToCompare: Date,
  value:
    | {
        from?: Date | undefined;
        to?: Date;
      }
    | undefined,
) => {
  const formattedDate = new Date(dateToCompare);
  const from = value?.from ? new Date(value?.from) : undefined;
  const to = value?.to ? new Date(value?.to) : undefined;

  if (formattedDate) formattedDate.setHours(0, 0, 0, 0);
  if (from) from.setHours(0, 0, 0, 0);
  if (to) to.setHours(0, 0, 0, 0);

  if ((from || to) && !formattedDate) return false;

  if (formattedDate && from && to) {
    return formattedDate >= from && formattedDate <= to;
  }

  if (formattedDate && from && !to) {
    return formattedDate >= from;
  }

  if (formattedDate && !from && to) {
    return formattedDate <= to;
  }

  return true;
};

/**
 * Checks if the current date falls within a specified start and end date range.
 * @param {string | null} startDate - The start date of the range (format: dd/MM/yy).
 * @param {string | null} endDate - The end date of the range (format: dd/MM/yy).
 * @returns {boolean} Returns true if the current date is within the specified date range, false otherwise.
 */
export function isCurrentDateWithinDateRange(
  startDate: string | null,
  endDate: string | null,
): boolean {
  if (!startDate || !endDate) {
    return false;
  }

  const removeTimeStart = startDate.split(' ')[0] || '';
  const formattedStartDate = parse(removeTimeStart, 'dd/MM/yy', new Date(), {
    locale: ptBR,
  });
  const startDateWithoutTime = startOfDay(formattedStartDate);

  // const removeTimeEnd = endDate.split(' ')[0] || '';
  const formattedEndDate = parse(endDate, 'dd/MM/yy HH:mm', new Date(), {
    locale: ptBR,
  });

  const currentDate = new Date();
  const currentDateWithoutTime = startOfDay(currentDate);

  return (
    isValid(formattedStartDate) &&
    isValid(formattedEndDate) &&
    (isSameDay(startDateWithoutTime, currentDateWithoutTime) ||
      isAfter(currentDateWithoutTime, startDateWithoutTime)) &&
    (isBefore(currentDate, formattedEndDate) ||
      (isSameDay(formattedEndDate, currentDate) &&
        isBefore(currentDate, formattedEndDate)))
  );
}

/**
 * Checks if the function argument is equal to current day
 * @param {string | null} date - The start date of the range (format: dd/MM/yy).
 * @returns {boolean} Returns true if the argument date is equal current date.
 */
export function isTodayDate(date: string | null): boolean {
  const removeTime = date?.split(' ')[0] || '';
  const formattedStartDate = parse(
    removeTime.toString(),
    'dd/MM/yy',
    new Date(),
    {
      locale: ptBR,
    },
  );

  if (
    formattedStartDate &&
    isValid(formattedStartDate) &&
    isToday(startOfDay(formattedStartDate))
  ) {
    return true;
  }

  return false;
}

/**
 * Checks if the function argument is before the current date
 * @param {string | null} date - The start date of the range (format: dd/MM/yy).
 * @returns {boolean} Returns true if the argument date before current date.
 */
export function isOverdueDate(date: string | null): boolean {
  if (!date) {
    return false;
  }

  // const removeTime = date?.split(' ')[0] || '';
  const currentDate = new Date();
  const formattedDate = parse(date.toString(), 'dd/MM/yy HH:mm', new Date(), {
    locale: ptBR,
  });

  if (
    formattedDate &&
    isValid(formattedDate) &&
    isBefore(formattedDate, currentDate)
  ) {
    return true;
  }

  return false;
}

/**
 * Generic comparison function for sorting objects by two date properties.
 * @param {string | Date | null} dateA The first date property to compare.
 * @param {string | Date | null} dateB The second date property to compare.
 * @param {boolean} descending Optional. If true, sort in descending order.
 * @returns A comparison value suitable for use with the `sort()` method.
 */
export const sortByDateProperty = (
  dateA: string | Date | null,
  dateB: string | Date | null,
  descending: boolean = false,
): number => {
  const parsedDateA = dateA
    ? parse(dateA.toString(), 'dd/MM/yy HH:mm', new Date())
    : null;
  const parsedDateB = dateB
    ? parse(dateB.toString(), 'dd/MM/yy HH:mm', new Date())
    : null;

  const isValidDateA = parsedDateA && isValid(parsedDateA);
  const isValidDateB = parsedDateB && isValid(parsedDateB);

  if (isValidDateA && isValidDateB) {
    if (descending) {
      return parsedDateB!.getTime() - parsedDateA!.getTime();
    }
    return parsedDateA!.getTime() - parsedDateB!.getTime();
  }
  if (!isValidDateA && isValidDateB) {
    return 1;
  }
  if (isValidDateA && !isValidDateB) {
    return -1;
  }
  return 0;
};

/**
 * Convert an ISO Date format into a string format dd/MM/yy HH:mm
 * @param {string | Date | null} date - dd/MM/yy HH:mm to format.
 * @returns A string Date format .
 */
export const convertISODateToStringFormat = (date: Date | string) => {
  const newDate =
    format(parseISO(date.toString()), 'dd/MM/yy HH:mm', {
      locale: ptBR,
    }) || '';
  return newDate;
};

// Utility function to format the ISO string for `datetime-local` format `yyyy-MM-ddThh:mm`
// used for the TimestampField customField, dateType DATETIME
export const formatDatetimeLocal = (isoString: string) => {
  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(isoString)) {
    return isoString;
  }
  const date = new Date(isoString);
  if (Number.isNaN(date.getTime())) {
    return isoString;
  }
  const pad = (n: number) => n.toString().padStart(2, '0');

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate(),
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
};

export const formatDatetimeLocalToBackendISO = (
  localDateString: string | undefined,
) => {
  if (localDateString === null || localDateString === undefined) {
    return localDateString;
  }
  const date = new Date(localDateString);
  if (Number.isNaN(date.getTime())) {
    return localDateString;
  }

  return date.toISOString();
};

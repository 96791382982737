import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { Checkbox } from 'src/components/ui/checkbox';
import { cn } from 'src/lib/utils';
import { IEmail } from '../User.i';

interface EmailTableProps {
  filteredEmails: IEmail[];
  handleSelectEmail: (
    email: IEmail,
    checkboxType: string,
    checked: boolean,
  ) => void;
  defaultEmailChecked: boolean;
  className?: string;
}

export const EmailTable: React.FC<EmailTableProps> = ({
  filteredEmails,
  handleSelectEmail,
  defaultEmailChecked,
  className = '',
}) => {
  return (
    <div
      className={cn(
        'w-full overflow-auto border-2 box-border rounded-md',
        className,
      )}
    >
      <Table className="w-full overflow-clip">
        <TableHeader className="z-10">
          <TableRow>
            <TableCell align="left" className="p-2 font-medium">
              Email
            </TableCell>
            <TableCell align="center" className="p-2 font-medium">
              Vincular
            </TableCell>
            <TableCell align="center" className="p-2 font-medium">
              Padrão
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredEmails.map(email => (
            <TableRow key={email.id}>
              <TableCell align="left" className="p-2">
                {email.email}
              </TableCell>
              <TableCell align="center" className="p-2">
                <Checkbox
                  name="isAssociated"
                  checked={email.isAssociated || false}
                  onCheckedChange={checked =>
                    handleSelectEmail(email, 'associated', checked as boolean)
                  }
                  className="p-0 m-0"
                />
              </TableCell>
              <TableCell align="center" className="p-2">
                <Checkbox
                  name="isDefault"
                  checked={email.isDefault || false}
                  disabled={
                    !email.isAssociated ||
                    (email.isAssociated &&
                      defaultEmailChecked &&
                      !email.isDefault)
                  }
                  onCheckedChange={checked =>
                    handleSelectEmail(email, 'default', checked as boolean)
                  }
                  className="p-0 m-0"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

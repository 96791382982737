import React, { useCallback, useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { Button } from 'src/components/ui/button';
import { MoreHorizontalIcon } from 'lucide-react';
import { Skeleton } from 'src/components/ui/skeleton';
import { DeleteAlertDialog } from 'src/components/DeleteAlertDialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { DataSourceListType } from 'src/services/dataSourceApi';
import { IArea } from 'src/services/areaApi';

export interface DataSourceListProps {
  handleEditDataSource: (profile: DataSourceListType) => void;
  dataSourceList: DataSourceListType[];
  loadingDataSources: boolean;
  handleDeleteDataSource: (dataSource_id: string) => void;
  areas: IArea[];
}

export const DataSourceList: React.FC<DataSourceListProps> = ({
  handleEditDataSource,
  dataSourceList,
  loadingDataSources,
  handleDeleteDataSource,
  areas,
}) => {
  const [deleteAlertState, setDeleteAlertState] = useState({
    isOpen: false,
    dataSourceId: '',
  });

  const handleOpenDeleteDialog = useCallback((dataSource_id: string) => {
    setDeleteAlertState({ isOpen: true, dataSourceId: dataSource_id });
  }, []);

  const handleCloseDeletionDialog = useCallback((value: boolean) => {
    setDeleteAlertState({ isOpen: value, dataSourceId: '' });
  }, []);

  return (
    <div className="w-full h-44 max-h-60 overflow-auto border-2 rounded-sm">
      {loadingDataSources ? (
        <ul>
          {[1, 2, 3, 4, 5, 6].map(item => (
            <li
              key={item}
              className="flex justify-between items-center border-b p-1"
            >
              <Skeleton className="w-full h-6" />
            </li>
          ))}
        </ul>
      ) : (
        <Table className="w-full overflow-clip">
          <TableHeader className="z-10">
            <TableRow>
              <TableCell align="left" className="p-2 font-medium">
                Nome de Exibição
              </TableCell>
              <TableCell align="left" className="p-2 font-medium">
                Áreas
              </TableCell>
              <TableCell align="left" className="p-2 font-medium">
                Dados
              </TableCell>
              <TableCell align="center" className="p-2 font-medium">
                Ações
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {dataSourceList.length > 0 &&
              dataSourceList.map((dataSource: DataSourceListType) => (
                <TableRow key={dataSource.id}>
                  <TableCell
                    align="left"
                    className="p-2"
                    onClick={() => {
                      handleEditDataSource(dataSource);
                    }}
                  >
                    {dataSource.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="p-2"
                    onClick={() => {
                      handleEditDataSource(dataSource);
                    }}
                  >
                    {Object.keys(dataSource.parameters.areas).length > 0 &&
                      Object.keys(dataSource.parameters.areas)
                        .map(areaId => {
                          const area = areas.find(area => area.id === areaId);
                          return area ? area.name : null;
                        })
                        .filter(Boolean)
                        .join(', ')}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="p-2"
                    onClick={() => {
                      handleEditDataSource(dataSource);
                    }}
                  >
                    {[
                      dataSource.parameters.accounts.length && 'Contas',
                      dataSource.parameters.cards.length && 'Cards',
                      dataSource.parameters.tasks.length && 'Tarefas',
                      Object.keys(dataSource.parameters.areas).length > 0 &&
                        'Áreas',
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </TableCell>
                  <TableCell align="center" className="p-1">
                    <DropdownMenu>
                      <DropdownMenuTrigger
                        asChild
                        className="rounded cursor-pointer"
                      >
                        <Button variant="ghost" size="sm">
                          <span className="sr-only">Open menu</span>
                          <MoreHorizontalIcon size={16} />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="bg-white shadow-lg border rounded">
                        <DropdownMenuItem
                          onSelect={() => {
                            handleOpenDeleteDialog(dataSource.id as string);
                          }}
                        >
                          Excluir
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
      {deleteAlertState.isOpen && deleteAlertState.dataSourceId && (
        <DeleteAlertDialog
          open={deleteAlertState.isOpen}
          setOpen={handleCloseDeletionDialog}
          itemId={deleteAlertState.dataSourceId}
          handleConfirmDelete={handleDeleteDataSource}
        />
      )}
    </div>
  );
};

import { toast } from 'react-toastify';

export const allowedMimeTypes = [
  'application/msword',
  'application/vnd.openxmlformatsofficedocument.wordprocessingml.document',
  'application/pdf',
  'application/x-zip-compressed',
  'application/zip',
  'application/ogg',
  'application/xml',
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/svg+xml',
  'text/csv',
  'text/plain',
  'text/xml',
  'audio/mp4',
  'audio/mpeg',
  'audio/x-wav',
];

export const cleanFileName = (fileName: string): string => {
  // Remove accents and special characters
  const normalizedFileName = fileName
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  // Replace spaces with underscores
  let cleanName = normalizedFileName.replace(/\s+/g, '_');

  // This keeps letters, digits, underscores, dots, and hyphens
  cleanName = cleanName.replace(/[^\w.-]/g, '');
  cleanName = cleanName.toUpperCase();

  return cleanName;
};

export const validateIfIsInvalidFile = (
  file: File,
  MAX_FILE_SIZE: number,
  companyFileSizeInMb: number,
  nameMaxLength: number | undefined = 100,
): boolean => {
  const notValidType = !allowedMimeTypes.includes(file.type);
  const fileSizeKiloBytes = file.size / 1000;
  const overLimitSize = fileSizeKiloBytes > MAX_FILE_SIZE;
  const cleanedFileName = cleanFileName(file.name);

  if (notValidType || overLimitSize || cleanedFileName.length > nameMaxLength) {
    const message =
      notValidType && overLimitSize
        ? `Extensão inválida e tamanho além do permitido (${companyFileSizeInMb}Mb).`
        : notValidType && !overLimitSize
        ? `Extensão do tipo ${file.type} não é permitido.`
        : !notValidType && overLimitSize
        ? `Tamanho do arquivo além do permitido. (${companyFileSizeInMb}Mb).`
        : cleanedFileName.length > nameMaxLength
        ? `O nome do arquivo excede o tamanho máximo de ${nameMaxLength} caracteres.`
        : 'Arquivo inválido.';

    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
      autoClose: 5000,
    });

    return true;
  }

  return false;
};

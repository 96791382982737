/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';

import Swal from 'sweetalert2';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { Edit, Delete } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import {
  TableContainer,
  TextField,
  Container,
  TablePagination,
  TableFooter,
} from '@material-ui/core';
import { toast } from 'react-toastify';

import { useAuth } from 'src/context/AuthContext';
import TablePaginationActions from '../../../components/TablePaginationActions';
import api from '../../../services/api';
import HeaderPage from '../../../components/TitleAndButton';
import { ModalWithTitle } from '../../../components/ModalWithTitle';
import { Form } from '../index';

import { IListForm } from '../Form.i';
import { styles } from './styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const FormsList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuth();
  const userCantEditForm = user.permissions.FORM !== 'READ_AND_WRITE';
  const [openModal, setOpenModal] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 10,
  );

  const [findName, setFindName] = useState('');

  const [forms, setForms] = useState<IListForm[]>([]);
  const [formsFiltered, setFormsFiltered] = useState<IListForm[]>([]);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, forms.length - page * rowsPerPage);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    api.get('/forms').then(response => setForms(response.data));
  }, []);

  const handleEdit: any = useCallback(
    (form_id: string) => {
      history.push(`/forms/${form_id}`);
    },
    [history],
  );

  useEffect(() => {
    setFormsFiltered(forms);
  }, [forms]);

  useEffect(() => {
    const FormsF = forms.filter(
      form =>
        form.title
          .toUpperCase()
          .trim()
          .indexOf(findName.toUpperCase().trim()) >= 0 ||
        form.areaName
          .toUpperCase()
          .trim()
          .indexOf(findName.toUpperCase().trim()) >= 0 ||
        form.phaseName
          .toUpperCase()
          .trim()
          .indexOf(findName.toUpperCase().trim()) >= 0 ||
        form.userName
          .toUpperCase()
          .trim()
          .indexOf(findName.toUpperCase().trim()) >= 0,
    );
    setPage(0);
    setFormsFiltered(FormsF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findName]);

  const handleDelete = useCallback((form_id: string) => {
    Swal.fire({
      title: 'Deseja excluir?',
      text: 'Essa opção não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
      customClass: {
        container: classes.swalAlert,
      },
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/forms/${form_id}`);
          await api.get('/forms').then(response => setForms(response.data));
        } catch (error: any) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      }
    });
  }, []);

  const handleAddForm = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = () => {
    // eslint-disable-next-line no-console
    setOpenModal(false);
  };

  useEffect(() => {
    api.get('/forms').then(response => setForms(response.data));
  }, []);

  const urlOrigin = window.location.origin;

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        <HeaderPage
          title="Formulários"
          handleAdd={handleAddForm}
          disabled={userCantEditForm}
        />
        <TextField
          label="Pesquisar"
          name="find"
          margin="dense"
          variant="outlined"
          fullWidth
          value={findName}
          style={{ marginBottom: 15, maxWidth: '300px' }}
          onChange={value => {
            setFindName(value.target.value);
          }}
        />
        <Box className={classes.boxTable}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="lista de formulários">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnActions} />
                  <TableCell>Titulo</TableCell>
                  <TableCell>Área</TableCell>
                  <TableCell>Fase</TableCell>
                  <TableCell>Usuário</TableCell>
                  <TableCell>Ativo</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell className={classes.columnActions} />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? formsFiltered.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : formsFiltered
                )
                  /* .sort(
                    (a, b) =>
                      Number(b.isActive) - Number(a.isActive) ||
                      a.title.localeCompare(b.title),
                  ) */
                  .map(form => (
                    <TableRow key={form.id}>
                      <TableCell component="th" scope="row">
                        <IconButton
                          aria-label="Editar"
                          onClick={() => handleEdit(form.id)}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {form.title}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {form.areaName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {form.phaseName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {form.userName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {form.isActive === true ? 'Sim' : 'Não'}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Link
                          to={`/fillForm/${form?.id}`}
                          style={{ color: '#3C7CC6' }}
                        >
                          {`${urlOrigin}/publicForms/${form.id}`}
                        </Link>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <IconButton
                          aria-label="Excluir"
                          size="small"
                          onClick={() => handleDelete(form.id)}
                          disabled={userCantEditForm}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 43 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, { label: 'Todos', value: -1 }]}
                    colSpan={8}
                    count={formsFiltered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Linhas por página"
                    SelectProps={{
                      inputProps: { 'aria-label': '' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {openModal && (
        <ModalWithTitle
          open
          closeModal={handleCloseModal}
          title="Adicionar novo Formulário"
          titleColor="#000000"
        >
          <Form closeModal={handleCloseModal} />
        </ModalWithTitle>
      )}
    </Container>
  );
};

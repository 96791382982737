import React, { FormEventHandler, useCallback, useState } from 'react';

import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import * as Yup from 'yup';
import { pt } from 'yup-locale-pt';
import { Button } from 'src/components/ui/button';
import { ICustomField } from 'src/interface/ICardFields';
import { evaluateFillRulesAsFieldCondition } from 'src/utils/fillRules/evaluateRules';
import { ModalIntegratedCustomFields } from '../../../components/ModalIntegratedCustomFields';

import { styles } from './styles';

import { CardCustomFieldPhase } from './phase';
import { ICardCustomFieldProps, IModalData } from './CardCustomField.i';
import { CardCustomFieldContext } from '../../../context/CardCustomFieldContext';
import { useCardDataContext } from '../../../context/CardDataContext';

const useStyles = makeStyles(() => createStyles(styles()));

Yup.setLocale(pt);

const CardCustomField: React.FC<ICardCustomFieldProps> = () => {
  const classes = useStyles();
  const {
    handleFillCustomField,
    fillRuleDictionary,
    cardCustomFieldPhases,
    accountFields,
    showLoadingMessage,
    transitioning,
    rulesEvaluatedOnLoad,
    isSubmittingFields,
    setIsSubmittingFields,
    setCardCustomFieldPhases,
    cardCurrentPhase,
    handleSubmitCard,
  } = useCardDataContext();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<IModalData>();

  const handleOpenModal = (
    customField: ICustomField,
    customFieldIndex: number,
    phaseIndex: number,
    tableRowIndex?: number,
  ) => {
    const newModalData = {
      customField,
      customFieldIndex,
      phaseIndex,
      tableRowIndex,
    };
    setModalData(newModalData);
    setOpenModal(true);
  };

  const handleFillIntegratedField = (
    value: Record<string, unknown> | undefined,
  ) => {
    if (modalData) {
      const newCardCustomFields = [...cardCustomFieldPhases];
      if (typeof modalData.tableRowIndex !== 'undefined' && value) {
        const oldValues =
          newCardCustomFields[modalData.phaseIndex].customFields[
            modalData.customFieldIndex
          ].valueJSON[modalData.tableRowIndex];

        newCardCustomFields[modalData.phaseIndex].customFields[
          modalData.customFieldIndex
        ].valueJSON[modalData.tableRowIndex] = {
          ...oldValues,
          [modalData.customField.id]: { valueJSON: value },
        };
      } else {
        newCardCustomFields[modalData.phaseIndex].customFields[
          modalData.customFieldIndex
        ].valueJSON = value;
        newCardCustomFields[modalData.phaseIndex].customFields[
          modalData.customFieldIndex
        ].hasChanged = true;
      }

      if (modalData.customField.id && fillRuleDictionary) {
        evaluateFillRulesAsFieldCondition(
          modalData.customField.id,
          newCardCustomFields,
          accountFields,
          fillRuleDictionary,
          handleFillCustomField,
          cardCurrentPhase.id || '',
        );
      }
      setCardCustomFieldPhases(newCardCustomFields);
      setOpenModal(false);
    }
  };

  const handleCloseIntagratedModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <CardCustomFieldContext.Provider value={{ handleOpenModal }}>
      <Box className={classes.content}>
        {showLoadingMessage && (
          <div
            style={{ width: '100%' }}
            className={`${classes.loadingMessage} ${
              transitioning || rulesEvaluatedOnLoad ? '' : classes.hidden
            }`}
          >
            <span>Aguarde... avaliando regras de preenchimento.</span>
            <CircularProgress size={20} />
          </div>
        )}

        <div
          className={`${classes.customFieldPhases} ${
            transitioning ? '' : classes.hidden
          }`}
        >
          <form
            onSubmit={handleSubmitCard as FormEventHandler<HTMLFormElement>}
          >
            {!showLoadingMessage &&
              cardCustomFieldPhases &&
              cardCustomFieldPhases.map((cardCustomFieldPhase, phaseIndex) => (
                <CardCustomFieldPhase
                  cardCustomFieldPhase={cardCustomFieldPhase}
                  phaseIndex={phaseIndex}
                  key={cardCustomFieldPhase.id}
                />
              ))}
            <Button
              type="submit"
              className="absolute bottom-4 right-8 z-10"
              size="lg"
              onClick={e => {
                setIsSubmittingFields(true);
                handleSubmitCard(e);
              }}
              disabled={isSubmittingFields}
            >
              Salvar
            </Button>
          </form>
        </div>
        {cardCustomFieldPhases && openModal && modalData && (
          <ModalIntegratedCustomFields
            openModal={openModal}
            handleCloseModal={handleCloseIntagratedModal}
            onSelectedIntegratedItem={handleFillIntegratedField}
            customFields={cardCustomFieldPhases}
            customField_id={modalData.customField.id}
            customField_name={modalData.customField.name}
            format={modalData.customField.format}
            dynamicFormat={modalData.customField.dynamicFormat}
            key={modalData.customField.id}
            primaryKeyColumnName={modalData.customField.primaryKeyColumnName}
          />
        )}
      </Box>
    </CardCustomFieldContext.Provider>
  );
};

export default CardCustomField;

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { toastLoadingUpdate } from 'src/utils/toastifyUtils';
import { isNotNullish } from 'src/utils/comparisonUtils';
import { ICardData } from 'src/interface/ICardData';
import { ICustomField } from 'src/interface/ICardFields';
import api from '../../services/api';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Skeleton } from '../ui/skeleton';

export interface IntegratedItemFormat {
  name: string;
  align: string;
  order: string;
  isHidden?: boolean;
  isPrimaryKeyColumn?: boolean;
  isDisplayColumn?: boolean;
}

interface IModalCustomProps {
  openModal: boolean;
  customFields?: ICardData[];
  customField_id: string;
  customField_name: string;
  format: any;
  dynamicFormat: {
    [key: string]: IntegratedItemFormat;
  } | null;
  primaryKeyColumnName: string;
  handleCloseModal: () => void;
  onSelectedIntegratedItem: (
    integratedItem: Record<string, unknown> | undefined,
  ) => void;
}

export const ModalIntegratedCustomFields: React.FC<IModalCustomProps> = ({
  openModal,
  customField_id,
  customField_name,
  format,
  dynamicFormat,
  primaryKeyColumnName,
  customFields = [],
  handleCloseModal,
  onSelectedIntegratedItem,
}) => {
  const hasFormat = format !== null && format !== undefined;
  const hasDynamicFormat =
    dynamicFormat !== null && dynamicFormat !== undefined;
  const currentFormat: Record<string, IntegratedItemFormat> = hasFormat
    ? format
    : dynamicFormat;
  const [requestError, setRequestError] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string | any>('');
  const [loadingData, setLoadingData] = useState(true);
  const [originalRows, setOriginalRows] = useState<Record<string, unknown>[]>(
    [],
  );
  const [loadingFilters, setLoadingFilters] = useState<boolean>(true);
  const [filters, setFilters] = useState<{ filterValues: {} }>({
    filterValues: {},
  });
  const [integratedItemList, setIntegratedItemList] = useState<
    Record<string, unknown>[]
  >([]);
  const [integratedItemSelected, setIntegratedItemSelected] =
    useState<Record<string, unknown>>();
  const [tableColumns, setTableColumns] = useState<IntegratedItemFormat[]>([]);
  const [activeFilters, setActiveFilters] = useState<string>('');

  function getFormatAlignValue(columnName: string): string | undefined {
    const alignValue = tableColumns.find(tc => tc.name === columnName)?.align;
    return alignValue;
  }

  useEffect(() => {
    if (hasFormat || hasDynamicFormat) {
      const formatEntries = Object.entries(currentFormat)
        .sort(
          (entryA: any, entryB: any) =>
            Number(entryA[1].order) - Number(entryB[1].order),
        )
        .map((f: any) => f[1]);

      setTableColumns(formatEntries);
    }
  }, [format, dynamicFormat]);

  useEffect(() => {
    async function getFilters() {
      await api
        .get(`/customFields/${customField_id}`)
        .then(response => {
          if (response.data) {
            const customField = response.data;
            const fieldPhaseId = customField.phase && customField.phase.id;
            const hasFilters =
              customField?.integratedFilterAssociations?.length > 0;

            if (hasFilters && fieldPhaseId) {
              const cardPhaseFieldValues: ICustomField[] =
                customFields?.length > 0
                  ? customFields?.find(phase => phase.id === fieldPhaseId)
                      ?.customFields || []
                  : [];
              const arrayOfFieldValues = [...cardPhaseFieldValues];

              const result = customField.integratedFilterAssociations.reduce(
                (acc: any, curr: any) => {
                  const { associatedCustomField, fixedValue } = curr;
                  if (associatedCustomField && arrayOfFieldValues.length > 0) {
                    const item = arrayOfFieldValues.find(
                      field => field.id === associatedCustomField.id,
                    );
                    if (item !== undefined) {
                      const itemType = item.type;
                      if (
                        item.type === 'INTEGRATED' &&
                        isNotNullish(item.valueJSON) &&
                        typeof item.valueJSON === 'object'
                      ) {
                        const hasDynamicFormat =
                          isNotNullish(item.dynamicFormat) &&
                          typeof item.dynamicFormat === 'object' &&
                          Object.keys(item?.dynamicFormat).length > 0;
                        const itemKey = hasDynamicFormat
                          ? item.dynamicFormat?.[primaryKeyColumnName]?.name ||
                            ''
                          : item.primaryKeyColumnName;
                        const filterValue =
                          itemKey && item.valueJSON?.[itemKey]
                            ? item.valueJSON[itemKey]
                            : '';
                        if (filterValue.length > 0)
                          acc.filterValues[curr.filterCode] = filterValue;
                      }
                      if (
                        itemType !== 'INTEGRATED' &&
                        item?.value &&
                        item.value !== '' &&
                        isNotNullish(item.value)
                      ) {
                        acc.filterValues[curr.filterCode] = item.value;
                      }
                    }
                  } else if (fixedValue && fixedValue.length > 0) {
                    acc.filterValues[curr.filterCode] = curr.fixedValue;
                  }
                  return acc;
                },
                { filterValues: {} },
              );
              setFilters(result);
              setLoadingFilters(false);
            }
          }
        })
        .catch(error => {
          toast.error(`Erro ao buscar filtros ${error?.message}`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        })
        .finally(() => setLoadingFilters(false));
    }
    async function getIntegrationData() {
      const loadingData = toast.loading(`Buscando dados da integração`, {
        autoClose: 5000,
      });
      document.body.style.cursor = 'progress';
      await api
        .post(
          `/integrations/integrated_items/customField/${customField_id}`,
          filters,
        )
        .then(response => {
          if (response.data.length === 0) {
            toast.warning('Nenhum dado corresponde a pesquisa', {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 3000,
            });
          }
          if (response.data) {
            const dataResp = response?.data;
            const sortedColumns = Object.entries(currentFormat)
              .sort(
                (entryA: any, entryB: any) =>
                  Number(entryA[1].order) - Number(entryB[1].order),
              )
              .map((f: any) => {
                if (hasDynamicFormat) return f[1]?.name || f[0];
                return f[0];
              });

            // Create new itens in correct column order
            const newOrderedList = dataResp.map((item: any) => {
              const row: any = {};
              sortedColumns.forEach((column: any) => {
                row[column] = item[column] || '';
              });
              return row;
            });

            setOriginalRows(newOrderedList);
            setIntegratedItemList(newOrderedList);
          }
          toastLoadingUpdate(loadingData, ``, 'success', 500);
        })
        .catch(error => {
          let newError = error?.response?.data?.message || '';
          if (error.message === 'Request failed with status code 422') {
            newError = `Nenhum dado encontrado`;
            toast.warning(
              `Filtros ativos: ${JSON.stringify(filters.filterValues)}`,
              {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'dark',
                autoClose: 4000,
                closeOnClick: true,
              },
            );
            setActiveFilters(
              `Filtros ativos: ${JSON.stringify(filters.filterValues)}`,
            );
          }
          if (error.message === 'Request failed with status code 404') {
            newError = `Erro na conexão`;
          }
          toastLoadingUpdate(loadingData, `Erro. ${newError}`, 'error', 4000);
          setRequestError(newError);
        })
        .finally(() => {
          document.body.style.cursor = 'auto';
          setLoadingData(false);
        });
    }

    if (customField_id) {
      if (!loadingFilters) {
        getIntegrationData();
      } else {
        getFilters();
      }
    }
  }, [customFields, customField_id, loadingFilters]);

  useEffect(() => {
    function requestSearch(searchVal: string) {
      const filteredRows = originalRows.filter(field => {
        return Object.values(field)
          .toString()
          .toLowerCase()
          .includes(searchVal.toLowerCase());
      });
      setIntegratedItemList(filteredRows);
    }
    requestSearch(searchTerm);
  }, [searchTerm, originalRows]);

  function handleOnSelectIntegratedItem(
    selectedIntegratedItem: Record<string, unknown>,
  ) {
    setIntegratedItemSelected(selectedIntegratedItem);
  }

  const handleOnConfirmSelection = () => {
    onSelectedIntegratedItem(integratedItemSelected);
  };

  const primaryKeyDisplayName: string = hasDynamicFormat
    ? currentFormat[primaryKeyColumnName]?.name || ''
    : primaryKeyColumnName;

  const hiddenColumns: string[] =
    hasDynamicFormat && currentFormat
      ? Object.entries(currentFormat)
          .filter(([, value]) => value?.isHidden)
          .map(([, value]) => value.name)
      : [];

  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        if (openModal) handleCloseModal();
      }}
      modal
    >
      <DialogContent
        onKeyDown={e => {
          e.stopPropagation();
          if (openModal && e.key === 'Escape') {
            handleCloseModal();
          }
        }}
        onClick={e => e.stopPropagation()}
        onFocus={e => e.stopPropagation()}
        onEscapeKeyDown={e => e.stopPropagation()}
        onPointerDownOutside={e => e.stopPropagation()}
        className="z-[2000] min-w-[800px] max-md:min-w-full h-full flex flex-col"
      >
        <DialogHeader>
          <DialogTitle>
            {customField_name || 'Pesquisa de Campo Integrado'}
          </DialogTitle>
        </DialogHeader>
        <div className="h-full w-full">
          <div className="flex items-center justify-between my-4 p-1">
            <Input
              placeholder="Pesquisar"
              name="searchIntegrateValue"
              autoFocus
              value={searchTerm || ''}
              onChange={e => setSearchTerm(e.target.value)}
              onClick={e => e.stopPropagation()}
              onSelect={e => e.stopPropagation()}
              onFocus={e => e.stopPropagation()}
              className="max-w-[50%]"
              disabled={loadingData || loadingFilters}
            />
            <Button onClick={() => handleOnConfirmSelection()}>
              Selecionar
            </Button>
          </div>
          {loadingData || loadingFilters ? (
            <div>
              <span className="font-semibold">
                Aguarde. Carregando dados...
              </span>
              <Skeleton className="h-9 w-full mt-1" />
              <div className="space-y-2">
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
              </div>
            </div>
          ) : (
            <div className="overflow-auto box-border border-2 rounded-md max-h-[80vh]">
              <Table className="w-full h-auto m-0 p-0">
                <TableHeader className="sticky -top-0.5">
                  <TableRow>
                    {(format || dynamicFormat) &&
                      tableColumns.map((column: IntegratedItemFormat) => {
                        if (
                          dynamicFormat &&
                          hiddenColumns.length > 0 &&
                          hiddenColumns.includes(column?.name)
                        ) {
                          return null;
                        }

                        return (
                          <TableHead
                            align={column?.align as any}
                            key={column?.name}
                            className="py-1.5 h-fit w-fit font-semibold text-nowrap"
                          >
                            {column?.name}
                          </TableHead>
                        );
                      })}
                  </TableRow>
                </TableHeader>
                <TableBody className="m-0">
                  {integratedItemList && integratedItemList.length > 0 ? (
                    integratedItemList.map(
                      (
                        integratedItem: Record<string, unknown>,
                        index: number,
                      ) => {
                        return (
                          <TableRow
                            key={
                              (integratedItem?.[
                                primaryKeyDisplayName
                              ] as string) || `row${index}`
                            }
                            className={`cursor-pointer ${
                              integratedItemSelected &&
                              primaryKeyDisplayName &&
                              integratedItemSelected?.[
                                primaryKeyDisplayName
                              ] === integratedItem?.[primaryKeyDisplayName]
                                ? 'bg-primary text-white hover:bg-primary hover:text-white'
                                : ''
                            }`}
                            onClick={() => {
                              handleOnSelectIntegratedItem(integratedItem);
                            }}
                          >
                            {Object.entries(integratedItem).map(
                              ([columnKey, field], cellIndex) => {
                                if (
                                  dynamicFormat &&
                                  hiddenColumns.length > 0 &&
                                  hiddenColumns.includes(columnKey)
                                ) {
                                  return null;
                                }

                                return (
                                  <TableCell
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${integratedItem[primaryKeyDisplayName]}-${field}-${cellIndex}}`}
                                    align={
                                      getFormatAlignValue(columnKey) as any
                                    }
                                    className="py-1 text-nowrap"
                                  >
                                    {typeof field === 'boolean'
                                      ? field
                                        ? 'Sim'
                                        : 'Não'
                                      : field || ''}
                                  </TableCell>
                                );
                              },
                            )}
                          </TableRow>
                        );
                      },
                    )
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={tableColumns.length}
                        className="text-center"
                      >
                        Sem resultados.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </div>

        {requestError.length > 0 && (
          <>
            <p className="text-center font-medium">{requestError}</p>
            <p className="text-center font-medium">{activeFilters}</p>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

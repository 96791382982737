import { makeStyles, StyleRules, Theme } from '@material-ui/core';
import { COLORS } from 'src/styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  children: {
    padding: 0,
    margin: 0,
    marginTop: 15,
    paddingBottom: 5,
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  dialog: {
    minWidth: '300px',
    maxHeight: '90vh',
  },
  modalButtons: {
    fontSize: '14px',
    marginTop: 0,
    marginLeft: theme.spacing(2),
    '&:hover': { textDecoration: 'none' },
  },
  content: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginBottom: 80,
  },

  muiInput: {
    '& .MuiInputLabel-shrink': {
      color: COLORS.GREEN,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.GREEN,
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: COLORS.GREEN,
      },
    },
    marginBottom: 10,
  },
  muiInputSelected: {
    '& label.Mui-focused': {
      color: COLORS.GREEN,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.GREEN,
    },
    '& fieldset': {
      borderColor: COLORS.GREEN,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.GREEN,
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: COLORS.GREEN,
      },
    },
    marginBottom: 10,
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    marginTop: '30px',
  },
});

export const useStyles = makeStyles(styles);

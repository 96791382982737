/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import {
  Box,
  Button as ButtonMaterialUI,
  CircularProgress,
  Container,
  createStyles,
  Divider,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Autocomplete } from '@material-ui/lab';
import { brazilianStates } from 'src/utils/brazilianStates';
import { useUsersAll } from 'src/hooks/useUsersAll';
import { useAreasAll } from 'src/hooks/useAreasAll';
import ListContacts from '../Contact/List';
import Button from '../../components/Button';
import { InputZipCodeMask } from '../../components/InputZipCodeMask';
import { IAccountParams, IAccountProps } from './Account.i';
import { styles } from './styles';
import { AccountCustomFields } from './components/AccountCustomFields';
import { UserSelector } from './components/UserSelector';
import { useAccount } from './hooks/useAccount';
import { ResponsibleUserAlert } from './components/ReponsibleUserAlert';
import { ResponsibleUserEditDialog } from './components/ReponsibleUserEditDialog';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const Account: React.FC<IAccountProps> = ({
  closeModal = () => {},
  account_id_prop,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<IAccountParams>();
  const { account_id } = params;
  const accountId = account_id || account_id_prop;
  const [open, setOpen] = useState(false);
  const {
    formik,
    customHandleChange,
    accountFields,
    responsibleUsers,
    setResponsibleUsers,
    isResponsibleAlertOpen,
    isResponsibleDialogOpen,
    handleResponsibleUserAlertConfirm,
    handleCloseResponsibleUserDialog,
    loadingCep,
    fetchCepApi,
    customHandlePhoneChange,
    fieldValues,
    setFieldValues,
    account,
    pendingReassignments,
    cantLinkResponsibleUser,
    userCantEditAccount,
  } = useAccount({ accountId, closeModal });
  const { users, loadingUsers } = useUsersAll();
  const { areas } = useAreasAll();

  const goBack = () => {
    if (!account_id) {
      closeModal();
    } else {
      history.goBack();
    }
  };

  return (
    <Container
      maxWidth={false}
      autoFocus={!isResponsibleAlertOpen && !isResponsibleDialogOpen}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: account?.id ? 35 : 15,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        {accountId ? (
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Editar Conta
          </Typography>
        ) : (
          <div />
        )}
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box className={classes.formContainer}>
            <Box className={classes.form}>
              <TextField
                id="name"
                label="Nome"
                name="name"
                type="text"
                margin="dense"
                autoComplete="off"
                autoFocus
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                id="cpfCnpj"
                label="CPF/CNPJ"
                name="CPF/CNPJ"
                margin="dense"
                autoComplete="off"
                fullWidth
                onChange={customHandleChange}
                value={formik.values.cpfCnpj}
                error={formik.touched.cpfCnpj && Boolean(formik.errors.cpfCnpj)}
                helperText={formik.touched.cpfCnpj && formik.errors.cpfCnpj}
              />
              <TextField
                id="corporateName"
                label="Razão social"
                name="corporateName"
                margin="dense"
                autoComplete="off"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.corporateName}
                error={
                  formik.touched.corporateName &&
                  Boolean(formik.errors.corporateName)
                }
                helperText={
                  formik.touched.corporateName && formik.errors.corporateName
                }
              />
              <TextField
                id="phone"
                label="Telefone"
                name="phone"
                margin="dense"
                autoComplete="off"
                fullWidth
                onChange={customHandlePhoneChange}
                value={formik.values.phone}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <Box className={classes.userSelectorContainer}>
                <UserSelector
                  onChange={setResponsibleUsers}
                  value={responsibleUsers}
                  users={users}
                  loadingUsers={loadingUsers}
                  cantLinkResponsibleUser={cantLinkResponsibleUser}
                />
              </Box>
              <Typography
                onClick={() => setOpen(!open)}
                className={classes.titulo2}
              >
                {open ? <>Ver Menos ...</> : <>Ver Mais ...</>}
                {!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </Typography>
              {open && (
                <>
                  <Box display="flex" flexDirection="row" flexWrap="nowrap">
                    <TextField
                      id="zipCode"
                      label="CEP"
                      name="zipCode"
                      margin="dense"
                      fullWidth
                      autoComplete="off"
                      disabled={loadingCep}
                      placeholder="89000134"
                      onChange={(event: any) => {
                        formik.setFieldValue(
                          'zipCode',
                          event.target.value.replace('-', ''),
                        );
                      }}
                      value={formik.values.zipCode || ''}
                      InputProps={{
                        inputComponent: InputZipCodeMask as any,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Buscar CEP"
                              onClick={() => {
                                fetchCepApi(formik.values.zipCode);
                              }}
                              edge="end"
                              size="small"
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={
                        formik.touched.zipCode && Boolean(formik.errors.zipCode)
                      }
                      helperText={
                        formik.touched.zipCode && formik.errors.zipCode
                      }
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexWrap="nowrap"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <TextField
                      id="cityCodeIBGE"
                      label="Código do IBGE"
                      name="cityCodeIBGE"
                      margin="dense"
                      autoComplete="off"
                      disabled={loadingCep}
                      style={{ display: 'flex', width: '50%' }}
                      fullWidth={!!account_id || false}
                      onChange={formik.handleChange}
                      value={formik.values.cityCodeIBGE}
                      error={
                        formik.touched.cityCodeIBGE &&
                        Boolean(formik.errors.cityCodeIBGE)
                      }
                      helperText={
                        formik.touched.cityCodeIBGE &&
                        formik.errors.cityCodeIBGE
                      }
                      InputLabelProps={{
                        shrink: !!formik.values.cityCodeIBGE || undefined,
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {loadingCep ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                          </>
                        ),
                      }}
                    />
                    <TextField
                      id="city"
                      label="Município"
                      name="city"
                      margin="dense"
                      autoComplete="off"
                      style={{
                        display: 'flex',
                        width: '50%',
                        marginLeft: '1rem',
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                      InputLabelProps={{
                        shrink: !!formik.values.city || undefined,
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {loadingCep ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                          </>
                        ),
                      }}
                    />
                  </Box>
                  <TextField
                    id="street"
                    label="Rua"
                    name="street"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    disabled={loadingCep}
                    onChange={formik.handleChange}
                    value={formik.values.street}
                    error={
                      formik.touched.street && Boolean(formik.errors.street)
                    }
                    helperText={formik.touched.street && formik.errors.street}
                    InputLabelProps={{
                      shrink: !!formik.values.street || undefined,
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {loadingCep ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </>
                      ),
                    }}
                  />
                  <TextField
                    id="district"
                    label="Bairro"
                    name="district"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    disabled={loadingCep}
                    onChange={formik.handleChange}
                    value={formik.values.district}
                    error={
                      formik.touched.district && Boolean(formik.errors.district)
                    }
                    helperText={
                      formik.touched.district && formik.errors.district
                    }
                    InputLabelProps={{
                      shrink: !!formik.values.district || undefined,
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {loadingCep ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </>
                      ),
                    }}
                  />
                  <Autocomplete
                    id="state"
                    getOptionLabel={state => state.label}
                    getOptionSelected={(state, value) =>
                      state.value === value.value
                    }
                    options={brazilianStates || []}
                    value={
                      brazilianStates.find(
                        s => s.value === formik.values.state,
                      ) || null
                    }
                    onChange={(e, value) => {
                      formik.setFieldValue('state', value?.value);
                    }}
                    loadingText="Carregando"
                    fullWidth
                    renderInput={rest => (
                      <TextField
                        {...rest}
                        id="state"
                        name="state"
                        label="Estado"
                        margin="dense"
                        value={formik.values.state}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={formik.touched.state && formik.errors.state}
                        InputProps={{
                          ...rest.InputProps,
                          endAdornment: (
                            <>
                              {loadingCep ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {rest.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                  <TextField
                    id="numeral"
                    label="Número"
                    name="numeral"
                    margin="dense"
                    autoComplete="off"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.numeral}
                    error={
                      formik.touched.numeral && Boolean(formik.errors.numeral)
                    }
                    helperText={formik.touched.numeral && formik.errors.numeral}
                    InputLabelProps={{
                      shrink: !!formik.values.numeral || undefined,
                    }}
                  />
                  <TextField
                    id="complement"
                    label="Complemento"
                    name="complement"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    disabled={loadingCep}
                    onChange={formik.handleChange}
                    value={formik.values.complement}
                    error={
                      formik.touched.complement &&
                      Boolean(formik.errors.complement)
                    }
                    helperText={
                      formik.touched.complement && formik.errors.complement
                    }
                    InputLabelProps={{
                      shrink: !!formik.values.complement || undefined,
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {loadingCep ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </>
                      ),
                    }}
                  />
                  <AccountCustomFields
                    accountFields={accountFields.filter(
                      (field: any) => field.isInactive !== true,
                    )}
                    fieldValues={fieldValues}
                    setFieldValues={setFieldValues as any}
                  />
                </>
              )}
            </Box>
            <ButtonMaterialUI
              className={classes.buttonSubmit}
              disabled={formik.isSubmitting}
              size="large"
              variant="contained"
              color="inherit"
              onClick={goBack}
            >
              Voltar
            </ButtonMaterialUI>
            <Button
              className={classes.buttonSubmit}
              disabled={userCantEditAccount || formik.isSubmitting}
              type="submit"
            >
              Salvar
            </Button>
          </Box>
        </form>
        {account_id && (
          <>
            <Divider className={classes.divider} />
            <ListContacts account_id={accountId as string} />
          </>
        )}
        {accountId &&
          isResponsibleAlertOpen &&
          pendingReassignments !== null && (
            <ResponsibleUserAlert
              open={isResponsibleAlertOpen}
              handleReturnSelectedOption={handleResponsibleUserAlertConfirm}
            />
          )}
        {accountId &&
          isResponsibleDialogOpen &&
          pendingReassignments !== null && (
            <ResponsibleUserEditDialog
              open={isResponsibleDialogOpen}
              responsibleUsers={responsibleUsers}
              handleCloseResponsibleUserDialog={
                handleCloseResponsibleUserDialog
              }
              users={users}
              areas={areas}
              pendingReassignments={pendingReassignments}
              account_id={accountId}
            />
          )}
      </Box>
    </Container>
  );
};

export default Account;

/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { parse } from 'mathjs';
import { AccountCustomFieldValues, ICardData } from 'src/interface/ICardData';
import { ICustomField } from 'src/interface/ICardFields';
import { convertTimestampToNumeric } from 'src/utils/customFieldUtils';

/**
 * Exctract UUID (field id) and values (from cardFields) to be used in a numeric field rule expression
 * @param {ICardData[]} dataStructure - the array of cardFields
 * @param {AccountCustomFieldValues[]} accountFields - the array of accountFields values
 * @param {string} actionFieldId - the field ID that the action is being applied
 * @returns 'A object of UUID keys and values'
 */
export function extractUuidValuesFromNumericAndTimestampFields(
  dataStructure: ICardData[],
  accountFields: AccountCustomFieldValues[],
  actionFieldId: string,
): {
  [key: string]: string;
} {
  const uuidValues: { [key: string]: string } = {};

  dataStructure.forEach(phase => {
    phase.customFields.forEach(customField => {
      if (
        (customField.type === 'NUMERIC' || customField.type === 'TIMESTAMP') &&
        customField.value !== null &&
        customField.value !== '' &&
        customField.id !== actionFieldId
      ) {
        uuidValues[`@${customField.id}`] =
          customField?.type === 'NUMERIC'
            ? customField?.value?.toString() || ''
            : convertTimestampToNumeric(
                customField?.value?.toString() || '',
                customField?.dateType as any,
              ).toString();
      } else if (
        customField.type === 'TABLE' &&
        customField.tableColumns &&
        customField.valueJSON &&
        !!customField.tableColumns.find(
          cf => cf.type === 'NUMERIC' || cf.type === 'TIMESTAMP',
        )
      ) {
        const tableJSON = customField.valueJSON;
        const tableNumericAndTimestampFields = customField.tableColumns.filter(
          cf => cf.type === 'NUMERIC' || cf.type === 'TIMESTAMP',
        );

        if (tableNumericAndTimestampFields) {
          tableNumericAndTimestampFields.forEach(field => {
            let totalFieldValue = 0;
            tableJSON.forEach((row: any, index: number) => {
              // sum value when there is table row and field value
              if (tableJSON[index][field.id]?.value) {
                const tableFieldValue = tableJSON[index][field.id].value;
                const formattedValue =
                  field?.type === 'NUMERIC'
                    ? Number(tableFieldValue) || 0
                    : convertTimestampToNumeric(
                        tableFieldValue.toString(),
                        field?.dateType as any,
                      );

                totalFieldValue += formattedValue || 0;
                uuidValues[`@${field.id}_row_${index}`] =
                  formattedValue.toString();
              }
            });

            uuidValues[`@${field.id}`] = totalFieldValue.toString();
          });
        }
      }
    });
  });

  accountFields.forEach(accountField => {
    if (
      (accountField.field.type === 'NUMERIC' ||
        accountField.field.type === 'TIMESTAMP') &&
      accountField.value !== null &&
      accountField.value !== '' &&
      accountField.field.id !== actionFieldId
    ) {
      uuidValues[`@${accountField.field.id}`] =
        accountField.field.type === 'NUMERIC'
          ? accountField.value.toString()
          : convertTimestampToNumeric(
              accountField.value.toString(),
              accountField.field.dateType as any,
            ).toString();
    }
  });

  return uuidValues;
}

/**
 * Format the rule arithmetic expression (replacing fields UUID with values) and call the evaluateExpression function
 * @param {string} expression - the original expression string
 * @param {{ [key: string]: string }} uuidValues - object of numeric fields UUID key and value
 * @param {ICustomField | undefined} tableField - a custom table field
 * @param {number | undefined} rowIndex - the field ID that the action is being applied
 * @returns 'Number|null: the result of the evaluated expression'
 */
export function evaluateArithmeticExpression(
  expression: string,
  uuidValues: { [key: string]: string },
  tableField?: ICustomField,
  rowIndex?: number,
): number | null {
  let fieldExpression = expression;
  const variablePattern =
    /@[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(?:_row_\d+)?/g;
  const uuidMatches = fieldExpression.match(variablePattern);
  const uniqueUuids: string[] = [];

  // If no UUIDs are found, evaluate the expression as is
  if (!uuidMatches) {
    return evaluateExpression(fieldExpression);
  }

  for (const uuid of uuidMatches) {
    let belongsToSameTable = false;
    if (
      tableField &&
      tableField?.tableColumns &&
      rowIndex !== undefined &&
      rowIndex >= 0
    ) {
      const onlyUUID = uuid.replace(/@/g, '');
      belongsToSameTable = !!tableField.tableColumns.find(
        column => column.id === onlyUUID,
      );
    }
    if (!uniqueUuids.includes(uuid)) {
      uniqueUuids.push(!belongsToSameTable ? uuid : `${uuid}_row_${rowIndex}`);

      if (belongsToSameTable) {
        fieldExpression = fieldExpression.replace(
          new RegExp(uuid, 'g'),
          `${uuid}_row_${rowIndex}`,
        );
      }
    }
  }

  for (const uuid of uniqueUuids) {
    if (
      Object.prototype.hasOwnProperty.call(uuidValues, uuid) &&
      (typeof uuidValues[uuid] === 'string' ||
        typeof uuidValues[uuid] === 'number')
    ) {
      const replacement = uuidValues[uuid].toString();
      fieldExpression = fieldExpression.replace(
        new RegExp(uuid, 'g'),
        replacement,
      );
    } else {
      // UUID not found in uuidValues or its value is invalid, stop and return null
      return null;
    }
  }

  // Check if there are any unresolved UUIDs
  if (variablePattern.test(fieldExpression)) {
    return null;
  }

  return evaluateExpression(fieldExpression);
}

/**
 * Parse and evaluate the converted arithmetic expression
 * @param {string} expression - the formatted expression (uuid replaced by it's cardField value)
 * @returns 'Number|null: the result of the evaluated expression'
 */
function evaluateExpression(expression: string): number | null {
  try {
    const parsedExpression = parse(expression);
    const result = parsedExpression.evaluate();

    if (typeof result === 'number' && Number.isFinite(result)) {
      return result;
    }
    throw new Error('Result is not a valid number');
  } catch (error) {
    // Handle errors or invalid expressions
    return null;
  }
}

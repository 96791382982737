/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';

import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import {
  Box,
  Button as ButtonMaterialUI,
  Checkbox,
  Container,
  createStyles,
  FormControlLabel,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';

import { toast } from 'react-toastify';
import Button from '../../components/Button';

import api from '../../services/api';

import { IFormData, IPhase, IPhaseParams, IPhaseProps } from './Phase.i';
import { styles } from './styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const Phase: React.FC<IPhaseProps> = ({
  closeModal,
  area_id_prop,
  phase_id_prop,
}) => {
  const classes = useStyles();

  const params = useParams<IPhaseParams>();

  const area_id = area_id_prop || params.area_id;
  const phase_id = phase_id_prop || params.phase_id;

  const goBack = (shouldRefreshData: boolean) => {
    if (phase_id_prop || area_id_prop) {
      closeModal(shouldRefreshData);
    } else {
      history.goBack();
    }
  };

  const [phase, setPhase] = useState<IPhase>();

  const history = useHistory();

  useEffect(() => {
    if (phase_id)
      api.get(`/phases/${phase_id}`).then(response => setPhase(response.data));
  }, [phase_id]);

  const initialValues: IFormData = {
    name: phase ? phase.name : '',
    description: phase ? phase.description : '',
    isInactive: phase ? phase.isInactive : false,
  };

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required('Obrigatório')
      .min(2, 'O nome deve ter pelo menos 2 caracteres'),
    description: Yup.string().required('Descrição é Obrigatório'),
    isInactive: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (phase_id) {
          await api.put(`/phases/${phase_id}`, {
            name: values.name,
            description: values.description,
            isInactive: values.isInactive,
          });
          toast.success('Fase editada com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          goBack(true);
        } else {
          await api.post('/phases', {
            name: values.name,
            description: values.description,
            isInactive: values.isInactive,
            area_id,
          });
          toast.success('Fase salva com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          setTimeout(() => {
            goBack(true);
          }, 2000);
        }
        setSubmitting(true);
      } catch (error: any) {
        setSubmitting(false);

        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    },
  });

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        {phase_id ? (
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            {formik.values.name || ''}
          </Typography>
        ) : (
          <div />
        )}
        <form noValidate onSubmit={formik.handleSubmit}>
          <TextField
            id="name"
            label="Nome"
            name="name"
            autoComplete="name"
            margin="normal"
            autoFocus
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            id="description"
            label="Descrição"
            name="description"
            autoComplete="description"
            margin="normal"
            fullWidth
            multiline
            minRows={1}
            maxRows={4}
            onChange={formik.handleChange}
            value={formik.values.description}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
          <FormControlLabel
            style={{
              display: 'block',
            }}
            control={
              <Checkbox
                name="isInactive"
                checked={formik.values.isInactive}
                onChange={formik.handleChange}
              />
            }
            label="Inativar"
          />
          <ButtonMaterialUI
            className={classes.buttonSubmit}
            disabled={formik.isSubmitting}
            size="large"
            variant="contained"
            color="inherit"
            onClick={() => goBack(false)}
          >
            Voltar
          </ButtonMaterialUI>
          <Button
            className={classes.buttonSubmit}
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
          >
            Salvar
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Phase;

import React from 'react';
import { Button } from 'src/components/ui/button';
import { UserFooterProps } from '../User.i';

export const UserButtons: React.FC<UserFooterProps> = ({
  formik,
  goBack,
  editPermissionDisabledForUser,
  isSameUser,
}) => {
  return (
    <div className="flex gap-2 mr-4">
      <Button
        disabled={formik.isSubmitting}
        size="default"
        variant="secondary"
        color="inherit"
        onClick={goBack}
        className="min-w-24"
      >
        Voltar
      </Button>
      <Button
        disabled={
          (editPermissionDisabledForUser && !isSameUser) || formik.isSubmitting
        }
        type="submit"
        color="primary"
        variant="default"
        className="min-w-24"
        onClick={() => formik.handleSubmit()}
      >
        Salvar
      </Button>
    </div>
  );
};

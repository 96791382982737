import { ICardData, ICardTask } from 'src/interface/ICardData';
import { ICustomField } from 'src/interface/ICardFields';
import { CFDateTypes } from 'src/utils/fieldTypes.constants';

export function a11yProps(index: string | number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export function updateCardCustomFieldArray(
  cardFields: ICardData[],
  phaseIndex: number,
  customFieldIndex: number,
  hideField: boolean,
  blockField: boolean,
  value?: string,
  valueJSON?: string[],
  predefinedActiveOptions?: string[],
): ICardData[] {
  const updatedPhases = cardFields || [];

  updatedPhases[phaseIndex].customFields[customFieldIndex].hasChanged = true;
  updatedPhases[phaseIndex].customFields[customFieldIndex].isHidden = hideField;
  updatedPhases[phaseIndex].customFields[customFieldIndex].isDisabled =
    blockField;

  if (typeof value !== 'undefined') {
    updatedPhases[phaseIndex].customFields[customFieldIndex].value = value;
  } else {
    updatedPhases[phaseIndex].customFields[customFieldIndex].valueJSON =
      valueJSON;
  }

  if (predefinedActiveOptions && predefinedActiveOptions.length > 0) {
    updatedPhases[phaseIndex].customFields[
      customFieldIndex
    ].predefinedActiveOptions = predefinedActiveOptions;
  }

  return updatedPhases;
}

export function findCustomFieldById(
  phases: ICardData[],
  customFieldId: string,
): ICustomField | null {
  const foundCustomField = phases
    .map(phase => phase.customFields.find(field => field.id === customFieldId))
    .find(customField => customField !== undefined);

  return foundCustomField || null;
}

export function findTaskById(
  phases: ICardData[],
  taskId: string,
): ICardTask | null {
  const foundTask = phases
    .map(phase => phase.cardTasks.find(field => field.id === taskId))
    .find(task => task !== undefined);

  return foundTask || null;
}

export function findPhaseAndCustomFieldIndexes(
  customFieldId: string,
  updatedPhases: ICardData[],
): {
  phaseIndex: number;
  customFieldIndex: number;
} {
  const phaseIndex = updatedPhases.findIndex(phase =>
    phase.customFields.some(field => field.id === customFieldId),
  );

  if (phaseIndex === -1) {
    return { phaseIndex: -1, customFieldIndex: -1 };
  }

  const customFieldIndex = updatedPhases[phaseIndex].customFields.findIndex(
    field => field.id === customFieldId,
  );

  return { phaseIndex, customFieldIndex };
}

/**
 * Transform a timestamp string value into milliseconds
 * @param {string} the timestamp customField value
 * @param {string} dateType of the timestamp customField
 * @returns The timestamp maped to milliseconds to be used in fillrule comparison
 */
export function mapTimestampToMilliseconds(
  timestamp: string,
  dateType: string,
): number {
  let output: number;
  const date = new Date(timestamp);
  if (Number.isNaN(date.getTime())) {
    throw new Error(`Timestamp '${timestamp}' inválido.`);
  }
  switch (dateType) {
    case CFDateTypes.DATE: {
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth();
      const day = date.getUTCDate();
      output = new Date(year, month, day, 0, 0, 0).getTime();
      break;
    }
    case CFDateTypes.TIME: {
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      output = hours * 60 * 60 * 1000 + minutes * 60 * 1000;
      break;
    }
    case CFDateTypes.DATETIME: {
      output = date.getTime();
      break;
    }
    default:
      throw new Error(`Tipo de campo timestamp '${dateType}' não existe.`);
  }
  return output;
}

/**
 * Transform a a partial(date or time) timestamp string value into milliseconds
 * @param {string} the timestamp customField value
 * @param {string} dateType of the timestamp customField
 * @returns The timestamp maped to milliseconds to be used in fillrule comparison
 */
export function mapPartialToTimestamp(
  partial: string,
  dateType: string,
): string {
  if (partial.length === 0) return '';
  try {
    switch (dateType) {
      case CFDateTypes.DATE: {
        return new Date(partial).toISOString();
      }
      case CFDateTypes.TIME: {
        const [hours, minutes] = partial.split(':');
        const rightNow = new Date();
        rightNow.setUTCHours(Number(hours));
        rightNow.setUTCMinutes(Number(minutes));
        rightNow.setUTCSeconds(0);
        return rightNow.toISOString();
      }
      case CFDateTypes.DATETIME: {
        // Convert the local datetime to ISO string, preserving local timezone
        const [datePart, timePart] = partial.split('T');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hours, minutes] = timePart.split(':').map(Number);

        const localDate = new Date(year, month - 1, day, hours, minutes);
        return localDate.toISOString();
      }
      default:
        throw new Error(`Tipo de campo timestamp '${dateType}' não existe.`);
    }
  } catch (error) {
    if (error instanceof RangeError) {
      throw new Error(
        `Timestamp '${partial}' é inválido para o tipo ${dateType}.`,
      );
    } else {
      throw error;
    }
  }
}

import React, { useCallback, useState } from 'react';
import { CheckboxWithText } from 'src/components/CheckboxWithText';
import { Input } from 'src/components/ui/input';
import { TabsContent } from 'src/components/ui/tabs';
import { DataSourceParametersType } from 'src/services/dataSourceApi';
import { FIXED_CARD_FIELDS } from 'src/utils/dataSourceFixedFields';

export interface CardParamsTabContentProps {
  handleSelectedSourceOption: (params: {
    dataSource: 'accounts' | 'cards' | 'tasks' | 'areas';
    sourceOptionId: string;
    areaId?: string;
    isCardField: boolean;
    isCardTask: boolean;
  }) => void;
  dataSourceParameters: DataSourceParametersType;
  handleUncheckAllOptions: (
    dataSource: 'accounts' | 'cards' | 'tasks' | 'areas',
  ) => void;
  handleCheckAllOptions: (
    dataSource: 'accounts' | 'cards' | 'tasks' | 'areas',
    listOfIds: string[],
  ) => void;
  disableOptions?: boolean;
}

export const CardParamsTabContent: React.FC<CardParamsTabContentProps> = ({
  handleSelectedSourceOption,
  dataSourceParameters,
  handleUncheckAllOptions,
  handleCheckAllOptions,
  disableOptions = false,
}) => {
  const [search, setSearch] = useState('');
  const isAllChecked =
    FIXED_CARD_FIELDS.length === dataSourceParameters?.cards?.length;

  const handleFilterOptions = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value;
      if (searchValue.length === 0) {
        setSearch('');
      } else {
        setSearch(searchValue);
      }
    },
    [],
  );

  const handleCheckAllCardOptions = useCallback(() => {
    handleCheckAllOptions(
      'cards',
      FIXED_CARD_FIELDS.map(field => field.id),
    );
  }, [FIXED_CARD_FIELDS, handleCheckAllOptions]);

  const filteredCardFields =
    search.length > 0
      ? FIXED_CARD_FIELDS.filter(option => {
          const formattedSearch = search.toUpperCase().trim();
          return option.name.toUpperCase().trim().indexOf(formattedSearch) >= 0;
        })
      : FIXED_CARD_FIELDS;

  return (
    <TabsContent
      value="cardParams"
      className="flex-1 overflow-y-auto box-border"
    >
      <div className="sticky top-0 z-10 bg-white w-full py-2 flex flex-nowrap gap-2">
        <CheckboxWithText
          checked={isAllChecked}
          id="selectAllCardFixedFields"
          labelText={isAllChecked ? 'Desmarcar todos' : 'Marcar todos'}
          onCheckedChange={value => {
            if (!value) {
              handleUncheckAllOptions('cards');
            } else {
              handleCheckAllCardOptions();
            }
          }}
          className="min-w-fit px-2 truncate border rounded-md border-gray-300"
          disabled={disableOptions}
        />
        <Input
          id="search"
          name="search"
          required
          className="border border-gray-300 rounded-md w-full"
          placeholder="Pesquise por campos fixos de Card"
          onChange={handleFilterOptions}
          autoComplete="off"
        />
      </div>
      <div className="flex flex-row flex-wrap gap-2 items-center p-2">
        {filteredCardFields.map(field => (
          <CheckboxWithText
            key={field.id}
            checked={(dataSourceParameters?.cards || []).includes(field.id)}
            labelText={field.name}
            id={field.id}
            onCheckedChange={() =>
              handleSelectedSourceOption({
                dataSource: 'cards',
                sourceOptionId: field.id,
                isCardField: false,
                isCardTask: false,
              })
            }
            className="w-80 truncate"
            disabled={disableOptions}
          />
        ))}
      </div>
    </TabsContent>
  );
};

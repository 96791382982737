/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import { Loader2, SlidersHorizontalIcon } from 'lucide-react';
import { useWorkspaceFilters } from 'src/context/WorkspaceFiltersContext';
import { DatePickerWithRange } from 'src/components/DateRangePicker';
import { DateRange } from 'react-day-picker';
import CustomSelect from 'src/components/Select';
import api from 'src/services/api';
import { Button } from '../../../components/ui/button';
import { Filters, WorkspaceData } from '../types';
import { useAuth } from '../../../context/AuthContext';
import { OptionType } from '../Widgets/column-graph';

export interface FiltersWithFields extends Filters {
  readonly field_ids: string[];
}

interface FilterButtonProps {
  readonly users?: { id: string; name: string }[];
  readonly fieldOptions: OptionType[];
}

export const ColumnsFilter: React.FC<FilterButtonProps> = ({
  fieldOptions: initialFieldOptions,
  users,
}) => {
  const { filtersData, setFiltersData, areas, loading } = useWorkspaceFilters();
  const { user } = useAuth();
  const [selectedUser, setSelectedUser] = useState<OptionType[]>([user]);
  const [open, setOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const [selectedFields, setSelectedFields] = useState<OptionType[]>();
  const [selectedArea, setSelectedArea] = useState<OptionType[]>();
  const [fieldOptions, setFieldOptions] =
    useState<OptionType[]>(initialFieldOptions);

  useEffect(() => {
    if (filtersData.column?.startDate && filtersData.column?.endDate) {
      setDateRange({
        from: new Date(filtersData.column.startDate),
        to: new Date(filtersData.column.endDate),
      });
    }

    if (filtersData.column?.field_ids) {
      const selectFieldOptions = filtersData.column?.field_ids.map(
        field_id =>
          fieldOptions.find(field => field.id === field_id) || {
            id: field_id,
            name: '-',
          },
      );
      setSelectedFields(selectFieldOptions);
    }

    if (
      filtersData &&
      filtersData.column &&
      filtersData.column?.user_id &&
      users
    ) {
      setSelectedUser([
        users.find(user => user.id === filtersData.column?.user_id) || users[0],
      ]);
    }

    if (filtersData && filtersData.column && filtersData.column?.area_id) {
      setSelectedArea([
        areas.find(area => area.id === filtersData.column?.area_id) || areas[0],
      ]);
    }
  }, [filtersData.column]);

  useEffect(() => {
    if (
      selectedArea &&
      selectedArea.length > 0 &&
      !selectedArea.every(item => item === undefined)
    ) {
      api
        .post<WorkspaceData>('/workspace', {
          column: {
            field_ids: [],
            area_id: selectedArea[0].id,
          },
        })
        .then(response => {
          const newFieldOptions = response.data.column?.selectableFields || [];
          setFieldOptions(newFieldOptions);
          setSelectedFields(undefined);
        });
    }
  }, [selectedArea]);

  useEffect(() => {
    if (initialFieldOptions) {
      setFieldOptions(initialFieldOptions);
    }
  }, [initialFieldOptions]);

  const handleApplyFilter = () => {
    setFiltersData({
      ...filtersData,
      column: {
        field_ids: selectedFields?.map(field => field.id) || [],
        startDate: dateRange?.from?.toISOString() || undefined,
        endDate: dateRange?.to?.toISOString() || undefined,
        user_id: selectedUser[0].id || undefined,
        area_id:
          selectedArea && selectedArea[0] && selectedArea[0].id
            ? selectedArea[0].id
            : undefined,
      },
    });
  };

  const handleResetFilter = () => {
    setFiltersData({
      ...filtersData,
      column: {
        field_ids: [],
        startDate: undefined,
        endDate: undefined,
        user_id: undefined,
      },
    });
    setSelectedFields([]);
    setDateRange(undefined);
    setSelectedUser([user]);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {loading ? (
        <Button
          size="icon"
          variant="default"
          className="h-[32px] w-[50px]"
          disabled
        >
          <Loader2 className="h-5 w-5 animate-spin" />
        </Button>
      ) : (
        <PopoverTrigger>
          <Button
            size="icon"
            variant={
              filtersData.column &&
              (filtersData.column.startDate ||
                filtersData.column.endDate ||
                filtersData.column.user_id ||
                filtersData.column.field_ids.length > 0)
                ? 'default'
                : 'outline'
            }
            className="h-[32px] w-[50px]"
          >
            <SlidersHorizontalIcon size={18} />
          </Button>
        </PopoverTrigger>
      )}
      <PopoverContent className="flex flex-col w-full h-full gap-3">
        <DatePickerWithRange
          date={dateRange}
          setDate={setDateRange}
          placeholder="Período"
        />
        {user?.profile.categoryName === 'ADMINISTRATOR' && (
          <CustomSelect
            items={areas || []}
            selectedItems={
              selectedArea && !selectedArea.every(item => item === undefined)
                ? selectedArea
                : []
            }
            setSelectedItems={setSelectedArea}
            placeholder="Área"
          />
        )}
        {filtersData.column?.field_ids && (
          <CustomSelect
            maxItems={3}
            items={fieldOptions}
            selectedItems={selectedFields}
            setSelectedItems={setSelectedFields}
            placeholder="Campos"
          />
        )}
        {user?.profile.categoryName === 'ADMINISTRATOR' && (
          <CustomSelect
            items={users || []}
            selectedItems={selectedUser}
            setSelectedItems={setSelectedUser}
            placeholder="Usuário"
          />
        )}
        <Button
          onClick={() => {
            handleApplyFilter();
            setOpen(false);
          }}
        >
          Aplicar
        </Button>
        <Button
          type="reset"
          variant="secondary"
          onClick={() => {
            handleResetFilter();
            setOpen(false);
          }}
        >
          Limpar
        </Button>
      </PopoverContent>
    </Popover>
  );
};

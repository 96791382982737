import React from 'react';

import {
  Box,
  Button,
  createStyles,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';

import Swal from 'sweetalert2';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import { SearchMultipleFilesIcon } from 'src/assets/SearchMultipleFilesIcon';
import { SearchFileIcon } from 'src/assets/SearchFileIcon';
import api from 'src/services/api';

interface IValueType {
  name: string;
  url: string;
  size: number;
}

interface ModalProps {
  open: boolean;
  closeModal: (open: boolean) => void;
  title?: string;
  card_id: string;
  customFieldId: string;
  filesData: IValueType[];
  allowedFileTypes: string;
  allowsMultipleFiles: boolean;
  handleFillCustomFieldOnDeleteFile: (
    valueJSON: IValueType[],
    fieldId?: string,
  ) => void;
  setFilesData: (filesData: IValueType[]) => void;
  handleOnFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasSignaturesCollectionStarted: boolean;
  isDisabled?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      width: '100%',
      height: '90%',
      minWidth: '300px',
      maxHeight: '600px',
      overflow: 'auto',
      margin: 0,
    },
    children: {
      padding: 5,
      margin: 0,
      height: '100%',
      width: '100%',
      overflow: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
    },
    goBackButton: {
      textDecoration: 'none',
      '&:hover': { textDecoration: 'none' },
      fontSize: '12px',
    },
    selectFileButton: {
      margin: '8px',
      color: '#FFF',
      padding: '8px',
      background: 'linear-gradient(to right, #007dc6, #2f98fa)',
    },
    swalAlert: {
      zIndex: 99999,
    },
  }),
);

export const ModalFileField: React.FC<ModalProps> = ({
  open,
  closeModal,
  title,
  filesData,
  setFilesData,
  card_id,
  customFieldId,
  allowedFileTypes,
  allowsMultipleFiles,
  handleFillCustomFieldOnDeleteFile,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleOnFileChange,
  hasSignaturesCollectionStarted,
  isDisabled = false,
  ...rest
}) => {
  const classes = useStyles();

  function calcFileSize(fileSize: number) {
    if (fileSize > 1000000) {
      return `${(fileSize / (1000 * 1000)).toFixed(2)} MB`;
    }
    return `${(fileSize / 1000).toFixed(0)} KB`;
  }

  const handleDeleteFile = (fileName: string) => {
    Swal.fire({
      title: 'Deseja remover o arquivo?',
      text: 'Essa opção não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      customClass: {
        container: classes.swalAlert,
      },
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const deletingFiles = toast.loading(
            `Removendo arquivo ${fileName}...`,
          );

          api
            .delete(
              `/cards/${card_id}/customFields/${customFieldId}/files/${fileName}`,
            )
            .then(() => {
              const filterRemovedFile: IValueType[] = filesData.filter(
                f => f.name !== fileName,
              );
              handleFillCustomFieldOnDeleteFile(filterRemovedFile);
              setFilesData(filterRemovedFile);

              toast.update(deletingFiles, {
                render: `Arquivo ${fileName} removido com sucesso.`,
                type: 'success',
                isLoading: false,
                autoClose: 2000,
                theme: 'colored',
                position: toast.POSITION.TOP_RIGHT,
              });
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
              const responseError =
                error.response?.data.message || error.message;

              toast.update(deletingFiles, {
                render: `Falha remover arquivo ${fileName}. ${responseError}`,
                type: 'error',
                isLoading: false,
                autoClose: 5000,
                theme: 'colored',
                position: toast.POSITION.TOP_RIGHT,
              });
            })
            .finally(() => {
              document.body.style.cursor = 'auto';
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      }
    });
  };

  const getExtension = (fileName: string) => {
    const extension = fileName.split('.').pop();
    return extension;
  };

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      aria-labelledby={title}
      onClose={closeModal}
      maxWidth="sm"
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <DialogTitle
          id={title}
          style={{ maxWidth: '80%', overflow: 'hidden', paddingLeft: '16px' }}
        >
          <Box height="22px">
            <span>{title}</span>
          </Box>
        </DialogTitle>
        <Box mt="10px" mr="25px">
          <Button
            className={classes.goBackButton}
            size="medium"
            variant="contained"
            onClick={() => closeModal(!open)}
          >
            Voltar
          </Button>
        </Box>
      </Box>
      <DialogContent className={classes.children}>
        <Box
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <Button
            color="inherit"
            component="label"
            variant="text"
            size="small"
            startIcon={
              allowsMultipleFiles ? (
                <SearchMultipleFilesIcon htmlColor="#FFF" />
              ) : (
                <SearchFileIcon htmlColor="#FFF" />
              )
            }
            className={classes.selectFileButton}
            disabled={hasSignaturesCollectionStarted || isDisabled}
          >
            Novo arquivo
            <input
              type="file"
              id={`modal ${customFieldId}`}
              multiple={allowsMultipleFiles}
              onChange={handleOnFileChange}
              hidden
              accept={allowedFileTypes}
            />
          </Button>
          <List>
            {filesData &&
              filesData.length > 0 &&
              Object.values(filesData).map((file: IValueType) => {
                return (
                  <div
                    key={file.name}
                    style={{
                      width: '100%',
                      boxSizing: 'border-box',
                    }}
                  >
                    <ListItem
                      style={{
                        width: '100%',
                        boxSizing: 'border-box',
                      }}
                    >
                      <Link
                        color="inherit"
                        underline="none"
                        target="_blank"
                        href={file.url}
                        display="block"
                        style={{
                          width: '100%',
                          boxSizing: 'border-box',
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              display="block"
                              align="left"
                              style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '100%',
                                whiteSpace: 'break-spaces',
                              }}
                            >
                              {file.name}
                            </Typography>
                          }
                          secondary={`Tamanho: ${calcFileSize(
                            file.size,
                          )} - Extensão: ${getExtension(file.name)}`}
                        />
                      </Link>
                      <ListItemSecondaryAction>
                        <Tooltip title="Remover arquivo">
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDeleteFile(file.name)}
                            disabled={
                              hasSignaturesCollectionStarted || isDisabled
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>

                    <Divider />
                  </div>
                );
              })}
          </List>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

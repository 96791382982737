import React from 'react';

import { TextField } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { ICustomField } from 'src/interface/ICardFields';
import { IGetAccountCustomFieldApi } from 'src/services/accountCustomFieldApi';
import { FixedAccountField } from 'src/utils/fixedAccountFields';
import { ITask } from '../../FillRule/FillRules.i';
import { IPhase } from '../Condition.i';

interface ITriggerProps {
  handleChange: (
    field: string,
    value: string,
    index: number,
    itemIndex: number,
    customFieldId?: string,
  ) => void;
  optionsArray: Array<
    | ICustomField
    | ITask
    | IPhase
    | IGetAccountCustomFieldApi
    | FixedAccountField
  >;
  groupIndex: number;
  itemIndex: number;
  itemId: string;
  fieldType: string;
  isCardField?: boolean;
}

export const OptionList: React.FC<ITriggerProps> = ({
  handleChange,
  optionsArray,
  groupIndex,
  itemIndex,
  itemId,
  fieldType,
  isCardField = false,
}) => {
  const isNotTask = fieldType !== 'task' && fieldType !== 'overdueTask';
  return (
    <Autocomplete
      id={`about ${groupIndex} ${itemIndex} ${fieldType}`}
      getOptionLabel={opt => opt.name}
      getOptionSelected={(opt, value) => opt.id === value.id}
      groupBy={field =>
        'phase' in field ? (field as ICustomField | ITask).phase?.name : ''
      }
      options={optionsArray || []}
      value={
        optionsArray[optionsArray.findIndex(field => field.id === itemId)] ||
        null
      }
      onChange={(e, value) => {
        const customFieldType = 'type' in value ? value.type : '';
        const isFixedAccountField =
          isNotTask &&
          !isCardField &&
          'phase' in value &&
          value.phase?.name === 'Fixo';
        const isAccountField =
          isNotTask &&
          !isCardField &&
          'phase' in value &&
          value.phase?.name !== 'Fixo';

        const conditionType = isFixedAccountField
          ? 'accountFieldFixed'
          : isAccountField
          ? 'accountField'
          : fieldType;

        if (
          (fieldType === 'customField' || fieldType === 'accountField') &&
          (customFieldType === 'FILE' ||
            customFieldType === 'BOOL' ||
            customFieldType === 'DOCUMENT')
        ) {
          handleChange(
            conditionType,
            value.id,
            groupIndex,
            itemIndex,
            customFieldType,
          );
        } else {
          handleChange(conditionType, value.id, groupIndex, itemIndex);
        }
      }}
      disableClearable
      size="small"
      loadingText="Carregando"
      style={{ marginRight: '3px' }}
      renderInput={rest => (
        <TextField
          {...rest}
          id={`about ${groupIndex} ${itemIndex} ${fieldType}`}
          label={
            fieldType === 'customField'
              ? 'Campo do Card'
              : fieldType === 'phase'
              ? 'Fase'
              : fieldType === 'accountField' ||
                fieldType === 'accountFieldFixed'
              ? 'Campo da Conta'
              : 'Tarefa'
          }
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          margin="dense"
          name="about"
          variant="outlined"
          size="small"
          InputProps={{
            ...rest.InputProps,
            style: {
              fontSize: '14px',
              width: '200px',
            },
          }}
        />
      )}
    />
  );
};

import React from 'react';

import PermissionComponent from 'src/components/PermissionComponent';
import { UserEmailsProps } from '../User.i';
import { EmailTable } from './EmailTable';

export const UserEmails: React.FC<UserEmailsProps> = ({
  handleSelectEmail,
  defaultEmailChecked,
  filteredEmails,
}) => {
  return (
    <PermissionComponent permissionName="BUSINESS">
      <div className="w-full border-2 p-2 rounded-sm">
        <div className="w-fit text-1xl font-bold ml-1 px-1 -mt-5 bg-white">
          E-mails
        </div>
        <EmailTable
          handleSelectEmail={handleSelectEmail}
          defaultEmailChecked={defaultEmailChecked}
          filteredEmails={filteredEmails}
          className="h-[250px] mt-2"
        />
      </div>
    </PermissionComponent>
  );
};

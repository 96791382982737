import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ConditionsExpression, IGroupStatus, TCondition } from '../Condition.i';

interface HookProps {
  setConditionsExpressionInput: Dispatch<SetStateAction<ConditionsExpression>>;
  conditionsExpressionInput: ConditionsExpression;
}

export function useCondition({
  setConditionsExpressionInput,
  conditionsExpressionInput,
}: HookProps) {
  const [groupStatus, setGroupStatus] = useState<IGroupStatus[]>([
    {
      index: 0,
      isOpen: true,
    },
  ]);

  function handleFillRuleConditionChange(
    field: string,
    value: string,
    index: number,
    itemIndex: number,
    customFieldType?: string,
  ) {
    setConditionsExpressionInput(oldValue => {
      const formattedConditions = [...oldValue];
      const groupIndex = formattedConditions[index] as Array<
        TCondition | string
      >;
      const item = groupIndex[itemIndex];

      if (field === 'groupCondition') {
        formattedConditions.splice(index, 1, value);
      }

      if (field === 'condition') {
        groupIndex.splice(itemIndex, 1, value);
      }

      if (typeof item === 'object') {
        if (field === 'type') {
          groupIndex[itemIndex] = { type: value, operator: '=' };
        }

        if (field === 'operator') {
          item.operator = value;
        }

        if (field === 'phaseValue') {
          item.phase_ids = value.split(',');
        }

        if (field === 'task') {
          item.task_id = value;
          item.operator = '=';
        }

        if (field === 'taskDone') {
          item.taskDone = value === 'Concluída';
        }

        if (field === 'customField' || field === 'accountField') {
          item.customField_id = field === 'customField' ? value : undefined;
          item.accountField_id = field === 'accountField' ? value : undefined;
          item.accountFieldName = undefined;
          item.type = field === 'customField' ? 'customField' : 'accountField';
          if (customFieldType === 'FILE') item.value = 'FILE';
          if (customFieldType === 'DOCUMENT') item.value = 'DOCUMENT';
          if (customFieldType === 'FILE' || customFieldType === 'DOCUMENT')
            item.operator = '∃';
          if (customFieldType === 'BOOL') item.operator = '=';
        }

        if (field === 'accountFieldFixed') {
          item.accountFieldName = value;
          item.type = 'accountFieldFixed';
          item.value = '';
          item.operator = '=';
          item.accountField_id = undefined;
          item.customField_id = undefined;
        }

        if (field === 'customFieldValue') {
          item.value =
            item.accountFieldName === 'cpfCnpj'
              ? value.replace(/[^\d]/g, '')
              : value;
        }
      }
      return formattedConditions;
    });
  }

  function handleTriggerConditionChange(
    field: string,
    value: string,
    index: number,
    itemIndex: number,
    customFieldType?: string,
  ) {
    setConditionsExpressionInput(oldValue => {
      const formattedConditions = [...oldValue];
      const groupIndex = formattedConditions[index] as Array<
        TCondition | string
      >;
      const item = groupIndex[itemIndex];

      if (field === 'groupCondition') {
        formattedConditions.splice(index, 1, value);
      }

      if (field === 'condition') {
        groupIndex.splice(itemIndex, 1, value);
      }

      if (field === 'type') {
        groupIndex[itemIndex] = { name: value, type: value, operator: '=' };
      }

      if (field === 'type' && value === 'staleCard') {
        groupIndex[itemIndex] = {
          name: value,
          type: value,
          operator: undefined,
          staleCardHours: 1,
        };
      }

      if (typeof item === 'object') {
        if (field === 'task') {
          item.task_id = value;
        }

        if (field === 'overdueTask') {
          item.task_id = value;
          item.operator = undefined;
          item.name = `${item.type} ${item.task_id}`;
        }

        const regexInteger = /^[0-9\b]+$/;
        if (
          field === 'staleCard' &&
          Number(value) > 0 &&
          regexInteger.test(value)
        ) {
          item.staleCardHours = Number(value);
          item.name = `${item.type} Card parado mais de ${value}`;
        }

        if (field === 'operator') {
          item.operator = value;
        }

        if (field === 'customField' || field === 'accountField') {
          item.customField_id = field === 'customField' ? value : undefined;
          item.accountField_id = field === 'accountField' ? value : undefined;
          item.type = field === 'customField' ? 'customField' : 'accountField';
          item.accountFieldName = undefined;
          item.customFieldValue = '';
          if (customFieldType === 'FILE') item.customFieldValue = 'FILE';
          if (customFieldType === 'DOCUMENT')
            item.customFieldValue = 'DOCUMENT';
          if (customFieldType === 'FILE' || customFieldType === 'DOCUMENT')
            item.operator = '∃';
          if (customFieldType === 'BOOL') item.operator = '=';
        }

        if (field === 'accountFieldFixed') {
          item.accountFieldName = value;
          item.type = 'accountFieldFixed';
          item.customFieldValue = '';
          item.operator = '=';
          item.accountField_id = undefined;
        }

        if (field === 'taskDone') {
          item.taskDone = value === 'Concluída';
          item.name = `${item.type} ${item.task_id} ${item.operator} ${value}`;
        }

        if (field === 'customFieldValue') {
          item.customFieldValue =
            item.accountFieldName === 'cpfCnpj'
              ? value.replace(/[^\d]/g, '')
              : value;
          item.name = `${item.type} ${
            item?.customField_id ||
            item?.accountField_id ||
            item?.accountFieldName
          } ${item.operator} ${value}`;
        }

        if (field === 'dispatchType') {
          item.dispatchType = value;
          item.name = `${item.type} ${item.operator} ${value}`;
        }

        if (field === 'progress') {
          item.progressPercentage = Number(value);
          item.name = `${item.type} ${item.operator} ${value}`;
        }

        if (field === 'phaseEntering') {
          item.phaseEntering = value === 'true';
          item.name = `${item.type} ${item.operator} ${value}`;
        }
      }
      return formattedConditions;
    });
  }

  function handleAddCondition(index: number) {
    setConditionsExpressionInput(oldValue => {
      const tempExpression = [...oldValue];
      const groupIndex = tempExpression[index] as Array<TCondition | string>;

      if (groupIndex.length > 0) {
        groupIndex.push('AND', {} as TCondition);
      } else {
        groupIndex.push({} as TCondition);
      }
      return tempExpression;
    });
  }

  function handleDeleteCondition(index: number, indexItem: number) {
    setConditionsExpressionInput(oldValue => {
      const tempExpression = [...oldValue];
      const groupIndex = tempExpression[index] as Array<TCondition | string>;

      if (indexItem > 0) {
        if (
          groupIndex[indexItem - 1] === 'AND' ||
          groupIndex[indexItem - 1] === 'OR'
        ) {
          groupIndex.splice(indexItem - 1, 2);
        } else {
          groupIndex.splice(indexItem, 1);
        }
      } else {
        groupIndex.splice(indexItem, 2);
      }
      return tempExpression;
    });
  }

  function handleAddConditionGroup() {
    setConditionsExpressionInput(oldValue => {
      const tempExpression = [...oldValue];
      if (tempExpression.length > 0) {
        tempExpression.push('AND', [{} as TCondition]);
      } else {
        tempExpression.push([{} as TCondition]);
      }
      return tempExpression;
    });
  }

  function handleDeleteConditionGroup(index: number) {
    setConditionsExpressionInput(oldValue => {
      const tempExpression = [...oldValue];
      if (index > 0) {
        tempExpression.splice(index - 1, 2);
      } else {
        tempExpression.splice(index, 2);
      }
      return tempExpression;
    });
  }

  function toggleGroupBox(index: number) {
    setGroupStatus(oldValue => {
      const tempGroup = oldValue.map((group: IGroupStatus) => {
        if (group.index === index) {
          return {
            index,
            isOpen: !group.isOpen,
          };
        }
        return group;
      });
      return tempGroup;
    });
  }

  useEffect(() => {
    const tempGroupArray: IGroupStatus[] = [];
    conditionsExpressionInput.forEach(
      (condition: string | TCondition[], index: number) => {
        if (Array.isArray(condition)) {
          tempGroupArray.push({
            index,
            isOpen: true,
          });
        }
      },
    );

    if (
      tempGroupArray.length > 0 &&
      tempGroupArray.length !== groupStatus.length
    ) {
      setGroupStatus(tempGroupArray);
    } else if (tempGroupArray.length <= 0) {
      setGroupStatus([
        {
          index: 0,
          isOpen: true,
        },
      ]);
    }
  }, [conditionsExpressionInput]);

  return {
    handleFillRuleConditionChange,
    handleTriggerConditionChange,
    handleAddCondition,
    handleDeleteCondition,
    handleAddConditionGroup,
    handleDeleteConditionGroup,
    toggleGroupBox,
    groupStatus,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { createContext, useContext } from 'react';
import { ICustomField } from 'src/interface/ICardFields';

interface CardCustomFieldContextType {
  handleOpenModal: (
    customField: ICustomField,
    customFieldIndex: number,
    phaseIndex: number,
    tableRowIndex?: number,
  ) => void;
}

export const CardCustomFieldContext = createContext<
  CardCustomFieldContextType | undefined
>(undefined);

export const useCardCustomFieldContext = (): CardCustomFieldContextType => {
  const context = useContext(CardCustomFieldContext);
  if (!context) {
    throw new Error(
      'useCardCustomFieldContext must be used within a CardCustomFieldContextProvider',
    );
  }
  return context;
};

// src/components/Conditions/ConditionInputs/OptionType.tsx

import React from 'react';
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  fillRuleTypeFields,
  instantTriggerTypeFields,
  scheduledTriggerTypeFields,
} from 'src/utils/conditionTypes';
import { styles } from '../styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

interface ITypeField {
  id: string;
  label: string;
}

interface ITriggerProps {
  handleChange: (
    field: string,
    value: string,
    index: number,
    itemIndex: number,
    customFieldId?: string,
  ) => void;
  groupIndex: number;
  itemIndex: number;
  itemId: string;
  isScheduledTrigger?: boolean;
  isInstantTrigger?: boolean;
  allowedTypes?: string[];
}

export const OptionType: React.FC<ITriggerProps> = ({
  handleChange,
  groupIndex,
  itemIndex,
  itemId,
  isScheduledTrigger = false,
  isInstantTrigger = false,
  allowedTypes,
}) => {
  const classes = useStyles();

  const allTypeFields: ITypeField[] =
    !isScheduledTrigger && !isInstantTrigger
      ? fillRuleTypeFields
      : isScheduledTrigger
      ? scheduledTriggerTypeFields
      : isInstantTrigger
      ? instantTriggerTypeFields
      : [];

  const typeFields = allowedTypes
    ? allTypeFields.filter(type => allowedTypes.includes(type.id))
    : allTypeFields;

  const selectedValue: ITypeField | null =
    typeFields.find(field => field.id === itemId) || null;

  return (
    <Autocomplete
      id="type"
      getOptionLabel={type => type.label}
      getOptionSelected={(type, value) => type?.id === value?.id}
      options={typeFields}
      onChange={(e, value) =>
        handleChange('type', value!.id, groupIndex, itemIndex)
      }
      disableClearable
      value={selectedValue as ITypeField}
      loadingText="Carregando"
      size="small"
      className={classes.conditionField}
      renderInput={params => (
        <TextField
          {...params}
          id="type"
          label="Tipo"
          margin="dense"
          name="type"
          variant="outlined"
          size="small"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          InputProps={{
            ...params.InputProps,
            style: {
              fontSize: '14px',
              width: '180px',
            },
          }}
        />
      )}
    />
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Signature } from 'src/components/Signature';
import { DigitalSignatureIcon } from 'src/assets/DigitalSignatureIcon';
import {
  formatCfToRichTextVariables,
  IRichTextVariable,
} from 'src/utils/customFieldUtils';
import { ModalDocumentField } from 'src/components/ModalDocumentField';
import { PdfIcon } from 'src/assets/PdfIcon';
import api from 'src/services/api';
import { EditDocumentIcon } from 'src/assets/EditDocumentIcon';
import { ICustomField } from 'src/interface/ICardFields';
import { TaskHandleCardValueProps } from 'src/components/TaskModal/TaskModal.i';
import { documentStyles } from './documentStyles';

export interface IGenericFieldProps {
  customField: ICustomField;
  card_id: string;
  area_id: string;
  isDisabled: boolean;
  isRequired: boolean;
  handleFillCardField: (params: TaskHandleCardValueProps) => void;
}

const useStyles = makeStyles(() => createStyles(documentStyles()));

export const DocumentField = ({
  customField,
  card_id,
  area_id,
  isDisabled,
  isRequired,
  handleFillCardField,
}: IGenericFieldProps): JSX.Element => {
  const classes = useStyles();

  const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false);
  const [openModalDocument, setOpenModalDocument] = useState<boolean>(false);
  const [openModalSignature, setOpenModalSignature] = useState<boolean>(false);
  const [fetchFieldsCompleted, setFetchFieldsCompleted] = useState(false);
  const [template, setTemplate] = useState<string>('');
  const [signaturesCollectionStarted, setSignaturesCollectionStarted] =
    useState<boolean>(false);

  const [suggestionsCF, setSuggestionsCF] = useState<IRichTextVariable[]>(
    [] as IRichTextVariable[],
  );

  useEffect(() => {
    if (customField.value !== template) {
      handleFillCardField({
        fieldId: customField.id,
        newValue: template,
        valueProperty: 'value',
      });
    }
  }, [template]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && customField?.document && template === '') {
      setTemplate(
        customField.value && customField.value.length > 0
          ? customField?.value
          : customField.document?.template || '',
      );
    }
    return () => {
      isMounted = false;
    };
  }, [customField.value, customField?.document?.template]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && customField?.document?.signatureCollectionStarted) {
      setSignaturesCollectionStarted(
        customField.document.signatureCollectionStarted,
      );
    }
    return () => {
      isMounted = false;
    };
  }, [customField?.document?.signatureCollectionStarted]);

  useEffect(() => {
    let isMounted = true;
    if (area_id && isMounted) {
      if (isMounted) {
        api
          .get(`/areas/${area_id}/customFields`)
          .then((response: { data: any[] }) => {
            const filteredFields: ICustomField[] = response.data.filter(
              (field: { id: string; name: string; type: string }) =>
                field?.type !== 'DOCUMENT' && field?.type !== 'FILE',
            );
            const formattedSugestions = formatCfToRichTextVariables(
              filteredFields as any,
            );
            setSuggestionsCF(formattedSugestions);
            setFetchFieldsCompleted(true);
          })
          .catch(() => {
            toast.error('Falha ao carregar campos.', {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            });
          });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [area_id]);

  const handleCloseModalDocument = () => {
    setOpenModalDocument(false);
  };

  const handleCloseModalSignature = () => {
    setOpenModalSignature(false);
  };

  const handleGetPdfDocument = () => {
    const loadingPDF = toast.loading(`Carregando PDF ${customField.name}...`);
    document.body.style.cursor = 'progress';

    api
      .get(`cards/${card_id}/customFields/${customField.id}/getPDF`)
      .then(response => {
        const { url } = response.data.file;
        const { warning } = response.data;

        if (response && warning && warning.length > 0) {
          toast.warning(warning, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'dark',
            autoClose: 4000,
          });
        }

        toast.update(loadingPDF, {
          render: `PDF ${customField.name} carregado com sucesso.`,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
        window.open(url, '_blank');
      })
      .catch(e => {
        let responseError = '';
        responseError = e.response?.data.message || e.message;

        toast.update(loadingPDF, {
          render: `Falha ao carregar PDF ${customField.name}. ${responseError}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        setIsPdfLoading(false);
        document.body.style.cursor = 'auto';
      });
  };

  const handleRestoreTemplate = () => {
    setTemplate(customField?.document?.template || '');
    handleFillCardField({
      fieldId: customField.id,
      newValue: customField?.document?.template || '',
      valueProperty: 'value',
    });
  };

  return (
    <Box className={classes.documentType}>
      <TextField
        contentEditable={false}
        label={customField.name}
        autoComplete="off"
        required={
          isRequired && (template.length === 0 || template.trim() === '<p></p>')
        }
        InputLabelProps={{
          shrink: false,
          style: {
            maxWidth: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            paddingBottom: '1px',
          },
        }}
        style={{
          flex: '1',
          padding: 0,
          margin: 0,
        }}
        fullWidth
        margin="dense"
        InputProps={{
          disableUnderline: true,
          onKeyDown: (e: { preventDefault: () => void }) => {
            e.preventDefault();
            return false;
          },
        }}
      />
      <Box mt={1} mb={0} pb={0}>
        <IconButton
          aria-label="Editar documento"
          size="small"
          disabled={
            !customField?.document?.isEditable ||
            (customField?.document?.isEditable &&
              signaturesCollectionStarted) ||
            isDisabled
          }
          onClick={() => setOpenModalDocument(true)}
        >
          <Tooltip title="Editar documento">
            <div style={{ margin: 0, padding: 0 }}>
              <EditDocumentIcon />
            </div>
          </Tooltip>
        </IconButton>

        <IconButton
          aria-label="Definir assinaturas"
          size="small"
          disabled={!customField?.document?.isRequiredToSign || isDisabled}
          onClick={() => setOpenModalSignature(true)}
        >
          <Tooltip title="Assinaturas">
            <div style={{ margin: 0, padding: 0 }}>
              <DigitalSignatureIcon />
            </div>
          </Tooltip>
        </IconButton>

        <IconButton
          aria-label="Gerar PDF do template"
          size="small"
          onClick={() => {
            setIsPdfLoading(true);
            handleGetPdfDocument();
          }}
          disabled={isPdfLoading || signaturesCollectionStarted}
        >
          <Tooltip title="Visualizar PDF">
            <div style={{ margin: 0, padding: 0 }}>
              <PdfIcon />
            </div>
          </Tooltip>
        </IconButton>
      </Box>
      {openModalDocument && fetchFieldsCompleted && (
        <ModalDocumentField
          title={`Editar documento - ${customField.name}`}
          open={openModalDocument}
          closeModal={handleCloseModalDocument}
          template={template}
          setTemplate={setTemplate}
          suggestionsCF={suggestionsCF}
          handleRestoreTemplate={handleRestoreTemplate}
        />
      )}
      {openModalSignature && (
        <Signature
          title={`Assinaturas - ${customField.name}`}
          open={openModalSignature}
          closeModal={handleCloseModalSignature}
          card_id={card_id}
          signature_id={customField.document?.signature_id}
          cardField_id={customField.id}
          hasFilesOrTemplate
          setSignaturesCollectionStarted={setSignaturesCollectionStarted}
        />
      )}
    </Box>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Container,
  createStyles,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
  Button as ButtonMaterialUI,
  Chip,
} from '@material-ui/core';

import SendIcon from '@material-ui/icons/Send';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  allowedMimeTypes,
  cleanFileName,
  validateIfIsInvalidFile,
} from 'src/utils/fileUtils';
import api from '../../services/api';
import Button from '../Button';

import { useLoader } from '../../context/LoaderContext';
import { ModalProps, IFormData, IEmail } from './Email.i';
import { styles } from './styles';
import { CustomRenderCheckboxOption } from '../AutoCompleteCustomComponents/CustomRenderCheckboxOption';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export function EmailSenderModal({
  open,
  closeModal,
  title,
  titleLeft,
  receiver,
  cardId,
}: ModalProps): JSX.Element {
  const classes = useStyles();
  const { showLoading, hideLoading } = useLoader();

  const inputFile = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileList | null>(null);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [emailsTo, setEmailsTo] = useState<string[]>([]);
  const [newEmailTo, setNewEmailTo] = useState('');
  const [userEmails, setUserEmails] = useState<IEmail[]>([]);
  const [defaultEmail, setDefaultEmail] = useState<IEmail>();

  const MAX_FILE_SIZE = 5120;

  useEffect(() => {
    setEmailsTo(oldValues => [...oldValues, receiver]);
  }, [receiver]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let gettingUser = true;
    const storedUser = localStorage.getItem('@Brasao:user') || '';
    const user = JSON.parse(storedUser);

    try {
      api.get(`/users/${user.id}`).then(response => {
        const data = response?.data;
        const emails = data?.emails;
        if (emails !== undefined && emails.length > 0) {
          const formattedEmails = data.emails.map((email: IEmail) => {
            return {
              id: email.id,
              email: email.email,
              isDefault: email.isDefault,
            };
          });
          setUserEmails(formattedEmails);
          const eDefault = formattedEmails.find(
            (email: IEmail) => email.isDefault,
          );
          setDefaultEmail(eDefault || formattedEmails[0]);
        }
      });
    } catch {
      toast.error('Falha ao carregar e-mails do usário', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        autoClose: 5000,
      });
    }

    return () => {
      gettingUser = false;
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onButtonClick = (e: any) => {
    inputFile.current && inputFile.current.click();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      setFiles({} as FileList);
      setFileNames([]);
      return;
    }

    let invalidFiles = false;
    Object.values(e.target.files).forEach((f: File) => {
      if (validateIfIsInvalidFile(f, MAX_FILE_SIZE, 5)) {
        invalidFiles = true;
        setFiles({} as FileList);
        setFileNames([]);

        return;
      }

      !invalidFiles ? setFiles(e.target.files) : setFiles({} as FileList);
    });

    if (!invalidFiles) {
      const fileNameValues: string[] = [];
      Object.values(e.target.files).map((f: File) =>
        fileNameValues.push(cleanFileName(f.name)),
      );

      setFileNames(fileNameValues);
    } else {
      setFileNames([]);
    }
  };

  const goBack = () => {
    closeModal();
  };

  const initialValues: IFormData = {
    card_id: '',
    sender: defaultEmail?.email || '',
    email_to: [receiver],
    subject: '',
    message: '',
  };

  const formSchema = Yup.object().shape({
    sender: Yup.string().required('Remetente não pode ser vazio.'),
    email_to: Yup.array().required().min(1, 'Destinatário não pode ser vazio.'),
    subject: Yup.string().required('Assunto não pode ser vazio.'),
    message: Yup.string().required('Conteúdo não pode ser vazio.'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = new FormData();
        formData.append('card_id', cardId);
        formData.append('message', formik.values.message);
        formData.append('subject', formik.values.subject);
        formData.append('from', formik.values.sender);

        if (files && fileNames.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < files.length; i++) {
            formData.append(`attachments`, files[i]);
          }
        }

        if (emailsTo.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < emailsTo.length; i++) {
            formData.append(`to[${i}]`, emailsTo[i]);
          }
        }

        setSubmitting(true);
        showLoading('Aguarde, enviando e-mail.');
        await api.post('/emails/send', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('E-mail enviado com sucesso.', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      } catch (error: any) {
        setSubmitting(false);
        hideLoading();
        /* message:
           'Não foi possível enviar o email. Verifique as configurações.', */

        toast.warning(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
      setSubmitting(false);
      hideLoading();
    },
  });

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      onClose={closeModal}
      aria-labelledby={title}
      maxWidth="lg"
      // {...rest}
    >
      <DialogTitle id={title}>
        <Box marginTop="10px" justifyContent="space-between">
          <Box height="22px">
            <span>{title}</span>
          </Box>
          <Box>
            <span style={{ fontWeight: 'bolder' }}>{titleLeft}</span>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.children}>
        <Container>
          <Box className={classes.content}>
            <Box>
              <form noValidate onSubmit={formik.handleSubmit}>
                <Autocomplete
                  id="sender"
                  options={userEmails}
                  getOptionLabel={email => email.email || ''}
                  getOptionSelected={(email, value) => email.id === value.id}
                  fullWidth
                  value={defaultEmail || null}
                  onChange={(e, value) => {
                    formik.setFieldValue('sender', value?.email);
                    setDefaultEmail(value ?? undefined);
                  }}
                  loadingText="Carregando"
                  renderInput={rest => (
                    <TextField
                      {...rest}
                      name="sender"
                      label="Remetente"
                      margin="normal"
                      type="text"
                      error={
                        formik.touched.sender && Boolean(formik.errors.sender)
                      }
                      helperText={formik.touched.sender && formik.errors.sender}
                      InputProps={{
                        ...rest.InputProps,
                      }}
                    />
                  )}
                />
                <Autocomplete
                  id="email_to"
                  multiple
                  freeSolo
                  limitTags={2}
                  options={emailsTo}
                  getOptionSelected={(emails, value) => emails === value}
                  fullWidth
                  onChange={(e, value) => {
                    /* if (value?.length > 0) {
                      setEmailsTo(value);
                    } else {
                      setEmailsTo([receiver]);
                    } */
                    setEmailsTo(value);
                    setNewEmailTo('');
                    formik.setFieldValue('email_to', value);
                  }}
                  value={emailsTo}
                  inputValue={newEmailTo}
                  onInputChange={(e, value, reason) => {
                    if (reason === 'input') {
                      setNewEmailTo(value);
                    }
                  }}
                  loadingText="Carregando"
                  renderOption={(option, { selected }) => (
                    <CustomRenderCheckboxOption
                      option={option}
                      selected={selected}
                    />
                  )}
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => (
                      <Chip
                        key={option}
                        {...getTagProps({ index })}
                        label={option}
                        size="small"
                        style={{
                          fontSize: '12px',
                          // color: '#fafafa',
                          margin: '1px',
                          // backgroundColor: '#53a546',
                        }}
                      />
                    ));
                  }}
                  renderInput={rest => (
                    <TextField
                      {...rest}
                      name="email_to"
                      label="Destinatário(os)"
                      margin="normal"
                      type="text"
                      placeholder="Inserir um novo e-mail..."
                      error={
                        formik.touched.email_to &&
                        Boolean(formik.errors.email_to)
                      }
                      helperText={
                        formik.touched.email_to && formik.errors.email_to
                      }
                      InputProps={{
                        ...rest.InputProps,
                      }}
                    />
                  )}
                />
                <TextField
                  id="subject"
                  label="Assunto"
                  name="subject"
                  type="text"
                  margin="dense"
                  autoComplete="off"
                  autoFocus
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                  error={
                    formik.touched.subject && Boolean(formik.errors.subject)
                  }
                  helperText={formik.touched.subject && formik.errors.subject}
                />
                <TextField
                  id="message"
                  label="Conteúdo"
                  name="message"
                  type="text"
                  margin="dense"
                  autoComplete="off"
                  fullWidth
                  multiline
                  minRows={10}
                  maxRows={10}
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                />
                <Box display="flex" flexDirection="row" flexWrap="nowrap">
                  <ButtonMaterialUI
                    onClick={e => onButtonClick(e)}
                    startIcon={<CloudUploadIcon />}
                    size="small"
                    variant="contained"
                    color="inherit"
                    style={{
                      marginTop: '15px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                      fontSize: '14px',
                    }}
                  >
                    Anexos
                  </ButtonMaterialUI>
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    multiple
                    style={{ display: 'none' }}
                    accept={allowedMimeTypes.toString()}
                    onChange={handleOnChange}
                  />
                </Box>
                {files && fileNames.length > 0 && (
                  <TextField
                    id="attachments"
                    label="Arquivos Selecionados"
                    name="attachments"
                    type="text"
                    margin="dense"
                    autoComplete="off"
                    multiline
                    minRows={1}
                    maxRows={4}
                    disabled
                    fullWidth
                    value={fileNames}
                  />
                )}
                <Box mt={2}>
                  <ButtonMaterialUI
                    className={classes.buttonSubmit}
                    style={{ height: '40px' }}
                    disabled={formik.isSubmitting}
                    size="small"
                    variant="contained"
                    color="inherit"
                    onClick={goBack}
                  >
                    Voltar
                  </ButtonMaterialUI>
                  <Button
                    className={classes.buttonSubmit}
                    disabled={formik.isSubmitting}
                    type="submit"
                    variant="contained"
                    style={{
                      height: '40px',
                      fontSize: '14px',
                      padding: '4px 10px',
                    }}
                    size="small"
                  >
                    Enviar
                    <SendIcon fontSize="small" style={{ marginLeft: '10px' }} />
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
}

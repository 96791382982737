import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { Button } from 'src/components/ui/button';
import { SettingsIcon } from 'lucide-react';
import { useHistory } from 'react-router-dom';

interface ITitleAndButtonProps {}

export const UserListHeader: React.FC<ITitleAndButtonProps> = () => {
  const history = useHistory();

  const handleRedirectToProfiles = () => {
    history.push('/profiles');
  };

  return (
    <div className="w-full flex items-center my-5">
      <h1 className="text-3xl font-bold mr-8 mb-0">Usuários</h1>

      <div className="w-fit p-0">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              className="p-2 m-0 rounded-full w-fit h-fit"
            >
              <span className="sr-only">Open menu</span>
              <SettingsIcon className="h-6 w-6" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuLabel>Configurações</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="hover:bg-gray-200 cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                handleRedirectToProfiles();
              }}
            >
              Permissões
            </DropdownMenuItem>
            <DropdownMenuItem
              className="hover:bg-gray-200 cursor-pointer"
              onClick={e => {
                e.stopPropagation();
              }}
              disabled
            >
              Equipes
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

import React from 'react';

import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { ProfileCategory } from 'src/services/permissionsApi';
import { IUserProps } from './User.i';
import { styles } from './styles';
import { useUser } from './hooks/useUser';
import { UserData } from './components/UserData';
import { UserEmails } from './components/UserEmails';
import { UserButtons } from './components/UserButtons';
import { UserPermissions } from './components/UserPermissions';
import { UserPassword } from './components/UserPassword';

export const useStyles = makeStyles(() => createStyles(styles()));

export const User: React.FC<IUserProps> = ({
  closeModal = () => {},
  userIdProp,
  open,
}) => {
  const classes = useStyles();
  const {
    formik,
    user_id,
    businesses,
    areas,
    emails,
    loadingBusiness,
    loadingArea,
    filteredEmails,
    handleSelectEmail,
    defaultEmailChecked,
    goBack,
    profilesList,
    loadingProfiles,
    editPermissionDisabledForUser,
    handleChangePermission,
    permissionProperties,
    permissionsByCategories,
    isSameUser,
    isUserAdmin,
  } = useUser({ closeModal, userIdProp });

  const profilePermissions =
    profilesList && formik.values.profile?.id
      ? profilesList.find(profile => profile.id === formik.values.profile?.id)
          ?.activePermissions || []
      : [];
  const profileCategory: ProfileCategory | null =
    profilesList && formik.values.profile?.id
      ? (profilesList.find(profile => profile.id === formik.values.profile?.id)
          ?.category.name as ProfileCategory) || null
      : null;

  return (
    <Dialog
      maxWidth="xl"
      classes={{ paper: classes.content }}
      className="max-md:w-full max-md:h-full"
      open={open}
      scroll="paper"
      onClose={closeModal}
    >
      <div className="flex flex-row justify-between items-center flex-nowrap p-0 m-0 max-xs:flex-wrap max-xs:justify-center max-xs:mb-4">
        <DialogTitle className="max-w-[70%] overflow-hidden px-3 py-4 m-0 text-lg font-bold">
          <span>{user_id ? 'Editar Usuário' : 'Cadastrar Usuário'}</span>
        </DialogTitle>
        <UserButtons
          formik={formik}
          goBack={goBack}
          editPermissionDisabledForUser={editPermissionDisabledForUser}
          isSameUser={isSameUser}
        />
      </div>
      <DialogContent className="h-full w-full overflow-auto flex flex-wrap">
        {/* <div className="w-[1200px] h-fit min-h-[calc(90vh-50px)] max-h-screen"> */}
        <div className="w-[1200px] h-fit max-h-screen">
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className="flex items-start flex-col gap-4">
              <UserData
                formik={formik}
                businesses={businesses}
                loadingBusiness={loadingBusiness}
                loadingArea={loadingArea}
                areas={areas}
              />
              <div className="w-full flex flex-row gap-2 mb-8 max-md:flex-wrap box-border">
                <div className="w-full h-fit flex flex-col gap-4">
                  <UserPassword
                    user_id={user_id}
                    formik={formik}
                    isSameUser={isSameUser}
                    isUserAdmin={isUserAdmin}
                  />
                  <UserEmails
                    emails={emails}
                    handleSelectEmail={handleSelectEmail}
                    defaultEmailChecked={defaultEmailChecked}
                    filteredEmails={filteredEmails}
                  />
                </div>
                <UserPermissions
                  profilePermissions={profilePermissions}
                  permissionsByCategories={permissionsByCategories}
                  permissionProperties={permissionProperties}
                  profileCategory={profileCategory}
                  handleChangePermission={handleChangePermission}
                  customPermissions={formik.values.customPermissions}
                  user_id={user_id}
                  profilesList={profilesList}
                  loadingProfiles={loadingProfiles}
                  formik={formik}
                  isUserAdmin={isUserAdmin}
                />
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

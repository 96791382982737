/* eslint-disable @typescript-eslint/no-explicit-any */
import JsPDF from 'jspdf';
import 'jspdf-autotable';

interface PDFExportOptions {
  margin?: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
  orientation?: 'portrait' | 'landscape';
  format?: string | number[];
}

export async function exportListToPDF(
  data: any[],
  options: PDFExportOptions = {},
  fileName: string = 'documento.pdf',
): Promise<void> {
  const {
    margin = { top: 15, bottom: 15, left: 10, right: 10 },
    orientation = 'landscape',
    format = 'a4',
  } = options;

  const pdf = new JsPDF({
    format,
    unit: 'mm',
    orientation,
  });

  const date = new Date();
  const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(
    date.getMonth() + 1,
  ).padStart(2, '0')}/${date.getFullYear()}`;

  const truncateText = (text: string, wordLimit: number = 25): string => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(' ')}...`;
    }
    return text;
  };

  const fontSize = 7;
  let currentY = margin.top + 10;

  const isObjectEmpty = (obj: any): boolean => {
    if (typeof obj !== 'object' || obj === null) {
      return true;
    }
    return Object.values(obj).every(value => {
      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          return value.length === 0;
        }
        return isObjectEmpty(value);
      }
      return value === '' || value === null || value === undefined;
    });
  };

  const isPrimitive = (val: any): boolean => {
    return val !== Object(val);
  };

  const totalPagesExp = '{total_pages_count_string}';

  // Recursividade para lidar com os Json Dinâmicos
  const processData = (
    data: any,
    depth: number = 0,
    parentKey: string = '',
  ) => {
    if (Array.isArray(data)) {
      if (data.length === 0) {
        pdf.setFontSize(fontSize);
        pdf.setFont('', 'italic');
        pdf.text('Sem valores', margin.left, currentY);
        pdf.setFont('', 'normal');
        currentY += 5;
        return;
      }

      if (data.every(isPrimitive)) {
        // Array de valores primitivos
        const headers = ['Valor'];
        const bodyData = data.map((item: any) => [String(item)]);
        pdf.autoTable({
          head: [headers],
          body: bodyData,
          startY: currentY,
          margin: { left: margin.left, right: margin.right },
          pageBreak: 'auto',
          horizontalPageBreak: true,
          showHead: 'everyPage',
          styles: {
            overflow: 'linebreak',
            fontSize,
            cellPadding: 1,
            cellWidth: 'wrap',
          },
          tableWidth: 'wrap',
        });

        if (pdf.lastAutoTable !== undefined) {
          currentY = (pdf.lastAutoTable as any).finalY + 10;
        } else {
          currentY += 10;
        }
        return;
      }

      // headers Dinâmicos
      const headers = Object.keys(
        data.reduce((result, obj) => Object.assign(result, obj), {}),
      );

      const bodyData = data.map((item: any) => {
        return headers.map(header => {
          const cellContent = item[header];
          if (
            typeof cellContent === 'object' &&
            cellContent !== null &&
            !Array.isArray(cellContent)
          ) {
            if (isObjectEmpty(cellContent)) {
              return 'Vazio';
            }
            return 'Veja detalhes';
          }
          if (Array.isArray(cellContent)) {
            if (cellContent.length === 0) {
              return 'Vazio';
            }
            return 'Veja detalhes';
          }
          if (typeof cellContent === 'string') {
            return truncateText(cellContent);
          }
          if (cellContent !== undefined && cellContent !== null) {
            return String(cellContent);
          }
          return '';
        });
      });

      pdf.autoTable({
        head: [headers],
        body: bodyData,
        startY: currentY,
        margin: { left: margin.left, right: margin.right },
        pageBreak: 'auto',
        horizontalPageBreak: true,
        showHead: 'everyPage',
        styles: {
          overflow: 'linebreak',
          fontSize,
          cellPadding: 1,
          cellWidth: 'wrap',
        },
        tableWidth: 'wrap',
      });

      if (pdf.lastAutoTable !== undefined) {
        currentY = (pdf.lastAutoTable as any).finalY + 10;
      } else {
        currentY += 10;
      }

      // Json aninhado
      data.forEach((item: any, index: number) => {
        headers.forEach(header => {
          const cellContent = item[header];
          if (typeof cellContent === 'object' && cellContent !== null) {
            if (
              (Array.isArray(cellContent) && cellContent.length > 0) ||
              (typeof cellContent === 'object' && !isObjectEmpty(cellContent))
            ) {
              pdf.setFontSize(fontSize + 1);
              pdf.setFont('', 'bold');
              pdf.text(
                `${'  '.repeat(depth)}Detalhes de "${header}" (Item ${
                  index + 1
                })`,
                margin.left,
                currentY,
              );
              pdf.setFont('', 'normal');
              currentY += 5;

              processData(cellContent, depth + 1, header);
            }
          }
        });
      });
    } else if (typeof data === 'object' && data !== null) {
      if (!isObjectEmpty(data)) {
        processData([data], depth, parentKey);
      } else {
        pdf.setFontSize(fontSize);
        pdf.setFont('', 'italic');
        pdf.text('Sem valores', margin.left, currentY);
        pdf.setFont('', 'normal');
        currentY += 5;
      }
    } else {
      pdf.setFontSize(fontSize);
      pdf.text(String(data), margin.left, currentY);
      currentY += 5;
    }
  };

  processData(data);

  const totalPages = pdf.getNumberOfPages();

  if (typeof pdf.putTotalPages === 'function') {
    pdf.putTotalPages(totalPagesExp);
  }

  for (let i = 1; i <= totalPages; i += 1) {
    pdf.setPage(i);

    pdf.setFontSize(10);
    pdf.setTextColor(0);
    pdf.setFont('', 'normal');
    pdf.text(formattedDate, margin.left, margin.top - 5);

    const { pageSize } = pdf.internal;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    let str = `Página ${i}`;
    if (typeof pdf.putTotalPages === 'function') {
      str = `${str} de ${totalPages}`;
    }
    pdf.setFontSize(10);
    pdf.text(
      str,
      pdf.internal.pageSize.getWidth() - margin.right - 30,
      pageHeight - margin.bottom + 10,
    );
  }

  pdf.save(fileName);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Card } from 'src/components/ui/card';
import { DataTable } from 'src/components/ui/data-table';
import { DataTableContext } from 'src/context/DataTableContext';
import { DeleteAlertDialog } from 'src/components/DeleteAlertDialog';

import { UserColumns } from './UserColumns';
import { useUserList } from '../../hooks/useUserList';
import { UserListHeader } from '../Header';
import { User } from '../..';

const ListUser: React.FC = () => {
  const {
    handleEdit,
    handleAddUser,
    handleCloseModal,
    users,
    openUserModal,
    columnsToFilter,
    deleteAlertState,
    handleOpenDeleteUserModal,
    handleDeleteUser,
    handleDeleteAlertState,
    editPermissionDisabledForUser,
  } = useUserList();

  return (
    <div className="w-full h-full px-8 max-sm:px-2">
      <div className="max-w-6xl">
        <UserListHeader />
        <Card>
          <div className="w-full rounded-md p-1 overflow-hidden">
            <DataTableContext.Provider
              value={{
                handleOpenModal: handleEdit,
                handleDeleteItem: handleOpenDeleteUserModal,
              }}
            >
              <DataTable
                columns={UserColumns as any}
                data={users}
                showTableToolbar
                pageSize={15}
                onClickRow={handleEdit}
                showGlobalSearchInput
                showFilters
                columnsToFilter={columnsToFilter}
                handleClickAddNewButton={
                  editPermissionDisabledForUser ? undefined : handleAddUser
                }
                fillEmptyRowsPerPage
              />
            </DataTableContext.Provider>
          </div>
        </Card>
        {openUserModal.isOpen && (
          <User
            closeModal={handleCloseModal}
            userIdProp={openUserModal?.userId || ''}
            open={openUserModal.isOpen}
          />
        )}
        {deleteAlertState.isOpen && deleteAlertState.userId && (
          <DeleteAlertDialog
            open={deleteAlertState.isOpen}
            setOpen={handleDeleteAlertState}
            itemId={deleteAlertState.userId}
            handleConfirmDelete={handleDeleteUser}
          />
        )}
      </div>
    </div>
  );
};

export default ListUser;

import {
  IAccount,
  ICardData,
  IHandleFillCustomField,
} from 'src/interface/ICardData';
import {
  IExecuteRuleReturnType,
  IFillRuleDictionary,
  TNewTableFieldValues,
} from 'src/interface/ICardFillRule';
import { ICustomField } from 'src/interface/ICardFields';
import { evaluateConditionsExpression } from './evaluateConditions';
import { executeRuleAction } from './executeRule';
import { executeTableFieldRuleAction } from './executeTableRule';
import {
  handleRestoreTableColumnOptions,
  handleRestoreActionOptions,
} from './restoreActions';
import { deepClone } from '../deepCloneObject';

const actionTypeList = [
  'ASSIGN_PREDEFINED',
  'HIDE_FIELD',
  'BLOCK_FIELD',
  'ASSIGN_AND_BLOCK_NUMERIC',
  'ASSIGN_AND_BLOCK_BOOL',
  'ASSIGN_AND_BLOCK_PREDEFINED',
  'ASSIGN_AND_HIDE_NUMERIC',
  'ASSIGN_AND_HIDE_BOOL',
  'ASSIGN_AND_HIDE_PREDEFINED',
  'RESTRICT_PREDEFINED',
];

/**
 * Evalute if the conditionFieldId is a fillRule condition of other fields
 * @param {string} conditionFieldId - the field id to evaluate if is condition
 * @param {ICardData[]} cardFields - the array of cardFields
 * @param {IAccount} accountFields - the account field values
 * @param {IFillRuleDictionary} fillRuleDictionary - the fillRule dictionary that contains the rules of eachField and if is condition of other
 * @param {IHandleFillCustomField} handleFillCustomField - function that update cardFields state
 * @param {string} phaseId - the field phase id
 * @returns 'Call the execute rule function if the conditions are met'
 */
export function evaluateFillRulesAsFieldCondition(
  conditionFieldId: string,
  cardFields: ICardData[],
  accountFields: IAccount,
  fillRuleDictionary: IFillRuleDictionary,
  handleFillCustomField: IHandleFillCustomField,
  phaseId: string,
): void {
  const fieldsToEvaluate =
    fillRuleDictionary?.[conditionFieldId]?.isConditionOnFields;

  if (!fieldsToEvaluate || fieldsToEvaluate.length === 0) return;

  fieldsToEvaluate.forEach((fieldId: string) => {
    const rulesToEvaluate = fillRuleDictionary?.[fieldId]?.rules;
    const tableId = fillRuleDictionary?.[fieldId]?.belongsToTable_id;

    if (!rulesToEvaluate || rulesToEvaluate.length === 0) return;

    let actionWasExecuted = false;

    const clonedCardFields = deepClone(cardFields);
    rulesToEvaluate.sort((a, b) => a.order - b.order);
    rulesToEvaluate.forEach(rule => {
      if (!actionWasExecuted) {
        const result = evaluateConditionsExpression(
          rule,
          clonedCardFields,
          accountFields,
          phaseId,
        );
        const actionType = rule.action.type;
        if (result === true) {
          if (tableId) {
            executeTableFieldRuleAction(
              fieldId,
              rule.action,
              clonedCardFields,
              accountFields.fieldValues,
              tableId,
              handleFillCustomField,
              false,
            );
          } else {
            executeRuleAction(
              fieldId,
              rule.action,
              clonedCardFields,
              accountFields.fieldValues,
              false,
              handleFillCustomField,
            );
          }
          actionWasExecuted = true;
        } else if (actionTypeList.includes(actionType)) {
          if (tableId) {
            handleRestoreTableColumnOptions(
              fieldId,
              clonedCardFields,
              handleFillCustomField,
              tableId,
            );
          } else {
            handleRestoreActionOptions(
              fieldId,
              clonedCardFields,
              handleFillCustomField,
            );
          }
        }
      }
    });
  });
}

/**
 * Evalute the fillRule for each table column/field
 * @param {tableId} tableId - the table field id to be evaluated
 * @param {ICardData[]} cardFields - the array of cardFields
 * @param {IAccount} accountFields - the account field values
 * @param {IFillRuleDictionary} fillRuleDictionary - the fillRule dictionary that contains the rules of eachField and if is condition of other
 * @param {ICustomField[]} tableColumns - array of table columns/fields data
 * @param {string} cardCurrentPhaseId - the current phase id
 * @returns 'new table value for each column/field'
 */
export function evaluateTableColumnsRules(
  tableId: string,
  cardFields: ICardData[],
  accountFields: IAccount,
  fillRuleDictionary: IFillRuleDictionary,
  tableColumns: ICustomField[],
  cardCurrentPhaseId: string,
): TNewTableFieldValues | null {
  let newTableData: TNewTableFieldValues | null = null;

  tableColumns.forEach(column => {
    const rulesToEvaluate = fillRuleDictionary?.[column.id]?.rules;
    let actionWasExecuted = false;

    if (rulesToEvaluate) {
      rulesToEvaluate.sort((a, b) => a.order - b.order);
      rulesToEvaluate.forEach(rule => {
        if (!actionWasExecuted) {
          const noConditionsToEvaluate = !!(
            rulesToEvaluate.length === 1 &&
            rulesToEvaluate[0].conditions.length === 0
          );
          const result = noConditionsToEvaluate
            ? true
            : evaluateConditionsExpression(
                rule,
                cardFields,
                accountFields,
                cardCurrentPhaseId,
              );

          if (result === true) {
            const actionResult = executeTableFieldRuleAction(
              column.id,
              rule.action,
              cardFields,
              accountFields.fieldValues,
              tableId,
              undefined,
              true,
            ) as IExecuteRuleReturnType;
            newTableData = actionResult.newTableValues;
            actionWasExecuted = true;
          }
        }
      });
    }
  });

  return newTableData;
}

/**
 * Evalute rules that don't have a field as condition
 * @param {string} fieldId - the field id to evaluate the conditions
 * @param {ICardData[]} cardFields - the array of cardFields
 * @param {IAccount} accountFields - the account field values
 * @param {IFillRuleDictionary} fillRuleDictionary - the fillRule dictionary that contains the rules of eachField and if is condition of other
 * @param {string} cardCurrentPhaseId - current phase id
 * @param {string | undefined} belongsToTable_id - if a field belongs to a table field
 * @returns 'New card field/table values'
 */
export function evaluateRuleWithoutFieldCondition(
  fieldId: string,
  cardFields: ICardData[],
  accountFields: IAccount,
  fillRuleDictionary: IFillRuleDictionary,
  cardCurrentPhaseId: string,
  belongsToTable_id?: string,
): IExecuteRuleReturnType {
  let tempCardFields: ICardData[] = cardFields;
  const shouldReturnNewArrayOfFields = !belongsToTable_id;
  const shouldReturnTableData = !!belongsToTable_id;
  let newTableValues: TNewTableFieldValues | null = null;
  const fieldOwnRules = fillRuleDictionary?.[fieldId]?.rules || [];
  const hasRules = fieldOwnRules?.length > 0 || false;

  if (hasRules) {
    const sortedRules =
      fieldOwnRules.length > 1
        ? fieldOwnRules.sort((a, b) => a.order - b.order)
        : fieldOwnRules;
    const fieldOwnRulesWithoutCondition = sortedRules.filter(rule => {
      const isConditionNotCardField = !rule.conditions.find(
        c =>
          c.type !== 'phase' &&
          c.type !== 'task' &&
          c.type !== 'accountField' &&
          c.type !== 'accountFieldFixed',
      );
      const result = isConditionNotCardField
        ? evaluateConditionsExpression(
            rule,
            cardFields,
            accountFields,
            cardCurrentPhaseId,
          )
        : false;
      return (
        rule.action &&
        (!rule.conditions ||
          rule.conditions.length === 0 ||
          (isConditionNotCardField && result))
      );
    });

    if (
      fieldOwnRules &&
      fieldOwnRulesWithoutCondition &&
      fieldOwnRulesWithoutCondition[0]?.order === fieldOwnRules[0]?.order
    ) {
      if (shouldReturnNewArrayOfFields) {
        const actionResult = executeRuleAction(
          fieldId,
          fieldOwnRulesWithoutCondition[0].action,
          cardFields,
          accountFields.fieldValues,
          shouldReturnNewArrayOfFields,
        ) as IExecuteRuleReturnType;
        tempCardFields = actionResult.cardFields;
        tempCardFields.forEach(phase => {
          const cahngedFields = phase.customFields.map(cf => {
            if (cf.id === fieldId) {
              return { ...cf, hasChanged: true };
            }
            return { cf };
          });
          return { ...phase, customFields: cahngedFields };
        });
      }
      if (belongsToTable_id && shouldReturnTableData) {
        const actionResult = executeTableFieldRuleAction(
          fieldId,
          fieldOwnRulesWithoutCondition[0].action,
          cardFields,
          accountFields.fieldValues,
          belongsToTable_id,
          undefined,
          true,
        ) as IExecuteRuleReturnType;
        newTableValues = actionResult.newTableValues || null;
      }
    }
  }

  return {
    cardFields: tempCardFields,
    newTableValues,
  };
}

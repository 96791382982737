import React, { useEffect, useState } from 'react';
import { ConditionsExpression } from 'src/components/Conditions/Condition.i';
import api from 'src/services/api';
import { toastLoadingUpdate } from 'src/utils/toastifyUtils';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { IGetAccountCustomFieldApi } from 'src/services/accountCustomFieldApi';
import { formatConditionsExpression } from 'src/components/Conditions/utils/formatConditionsExpression';
import { ICustomField } from 'src/interface/ICardFields';
import {
  IActionValue,
  IFillRule,
  IFillRuleCustomFieldProps,
  ITypeField,
} from '../FillRules.i';
import {
  actionTypes,
  handleFormatActionValue,
  handleFormatResponseExpression,
  hasMultipleUUIDTypes,
  ISlugAndUUIDMap,
  replaceFieldsWithUUIDs,
  replaceUUIDsWithSlugs,
  stripHtmlTags,
  validateArithmeticExpression,
} from '../utils/fillRuleUtils';

interface HookProps {
  closeModal: () => void;
  handleRefreshCf: () => Promise<void>;
  customField: IFillRuleCustomFieldProps;
  fillRuleIdToEdit?: string;
  uuidToSlugMap: ISlugAndUUIDMap[];
  fieldFillRules: IFillRule[];
  customFields: ICustomField[];
  accountCustomFields?: IGetAccountCustomFieldApi[];
}

interface HookReturn {
  filteredActionType: ITypeField[];
  actionType: ITypeField;
  setActionType: React.Dispatch<React.SetStateAction<ITypeField>>;
  actionValue: IActionValue | null;
  setActionValue: React.Dispatch<React.SetStateAction<IActionValue | null>>;
  valueError: string | null;
  conditionsExpressionInput: ConditionsExpression;
  setConditionsExpressionInput: React.Dispatch<
    React.SetStateAction<ConditionsExpression>
  >;
  onSubmitFillRule: () => void;
  savingRule: boolean;
}

export function useFillRule({
  closeModal,
  handleRefreshCf,
  customField,
  fillRuleIdToEdit,
  uuidToSlugMap,
  fieldFillRules,
  customFields,
  accountCustomFields,
}: HookProps): HookReturn {
  const filteredActionType = actionTypes.filter(
    type =>
      type.id === 'HIDE_FIELD' ||
      type.id === 'BLOCK_FIELD' ||
      (customField.type === 'BOOL' &&
        (type.id === 'ASSIGN_BOOL' ||
          type.id === 'ASSIGN_AND_BLOCK_BOOL' ||
          type.id === 'ASSIGN_AND_HIDE_BOOL')) ||
      (customField.type === 'PREDEFINED_STRINGS' &&
        (type.id === 'ASSIGN_PREDEFINED' ||
          type.id === 'ASSIGN_AND_HIDE_PREDEFINED' ||
          type.id === 'ASSIGN_AND_BLOCK_PREDEFINED' ||
          type.id === 'RESTRICT_PREDEFINED')) ||
      (customField.type === 'NUMERIC' &&
        (type.id === 'ASSIGN_NUMERIC' ||
          type.id === 'ASSIGN_AND_HIDE_NUMERIC' ||
          type.id === 'ASSIGN_AND_BLOCK_NUMERIC')),
  );
  const [actionType, setActionType] = useState<ITypeField>(
    filteredActionType[0],
  );
  const [actionValue, setActionValue] = useState<IActionValue>(null);
  const [valueError, setValueError] = useState<string | null>(null);

  const [conditionsExpressionInput, setConditionsExpressionInput] =
    useState<ConditionsExpression>([]);
  const [savingRule, setSavingRule] = useState<boolean>(false);

  useEffect(() => {
    if (fillRuleIdToEdit) {
      api
        .get(`/fillRules/${fillRuleIdToEdit}`)
        .then(response => {
          const fillRule = response.data;
          handleFormatResponseExpression(
            fillRule.expression,
            fillRule.conditions,
            setConditionsExpressionInput,
          );
          const { action } = fillRule;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          setActionType(filteredActionType.find(at => at.id === action.type)!);

          const value = handleFormatActionValue(action);
          const formattedValue =
            action.type === 'ASSIGN_NUMERIC' ||
            action.type === 'ASSIGN_AND_HIDE_NUMERIC' ||
            action.type === 'ASSIGN_AND_BLOCK_NUMERIC'
              ? replaceUUIDsWithSlugs(value as string, uuidToSlugMap)
              : value;
          setActionValue(formattedValue as IActionValue);
        })
        .catch((err: AxiosError) => {
          toast.error(`Falha ao carregar regra. ${err.message}`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 4000,
            closeOnClick: true,
          });
        });
    }
  }, [fillRuleIdToEdit]);

  const findRulesWithEmptyConditions = (): boolean => {
    const currentRule = fillRuleIdToEdit ?? '';
    const findRuleWithoutCondition = !!fieldFillRules.find(rule => {
      return rule.expression.length === 0 && rule.id !== currentRule;
    });

    return findRuleWithoutCondition;
  };

  const handleCloseFillRule = () => {
    closeModal();
    setSavingRule(false);
  };

  const onSubmitFillRule = () => {
    // event.preventDefault();
    setSavingRule(true);
    const savingRule = toast.loading(`Salvando regra`, {
      autoClose: 5000,
    });
    document.body.style.cursor = 'progress';
    let errorFound = false;

    if (
      (!conditionsExpressionInput || conditionsExpressionInput.length === 0) &&
      findRulesWithEmptyConditions()
    ) {
      toastLoadingUpdate(
        savingRule,
        `Não é permitido cadastrar mais de uma regra que não tenha condições definidas.`,
        'error',
        5000,
      );
      errorFound = true;
    }

    if (
      actionType.id !== 'HIDE_FIELD' &&
      actionType.id !== 'BLOCK_FIELD' &&
      !actionValue
    ) {
      toastLoadingUpdate(
        savingRule,
        `Valor final ou expressão da regra é obrigatório.`,
        'error',
        5000,
      );
      errorFound = true;
      setValueError('Campo obrigatório');
    }

    const formattedConditions = formatConditionsExpression({
      conditionsExpressionInput,
      customFields,
      accountCustomFields: accountCustomFields || [],
      isFillRule: true,
    });
    const formattedAction = {
      type: actionType.id,
      boolToAssign:
        (actionType.id === 'ASSIGN_BOOL' ||
          actionType.id === 'ASSIGN_AND_BLOCK_BOOL' ||
          actionType.id === 'ASSIGN_AND_HIDE_BOOL') &&
        actionValue
          ? actionValue === 'true'
          : undefined,
      predefinedValuesToAssign:
        (actionType.id === 'ASSIGN_PREDEFINED' ||
          actionType.id === 'RESTRICT_PREDEFINED' ||
          actionType.id === 'ASSIGN_AND_HIDE_PREDEFINED' ||
          actionType.id === 'ASSIGN_AND_BLOCK_PREDEFINED') &&
        actionValue
          ? actionValue
          : undefined,
      arithmeticExpression:
        (actionType.id === 'ASSIGN_NUMERIC' ||
          actionType.id === 'ASSIGN_AND_HIDE_NUMERIC' ||
          actionType.id === 'ASSIGN_AND_BLOCK_NUMERIC') &&
        actionValue
          ? replaceFieldsWithUUIDs(
              stripHtmlTags(actionValue as string),
              uuidToSlugMap,
            ).trim()
          : undefined,
    };

    const isNumericRule =
      formattedAction.type === 'ASSIGN_NUMERIC' ||
      formattedAction.type === 'ASSIGN_AND_HIDE_NUMERIC' ||
      formattedAction.type === 'ASSIGN_AND_BLOCK_NUMERIC';

    if (
      isNumericRule &&
      !validateArithmeticExpression(formattedAction.arithmeticExpression || '')
    ) {
      toastLoadingUpdate(
        savingRule,
        'Expressão inválida. Reavalie a fórmula matemática preenchida.',
        'error',
        5000,
      );
      errorFound = true;
    }

    if (
      isNumericRule &&
      hasMultipleUUIDTypes(
        formattedAction.arithmeticExpression || '',
        uuidToSlugMap,
      )
    ) {
      toastLoadingUpdate(
        savingRule,
        'Todos os campos da expressão aritmética devem ser de um único tipo.',
        'error',
        5000,
      );
      errorFound = true;
    }

    if (errorFound) {
      setSavingRule(false);
      document.body.style.cursor = 'auto';
      return;
    }

    const successMessage = !fillRuleIdToEdit
      ? 'Regra cadastrada com sucesso.'
      : 'Regra alterada com sucesso.';

    const request = (!fillRuleIdToEdit ? api.post : api.put)(
      !fillRuleIdToEdit
        ? `/customFields/${customField?.id}/fillRules`
        : `/fillRules/${fillRuleIdToEdit}`,
      {
        expression: formattedConditions,
        action: formattedAction,
      },
    );

    request
      .then(() => {
        handleRefreshCf();
      })
      .then(() => {
        toastLoadingUpdate(
          savingRule,
          successMessage,
          'success',
          2000,
          handleCloseFillRule,
        );
      })
      .catch(err => {
        toastLoadingUpdate(
          savingRule,
          `Ocorreu um erro. ${err.response.data.message}`,
          'error',
          5000,
        );
        setSavingRule(false);
      })
      .finally(() => {
        document.body.style.cursor = 'auto';
      });
  };

  return {
    filteredActionType,
    actionType,
    setActionType,
    actionValue,
    setActionValue,
    valueError,
    conditionsExpressionInput,
    setConditionsExpressionInput,
    onSubmitFillRule,
    savingRule,
  };
}

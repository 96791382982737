/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import {
  IPermissionsCategories,
  PermissionProperties,
  permissionsApi,
  PermissionsByCategories,
} from 'src/services/permissionsApi';

interface HookReturn {
  permissionProperties: PermissionProperties | null;
  permissionsByCategories: PermissionsByCategories | null;
  loadingPermissions: boolean;
  setRefreshPermissions: React.Dispatch<React.SetStateAction<boolean>>;
}

export function usePermissions(): HookReturn {
  const [permissionProperties, setPermissionProperties] =
    useState<PermissionProperties | null>(null);
  const [permissionsByCategories, setPermissionsByCategories] =
    useState<PermissionsByCategories | null>(null);
  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);
  const [refreshPermissions, setRefreshPermissions] = useState<boolean>(false);

  useEffect(() => {
    setLoadingPermissions(true);
    permissionsApi
      .getPermissionsCategories()
      .then((response: { data: IPermissionsCategories }) => {
        if (response.data?.permissionsByCategories)
          setPermissionsByCategories(response.data.permissionsByCategories);

        if (response.data?.permissionProperties)
          setPermissionProperties(response.data.permissionProperties);
      })
      .catch((error: any) => {
        const errMessage = error.response?.data?.message || error.message;
        toast.error(
          `Falha ao buscar lista de permissões. ${errMessage || ''}`,
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          },
        );
      })
      .finally(() => {
        setLoadingPermissions(false);
      });
  }, [refreshPermissions]);

  return {
    permissionProperties,
    permissionsByCategories,
    loadingPermissions,
    setRefreshPermissions,
  } as HookReturn;
}

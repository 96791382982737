/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { ColumnDef } from '@tanstack/react-table';
import { returnColorByTaskStatus } from 'src/utils/taskUtils';
import { Button } from 'src/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { LucideIcon, MoreHorizontalIcon } from 'lucide-react';
import { useTaskModalContext } from 'src/context/TaskModalContext';
import { DataTableColumnHeader } from 'src/components/ui/data-table-column-header';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { statuses } from 'src/utils/taskFilterColumnsUtils';
import { useAuth } from 'src/context/AuthContext';
import { TaskData } from '../types';

export const taskColumns: ColumnDef<TaskData>[] = [
  {
    accessorKey: 'createdAt',
    id: 'Criação',
    enableGlobalFilter: true,
    filterFn: 'dateBetweenFilterFn',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        disabledSortOption
        title="Data de criação"
        localStorageKey="@Brasao-widget-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string | Date = row.original.createdAt || '';
      return <div className="text-left text-nowrap p-1">{value}</div>;
    },
  },
  {
    accessorKey: 'startsAt',
    id: 'Inicio',
    enableGlobalFilter: true,
    filterFn: 'dateBetweenFilterFn',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        disabledSortOption
        title="Inicio Agenda"
        localStorageKey="@Brasao-widget-table-v1:columnVisibility"
        // className="justify-left"
      />
    ),
    cell: ({ row }) => {
      const value: string | Date = row.original.startsAt || '';
      return <div className="text-left text-nowrap p-1">{value}</div>;
    },
  },
  {
    accessorKey: 'endsAt',
    id: 'Fim',
    enableGlobalFilter: true,
    filterFn: 'dateBetweenFilterFn',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        disabledSortOption
        title="Fim Agenda"
        localStorageKey="@Brasao-widget-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string | Date = row.original.endsAt || '';
      return <div className="text-left text-nowrap p-1">{value}</div>;
    },
  },
  {
    accessorKey: 'name',
    id: 'Nome',
    enableGlobalFilter: true,
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        disabledSortOption
        title="Nome"
        localStorageKey="@Brasao-widget-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original.name;
      return (
        <TooltipWrapper value={value} className="font-medium text-xs">
          <div className="text-left text-nowrap max-w-[150px] truncate">
            {value}
          </div>
        </TooltipWrapper>
      );
    },
  },
  {
    accessorKey: 'type',
    id: 'Tipo',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const typeValue = row.original.type;
      return value.includes(typeValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        disabledSortOption
        title="Tipo"
        localStorageKey="@Brasao-widget-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const formatted = row.original.type;
      return (
        <div className="text-left text-nowrap max-w-[100px] truncate">
          {formatted}
        </div>
      );
    },
  },
  {
    accessorKey: 'account.name',
    id: 'Conta',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const rowFormattedValue = row.original.account.name;
      return value.includes(rowFormattedValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        disabledSortOption
        title="Conta"
        localStorageKey="@Brasao-widget-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original.account.name;
      return (
        <div className="text-left text-nowrap max-w-[200px] truncate">
          {value}
        </div>
      );
    },
  },
  {
    accessorKey: 'contacts',
    id: 'Contato',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      let result = false;
      row.original.contacts.forEach(contact => {
        if (value.includes(contact?.name)) {
          result = true;
        }
      });
      return result;
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        disabledSortOption
        title="Contato"
        localStorageKey="@Brasao-widget-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original.contacts?.[0]?.name || '';
      const numberOfContacts = row.original?.contacts?.length - 1;
      return (
        <div className="text-left text-nowrap max-w-[200px] truncate">
          {`${value} ${numberOfContacts > 0 ? `... +${numberOfContacts}` : ''}`}
        </div>
      );
    },
  },
  {
    accessorKey: 'card',
    accessorFn: row => {
      return row?.card?.keyCard?.toString() || '';
    },
    id: 'Card',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const rowValue = row.original?.card?.keyCard || '';
      const rowFormattedValue = rowValue ? rowValue.toString() : '';
      return value.includes(rowFormattedValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        disabledSortOption
        title="Card"
        localStorageKey="@Brasao-widget-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value = row.original?.card?.keyCard;
      const formattedValue = value ? value.toString() : '';
      return (
        <div className="text-left text-nowrap max-w-[100px] truncate">
          {formattedValue}
        </div>
      );
    },
  },
  {
    accessorKey: 'status',
    id: 'Situação',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        disabledSortOption
        title=""
        localStorageKey="@Brasao-widget-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original.status;
      const statusIcon: LucideIcon | undefined = statuses.find(
        st => st.value === value,
      )?.icon;
      const statusColor = returnColorByTaskStatus(value);

      if (!statusIcon) {
        return null;
      }

      const IconComponent = statusIcon as React.FC<
        React.SVGProps<SVGSVGElement>
      >;

      return (
        <TooltipWrapper value={value} className="font-medium text-xs">
          <IconComponent
            className="h-[22px] w-[22px] ml-0.5 text-muted-foreground"
            fill={statusColor}
            strokeWidth={1.8}
            color="#fff"
          />
        </TooltipWrapper>
      );
    },
  },
  {
    accessorKey: 'origin',
    id: 'Origem',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Origem"
        disabledSortOption
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
        className="justify-center"
      />
    ),
    cell: ({ row }) => {
      const formatted = row.original.origin;
      return (
        <TooltipWrapper
          value={row.original.origin === 'P' ? 'Processo' : 'Avulsa'}
          className="font-medium text-xs"
        >
          <div className="text-center text-nowrap">{formatted}</div>
        </TooltipWrapper>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      const { handleOpenModal, handleDeleteTask } = useTaskModalContext();
      const { user } = useAuth();
      const task = row.original;
      const editPermissionDisabledForUser =
        task.user.id !== user.id &&
        user?.profile.categoryName !== 'ADMINISTRATOR';
      const handleDeleteTaskClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        event.stopPropagation(); // Stop the propagation of the click event
        handleDeleteTask(task.taskId);
      };

      return (
        <div className="max-w-4 p-0">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-6 w-6 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontalIcon className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuLabel>Ações</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="hover:bg-gray-200 cursor-pointer"
                onClick={() => handleOpenModal(task.id)}
              >
                Alterar
              </DropdownMenuItem>
              <DropdownMenuItem
                className="hover:bg-gray-200 cursor-pointer"
                onClick={e => handleDeleteTaskClick(e)}
                disabled={
                  task.isProcessTask ||
                  (!task.isProcessTask && task.status === 'Concluída') ||
                  editPermissionDisabledForUser
                }
              >
                Excluir
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { ColumnDef } from '@tanstack/react-table';
import { Button } from 'src/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { MoreHorizontalIcon } from 'lucide-react';
import { DataTableColumnHeader } from 'src/components/ui/data-table-column-header';
import { useAuth } from 'src/context/AuthContext';
import { IUser } from 'src/services/userApi';
import { useDataTableContext } from 'src/context/DataTableContext';
import {
  ProfileCategory,
  ProfileCategoryNames,
} from 'src/services/permissionsApi';

export const UserColumns: ColumnDef<IUser>[] = [
  {
    accessorKey: 'name',
    id: 'Usuário',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const rowFormattedValue = row.original.name;
      return value.includes(rowFormattedValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Usuário"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original.name;
      return (
        <div className="text-left px-2 text-nowrap max-w-[300px] truncate">
          {value}
        </div>
      );
    },
  },
  {
    accessorKey: 'email',
    id: 'Email',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const rowFormattedValue = row.original?.email || '';
      return value.includes(rowFormattedValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Email"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original?.email || '';
      return (
        <div className="text-left px-2 text-nowrap max-w-[300px] truncate">
          {value}
        </div>
      );
    },
  },
  {
    accessorKey: 'profile.name',
    id: 'Perfil de Acesso',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const rowFormattedValue = row.original?.profile?.name || '';
      return value.includes(rowFormattedValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Perfil de Acesso"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original?.profile?.name || '';
      return (
        <div className="text-left px-2 text-nowrap max-w-[200px] truncate">
          {value}
        </div>
      );
    },
  },
  {
    accessorKey: 'profile.category.description',
    id: 'Perfil',
    enableGlobalFilter: true,
    filterFn: (row, id, value) => {
      const rowFormattedValue =
        row.original.profile?.category?.description || '';
      return value.includes(rowFormattedValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Perfil"
        localStorageKey="@Brasao-task-table-v1:columnVisibility"
      />
    ),
    cell: ({ row }) => {
      const value: string = row.original.profile?.category?.name || '';
      const formattedValue: string = value
        ? ProfileCategoryNames[value as ProfileCategory]
        : '';
      return (
        <div className="text-left px-2 text-nowrap max-w-[200px] truncate">
          {formattedValue}
        </div>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Ações"
        className="text-center"
      />
    ),
    cell: ({ row }) => {
      const { handleOpenModal, handleDeleteItem } = useDataTableContext();
      const { user } = useAuth();
      const editPermissionDisabledForUser =
        user?.permissions.USER !== 'READ_AND_WRITE';

      const task = row.original;
      const handleDeleteTaskClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        event.stopPropagation();
        handleDeleteItem(task.id);
      };

      return (
        <div className="flex w-full place-content-center">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontalIcon className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Ações</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="hover:bg-gray-200 cursor-pointer"
                onClick={() => handleOpenModal(task.id)}
              >
                Alterar
              </DropdownMenuItem>
              <DropdownMenuItem
                className="hover:bg-gray-200 cursor-pointer"
                onClick={e => handleDeleteTaskClick(e)}
                disabled={editPermissionDisabledForUser}
              >
                Excluir
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];

import api from './api';

export type DataSourceParametersType = {
  cards: string[];
  tasks: string[];
  accounts: string[];
  areas: {
    [uuid: string]: {
      task_ids: string[];
      cardField_ids: string[];
    };
  };
};

export type DataSourceListType = {
  id: string;
  name: string;
  parameters: DataSourceParametersType;
};

interface DataSourcesListResponse {
  visionDataSources: DataSourceListType[];
}

export const dataSourceApi = {
  getDataSourceList: () =>
    api.get<DataSourcesListResponse>('/visions/dataSources'),
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { Button } from 'src/components/ui/button';
import { MemoizedDataTable } from 'src/components/ui/data-table';
import { TaskModalContext } from 'src/context/TaskModalContext';
import api from 'src/services/api';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { statuses, taskOrigin, types } from 'src/utils/taskFilterColumnsUtils';
import { TaskDeletionDialog } from 'src/components/TaskModal/components/TaskDeletionDialog';
import { useAuth } from 'src/context/AuthContext';
import { useCardDataContext } from '../../../context/CardDataContext';
import { ModalDefaultWidthLg } from '../../../components/ModalDefaultWidthLg';
import { TaskModal } from '../../../components/TaskModal';
import { SelectTaskTypeModal } from '../../../components/TaskModal/components/SelectTaskTypeModal';
import { TaskTypesIds } from '../../../utils/taskTypes.constants';
import { ICardTaskProps, IFilterValues } from '../Card.i';
import { columns } from './components/Columns';

export const CardTask: React.FC<ICardTaskProps> = ({
  taskList,
  phaseList,
  selectedPhases,
  handleToggleTaskPhaseFilter,
  handleRefreshTaskList,
  cardData,
}) => {
  const { user } = useAuth();
  const userCantCreateSingleTask =
    user?.permissions.CREATE_SINGLE_TASK !== 'READ_AND_WRITE';
  const {
    card_id,
    isSubmittingFields,
    setIsSubmittingFields,
    handleSubmitCard,
  } = useCardDataContext();

  const [deleteAlertState, setDeleteAlertState] = useState<{
    isOpen: boolean;
    taskId: string | null;
    canSendEmail: boolean;
  }>({
    isOpen: false,
    taskId: '',
    canSendEmail: false,
  });
  const [selectTypeModalOpen, setSelectTypeModalOpen] = useState(false);
  const [phaseArea, setPhaseArea] = useState<
    | {
        id: string;
        name: string;
        description: string;
      }
    | undefined
  >(undefined);
  const [taskModalState, setTaskModalState] = useState<{
    selectedTaskId: string | null;
    newTaskType: TaskTypesIds | null;
    isOpen: boolean;
    isProcessTask: boolean;
    associateCard: boolean;
  }>({
    selectedTaskId: null,
    newTaskType: null,
    isOpen: false,
    isProcessTask: false,
    associateCard: false,
  });

  useEffect(() => {
    if (cardData && cardData.phase.id) {
      api.get(`/phases/${cardData.phase.id}`).then(response => {
        if (response.data && response.data.area) {
          setPhaseArea(response.data.area);
        }
      });
    }
  }, [cardData]);

  const handleCloseSelectModal = () => {
    setSelectTypeModalOpen(false);
  };

  const handleAddNewTask = () => {
    setSelectTypeModalOpen(true);
  };

  const handleConfirmSelectModal = (
    typeId: TaskTypesIds,
    associateCard?: boolean,
  ) => {
    setSelectTypeModalOpen(false);
    const timeoutId = setTimeout(() => {
      setTaskModalState(oldValue => ({
        ...oldValue,
        newTaskType: typeId,
        isOpen: true,
        isProcessTask: false,
        associateCard: associateCard || false,
      }));
    }, 200); // Adjust the delay as needed

    return () => clearTimeout(timeoutId);
  };

  const handleOpenTaskModal = (taskId: string | null) => {
    const isProcessTask = taskList.find(tl => tl.id === taskId)?.isProcessTask;
    setTaskModalState(oldValue => ({
      newTaskType: null,
      selectedTaskId: taskId,
      isOpen: true,
      isProcessTask: isProcessTask || false,
      associateCard: oldValue?.associateCard || false,
    }));
  };

  const handleCloseTaskModal = () => {
    setTaskModalState({
      newTaskType: null,
      selectedTaskId: null,
      isOpen: false,
      isProcessTask: false,
      associateCard: false,
    });
    handleRefreshTaskList();
  };

  const handleDeletAlertState = (state: boolean) => {
    setDeleteAlertState(oldValue => ({
      ...oldValue,
      isOpen: state,
    }));
  };

  const handleDeleteTask = (id: string) => {
    const canSendEmail = !!taskList.find(
      task =>
        task.id === id &&
        task.isProcessTask === false &&
        task.hasContacts &&
        task.status !== 'Não agendada',
    );
    setDeleteAlertState({
      isOpen: true,
      taskId: id,
      canSendEmail,
    });
  };

  const uniqueUsers: IFilterValues[] = taskList.reduce(
    (uniqueUserList: IFilterValues[], currentTask) => {
      const existingUserIndex = uniqueUserList.findIndex(
        user => user.value === currentTask.user.name,
      );

      if (existingUserIndex === -1) {
        uniqueUserList.push({
          value: currentTask.user.name,
          label: currentTask.user.name,
        });
      }

      return uniqueUserList;
    },
    [],
  );

  const columnsToFilter = [
    {
      columnId: 'Situação',
      title: 'Situação',
      options: statuses,
    },
    {
      columnId: 'Tipo',
      title: 'Tipo',
      options: types,
    },
    {
      columnId: 'Origem',
      title: 'Origem',
      options: taskOrigin,
    },
    {
      columnId: 'Usuário',
      title: 'Usuário',
      options: uniqueUsers,
    },
  ];

  const filteredTaskByActivePhases =
    selectedPhases.length > 0
      ? taskList.filter(task => selectedPhases.includes(task.phase.id))
      : taskList;

  return (
    <div className="w-full">
      {taskList.length === 0 ? (
        <span>Tarefas não definidas</span>
      ) : (
        <div>
          <div className="p-1">
            <div className="px-2 w-fit">
              <span className="bg-white font-medium text-base">Fases</span>
            </div>
            <div className="flex gap-2 border rounded-md flex-wrap max-h-[90px] overflow-auto p-2">
              {phaseList &&
                phaseList.map(phase => {
                  return (
                    <TooltipWrapper
                      value={phase.name}
                      key={phase.id}
                      className="font-medium text-xs"
                    >
                      <Button
                        variant={
                          selectedPhases.includes(phase.id)
                            ? 'default'
                            : 'secondary'
                        }
                        className="h-8 text-xs p-2 overflow-hidden"
                        onClick={() => handleToggleTaskPhaseFilter(phase.id)}
                      >
                        <span className="max-w-[100px] truncate text-nowrap">
                          {phase.name}
                        </span>
                      </Button>
                    </TooltipWrapper>
                  );
                })}
            </div>
          </div>
          <TaskModalContext.Provider
            value={{
              handleOpenModal: handleOpenTaskModal,
              handleDeleteTask,
            }}
          >
            <div className="mt-4 mb-10 ml-0 mr-0 p-1 overflow-hidden">
              <MemoizedDataTable
                columns={columns as any}
                data={filteredTaskByActivePhases}
                showTableToolbar
                pageSize={10}
                onClickRow={handleOpenTaskModal}
                showGlobalSearchInput
                showColumnVisibility
                showFilters
                columnsToFilter={columnsToFilter}
                handleClickAddNewButton={
                  userCantCreateSingleTask ? undefined : handleAddNewTask
                }
                initiallyInvisibleColumns={[
                  'Inicio',
                  'Fim',
                  'Usuário',
                  'Tipo',
                  'Origem',
                ]}
                localStorageKey="@Brasao-task-table-v1:columnVisibility"
                firstDateColumnId="Inicio"
                secondDateColumnId="Fim"
                fillEmptyRowsPerPage
                includeButtonText="Tarefa"
              />
            </div>
          </TaskModalContext.Provider>
        </div>
      )}
      {selectTypeModalOpen && (
        <SelectTaskTypeModal
          open={selectTypeModalOpen}
          onClose={handleCloseSelectModal}
          onConfirm={handleConfirmSelectModal}
          showCardCheckbox
        />
      )}

      {taskModalState.isOpen && (
        <ModalDefaultWidthLg
          closeModal={handleCloseTaskModal}
          open={taskModalState.isOpen}
          title="Tarefa"
          maxWidth="md"
          height="calc(100vh - 50px)"
          contentWidth="800px"
          titleBackgroundColor="#DFDFDF"
        >
          <TaskModal
            task_id={taskModalState.selectedTaskId}
            closeModal={handleCloseTaskModal}
            newTaskType={taskModalState.newTaskType}
            isProcessTask={taskModalState.isProcessTask}
            card_id={taskModalState.isProcessTask ? card_id : undefined}
            associateCard={taskModalState.associateCard}
            cardData={
              cardData && phaseArea
                ? {
                    ...cardData,
                    area: phaseArea,
                  }
                : undefined
            }
          />
        </ModalDefaultWidthLg>
      )}

      {deleteAlertState.isOpen && deleteAlertState.taskId && (
        <TaskDeletionDialog
          open={deleteAlertState.isOpen}
          setOpen={handleDeletAlertState}
          taskId={deleteAlertState.taskId}
          handleRefreshTaskList={handleRefreshTaskList}
          canSendEmail={deleteAlertState.canSendEmail}
        />
      )}

      <Button
        type="submit"
        className="absolute bottom-4 right-8 z-10"
        size="lg"
        onClick={e => {
          setIsSubmittingFields(true);
          handleSubmitCard(e);
        }}
        disabled={isSubmittingFields}
      >
        Salvar
      </Button>
    </div>
  );
};

import { StyleRules } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

export const styles = (): StyleRules => ({
  content: {
    // maxWidth: 650,
    // paddingBottom: 15,
    boxSizing: 'border-box',
    // width: '100%',
    height: 'auto',
    minHeight: '50%',
    maxHeight: '90%',
    overflow: 'hidden',
    margin: 0,
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#53a546',
    },
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
      fontWeight: 500,
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
});

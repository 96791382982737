/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import {
  formatDatetimeLocal,
  formatDatetimeLocalToBackendISO,
} from 'src/utils/dateFormatAndComparisonUtils';
import { getDateInputType } from 'src/utils/customFieldUtils';
import { TaskHandleCardValueProps } from 'src/components/TaskModal/TaskModal.i';

interface ITimestampInput {
  fieldId: string;
  fieldName: string;
  fieldLabel: string;
  fieldValue: string;
  dateType: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  margin?: 'normal' | 'dense' | 'none' | undefined;
  error?: boolean;
  helperText?: string;
  valueProperty?: 'value' | 'valueJSON';
  handleChangeValue?: (params: TaskHandleCardValueProps) => void;
  handleChangeTableValue?: (
    id: string,
    tableRowIndex: number,
    value: any,
    valueProperty: any,
  ) => void;
  tableRowIndex?: number;
}

export const TimestampField = ({
  fieldId,
  fieldName,
  fieldLabel,
  fieldValue,
  dateType,
  isDisabled = false,
  isRequired = false,
  margin,
  error,
  helperText,
  valueProperty,
  handleChangeValue = () => {},
  handleChangeTableValue = () => {},
  tableRowIndex,
}: ITimestampInput): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>('');
  const isTableColumn = tableRowIndex !== undefined;

  useEffect(() => {
    if (fieldValue) {
      const formattedValue =
        dateType === 'DATETIME' ? formatDatetimeLocal(fieldValue) : fieldValue;
      setInputValue(formattedValue);
    }
  }, [fieldValue]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  };

  const handleBlur = () => {
    if (isTableColumn) {
      handleChangeTableValue(
        fieldId,
        tableRowIndex,
        dateType === 'DATETIME'
          ? formatDatetimeLocalToBackendISO(inputValue)
          : inputValue,
        valueProperty,
      );
    } else {
      handleChangeValue({
        fieldId,
        newValue:
          dateType === 'DATETIME'
            ? formatDatetimeLocalToBackendISO(inputValue) || null
            : inputValue,
        valueProperty: valueProperty as any,
      });
    }
  };

  return (
    <TextField
      key={fieldId}
      id={fieldId}
      name={fieldName}
      type={getDateInputType(dateType)}
      label={!isTableColumn ? fieldLabel : undefined}
      autoComplete="off"
      margin={margin}
      fullWidth
      required={isRequired}
      disabled={isDisabled}
      onChange={handleInputChange}
      onBlur={handleBlur}
      value={inputValue}
      InputLabelProps={{
        shrink: true,
        style: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
      InputProps={{
        disableUnderline: isTableColumn ? true : undefined,
        margin: isTableColumn ? 'none' : undefined,
        inputProps: {
          style: {
            textAlign: isTableColumn ? 'center' : undefined,
            width: isTableColumn ? 'auto' : undefined,
            overflow: isTableColumn ? 'hidden' : undefined,
            fontSize: isTableColumn ? '14px' : undefined,
          },
          inputformat:
            dateType === 'DATE'
              ? 'dd/mm/yyyy'
              : dateType === 'TIME'
              ? 'hh:mm'
              : 'yyyy-MM-ddThh:mm',
          pattern:
            dateType === 'DATE'
              ? '[0-9]{4}-[0-9]{2}-[0-9]{2}'
              : dateType === 'TIME'
              ? '[0-9]{2}:[0-5]{1}[0-9]{1}'
              : '[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}',
          min:
            dateType === 'DATE'
              ? '1900-05-01'
              : dateType === 'TIME'
              ? '00:00'
              : '1900-05-01T00:00',
          max:
            dateType === 'DATE'
              ? '2999-12-31'
              : dateType === 'TIME'
              ? '23:59'
              : '2999-12-31T23:59',
        },
      }}
    />
  );
};

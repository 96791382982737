import unidecode from 'unidecode';
import { parse, parseISO } from 'date-fns';
import { CardFieldValueTypes } from './fieldTypes.constants';

interface ICustomField {
  id: string;
  name: string;
  slug: string;
  dateType: string;
}

export interface IRichTextVariable {
  id: string;
  name: string;
}
type ItemValue = string | object | Array<string | object> | null | undefined;

interface Item {
  id: number | string;
  value?: string | null;
  valueJSON?: object | Array<string | object> | null;
  isRequired: boolean;
  isApprovalCriterion: boolean;
  type: string;
}

export const typeFields = [
  {
    id: 'STRING',
    label: 'Alfanumérico',
  },
  {
    id: 'NUMERIC',
    label: 'Numérico',
  },
  {
    id: 'BOOL',
    label: 'Sim/Não',
  },
  {
    id: 'TIMESTAMP',
    label: 'Tempo (data/hora)',
  },
  {
    id: 'PREDEFINED_STRINGS',
    label: 'Valores Predefinidos',
  },
  {
    id: 'TABLE',
    label: 'Tabela',
  },
  {
    id: 'INTEGRATED',
    label: 'Integrado',
  },
  {
    id: 'DOCUMENT',
    label: 'Documento',
  },
  {
    id: 'FILE',
    label: 'Arquivo',
  },
];

export const dateTypes = [
  {
    id: 'DATE',
    label: 'Data',
  },
  {
    id: 'TIME',
    label: 'Hora',
  },
  {
    id: 'DATETIME',
    label: 'Data e Hora',
  },
];

export const getDateInputType = (type: string) => {
  switch (type) {
    case 'DATE':
      return 'date';
    case 'TIME':
      return 'time';
    case 'DATETIME':
      return 'datetime-local';
    default:
      return 'text';
  }
};

export function formatSlug(inputString: string): string {
  let slug = inputString.toUpperCase();
  slug = slug.replace(/\s+/g, '_');
  slug = unidecode(slug);
  slug = slug.replace(/[^a-zA-Z0-9-_.]/g, '');
  return slug;
}

export function generateSlugWithPhase(name: string, phaseName: string): string {
  const nameSlug = formatSlug(name);
  let phaseSlug = formatSlug(phaseName);
  if (phaseSlug.length > 3) phaseSlug = phaseSlug.substring(0, 3);
  return `${phaseSlug}.${nameSlug}`;
}

export function generateSlug(name: string): string {
  const nameSlug = formatSlug(name);
  if (nameSlug.length > 15) return nameSlug.substring(0, 15);
  return `${nameSlug}`;
}

export function formatCfToRichTextVariables(
  customFields: ICustomField[],
): IRichTextVariable[] {
  if (customFields.length > 0) {
    const formattedFields = customFields.flatMap((field: ICustomField) => {
      const baseObject = {
        name: field.name,
        id: `@${field.slug}`,
      };

      if (
        field?.dateType === 'DATE' ||
        field?.dateType === 'TIME' ||
        field?.dateType === 'DATETIME'
      ) {
        const extObject = {
          name: `${field.name} (por extenso)`,
          id: `@${field.slug}_EXT`,
        };
        return [baseObject, extObject];
      }
      return baseObject;
    });
    return formattedFields;
  }
  return customFields || [];
}

export function isFieldValueEmpty(value: ItemValue): boolean {
  if (value === null || value === undefined) return true;
  if (typeof value === 'string') {
    return value.trim() === '';
  }
  if (Array.isArray(value)) {
    return value.every(isFieldValueEmpty);
  }
  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).length === 0;
  }
  return true;
}

export function checkIfRequiredItemsAreNotEmpty(arr: Item[]): boolean {
  return arr.every(item => {
    const isValue = CardFieldValueTypes.includes(item.type);
    const value = isValue ? item.value : item.valueJSON;
    if (item.isRequired || item.isApprovalCriterion) {
      if (
        value === null ||
        value === undefined ||
        value === '' ||
        (Array.isArray(value) && value.length === 0)
      ) {
        return false;
      }
      if (
        typeof value === 'string' ||
        typeof value === 'object' ||
        Array.isArray(value)
      ) {
        return !isFieldValueEmpty(value);
      }
      return false;
    }
    return true;
  });
}

export function convertTimestampToNumeric(
  fieldValue: string,
  subtype: 'DATE' | 'TIME' | 'DATETIME',
) {
  let date;

  switch (subtype) {
    case 'TIME': {
      if (/^\d{2}:\d{2}$/.test(fieldValue)) {
        const [hours, minutes] = fieldValue.split(':').map(Number);
        return hours + minutes / 60;
      }
      date = parseISO(fieldValue);
      return date.getUTCHours() + date.getUTCMinutes() / 60;
    }
    case 'DATE': {
      if (/^\d{4}-\d{2}-\d{2}$/.test(fieldValue)) {
        date = parseISO(fieldValue);
        const epochStart = new Date(0);
        const daysSinceEpoch =
          (date.getTime() - epochStart.getTime()) / (1000 * 60 * 60 * 24);
        return daysSinceEpoch;
      }
      date = parseISO(fieldValue);
      const epochStart = new Date(0);
      const daysSinceEpoch =
        (date.getTime() - epochStart.getTime()) / (1000 * 60 * 60 * 24);
      return daysSinceEpoch;
    }
    case 'DATETIME': {
      if (fieldValue.includes('T')) {
        date = parseISO(fieldValue);
      } else {
        const [datePart, timePart] = fieldValue.split('T');
        date = parse(
          `${datePart}T${timePart}`,
          "yyyy-MM-dd'T'HH:mm",
          new Date(),
        );
      }

      return date.getTime() / (1000 * 60 * 60);
    }
    default:
      return 0;
  }
}

export function isCardFieldTypeValue(type: string): boolean {
  return CardFieldValueTypes.includes(type);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { Edit } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  TableContainer,
  TextField,
  Container,
  TablePagination,
  TableFooter,
} from '@material-ui/core';
import { ModalWithTitle } from 'src/components/ModalWithTitle';
import { useAuth } from 'src/context/AuthContext';
import TablePaginationActions from '../../../components/TablePaginationActions';

import api from '../../../services/api';
import HeaderPage from '../../../components/TitleAndButton';

import IntegrationRules from '../index';

const useStyles = makeStyles({
  content: { maxWidth: '750px' },
  boxTable: {
    maxWidth: '750px',
  },
  columnActions: {
    width: '60px',
  },
  titulo: {
    color: 'black',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '30px',
    marginBottom: '30px',
  },
  headerTitle: { fontSize: '30px', fontWeight: 'bold' },
  btnLoading: {
    color: '#fff !important',
  },
  btnIcon: {
    marginRight: '10px',
  },
});

interface IIntegrationRule {
  id: string;
  description: string;
  integrationTypeName: string;
  isActive: boolean;
  areaName: string;
}

const IntegrationRuleList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuth();
  const userCantEditIntegration =
    user.permissions.INTEGRATION !== 'READ_AND_WRITE';
  const [openModal, setOpenModal] = useState(false);

  const [integrationRules, setIntegrationRules] = useState<IIntegrationRule[]>(
    [],
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 10,
  );

  const [findName, setFindName] = useState('');

  const [integrationsFiltered, setIntegrationsFiltered] = useState<
    IIntegrationRule[]
  >([]);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, integrationRules.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    api.get('/integrationsrules').then(response => {
      setIntegrationRules(response.data);
    });
  }, []);

  const handleEdit = useCallback(
    (integrationRule_id: string) => {
      history.push(`/integrationsRules/${integrationRule_id}`);
    },
    [history],
  );

  useEffect(() => {
    setIntegrationsFiltered(integrationRules);
  }, [integrationRules]);

  useEffect(() => {
    const IntegrationsF = integrationRules.filter(
      integration =>
        integration.description
          .toUpperCase()
          .trim()
          .indexOf(findName.toUpperCase().trim()) >= 0 ||
        integration.integrationTypeName
          .toUpperCase()
          .trim()
          .indexOf(findName.toUpperCase().trim()) >= 0,
    );
    setPage(0);
    setIntegrationsFiltered(integrationRules);
    setIntegrationsFiltered(IntegrationsF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findName]);

  const handleAddForm = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = () => {
    // eslint-disable-next-line no-console
    setOpenModal(false);
  };

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        <HeaderPage
          title="Regras de Integração"
          handleAdd={handleAddForm}
          disabled={userCantEditIntegration}
        />
        <TextField
          label="Pesquisar"
          name="find"
          margin="dense"
          variant="outlined"
          fullWidth
          value={findName}
          style={{ marginBottom: 15, maxWidth: '300px' }}
          onChange={value => {
            setFindName(value.target.value);
          }}
        />
        <Box className={classes.boxTable}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="lista das regras de Integrações">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnActions} />
                  <TableCell>Descrição</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Área</TableCell>
                  <TableCell className={classes.columnActions} />
                  <TableCell>Ativa</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? integrationsFiltered.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : integrationsFiltered
                ).map(integration => (
                  <TableRow key={integration.id}>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="Editar"
                        onClick={() => handleEdit(integration.id)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {integration.description}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {integration.integrationTypeName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {integration.areaName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {integration.isActive}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {integration.isActive ? 'Sim' : 'Não'}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 43 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 15]}
                    colSpan={6}
                    count={integrationsFiltered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Linhas por página"
                    SelectProps={{
                      inputProps: { 'aria-label': '' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {openModal && (
        <ModalWithTitle
          open
          closeModal={handleCloseModal}
          title="Adicionar nova regra de Integração"
          titleColor="#000000"
        >
          <IntegrationRules closeModal={handleCloseModal} />
        </ModalWithTitle>
      )}
    </Container>
  );
};

export default IntegrationRuleList;

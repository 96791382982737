import React, { createContext, useContext } from 'react';
import {
  IAccount,
  ICardData,
  IHandleFillCustomFieldParams,
} from 'src/interface/ICardData';
import { IFillRuleDictionary } from 'src/interface/ICardFillRule';

interface CardDataContextType {
  handleFillCustomField: (params: IHandleFillCustomFieldParams) => void;
  handleSubmitCard: (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>,
    handleGetPdfDocument?: () => void,
  ) => void;
  cardCurrentPhase: {
    id: string;
    name: string;
  };
  card_id: string;
  cardCustomFieldPhases: ICardData[];
  accountFields: IAccount;
  fillRuleDictionary: IFillRuleDictionary | null;
  showLoadingMessage: boolean;
  transitioning: boolean;
  rulesEvaluatedOnLoad: boolean;
  isSubmittingFields: boolean;
  setIsSubmittingFields: (value: boolean) => void;
  setCardCustomFieldPhases: (value: ICardData[]) => void;
  toggleBox: (index: number, setOpen: boolean, type: 'field' | 'task') => void;
  handleSaveMainTab: () => void;
}

export const CardDataContext = createContext<CardDataContextType | undefined>(
  undefined,
);

export const useCardDataContext = (): CardDataContextType => {
  const context = useContext(CardDataContext);
  if (!context) {
    throw new Error(
      'useCardCustomFieldContext must be used within a CardCustomFieldContextProvider',
    );
  }
  return context;
};

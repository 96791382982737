import React from 'react';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { PlusCircleIcon } from 'lucide-react';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { Card } from 'src/components/ui/card';
import Select from 'src/components/Select';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import { useDataSource } from './hooks/useDataSource';
import { DataSourceList } from './components/DataSourceList';
import { DataSourceParameters } from './components/DataSourceParameters';

export const DataSource: React.FC = () => {
  const {
    formik,
    handleKeyDown,
    handleEditDataSource,
    handleDeleteDataSource,
    areaOptions,
    selectedAreas,
    setSelectedAreas,
    handleIncludeArea,
    paramsAreas,
    areas,
    loadingAreas,
    handleSelectedSourceOption,
    dataSourceList,
    loadingDataSources,
    handleRemoveArea,
    handleUncheckAllOptions,
    handleCheckAllOptions,
  } = useDataSource();
  const history = useHistory();
  const { user } = useAuth();
  const userCantEditDataSource =
    user.permissions.VISION_DATA_SOURCE !== 'READ_AND_WRITE';

  const removeSelectedAreas =
    paramsAreas.length > 0
      ? areaOptions?.filter(area => !paramsAreas.includes(area.id))
      : areaOptions;

  return (
    <div className="w-full h-full px-6 overflow-auto">
      <div className="space-y-1 my-6">
        <div className="flex flex-row  gap-2 items-center rounded-md mb-2 box-border">
          <h1 className="text-2xl font-bold leading-none mr-2">
            Mineração de Dados
          </h1>
          <Button
            onClick={() => history.goBack()}
            disabled={formik.isSubmitting}
            className="flex gap-1 items-center w-fit h-fit p-2 shadow-sm hover:shadow-sm min-w-20"
            variant="secondary"
          >
            Voltar
          </Button>
          <TooltipWrapper
            value="Novo Perfil"
            side="right"
            className="text-sm font-medium"
          >
            <Button
              onClick={() => handleEditDataSource(null)}
              disabled={userCantEditDataSource || formik.isSubmitting}
              className="flex gap-1 items-center w-fit h-fit p-2 shadow-lg hover:shadow-md  min-w-20"
            >
              <PlusCircleIcon size={16} />
              Criar
            </Button>
          </TooltipWrapper>
        </div>
        <p className="text-sm text-muted-foreground">
          Fontes de dados a serem utilizadas nas visões
        </p>
      </div>
      <Card className="pt-4 pb-8 max-w-7xl mb-8">
        <DataSourceList
          handleEditDataSource={handleEditDataSource}
          dataSourceList={dataSourceList || []}
          loadingDataSources={loadingDataSources}
          handleDeleteDataSource={handleDeleteDataSource}
          areas={areas}
        />
        <form noValidate className="w-full box-border">
          <div
            className={`flex flex-row ${
              formik.touched.name && formik.errors.name
                ? 'items-center'
                : 'items-end'
            } flex-nowrap gap-4 my-4 w-full`}
          >
            <div className="grid w-full max-w-[510px]">
              <Label htmlFor="name" className="mb-2.5">
                Nome de exibição
              </Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={formik.values.name || ''}
                onChange={formik.handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Digite o nome de exibição"
                className="max-w-lg"
                autoComplete="off"
                required
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-red-600 text-sm mt-1">
                  {formik.errors.name}
                </div>
              ) : null}
            </div>

            <Button
              className="rounded-md p-2 min-w-24 min-h-fit "
              type="button"
              onClick={() => formik.handleSubmit()}
              disabled={userCantEditDataSource || formik.isSubmitting}
            >
              {formik.values?.id ? 'Salvar' : 'Cadastrar'}
            </Button>
          </div>

          <div
            className={`flex flex-row ${
              formik.touched.name && formik.errors.name
                ? 'items-center'
                : 'items-end'
            } flex-nowrap gap-4 my-4 w-full`}
          >
            <div className="grid w-full max-w-[510px]">
              <span className="mb-2 font-medium">Áreas</span>
              <Select
                items={removeSelectedAreas || []}
                selectedItems={selectedAreas}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setSelectedItems={setSelectedAreas as any}
                placeholder="Selecione uma área"
                maxItems={1}
              />
            </div>
            <Button
              className="rounded-md p-2 min-w-24 min-h-fit "
              type="button"
              onClick={handleIncludeArea}
              disabled={userCantEditDataSource || formik.isSubmitting}
            >
              Acrescentar
            </Button>
          </div>

          <Label htmlFor="profile-permissions" className="mb-1.5 w-full">
            Fonte de Dados
          </Label>
          <DataSourceParameters
            paramsAreas={paramsAreas}
            areas={areas}
            loadingAreas={loadingAreas}
            handleSelectedSourceOption={handleSelectedSourceOption}
            dataSourceParameters={formik.values.parameters}
            handleRemoveArea={handleRemoveArea}
            handleUncheckAllOptions={handleUncheckAllOptions}
            handleCheckAllOptions={handleCheckAllOptions}
          />
        </form>
      </Card>
    </div>
  );
};

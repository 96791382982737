/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { PredefinedField } from 'src/components/TaskInputFields/PredefinedField';
import { BooleanField } from 'src/components/TaskInputFields/BooleanField';
import { TimestampField } from 'src/components/TaskInputFields/TimestampField';
import { ModalIntegratedCustomFields } from 'src/components/ModalIntegratedCustomFields';
import { IntegratedField } from 'src/components/TaskInputFields/IntegratedField';
import { DocumentField } from 'src/components/TaskInputFields/DocumentField';
import { FileField } from 'src/components/TaskInputFields/FileField';
import { useFormikErrorContext } from 'src/context/FormikErrorProvider';
import {
  ITableProps,
  TableFieldModal,
} from 'src/components/TaskInputFields/TableFieldModal';
import { ICustomField } from 'src/interface/ICardFields';
import { findCustomFieldById } from 'src/utils/card/cardUtils';
import { CfTextInput } from 'src/components/TaskInputFields/CfTextInput';
import { IValueJSON } from 'src/interface/ICardData';
import {
  IIntegratedModalData,
  TaskLinkedCardFieldsProps,
} from '../TaskModal.i';

const ErrorText: JSX.Element = (
  <div>
    <span className="text-red-500 text-xs">Campo obrigatório</span>
  </div>
);

export const TaskLinkedCardFields: React.FC<TaskLinkedCardFieldsProps> = ({
  linkedCardFieldValues,
  handleChangeCardFieldValue,
  cardId,
  areaId,
  done,
  cardFieldsErrorList,
  cardFieldsData,
}) => {
  const { errors, hasError } = useFormikErrorContext();
  const [openModal, setOpenModal] = useState(false);
  const [integratedModalData, setIntegratedModalData] =
    useState<IIntegratedModalData | null>(null);
  const [tableModalData, setTableModalData] = useState<ITableProps | null>(
    null,
  );

  const handleOpenIntegratedFieldModal = (
    cardField: ICustomField,
    table?: {
      id: string;
      tableRowIndex: number;
    },
  ) => {
    const newModalData = {
      cardField,
      cardFieldId: cardField.id,
      table,
    };
    setIntegratedModalData(newModalData);
    setOpenModal(true);
  };

  const handleFillIntegratedField = useCallback(
    (value: Record<string, unknown> | undefined, fieldId?: string) => {
      if (integratedModalData && integratedModalData.cardFieldId && !fieldId) {
        handleChangeCardFieldValue({
          fieldId: integratedModalData.cardFieldId,
          newValue: value as any,
          valueProperty: 'valueJSON',
        });
        setOpenModal(false);
      }

      if (fieldId) {
        handleChangeCardFieldValue({
          fieldId,
          newValue: value as any,
          valueProperty: 'valueJSON',
        });
        setOpenModal(false);
      }
    },
    [
      integratedModalData,
      handleChangeCardFieldValue,
      setOpenModal,
      linkedCardFieldValues,
    ],
  );

  const handleFillFileField = useCallback(
    (
      value: {
        name: string;
        url: string;
        size: number;
      }[],
      fieldId?: string,
    ) => {
      if (fieldId) {
        handleChangeCardFieldValue({
          fieldId,
          newValue: value,
          valueProperty: 'valueJSON',
        });
      }
    },
    [handleChangeCardFieldValue],
  );

  const handleCloseIntagratedModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenTableModal = (
    title: string,
    customFieldId: string,
    tableColumns: ICustomField[],
    valueJSON: IValueJSON[],
  ) => {
    setTableModalData({
      open: true,
      title,
      customFieldId,
      tableColumns,
      valueJSON,
    });
  };

  const handleCloseModalTable = () => {
    if (tableModalData && tableModalData !== null && tableModalData?.open) {
      setTableModalData(prevState => ({
        ...prevState!,
        open: false,
      }));
    } else {
      setTableModalData(null);
    }
  };

  function handleRenderCardFields(paramCardField: ICustomField) {
    const cardDataField = findCustomFieldById(
      cardFieldsData,
      paramCardField.id,
    );
    const field =
      cardDataField !== undefined && cardDataField !== null
        ? {
            ...cardDataField,
            isEditable: paramCardField?.isEditable || cardDataField?.isEditable,
            isRequired: paramCardField?.isRequired || cardDataField.isRequired,
            isApprovalCriterion: paramCardField.isApprovalCriterion,
            isDisabled: cardDataField?.isDisabled || paramCardField?.isDisabled,
            tableColumns:
              cardDataField.type === 'TABLE' && cardDataField?.tableColumns
                ? cardDataField.tableColumns
                : paramCardField.tableColumns,
          }
        : paramCardField;

    switch (field.type) {
      case 'BOOL':
        if (field.isHidden) return null;
        return (
          <Grid item xs={6} key={field.id}>
            <BooleanField
              fieldId={field.id}
              fieldName={field.name}
              fieldLabel={field.name}
              fieldValue={field?.value || ''}
              handleChangeValue={handleChangeCardFieldValue}
              isDisabled={done || !field.isEditable || field.isDisabled}
              isRequired={field.isRequired || field.isApprovalCriterion}
              valueProperty="value"
            />
            {cardFieldsErrorList[field.id] && ErrorText}
          </Grid>
        );

      case 'STRING':
      case 'NUMERIC':
        if (field.isHidden) return null;
        return (
          <Grid item xs={field.isBigString === true ? 12 : 6} key={field.id}>
            <CfTextInput
              fieldId={field.id}
              fieldName={field.name}
              fieldLabel={field.name}
              fieldValue={field?.value || ''}
              type={field.type}
              valueProperty="value"
              margin="none"
              handleOnBlur={handleChangeCardFieldValue}
              isDisabled={done || !field.isEditable || field.isDisabled}
              isRequired={field.isRequired || field.isApprovalCriterion}
              isMultiline={field.isBigString}
              minRows={1}
              maxRows={field.isBigString ? 3 : 1}
              error={hasError(`linkedCardFieldValues.${field.name}`)}
              helperText={
                hasError(`linkedCardFieldValues.${field.name}`)
                  ? errors[`linkedCardFieldValues.${field.name}`]
                  : ''
              }
            />
            {cardFieldsErrorList[field.id] && ErrorText}
          </Grid>
        );

      case 'TIMESTAMP':
        if (field.isHidden) return null;
        return (
          <Grid item xs={6} key={field.id}>
            <TimestampField
              fieldId={field.id}
              fieldName={field.name}
              fieldLabel={field.name}
              fieldValue={field?.value || ''}
              dateType={field.dateType as string}
              isDisabled={done || !field.isEditable || field.isDisabled}
              isRequired={field.isRequired || field.isApprovalCriterion}
              margin="none"
              valueProperty="value"
              handleChangeValue={handleChangeCardFieldValue}
            />
            {cardFieldsErrorList[field.id] && ErrorText}
          </Grid>
        );

      case 'PREDEFINED_STRINGS':
        if (field.isHidden || !field.predefinedValues) return null;
        return (
          <Grid item xs={6} key={field.id}>
            <PredefinedField
              fieldId={field.id}
              fieldName={field.name}
              fieldValue={field?.valueJSON || []}
              fieldOptions={field.predefinedValues}
              valueProperty="valueJSON"
              isDisabled={done || !field.isEditable || field.isDisabled}
              isRequired={field.isRequired || field.isApprovalCriterion}
              margin="none"
              handleChangeValue={handleChangeCardFieldValue}
              isMultipleOptions
              predefinedActiveOptions={
                field?.predefinedActiveOptions || field.predefinedValues
              }
            />
            {cardFieldsErrorList[field.id] && ErrorText}
          </Grid>
        );

      case 'INTEGRATED':
        if (field.isHidden) return null;
        return (
          <Grid item xs={6} key={field.id}>
            <IntegratedField
              customField={field}
              handleOpenModal={handleOpenIntegratedFieldModal as any}
              isDisabled={done || !field.isEditable || field.isDisabled}
              isRequired={field.isRequired || field.isApprovalCriterion}
              handleFillIntegratedField={handleFillIntegratedField}
              selectedIntegratedItem={field?.valueJSON || {}}
            />
            {cardFieldsErrorList[field.id] && ErrorText}
          </Grid>
        );

      case 'TABLE':
        if (field.isHidden) return null;
        return (
          <Grid item xs={6} key={field.id}>
            <div style={{ marginBottom: 0 }}>
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                onClick={() =>
                  handleOpenTableModal(
                    field.name,
                    field.id,
                    field.tableColumns || [],
                    field.valueJSON || [],
                  )
                }
              >
                {`${field.name} ${field.isRequired ? '*' : ''}`}
              </Button>
              {cardFieldsErrorList[field.id] && ErrorText}
            </div>
          </Grid>
        );

      case 'DOCUMENT':
        if (field.isHidden) return null;
        return (
          <Grid item xs={6} key={field.id}>
            <DocumentField
              customField={field as any}
              card_id={cardId}
              area_id={areaId}
              isDisabled={done || !field.isEditable || field.isDisabled}
              isRequired={field.isRequired || field.isApprovalCriterion}
              handleFillCardField={handleChangeCardFieldValue as any}
            />
            {cardFieldsErrorList[field.id] && ErrorText}
          </Grid>
        );

      case 'FILE':
        if (field.isHidden) return null;
        return (
          <Grid item xs={6} key={field.id}>
            <FileField
              customField={field as any}
              card_id={cardId}
              fieldValueJson={field?.valueJSON || []}
              handleFillCardField={handleFillFileField}
              isDisabled={done || !field.isEditable || field.isDisabled}
              isRequired={field.isRequired || field.isApprovalCriterion}
            />
            {cardFieldsErrorList[field.id] && ErrorText}
          </Grid>
        );

      default:
        return <Typography>Campo personalizado não definido</Typography>;
    }
  }

  return (
    <>
      {linkedCardFieldValues.length > 0 &&
        linkedCardFieldValues
          .sort((a, b) => {
            if (a.isApprovalCriterion !== b.isApprovalCriterion) {
              return a.isApprovalCriterion ? 1 : -1;
            }

            if (a.phase.order !== b.phase.order) {
              return a.phase.order - b.phase.order;
            }
            return a.phaseOrder - b.phaseOrder;
          })
          .map(field => handleRenderCardFields(field as ICustomField))}
      {openModal && integratedModalData && (
        <ModalIntegratedCustomFields
          openModal={openModal}
          handleCloseModal={handleCloseIntagratedModal}
          onSelectedIntegratedItem={handleFillIntegratedField}
          customFields={cardFieldsData || []}
          customField_id={integratedModalData.cardField.id}
          customField_name={integratedModalData.cardField.name}
          format={integratedModalData.cardField.format}
          dynamicFormat={integratedModalData.cardField.dynamicFormat}
          key={integratedModalData.cardField.id}
          primaryKeyColumnName={
            integratedModalData.cardField.primaryKeyColumnName
          }
        />
      )}
      {tableModalData && tableModalData.open && (
        <TableFieldModal
          open={tableModalData.open}
          closeModal={handleCloseModalTable}
          title={tableModalData.title}
          customFieldId={tableModalData.customFieldId}
          tableColumns={tableModalData.tableColumns}
          handleFillCustomField={handleChangeCardFieldValue}
          tableValueJSON={tableModalData.valueJSON}
          done={done}
          cardFieldsData={cardFieldsData}
        />
      )}
    </>
  );
};

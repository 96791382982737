import { ICardData } from 'src/interface/ICardData';
import { findCustomFieldById } from 'src/utils/card/cardUtils';
import { ILinkedCardFieldValues } from '../TaskModal.i';

export const formatLinkedCardFields = (
  taskLinkedCardFields: ILinkedCardFieldValues[],
  cardFieldsData: ICardData[],
): ILinkedCardFieldValues[] => {
  if (taskLinkedCardFields && cardFieldsData) {
    const newTaskCardLinkedCardFields: ILinkedCardFieldValues[] =
      taskLinkedCardFields.map(taskLinkedField => {
        const cardDataField = findCustomFieldById(
          cardFieldsData,
          taskLinkedField.id,
        );

        if (cardDataField) {
          return {
            ...taskLinkedField,
            value: cardDataField.value,
            valueJSON: cardDataField.valueJSON,
          };
        }

        return taskLinkedField;
      });
    return newTaskCardLinkedCardFields;
  }
  return [];
};

import { fixedAccountFields } from './fixedAccountFields';

export type FixedFieldType = {
  id: string;
  name: string;
};

export const FIXED_CONTACT_FIELDS = Object.freeze([
  { id: 'contactName', name: 'Nome do contato' },
  { id: 'contactEmail', name: 'Email do contato' },
  { id: 'contactPhone', name: 'Telefone do contato' },
  { id: 'contactBirthDate', name: 'Data de nasc. do contato' },
  { id: 'contactIsDefault', name: 'Contato padrão' },
]);

export const FIXED_ACCOUNT_FIELDS: FixedFieldType[] = [
  ...fixedAccountFields.map(field => ({
    id: field.id,
    name: field.name,
  })),
  ...FIXED_CONTACT_FIELDS,
];

export const FIXED_CARD_FIELDS = Object.freeze([
  { id: 'description', name: 'Descrição' },
  { id: 'date_finally', name: 'Data de finalização' },
  { id: 'date_suspended_until', name: 'Data final da suspensão' },
  { id: 'key_card', name: 'Chave' },
  { id: 'isPinned', name: 'Fixo' },
  { id: 'isHighlighted', name: 'Destaque' },
  { id: 'parent', name: 'Card pai' },
  { id: 'children', name: 'Card filho' },
  { id: 'phase', name: 'Fase atual' },
  { id: 'account', name: 'Conta' },
  { id: 'user', name: 'Usuário' },
  { id: 'contact', name: 'Contato' },
  { id: 'occurrences', name: 'Ocorrências' },
  { id: 'created_at', name: 'Data de criação' },
  { id: 'enteredPhaseAt', name: 'Data de entrada na fase' },
  { id: 'nextTask', name: 'Próxima tarefa' },
  { id: 'status', name: 'Situação' },
]);

export const FIXED_TASK_FIELDS = Object.freeze([
  { id: 'type', name: 'Tipo' },
  { id: 'user', name: 'Usuário responsável' },
  { id: 'name', name: 'Nome' },
  { id: 'description', name: 'Descrição' },
  { id: 'startsAt', name: 'Data início agendamento' },
  { id: 'endsAt', name: 'Data fim agendamento' },
  { id: 'status', name: 'Situação' },
  { id: 'account', name: 'Conta' },
  { id: 'contacts', name: 'Contato' },
  { id: 'area', name: 'Área' },
  { id: 'card', name: 'Card' },
  { id: 'phase', name: 'Fase' },
  { id: 'guest_users', name: 'Usuários convidados' },
  { id: 'created_at', name: 'Data de criação' },
  { id: 'subject', name: 'Assunto' },
  { id: 'meeting_url', name: 'URL da reunião' },
  { id: 'meeting_address', name: 'Endereço da reunião' },
  { id: 'approval', name: 'Aprovação' },
  { id: 'logs', name: 'Logs' },
]);

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { TaskHandleCardValueProps } from 'src/components/TaskModal/TaskModal.i';
import { booleanStyles } from './booleanStyles';

interface IBooleanInput {
  fieldId: string;
  fieldName: string;
  fieldLabel: string;
  fieldValue: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  error?: boolean;
  helperText?: string;
  valueProperty: 'value' | 'valueJSON';
  handleChangeValue?: (params: TaskHandleCardValueProps) => void;
  handleFillTableValue?: (
    columnCustomFieldId: string,
    tableRowIndex: number,
    value: string | string[] | null | object | object[],
    valueProperty: 'value' | 'valueJSON',
  ) => void;
  tableRowIndex?: number;
}

const useStyles = makeStyles(() => createStyles(booleanStyles()));

export const BooleanField = ({
  fieldId,
  fieldName,
  fieldLabel,
  fieldValue,
  isDisabled = false,
  isRequired = false,
  handleChangeValue = () => {},
  error,
  helperText,
  valueProperty,
  handleFillTableValue = () => {},
  tableRowIndex,
}: IBooleanInput): JSX.Element => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<string | null>('');
  const isTableColumn = tableRowIndex !== undefined;

  useEffect(() => {
    setInputValue(fieldValue);
  }, [fieldValue]);

  const handleInputChange = (value: string | null) => {
    const newValue = value;
    setInputValue(newValue);
    if (isTableColumn) {
      handleFillTableValue(
        fieldId,
        tableRowIndex,
        newValue || '',
        valueProperty,
      );
    } else {
      handleChangeValue({
        fieldId,
        newValue,
        valueProperty,
      });
    }
  };

  return (
    <Box
      className={isTableColumn ? classes.tableBooleanField : classes.boolField}
    >
      {!isTableColumn && (
        <Box className={classes.boolFieldTitle}>
          <Typography>{fieldLabel}</Typography>
        </Box>
      )}
      <Box className={classes.boolFieldOptions}>
        <Box className={classes.boolFieldOption}>
          <Checkbox
            name={fieldName}
            className={classes.checkBox}
            checked={inputValue === 'true'}
            required={isRequired}
            disabled={isDisabled}
            onChange={() => {
              handleInputChange(inputValue === 'true' ? null : 'true');
            }}
          />
          <Typography>Sim</Typography>
        </Box>
        <Box className={classes.boolFieldOption}>
          <Checkbox
            name={fieldName}
            className={classes.checkBox}
            checked={inputValue === 'false'}
            required={isRequired}
            disabled={isDisabled}
            onChange={() => {
              handleInputChange(inputValue === 'false' ? null : 'false');
            }}
          />
          <Typography>Não</Typography>
        </Box>
      </Box>
    </Box>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth } from 'src/context/AuthContext';
import { useUsers } from 'src/hooks/useUsers';
import api from 'src/services/api';
import { IUser } from 'src/services/userApi';

interface HookReturn {
  isNotAdmin: boolean;
  openUserModal: {
    isOpen: boolean;
    userId?: string;
  };
  handleEdit: (user_id: string) => void;
  handleAddUser: () => void;
  handleCloseModal: () => void;
  usersFiltered: IUser[];
  users: IUser[];
  setRefreshUsers: React.Dispatch<React.SetStateAction<boolean>>;
  columnsToFilter: {
    columnId: string;
    title: string;
    options: {
      value: string;
      label: string;
    }[];
  }[];
  handleOpenDeleteUserModal: (id: string) => void;
  handleDeleteUser: (id: string) => void;
  handleDeleteAlertState: (id: boolean) => void;
  deleteAlertState: {
    isOpen: boolean;
    userId: string;
  };
  editPermissionDisabledForUser: boolean;
}
type objectWithLabelAndValue = { value: string; label: string; id: string };

export function useUserList(): HookReturn {
  const history = useHistory();
  const { user } = useAuth();
  const { users, setRefreshUsers } = useUsers();
  const isNotAdmin = user?.profile.categoryName !== 'ADMINISTRATOR';
  const editPermissionDisabledForUser =
    user?.permissions.USER !== 'READ_AND_WRITE';
  const [openUserModal, setOpenUserModal] = useState<{
    isOpen: boolean;
    userId?: string;
  }>({
    isOpen: false,
  });
  const [usersFiltered, setUsersFiltered] = useState<IUser[]>([]);
  const [deleteAlertState, setDeleteAlertState] = useState<{
    isOpen: boolean;
    userId: string;
  }>({
    isOpen: false,
    userId: '',
  });

  useEffect(() => {
    setUsersFiltered(users);
  }, [users]);

  const handleEdit = useCallback(
    (user_id: string) => {
      setOpenUserModal({
        isOpen: true,
        userId: user_id,
      });
    },
    [history],
  );

  const handleAddUser = useCallback(() => {
    setOpenUserModal({
      isOpen: true,
      userId: undefined,
    });
  }, []);

  const handleCloseModal = () => {
    setOpenUserModal({
      isOpen: false,
      userId: undefined,
    });
    setRefreshUsers(oldValue => !oldValue);
  };

  const handleOpenDeleteUserModal = useCallback(
    (id: string) => {
      setDeleteAlertState({
        isOpen: true,
        userId: id,
      });
    },
    [users],
  );

  const handleDeleteAlertState = (state: boolean) => {
    setDeleteAlertState(oldValue => ({
      ...oldValue,
      isOpen: state,
    }));
  };

  const handleDeleteUser = (userId: string) => {
    const deleteRequest = `/users/${userId}`;
    api
      .delete(deleteRequest)
      .then(() => {
        toast.success(`Usuário excluído com sucesso`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
        setRefreshUsers(oldValue => !oldValue);
      })
      .catch(error => {
        const errorMessage = error.response?.data.message || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      });
  };

  const { uniqueProfiles, uniqueCategories } = users.reduce(
    (
      acc: {
        uniqueProfiles: objectWithLabelAndValue[];
        uniqueCategories: objectWithLabelAndValue[];
      },
      currentUser,
    ) => {
      if (
        !acc.uniqueProfiles.some(item => item.id === currentUser?.profile?.name)
      ) {
        acc.uniqueProfiles.push({
          value: currentUser?.profile?.name as string,
          label: currentUser?.profile?.name as string,
          id: currentUser?.profile?.name as string,
        });
      }

      if (
        !acc.uniqueCategories.some(
          item => item.id === currentUser?.profile?.category?.description,
        )
      ) {
        acc.uniqueCategories.push({
          value: currentUser?.profile?.category?.description as string,
          label: currentUser?.profile?.category?.description as string,
          id: currentUser?.profile?.category?.description as string,
        });
      }

      return acc;
    },
    { uniqueProfiles: [], uniqueCategories: [] },
  );

  const columnsToFilter = [
    {
      columnId: 'Perfil de Acesso',
      title: 'Perfil de Acesso',
      options: uniqueProfiles,
    },
    {
      columnId: 'Perfil',
      title: 'Perfil',
      options: uniqueCategories,
    },
  ];

  return {
    isNotAdmin,
    openUserModal,
    handleEdit,
    handleAddUser,
    handleCloseModal,
    users,
    usersFiltered,
    setRefreshUsers,
    columnsToFilter,
    deleteAlertState,
    handleOpenDeleteUserModal,
    handleDeleteUser,
    handleDeleteAlertState,
    editPermissionDisabledForUser,
  } as HookReturn;
}

import { deepClone } from 'src/utils/deepCloneObject';
import { formatDatetimeLocalToBackendISO } from 'src/utils/dateFormatAndComparisonUtils';
import { ICustomField } from 'src/interface/ICardFields';
import { IGetAccountCustomFieldApi } from 'src/services/accountCustomFieldApi';
import {
  ConditionsExpression,
  isConditionObject,
  TCondition,
} from '../Condition.i';

export const formatConditionsExpression = ({
  conditionsExpressionInput,
  customFields,
  accountCustomFields,
  isFillRule = false,
}: {
  conditionsExpressionInput: ConditionsExpression;
  customFields: ICustomField[];
  accountCustomFields: IGetAccountCustomFieldApi[];
  isFillRule?: boolean;
}) => {
  const formattedConditions = deepClone(conditionsExpressionInput).map(
    conditionGroup => {
      let clonedConditionGroup = deepClone(conditionGroup);
      if (Array.isArray(clonedConditionGroup)) {
        clonedConditionGroup = clonedConditionGroup.map(
          (condition: TCondition) => {
            // Assigning mapped result back
            if (
              isConditionObject(condition) &&
              (condition?.customFieldValue || (isFillRule && condition?.value))
            ) {
              const clonedCondition = deepClone(condition);
              const isCardField =
                condition.type === 'customField' && condition?.customField_id;
              const fieldId = isCardField
                ? condition?.customField_id
                : condition?.accountField_id;
              const customField = isCardField
                ? customFields.find(field => field.id === fieldId)
                : accountCustomFields.find(field => field.id === fieldId);

              if (
                customField?.type === 'TIMESTAMP' &&
                customField?.dateType === 'DATETIME'
              ) {
                if (isFillRule) {
                  clonedCondition.value = formatDatetimeLocalToBackendISO(
                    clonedCondition.value,
                  );
                } else {
                  clonedCondition.customFieldValue =
                    formatDatetimeLocalToBackendISO(
                      clonedCondition.customFieldValue,
                    );
                }
                return clonedCondition;
              }
              return clonedCondition;
            }
            return condition;
          },
        );
      }
      return clonedConditionGroup;
    },
  );
  return formattedConditions || [];
};

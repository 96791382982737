import React, { useCallback, useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { Button } from 'src/components/ui/button';
import { MoreHorizontalIcon } from 'lucide-react';
import { ProfileCategoryNames, ProfileType } from 'src/services/permissionsApi';
import { Skeleton } from 'src/components/ui/skeleton';
import { DeleteAlertDialog } from 'src/components/DeleteAlertDialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';

export interface ProfileListProps {
  handleEditProfile: (profile: ProfileType) => void;
  profilesList: ProfileType[];
  loadingProfiles: boolean;
  handleDeleteProfile: (profile_id: string) => void;
  userCantEditProfile: boolean;
}

export const ProfilesList: React.FC<ProfileListProps> = ({
  handleEditProfile,
  profilesList,
  loadingProfiles,
  handleDeleteProfile,
  userCantEditProfile,
}) => {
  const [deleteAlertState, setDeleteAlertState] = useState({
    isOpen: false,
    profileId: '',
  });

  const handleOpenDeleteDialog = useCallback((profile_id: string) => {
    setDeleteAlertState({ isOpen: true, profileId: profile_id });
  }, []);

  const handleCloseDeletionDialog = useCallback((value: boolean) => {
    setDeleteAlertState({ isOpen: value, profileId: '' });
  }, []);

  return (
    <div className="w-full h-44 max-h-60 overflow-auto border-2 rounded-sm">
      {loadingProfiles ? (
        <ul>
          {[1, 2, 3, 4, 5, 6].map(item => (
            <li
              key={item}
              className="flex justify-between items-center border-b p-1"
            >
              <Skeleton className="w-full h-6" />
            </li>
          ))}
        </ul>
      ) : (
        <Table className="w-full overflow-clip">
          <TableHeader className="z-10">
            <TableRow>
              <TableCell align="left" className="p-2 font-medium">
                Nome de Exibição
              </TableCell>
              <TableCell align="left" className="p-2 font-medium">
                Perfil
              </TableCell>
              <TableCell align="center" className="p-2 font-medium">
                Ações
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {profilesList.length > 0 &&
              profilesList.map((item: ProfileType) => (
                <TableRow key={item.id}>
                  <TableCell
                    align="left"
                    className="p-1 cursor-pointer"
                    onClick={() => {
                      handleEditProfile(item);
                    }}
                  >
                    {item.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="p-1 cursor-pointer"
                    onClick={() => {
                      handleEditProfile(item);
                    }}
                  >
                    {ProfileCategoryNames[item.category.name]}
                  </TableCell>
                  <TableCell align="center" className="p-1">
                    <DropdownMenu>
                      <DropdownMenuTrigger
                        asChild
                        className="rounded cursor-pointer"
                      >
                        <Button variant="ghost" size="sm">
                          <span className="sr-only">Open menu</span>
                          <MoreHorizontalIcon size={16} />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="bg-white shadow-lg border rounded">
                        <DropdownMenuItem
                          onSelect={() => {
                            handleOpenDeleteDialog(item.id as string);
                          }}
                          disabled={userCantEditProfile}
                        >
                          Excluir
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
      {deleteAlertState.isOpen && deleteAlertState.profileId && (
        <DeleteAlertDialog
          open={deleteAlertState.isOpen}
          setOpen={handleCloseDeletionDialog}
          itemId={deleteAlertState.profileId}
          handleConfirmDelete={handleDeleteProfile}
        />
      )}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import { Textarea } from 'src/components/ui/textarea';
import { Input } from 'src/components/ui/input';
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radiogroup';
import { Label } from 'src/components/ui/label';
import api from 'src/services/api';
import { toast } from 'react-toastify';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { useDataSourceList } from 'src/hooks/useDataSourceList';
import {
  TVision,
  TVisionTypes,
  VisionDialogProps,
  VisionFormValues,
} from './Visions.i';
import { LoadingSkeleton } from './components/LoadingSkeleton';

// Yup validation schema
const validationSchema = Yup.object({
  id: Yup.string().nullable(),
  name: Yup.string().required('Nome é obrigatório'),
  description: Yup.string().required('Descrição é obrigatória'),
  type: Yup.string()
    .oneOf(['REPORT', 'ANALYSIS'], 'Tipo inválido')
    .required('Tipo é obrigatório'),
  prompts: Yup.string().nullable(),
  dataSource_id: Yup.string().required('Mineração de Dados é obrigatório'),
});

export const Vision: React.FC<VisionDialogProps> = ({
  open,
  handleCloseDialog,
  vision_id,
}) => {
  const [loadingVision, setLoadingVision] = useState(!!vision_id);
  // const { areas, loadingAreas } = useAreasAll();
  const { dataSourceList, loadingDataSources } = useDataSourceList();
  // const [visionId, setVisionId] = useState<string>(vision_id || '');

  useEffect(() => {
    if (vision_id) {
      setLoadingVision(true);
      api
        .get<{ vision: TVision }>(`/visions/${vision_id}`)
        .then(response => {
          const vision = response.data?.vision;
          if (vision) {
            formik.setValues({
              id: vision.id,
              name: vision.name,
              description: vision.description,
              type: vision.type,
              prompts: vision.prompts || '',
              dataSource_id: vision?.dataSource?.id || '',
            });
          }
        })
        .catch(e => {
          const error = e.response?.data?.message || e?.message;
          const errMessage = `Erro ao buscar visão. ${error}`;
          toast.error(errMessage, {
            theme: 'colored',
            closeOnClick: true,
          });
        })
        .finally(() => setLoadingVision(false));
    }
  }, [vision_id]);

  const formik = useFormik<VisionFormValues>({
    initialValues: {
      id: undefined,
      name: '',
      description: '',
      type: 'REPORT',
      prompts: '',
      dataSource_id: '',
    },
    validationSchema,
    enableReinitialize: false,
    onSubmit: (values, { setValues, setSubmitting }) => {
      const visionId = vision_id || values.id;
      const requestType = visionId ? 'put' : 'post';
      const requestUrl = visionId ? `/visions/${visionId}` : '/visions';
      const formattedVision = {
        ...values,
        id: visionId && 'put' ? undefined : vision_id,
      };

      setSubmitting(true);
      api[requestType](requestUrl, formattedVision)
        .then(response => {
          const newId = response.data?.vision_id;
          if (requestType === 'post' && newId) {
            setValues({
              ...values,
              id: newId,
            });
            toast.success('Visão criada com sucesso', {
              theme: 'colored',
              autoClose: 2000,
              closeOnClick: true,
            });
          } else {
            toast.success('Visão editada com sucesso', {
              theme: 'colored',
            });
          }
        })
        .catch(e => {
          const error = e.response?.data?.message || e?.message;
          const errMessage = `Erro ao salvar visão. ${error}`;
          toast.error(errMessage, {
            autoClose: 5000,
            theme: 'colored',
            closeOnClick: true,
          });
        })
        .finally(() => setSubmitting(false));
    },
  });

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={isOpen => {
        if (!isOpen) handleCloseDialog();
      }}
    >
      <DialogOverlay className="z-[1400]" />
      <DialogContent
        autoFocus
        className="z-[1500] overflow-auto max-h-screen w-full min-w-fit max-w-4xl box-border"
      >
        <DialogHeader>
          <DialogTitle className="flex flex-row items-center">
            {vision_id ? 'Visão' : 'Nova visão'}
          </DialogTitle>
          <DialogDescription>
            {vision_id
              ? 'Edite os campos abaixo para alterar a visão'
              : 'Preencha os campos abaixo para criar uma nova visão'}
          </DialogDescription>
        </DialogHeader>
        {loadingVision || loadingDataSources ? (
          <LoadingSkeleton />
        ) : (
          <form className="w-full overflow-auto mb-8 box-border" noValidate>
            <div className="flex flex-row items-baseline gap-8">
              <div className="flex flex-col p-1 grow">
                <Label htmlFor="name">Nome</Label>
                <Input
                  id="name"
                  name="name"
                  required
                  className="w-full p-2 mt-2 border border-gray-300 rounded-md"
                  placeholder="Digite um nome para a visão"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onKeyDown={handleKeyDown}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-red-600 text-sm mt-1">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col p-1 grow">
                <Label htmlFor="type-radio-group" className="mb-2">
                  Tipo
                </Label>
                <RadioGroup
                  id="type-radio-group"
                  value={formik.values.type}
                  onValueChange={value =>
                    formik.setFieldValue('type', value as TVisionTypes)
                  }
                  required
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="REPORT" id="r1" />
                    <TooltipWrapper
                      value="Para listagem de visões em formato CSV ou PDF"
                      side="top"
                      className="text-xs font-medium"
                    >
                      <Label htmlFor="r1">Listagem</Label>
                    </TooltipWrapper>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="ANALYSIS" id="r2" />
                    <TooltipWrapper
                      value="Para análise de dados e extração de informações com IA"
                      side="bottom"
                      className="text-xs font-medium"
                    >
                      <Label htmlFor="r2">Análise</Label>
                    </TooltipWrapper>
                  </div>
                </RadioGroup>
                {formik.touched.type && formik.errors.type ? (
                  <div className="text-red-600 text-sm mt-1">
                    {formik.errors.type}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="flex flex-col items-start p-1 mt-2">
              <Label htmlFor="dataSource_id" className="mb-2">
                Mineração de Dados
              </Label>
              <Select
                onValueChange={value =>
                  formik.setFieldValue('dataSource_id', value)
                }
                value={formik.values.dataSource_id}
                name="dataSource_id"
              >
                <SelectTrigger>
                  <SelectValue placeholder="Selecione fonte de Mineração de Dados" />
                </SelectTrigger>
                <SelectContent autoFocus className="z-[1500]">
                  <SelectGroup>
                    <SelectLabel>Mineração de Dados</SelectLabel>
                    {dataSourceList.map(dataSource => (
                      <SelectItem key={dataSource.id} value={dataSource.id}>
                        {dataSource.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
              {formik.touched.dataSource_id && formik.errors.dataSource_id ? (
                <div className="text-red-600 text-sm mt-1">
                  {formik.errors.dataSource_id}
                </div>
              ) : null}
            </div>
            <div className="flex flex-col items-start p-1 mt-2">
              <Label htmlFor="description">Descrição</Label>
              <Textarea
                id="description"
                name="description"
                required
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full h-32 p-2 mt-2 border border-gray-300 rounded-md"
                placeholder="Descreva a visão e o que ela faz, e o contexto quando utilizado em análises"
                autoComplete="off"
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-red-600 text-sm mt-1">
                  {formik.errors.description}
                </div>
              ) : null}
            </div>
            <div className="flex flex-col items-start p-1 mt-2">
              <Label htmlFor="prompts">Prompt</Label>
              <Textarea
                id="prompts"
                name="prompts"
                value={formik.values.prompts || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full h-32 p-2 mt-2 border border-gray-300 rounded-md"
                placeholder="Digite aqui as perguntas a visão deve responder na integração com IA"
                autoComplete="off"
              />
              {formik.touched.prompts && formik.errors.prompts ? (
                <div className="text-red-600 text-sm mt-1">
                  {formik.errors.prompts}
                </div>
              ) : null}
            </div>
          </form>
        )}
        <DialogFooter className="mt-4">
          <Button
            disabled={formik.isSubmitting}
            type="button"
            onClick={handleCloseDialog}
            variant="secondary"
          >
            Fechar
          </Button>
          <Button
            disabled={formik.isSubmitting}
            type="button"
            onClick={() => formik.handleSubmit()}
          >
            {vision_id || formik.values?.id ? 'Salvar' : 'Criar'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Signature } from 'src/components/Signature';
import { ICustomField } from 'src/interface/ICardFields';
import { documentStyles } from './documentStyles';
import { ModalDocumentField } from '../../../../../components/ModalDocumentField';
import { DigitalSignatureIcon } from '../../../../../assets/DigitalSignatureIcon';
import { PdfIcon } from '../../../../../assets/PdfIcon';
import { EditDocumentIcon } from '../../../../../assets/EditDocumentIcon';
import api from '../../../../../services/api';
import {
  formatCfToRichTextVariables,
  IRichTextVariable,
} from '../../../../../utils/customFieldUtils';
import { useCardDataContext } from '../../../../../context/CardDataContext';
import { IGenericFieldProps } from '../../../Card.i';

const useStyles = makeStyles(() => createStyles(documentStyles()));

export const DocumentField = ({
  customField,
  phaseIndex,
  customFieldIndex,
}: IGenericFieldProps): JSX.Element => {
  const classes = useStyles();
  const { handleSubmitCard, cardCurrentPhase, card_id, handleFillCustomField } =
    useCardDataContext();

  const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false);
  const [openModalDocument, setOpenModalDocument] = useState<boolean>(false);
  const [openModalSignature, setOpenModalSignature] = useState<boolean>(false);
  const [fetchFieldsCompleted, setFetchFieldsCompleted] = useState(false);
  const [template, setTemplate] = useState<string>('');
  const [signaturesCollectionStarted, setSignaturesCollectionStarted] =
    useState<boolean>(false);
  const [signatureId, setSignatureId] = useState<string | null>(
    customField.document.signature_id || null,
  );

  const [suggestionsCF, setSuggestionsCF] = useState<IRichTextVariable[]>(
    [] as IRichTextVariable[],
  );

  useEffect(() => {
    if (customField.value !== template) {
      handleFillCustomField({
        phaseIndex,
        customFieldIndex,
        value: template,
        customFieldId: customField.id,
      });
    }
  }, [template]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && customField.document && template === '') {
      setTemplate(
        customField.value && customField.value.length > 0
          ? customField.value
          : customField.document.template,
      );
    }
    return () => {
      isMounted = false;
    };
  }, [customField.value, customField.document.template]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && customField.document.signatureCollectionStarted) {
      setSignaturesCollectionStarted(
        customField.document.signatureCollectionStarted,
      );
    }
    return () => {
      isMounted = false;
    };
  }, [customField.document.signatureCollectionStarted]);

  useEffect(() => {
    let isMounted = true;
    if (cardCurrentPhase?.id) {
      api
        .get(`/phases/${cardCurrentPhase.id}`)
        .then(phaseResponse => {
          if (isMounted) {
            api
              .get(`/areas/${phaseResponse.data.area.id}/customFields`)
              .then(response => {
                const filteredFields: ICustomField[] = response.data.filter(
                  (field: { id: string; name: string; type: string }) =>
                    field?.type !== 'DOCUMENT' && field?.type !== 'FILE',
                );
                const formattedSugestions =
                  formatCfToRichTextVariables(filteredFields);
                setSuggestionsCF(formattedSugestions);
                setFetchFieldsCompleted(true);
              })
              .catch(() => {
                toast.error('Falha ao carregar campos.', {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                  autoClose: 5000,
                });
              });
          }
        })
        .catch(() => {
          toast.error('Falha ao carregar dados da área.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        });
    }
    return () => {
      isMounted = false;
    };
  }, [cardCurrentPhase?.id]);

  const handleCloseModalDocument = () => {
    setOpenModalDocument(false);
  };

  const handleCloseModalSignature = () => {
    setOpenModalSignature(false);
  };

  const handleGetPdfDocument = () => {
    const loadingPDF = toast.loading(`Carregando PDF ${customField.name}...`);
    document.body.style.cursor = 'progress';

    api
      .get(`cards/${card_id}/customFields/${customField.id}/getPDF`)
      .then(response => {
        const { url } = response.data.file;
        const { warning } = response.data;

        if (response && warning && warning.length > 0) {
          toast.warning(warning, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'dark',
            autoClose: 4000,
          });
        }

        toast.update(loadingPDF, {
          render: `PDF ${customField.name} carregado com sucesso.`,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
        window.open(url, '_blank');
      })
      .catch(e => {
        let responseError = '';
        responseError = e.response?.data.message || e.message;

        toast.update(loadingPDF, {
          render: `Falha ao carregar PDF ${customField.name}. ${responseError}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        setIsPdfLoading(false);
        document.body.style.cursor = 'auto';
      });
  };

  const handleRestoreTemplate = () => {
    setTemplate(customField.document.template);
    handleFillCustomField({
      phaseIndex,
      customFieldIndex,
      value: customField.document.template,
    });
  };

  return (
    <Box className={classes.documentType}>
      <TextField
        contentEditable={false}
        label={customField.name}
        autoComplete="off"
        required={
          customField.isRequired &&
          (template.length === 0 || template.trim() === '<p></p>')
        }
        InputLabelProps={{
          shrink: false,
          style: {
            maxWidth: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            paddingBottom: '1px',
          },
        }}
        style={{
          flex: '1',
          padding: 0,
          margin: 0,
        }}
        fullWidth
        margin="dense"
        InputProps={{
          disableUnderline: true,
          onKeyDown: (e: { preventDefault: () => void }) => {
            e.preventDefault();
            return false;
          },
        }}
      />
      <Box mt={1} mb={0} pb={0}>
        <IconButton
          aria-label="Editar documento"
          size="small"
          disabled={
            !customField.document.isEditable ||
            (customField.document.isEditable && signaturesCollectionStarted) ||
            customField?.isDisabled
          }
          onClick={() => setOpenModalDocument(true)}
        >
          <Tooltip title="Editar documento">
            <div style={{ margin: 0, padding: 0 }}>
              <EditDocumentIcon />
            </div>
          </Tooltip>
        </IconButton>

        <IconButton
          aria-label="Definir assinaturas"
          size="small"
          disabled={
            !customField.document.isRequiredToSign || customField?.isDisabled
          }
          onClick={() => setOpenModalSignature(true)}
        >
          <Tooltip title="Assinaturas">
            <div style={{ margin: 0, padding: 0 }}>
              <DigitalSignatureIcon />
            </div>
          </Tooltip>
        </IconButton>

        <IconButton
          aria-label="Gerar PDF do template"
          size="small"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setIsPdfLoading(true);
            handleSubmitCard(e, handleGetPdfDocument);
          }}
          disabled={
            isPdfLoading ||
            signaturesCollectionStarted ||
            customField?.isDisabled
          }
        >
          <Tooltip title="Visualizar PDF">
            <div style={{ margin: 0, padding: 0 }}>
              <PdfIcon />
            </div>
          </Tooltip>
        </IconButton>
      </Box>
      {openModalDocument && fetchFieldsCompleted && (
        <ModalDocumentField
          title={`Editar documento - ${customField.name}`}
          open={openModalDocument}
          closeModal={handleCloseModalDocument}
          template={template}
          setTemplate={setTemplate}
          suggestionsCF={suggestionsCF}
          handleRestoreTemplate={handleRestoreTemplate}
        />
      )}
      {openModalSignature && (
        <Signature
          title={`Assinaturas - ${customField.name}`}
          open={openModalSignature}
          closeModal={handleCloseModalSignature}
          card_id={card_id}
          signature_id={signatureId}
          setSignatureId={setSignatureId}
          cardField_id={customField.id}
          hasFilesOrTemplate
          setSignaturesCollectionStarted={setSignaturesCollectionStarted}
        />
      )}
    </Box>
  );
};

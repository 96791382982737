import React from 'react';

import { ProfilePermissionTable } from 'src/components/ProfilePermissionTable';
import { Autocomplete } from '@material-ui/lab';
import { ProfileType } from 'src/services/permissionsApi';
import { CircularProgress, TextField } from '@material-ui/core';
import { UserPermissionsProps } from '../User.i';

export const UserPermissions: React.FC<UserPermissionsProps> = ({
  profilePermissions,
  permissionsByCategories,
  permissionProperties,
  profileCategory,
  handleChangePermission,
  customPermissions,
  user_id,
  profilesList,
  loadingProfiles,
  formik,
  isUserAdmin,
}) => {
  const canEditPermissions = isUserAdmin;

  return (
    <div className="w-full border-2 p-2 rounded-sm max-md:mt-2">
      <div className="w-fit text-1xl font-bold ml-1 px-1 -mt-5 bg-white">
        Permissões
      </div>
      <Autocomplete
        id="profile"
        getOptionLabel={profile => profile.name}
        getOptionSelected={(profile, value) => profile.id === value.id}
        options={profilesList}
        loading={loadingProfiles}
        value={(formik.values.profile as ProfileType) || null}
        onChange={(e, value) => {
          if (value?.id !== formik.values.profile?.id) {
            formik.setFieldValue('customPermissions', []);
          }
          formik.setFieldValue('profile', value || null);
        }}
        disableClearable
        loadingText="Carregando"
        renderInput={rest => (
          <TextField
            {...rest}
            id="profile_input"
            name="profile"
            label="Perfil de acesso"
            margin="dense"
            variant="outlined"
            className="max-w-[50%]"
            error={formik.touched.profile && Boolean(formik.errors.profile)}
            helperText={formik.touched.profile && formik.errors.profile}
            InputProps={{
              ...rest.InputProps,
              endAdornment: (
                <>
                  {loadingProfiles ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {rest.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {permissionProperties !== null &&
        permissionsByCategories !== null &&
        profileCategory !== null && (
          <ProfilePermissionTable
            permissionProperties={permissionProperties}
            permissionsByCategories={permissionsByCategories}
            customPermissions={customPermissions}
            activePermissions={profilePermissions}
            currentCategory={profileCategory}
            handleChangePermission={handleChangePermission}
            disableEdit={!user_id || !canEditPermissions}
            className={`${user_id ? 'h-96' : 'h-80'} mt-2`}
          />
        )}
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { TextField } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { ObjectKeyOptionsProps } from '../IntegrationRules.i';
import { CustomPopper } from '../../../components/AutoCompleteCustomComponents/CustomPopper';

export const ObjectKeyOptions: React.FC<ObjectKeyOptionsProps> = ({
  integrationTypeField,
  integrationFieldValues,
  handleIntegrationFieldValue,
  options,
}) => {
  return (
    <Autocomplete
      id={integrationTypeField.name}
      fullWidth
      style={{ minWidth: '200px' }}
      getOptionLabel={option =>
        integrationTypeField.parentName
          ? `${option} - ${integrationTypeField.parentName}`
          : option || ''
      }
      getOptionSelected={(option, value) => option === value}
      options={options}
      PopperComponent={CustomPopper}
      value={
        integrationFieldValues.find(
          integrationFieldValue =>
            integrationFieldValue.integrationTypeFieldId ===
            integrationTypeField.id,
        )?.fieldId || null
      }
      onChange={(e, value) => {
        handleIntegrationFieldValue({
          typeFieldId: integrationTypeField.id,
          fieldType: integrationTypeField.fieldType,
          requiresFullIntegratedItem: false,
          valueId: value || '',
          valueType: '',
          valueFieldType: undefined,
        });
      }}
      noOptionsText="Sem opções."
      loadingText="Carregando"
      renderInput={rest => (
        <TextField
          {...rest}
          required={
            integrationTypeField.isRequired &&
            !integrationFieldValues.find(
              integrationFieldValue =>
                integrationFieldValue.integrationTypeFieldId ===
                integrationTypeField.id,
            )?.fixedValue
          }
          label="Associação"
          margin="dense"
          InputProps={{
            ...rest.InputProps,
          }}
        />
      )}
    />
  );
};

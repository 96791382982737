/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { toast } from 'react-toastify';
import { useAuth } from 'src/context/AuthContext';
import { useTrigger } from 'src/context/TriggerContext';
import api from 'src/services/api';
import { ptBR } from 'date-fns/locale';
import { addMinutes, addSeconds, format, isValid, parseISO } from 'date-fns';
import { convertISODateToStringFormat } from 'src/utils/dateFormatAndComparisonUtils';
import { isFieldValueEmpty } from 'src/utils/customFieldUtils';
import { useFormik } from 'formik';
import { TaskTypes } from 'src/utils/taskTypes.constants';
import { useUsersAll } from 'src/hooks/useUsersAll';
import { useAreas } from 'src/hooks/useAreas';
import { useUsers } from 'src/hooks/useUsers';
import { useAccounts } from 'src/hooks/useAccounts';
import { findPhaseAndCustomFieldIndexes } from 'src/utils/card/cardUtils';
import { deepClone } from 'src/utils/deepCloneObject';
import { ICardData } from 'src/interface/ICardData';
import { isNotNullish } from 'src/utils/comparisonUtils';
import {
  IArea,
  ICard,
  IContact,
  InitialStateProps,
  IObject,
  IPhase,
  ITaskLog,
  ITaskValues,
  TaskHandleCardValueProps,
  TaskModalProps,
} from '../TaskModal.i';
import { formikTaskValidation } from '../formikConfig';
import { useTaskFillRule } from './useTaskFillRule';
import { formatLinkedCardFields } from '../utils/formatLinkedCardFields';

interface HookReturn {
  isTaskDoneModalOpen: boolean;
  cardFieldsErrorList: { [key: string]: boolean };
  users: IObject[];
  allUsers: IObject[];
  phases: IPhase[];
  wasInitiallyDone: boolean;
  areas: IArea[];
  handleRefreshAccounts: () => void;
  handleRefreshContacts: (accountId: string) => void;
  handleChangeSimpleFieldValue: (
    field: string,
    value: string | Date | boolean | null,
  ) => void;
  handleChangeAutocompleteFieldValue: (
    fieldId: string,
    value: IObject | IArea | IContact | IContact[] | null,
  ) => void;
  handleChangeTaskFieldValue: (
    fieldId: string,
    newValue: string | null,
  ) => void;
  handleChangeCardFieldValue: (params: TaskHandleCardValueProps) => void;
  handleAddNewLog: (newLog: ITaskLog) => void;
  handleOpenEmailConfirmation: () => void;
  handleSendEmail: (sendEmail: boolean) => void;
  handleFinishTask: (shouldFinishTask: boolean) => void;
  handleSubmitForm: (event: React.FormEvent<HTMLFormElement>) => void;
  taskDeadline: string | undefined;
  loading: boolean;
  isTaskDone: boolean;
  taskCurrentTypeId: string;
  isUserNotAdminAndDifferentArea: boolean;
  editPermissionDisabledForUser: boolean;
  isUserAdmin: boolean;
  isUserGuest: boolean;
  isEmailModalOpen: boolean;
  isUserNotAccountResponsible: boolean;
  setIsTaskDoneModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEmailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formik: ReturnType<typeof useFormik<ITaskValues>>;
  cards: ICard[];
  accounts: IObject[];
  accountContacts: IContact[];
  cardFieldsData: ICardData[];
}

export function useTaskModal({
  task_id,
  closeModal,
  newTaskType,
  isProcessTask,
  card_id,
  associateCard = false,
  cardData,
  dateRangeSuggestion,
}: TaskModalProps): HookReturn {
  const { updateTriggers } = useTrigger();
  const { user } = useAuth();
  const { users, loadingUsers } = useUsers();
  const { users: allUsers, loadingUsers: loadingUsersAll } = useUsersAll();
  const { areas, loadingAreas } = useAreas();
  const { accounts, loadingAccounts, setRefreshAccount } = useAccounts();

  const isUserAdmin = user?.profile.categoryName === 'ADMINISTRATOR' || false;
  const [shouldSendEmail, setShouldSendEmail] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isTaskDoneModalOpen, setIsTaskDoneModalOpen] = useState(false);
  const taskTypeOptions = Object.values(TaskTypes).sort(
    (a: IObject, b: IObject) => -b.name.localeCompare(a.name),
  );
  const [initialState, setInitialState] =
    useState<InitialStateProps>(undefined);
  const [cardFieldsErrorList, setCardFieldsErrorList] = useState<{
    [key: string]: boolean;
  }>({});

  const [cards, setCards] = useState<ICard[]>([]);
  const [phases, setPhases] = useState<IPhase[]>([]);
  const [accountContacts, setAccountContacts] = useState<IContact[]>([]);
  const [task, setTask] = useState<ITaskValues | null>(null);
  const [loadingTask, setLoadingTask] = useState(true);
  const [wasInitiallyDone, setWasInitiallyDone] = useState(false);

  const handleCloseTaskModal = () => {
    closeModal();
  };

  const formik = useFormik(
    formikTaskValidation(
      updateTriggers,
      task_id,
      task,
      handleSetTaskIdAfterCreate,
      handleCloseTaskModal,
      newTaskType,
      isProcessTask,
      associateCard,
      initialState,
      setInitialState,
      shouldSendEmail,
      setShouldSendEmail,
      setCardFieldsErrorList,
      setWasInitiallyDone,
      card_id,
      cardData,
    ),
  );

  function handleSetTaskIdAfterCreate(id: string) {
    formik.setFieldValue('id', id);
  }

  useEffect(() => {
    if (task_id !== null && task_id.length > 0) {
      const getRoute =
        isProcessTask && card_id
          ? `/cards/${card_id}/cardTasks/${task_id}`
          : `/singleTasks/${task_id}`;

      api
        .get(getRoute)
        .then(response => {
          const respData = response?.data;
          if (respData) {
            const { card, ...data } = respData;
            const newCardData =
              card && typeof card === 'object'
                ? {
                    ...card,
                    name: card.keyCard,
                  }
                : null;

            setTask({
              ...data,
              card: newCardData,
              done: !!data?.completedAt || data?.done || false,
            });
            setWasInitiallyDone(!!data?.completedAt || data?.done || false);
            setLoadingTask(false);
          }
        })
        .catch(e => {
          toast.error(
            `Falha ao carregar informações da tarefa. ${
              e.response.data?.message || ''
            }`,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            },
          );

          handleCloseTaskModal();
        });
    }
  }, [task_id]);

  useEffect(() => {
    if (!task_id) {
      if (dateRangeSuggestion !== undefined) {
        const { start, end } = dateRangeSuggestion;
        if (start) formik.setFieldValue('startsAt', start);
        if (end) formik.setFieldValue('endsAt', end);
      } else {
        const startDate = new Date();
        const endDate = new Date(startDate);
        const newEndDate = addMinutes(endDate, 1);
        // endDate.setHours(startDate.getHours() + 1);
        formik.setFieldValue('startsAt', startDate);
        formik.setFieldValue('endsAt', newEndDate);
      }
    }
  }, [task_id, dateRangeSuggestion]);

  useEffect(() => {
    const { startsAt } = formik.values;
    const { endsAt } = formik.values;
    if (
      (startsAt && !endsAt) ||
      (startsAt && endsAt && new Date(startsAt) > new Date(endsAt))
    ) {
      const endDate = new Date(startsAt);
      const newEndDate = addMinutes(endDate, 1);
      formik.setFieldValue('endsAt', newEndDate);
    }
  }, [formik.values.startsAt, formik.values.endsAt]);

  const loadContacts = useCallback((accountId: string) => {
    api
      .post('/accounts/contacts', {
        account_ids: [accountId],
      })
      .then(response => {
        const respData = response?.data;
        const sortByName = respData
          ? respData.sort(
              (a: IObject, b: IObject) => -b.name.localeCompare(a.name),
            )
          : [];
        setAccountContacts(sortByName);
      })
      .catch(() => {
        formik.setFieldValue('contacts', []);
        setAccountContacts([]);
        toast.error('Falha ao carregar contatos da conta selecionada', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      });
  }, []);

  const handleRefreshAccounts = useCallback(async () => {
    setRefreshAccount(oldValue => !oldValue);
  }, [setRefreshAccount]);

  const handleRefreshContacts = useCallback((accountId: string) => {
    loadContacts(accountId);
  }, []);

  const loadAccountCardsByArea = useCallback(
    async (accountId: string, areaId: string) => {
      const contactsResult = await api.get(
        `/cards/accounts/${accountId}/areas/${areaId}`,
      );
      const cardsList: ICard[] = contactsResult.data;
      const cardListWithName = cardsList.map((c: ICard) => ({
        ...c,
        name: c.keyCard,
      }));
      const sortByKeyCard = cardListWithName
        ? cardListWithName.sort((a: ICard, b: ICard) => {
            const numericA = parseInt(a.keyCard, 10);
            const numericB = parseInt(b.keyCard, 10);

            return numericA - numericB;
          })
        : [];
      return sortByKeyCard;
    },
    [],
  );

  // refresh account contacts phone
  useEffect(() => {
    const { account } = formik.values;
    if (
      accounts.length > 0 &&
      // type !== 'ONLINE_MEETING' &&
      account !== null &&
      account !== undefined
    ) {
      const refreshAccount = accounts.find(ac => ac.id === account.id);
      if (refreshAccount) {
        formik.setFieldValue('account', refreshAccount);
      }
    }
  }, [accounts]);

  useEffect(() => {
    const { contacts, type } = formik.values;
    if (
      accountContacts.length > 0 &&
      type !== 'ONLINE_MEETING' &&
      contacts !== null &&
      contacts !== undefined &&
      contacts.length > 0 &&
      contacts[0]
    ) {
      const refreshContact = accountContacts.find(
        contact => contacts[0]?.id === contact.id,
      );
      if (refreshContact) {
        formik.setFieldValue('contacts', [refreshContact] as IContact[]);
      }
    }
  }, [accountContacts]);

  // on edit task check if there is area.phases attribute
  useEffect(() => {
    if (
      task_id &&
      areas.length > 0 &&
      formik.values?.area?.id &&
      !formik.values?.area?.phases &&
      (isUserAdmin || user.areaId === formik.values?.area?.id)
    ) {
      const areaWithPhases = areas.find(a => a.id === formik.values.area?.id);
      formik.setFieldValue('area', areaWithPhases);
    }
  }, [formik.values.area, areas, task_id]);

  // get cards list by area.id and account.id
  useEffect(() => {
    const userAdminOrFromSameArea =
      isUserAdmin || user.areaId === formik.values?.area?.id;
    if (
      formik.values?.area?.id &&
      formik.values?.account?.id &&
      userAdminOrFromSameArea
    ) {
      const fetchData = async () => {
        try {
          const cardsList = await loadAccountCardsByArea(
            formik.values.account?.id || '',
            formik.values.area?.id || '',
          );
          setCards(cardsList);
        } catch (error) {
          formik.setFieldValue('card', null);
          setCards([]);
          toast.error('Falha ao carregar lista de cards', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      };
      fetchData();
    } else {
      if (userAdminOrFromSameArea) formik.setFieldValue('card', null);
      setCards([]);
    }
  }, [formik.values.area, formik.values.account, user]);

  // get contacts of the selected account.id
  useEffect(() => {
    if (formik.values?.account?.id)
      loadContacts(formik.values?.account?.id || '');
  }, [formik.values.account]);

  // set phases based on selectedArea
  useEffect(() => {
    if (
      areas.length > 0 &&
      formik.values.area &&
      formik.values.area?.phases &&
      formik.values.area?.phases !== null &&
      formik.values.area.phases.length > 0
    ) {
      setPhases(formik.values.area.phases);
    } else {
      setPhases([]);
    }
  }, [formik.values.area, areas]);

  useEffect(() => {
    if (
      !task_id &&
      associateCard &&
      cardData !== undefined &&
      areas.length > 0
    ) {
      const areaSuggestion = areas.find(a => a.id === cardData.area.id);
      const phaseSuggestion = areaSuggestion?.phases.find(
        a => a.id === cardData.phase.id,
      );
      if (areaSuggestion) formik.setFieldValue('area', areaSuggestion);
      if (phaseSuggestion) formik.setFieldValue('phase', phaseSuggestion);
    }
  }, [associateCard, cardData, task_id, areas]);

  useEffect(() => {
    if (
      !task_id &&
      associateCard &&
      cardData !== undefined &&
      cards.length > 0
    ) {
      const cardSuggestion = cards.find(a => a.id === cardData.card.id);
      if (cardSuggestion) formik.setFieldValue('card', cardSuggestion);
    }
  }, [associateCard, cardData, task_id, cards]);

  function handleChangeSimpleFieldValue(
    field: string,
    value: string | Date | boolean | null,
  ) {
    formik.setFieldValue(field, value);
  }

  const handleChangeAutocompleteFieldValue = useCallback(
    (
      fieldId: string,
      value: IObject | IArea | IContact | IContact[] | null,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let newValue: any = value;
      if (fieldId === 'type') {
        if (!formik.values.account) formik.setFieldValue('contacts', []);
        newValue =
          value !== null && typeof value === 'object' && 'id' in value
            ? value?.id
            : '';
      }

      if ((fieldId === 'startsAt' || fieldId === 'endsAt') && !isValid(value)) {
        formik.setFieldError(fieldId, 'Data inválida');
      }

      if (fieldId === 'account') {
        formik.setFieldValue('contacts', []);
        formik.setFieldValue('card', null);
        if (
          formik.values.type === 'IN_PERSON_MEETING' &&
          (newValue?.street ||
            newValue?.numeral ||
            newValue?.complement ||
            newValue?.district ||
            newValue?.city)
        ) {
          const newMeetingAddress = `${newValue?.street}${
            newValue?.numeral && `, ${newValue?.numeral}`
          }${newValue?.complement && ` - ${newValue?.complement}`}${
            newValue?.district && `, ${newValue?.district} - ${newValue?.city}`
          }`;
          const { fieldValues } = formik.values;
          const hasAddressValue = !!fieldValues.find(
            fv => fv.id === 'MEETING_ADDRESS',
          );
          let newFieldValues = [];

          if (hasAddressValue) {
            newFieldValues = fieldValues.map(fv => {
              if (fv.id === 'MEETING_ADDRESS') {
                return {
                  ...fv,
                  value: newMeetingAddress,
                };
              }

              return fv;
            });
          } else {
            newFieldValues.push({
              id: 'MEETING_ADDRESS',
              name: 'Endereço da Reunião',
              type: 'STRING',
              value: newMeetingAddress,
            });
          }
          formik.setFieldValue('fieldValues', newFieldValues);
        }
      }

      if (fieldId === 'area') {
        formik.setFieldValue('card', null);
        formik.setFieldValue('phase', null);
      }

      if (
        fieldId === 'contacts' &&
        value !== undefined &&
        value !== null &&
        (Array.isArray(value) || typeof value === 'object')
      ) {
        formik.setFieldValue(
          'contacts',
          (Array.isArray(value) ? value : [value]) as IContact[],
        );
      } else if (
        fieldId === 'contacts' &&
        (value === undefined || value === null)
      ) {
        formik.setFieldValue('contacts', []);
      }

      if (fieldId !== 'contacts') formik.setFieldValue(fieldId, newValue);
    },
    [formik.values.type],
  );

  const handleChangeTaskFieldValue = (
    fieldId: string,
    newValue: string | null,
  ) => {
    const { fieldValues } = formik.values;
    let newFieldValues = [...fieldValues];
    const fieldHasValue = newFieldValues.find(f => f.id === fieldId);
    if (fieldHasValue) {
      const changeValue = newFieldValues.map(fv => {
        if (fv.id === fieldId) {
          return {
            ...fv,
            value: newValue || null,
          };
        }
        return fv;
      });
      newFieldValues = changeValue;
    } else {
      newFieldValues.push({
        id: fieldId,
        value: newValue || null,
        name: '',
        type: '',
      });
    }
    formik.setFieldValue(`fieldValues`, newFieldValues);
  };

  // FILLRULE
  const shouldEvaluateFillRules = useMemo(() => {
    const result =
      isProcessTask &&
      !!card_id &&
      // !formik.values.done &&
      isNotNullish(formik.values?.linkedCardFieldValues) &&
      formik.values?.linkedCardFieldValues.length > 0;
    return result || false;
  }, [
    card_id,
    isProcessTask,
    formik.values.done,
    formik.values?.linkedCardFieldValues,
  ]);

  const { handleFillCustomField, cardFieldsData, rulesEvaluatedOnLoad } =
    useTaskFillRule({
      card_id,
      shouldEvaluateFillRules,
      taskCompleted: formik.values.done,
    });
  // END FILLRULE

  const handleChangeCardFieldValue = useCallback(
    ({ fieldId, newValue, valueProperty }: TaskHandleCardValueProps) => {
      setCardFieldsErrorList(oldValue => ({
        ...oldValue,
        [fieldId]: false,
      }));
      const deepClonedCardFields = deepClone(cardFieldsData);
      if (
        deepClonedCardFields.length > 0 &&
        shouldEvaluateFillRules &&
        rulesEvaluatedOnLoad
      ) {
        const { customFieldIndex, phaseIndex } = findPhaseAndCustomFieldIndexes(
          fieldId,
          deepClonedCardFields,
        );
        if (
          customFieldIndex >= 0 &&
          phaseIndex >= 0 &&
          handleFillCustomField !== undefined
        ) {
          handleFillCustomField({
            phaseIndex,
            customFieldIndex,
            value: valueProperty === 'value' ? (newValue as any) : undefined,
            valueJSON:
              valueProperty === 'valueJSON' ? (newValue as any) : undefined,
            customFieldId: fieldId,
          });
        }
      }
    },
    [
      cardFieldsData,
      shouldEvaluateFillRules,
      handleFillCustomField,
      rulesEvaluatedOnLoad,
    ],
  );

  const handleAddNewLog = (newLog: ITaskLog) => {
    const oldLogs = formik.values.logs;
    if (newLog) formik.setFieldValue('logs', [newLog, ...oldLogs]);
  };

  const handleOpenEmailConfirmation = () => {
    const taskTypeId = formik.values.type;
    const newStartDate = formik.values?.startsAt
      ? new Date(formik.values.startsAt).toISOString()
      : null;
    const newEndDate = formik.values?.endsAt
      ? new Date(formik.values.endsAt).toISOString()
      : null;
    const initialContacts =
      initialState && initialState.contacts
        ? initialState.contacts
            .map(c => c.id)
            .sort()
            .join(',')
        : '';
    const sortedNewContacts = formik.values.contacts
      ? formik.values.contacts
          .map(obj => obj.id)
          .sort()
          .join(',')
      : '';

    if (
      sortedNewContacts.length > 0 &&
      newStartDate &&
      newEndDate &&
      (taskTypeId === 'ONLINE_MEETING' || taskTypeId === 'IN_PERSON_MEETING') &&
      (initialState === undefined ||
        (initialState !== undefined &&
          (initialState?.startsAt?.toString() !== newStartDate ||
            initialState?.endsAt?.toString() !== newEndDate ||
            initialContacts !== sortedNewContacts)))
    ) {
      setIsEmailModalOpen(true);
    }
  };

  const handleSendEmail = (sendEmail: boolean) => {
    if (sendEmail) setShouldSendEmail(true);
    setIsEmailModalOpen(false);
    formik.handleSubmit();
  };

  const handleFinishTask = (shouldFinishTask: boolean) => {
    if (shouldFinishTask) {
      handleChangeSimpleFieldValue('done', true);
      formik.handleSubmit();
    }
    setIsTaskDoneModalOpen(false);
  };

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isEmailModalOpen) {
      return;
    }
    if (
      !formik.values.done &&
      formik.values.type === 'APPROVAL' &&
      formik.values?.linkedCardFieldValues.length > 0 &&
      formik.values?.linkedCardFieldValues.find(
        field =>
          field.isApprovalCriterion &&
          (!isFieldValueEmpty(field.value) ||
            !isFieldValueEmpty(field.valueJSON)),
      )
    ) {
      setIsTaskDoneModalOpen(true);
      return;
    }

    if (formik.values.linkedCardFieldValues && cardFieldsData) {
      const formattedLinkedCardFields = formatLinkedCardFields(
        formik.values.linkedCardFieldValues,
        cardFieldsData,
      );
      formik.setFieldValue('linkedCardFieldValues', formattedLinkedCardFields);
    }

    formik.handleSubmit();
  };

  const taskDeadline = useMemo(() => {
    const deadline = formik.values?.deadline || 0;
    const createdAt = formik.values?.createdAt;
    if (deadline > 0 && createdAt) {
      const parsedDate = parseISO(createdAt.toString());
      const deadlineInSeconds = deadline * 60 * 60;
      const dateAux = addSeconds(parsedDate, deadlineInSeconds);
      const formattedDeadline = format(dateAux, 'dd/MM/yy HH:mm', {
        locale: ptBR,
      });

      return `Prazo: ${formattedDeadline}`;
    }

    if (formik.values?.cumulativeDeadlineAt) {
      const formattedDeadline = convertISODateToStringFormat(
        formik.values.cumulativeDeadlineAt,
      );
      return `Prazo: ${formattedDeadline}`;
    }

    return undefined;
  }, [
    formik.values?.deadline,
    formik.values.createdAt,
    formik.values?.cumulativeDeadlineAt,
  ]);

  const isTaskDone = formik.values.done;
  const taskCurrentTypeId = taskTypeOptions
    ? taskTypeOptions.find(option => option.id === formik.values.type)?.id || ''
    : '';
  const isLoading =
    loadingUsers || loadingUsersAll || loadingAreas || loadingAccounts;
  const loading: boolean = task_id
    ? (isLoading && loadingTask) || (!isLoading && loadingTask)
    : isLoading;
  const isUserGuest = formik.values.user?.id !== user.id || false;
  const isUserNotAdminAndDifferentArea =
    !isUserAdmin &&
    !!formik.initialValues?.area?.id &&
    formik.initialValues.area.id !== user.areaId;
  const editPermissionDisabledForUser = isUserGuest && !isUserAdmin;
  const isUserNotAccountResponsible = !!(
    !isUserAdmin &&
    formik.initialValues?.account?.id &&
    accounts.length > 0 &&
    accounts.find(
      account => account.id === formik.initialValues?.account?.id,
    ) === undefined
  );

  return {
    formik,
    cards,
    accounts,
    accountContacts,
    isTaskDoneModalOpen,
    cardFieldsErrorList,
    users,
    allUsers,
    phases,
    wasInitiallyDone,
    areas,
    handleRefreshAccounts,
    handleRefreshContacts,
    handleChangeSimpleFieldValue,
    handleChangeAutocompleteFieldValue,
    handleChangeTaskFieldValue,
    handleChangeCardFieldValue,
    handleAddNewLog,
    handleOpenEmailConfirmation,
    handleSendEmail,
    handleFinishTask,
    handleSubmitForm,
    taskDeadline,
    loading,
    isTaskDone,
    isEmailModalOpen,
    taskCurrentTypeId,
    isUserNotAdminAndDifferentArea,
    isUserAdmin,
    isUserGuest,
    isUserNotAccountResponsible,
    editPermissionDisabledForUser,
    setIsTaskDoneModalOpen,
    setIsEmailModalOpen,
    cardFieldsData:
      cardFieldsData !== undefined &&
      cardFieldsData.length > 0 &&
      rulesEvaluatedOnLoad
        ? deepClone(cardFieldsData)
        : [],
  } as HookReturn;
}

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import BusinessIcon from '@material-ui/icons/Business';
// import PersonIcon from '@material-ui/icons/Person';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import FolderIcon from '@material-ui/icons/Folder';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EventIcon from '@material-ui/icons/Event';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FileBarChart2Icon, SearchCheckIcon, Settings } from 'lucide-react';
import { useAuth } from 'src/context/AuthContext';
import { isMobile } from 'react-device-detect';
import { useMenu } from '../../context/MenuContext';
import PermissionComponent from '../PermissionComponent';
import { TriggerIcon } from '../../assets/menuicon/TriggerIcon';
import { COLORS } from '../../styles/colors';

const drawerWidth = 170;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    top: 64,
    backgroundColor: COLORS.BGCOLORMENU,
    transition: theme.transitions.create(['width', 'background-color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 10,
  },
  drawerIcons: {
    width: 60,
    transition: theme.transitions.create(['width', 'background-color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    top: 64,
    backgroundColor: COLORS.BGCOLORMENU,
  },
  icon: {
    color: COLORS.GREEN,
    height: 26,
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  selected: {
    backgroundColor: COLORS.LIGHTGREEN,
  },
  notOpen: {
    zIndex: 0,
  },
}));

export const Menu: React.FC = () => {
  const history = useHistory();
  const { company } = useAuth();
  const classes = useStyles();
  const { open, handleMenuIsOpen } = useMenu();
  // const [openViaHover, setOpenViaHover] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState('');

  const envAllowedCompanies = process.env?.REACT_APP_ALLOWED_COMPANY_IDS || '';
  const allowedCompanies: string[] =
    envAllowedCompanies.length > 0 ? envAllowedCompanies.split(',') : [];
  const hideMenu =
    allowedCompanies.length > 0 &&
    company.id &&
    !allowedCompanies.includes(company.id);

  const handleMenu = (route: string) => {
    history.push(route);
    if (isMobile) handleMenuIsOpen();
  };

  const selected = (route: string) => {
    if (history.location.pathname.includes(route)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    menuItems.forEach(menuItem => {
      if (selected(menuItem.route)) {
        setSelectedMenu(menuItem.route);
      }
    });
  }, [history.location.pathname]);

  const menuItems = [
    {
      label: 'Inicio',
      route: '/workspace',
      permissionName: 'FULL_ACCESS',
      icon: <EventIcon className={classes.icon} />,
    },
    {
      label: 'Painel',
      route: '/dashboard',
      permissionName: 'FULL_ACCESS',
      icon: <ViewColumnIcon className={classes.icon} />,
    },
    {
      label: 'Empresas',
      route: '/businesses',
      permissionName: 'BUSINESS',
      icon: <BusinessIcon className={classes.icon} />,
    },
    {
      label: 'Usuários',
      route: '/listusers',
      permissionName: 'USER',
      icon: <PeopleAltRoundedIcon className={classes.icon} />,
    },
    {
      label: 'Contas',
      route: '/listaccounts',
      permissionName: 'ACCOUNT',
      icon: <FolderIcon className={classes.icon} />,
    },
    {
      label: 'Processos',
      route: '/listareas',
      permissionName: 'AREA',
      icon: <AccountTreeIcon className={classes.icon} />,
    },
    {
      label: 'Pesquisa',
      route: '/search',
      permissionName: 'SEARCH',
      icon: <SearchCheckIcon className={classes.icon} />,
    },
    {
      label: 'Integração',
      route: '/listIntegrationsRules',
      permissionName: 'INTEGRATION',
      icon: <Settings className={classes.icon} />,
    },
    {
      label: 'Gatilhos',
      route: '/listTriggers',
      permissionName: 'TRIGGER',
      icon: <TriggerIcon className={classes.icon} />,
    },
    {
      label: 'Formulários',
      route: '/listforms',
      permissionName: 'FORM',
      icon: <AssignmentIcon className={classes.icon} />,
    },
    {
      label: 'Visões',
      route: '/listVisions',
      permissionName: 'VISION',
      hideMenu,
      icon: <FileBarChart2Icon className={classes.icon} />,
    },
  ];

  const drawerMenu = () => (
    <List>
      {menuItems.map(menuItem => {
        if (menuItem.hideMenu) {
          return null;
        }

        return (
          <PermissionComponent
            key={menuItem.label}
            permissionName={menuItem.permissionName as string}
          >
            <ListItem
              className={`h-10 ${
                selectedMenu === menuItem.route ? classes.selected : undefined
              }`}
              button
              onClick={e => {
                e.stopPropagation();
                handleMenu(menuItem.route);
              }}
              key={menuItem.label}
            >
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              <ListItemText primary={menuItem.label} />
            </ListItem>
          </PermissionComponent>
        );
      })}
    </List>
  );

  return (
    <>
      <Drawer
        classes={{ paper: `${classes.drawer} ${classes.mobile}` }}
        variant="persistent"
        open={open}
        onClose={handleMenuIsOpen}
      >
        {drawerMenu()}
      </Drawer>
      <Box
        className={
          open
            ? `${classes.drawer} ${classes.desktop}`
            : `${classes.drawerIcons} ${classes.desktop} ${classes.notOpen}`
        }
        onMouseEnter={
          open
            ? () => {}
            : () => {
                handleMenuIsOpen();
                // setOpenViaHover(true);
              }
        }
        onMouseLeave={
          open
            ? () => {
                handleMenuIsOpen();
                // setOpenViaHover(false);
              }
            : () => {}
        }
      >
        <Drawer
          classes={
            open ? { paper: classes.drawer } : { paper: classes.drawerIcons }
          }
          variant="persistent"
          open
          onClose={handleMenuIsOpen}
          onClick={handleMenuIsOpen}
        >
          {drawerMenu()}
        </Drawer>
      </Box>
    </>
  );
};

import React from 'react';

import { TextField } from '@material-ui/core';
import { UserDataProps } from '../User.i';

export const UserPassword: React.FC<UserDataProps> = ({
  user_id,
  formik,
  isSameUser,
  isUserAdmin,
}) => {
  const canEditPassword = isUserAdmin || isSameUser;

  return (
    <div className="w-full flex flex-col items-start m-0 border-2 p-2 rounded-sm">
      <div className="w-fit text-1xl font-bold ml-1 px-1 -mt-5 bg-white">
        Segurança
      </div>
      <div className="w-full flex flex-col">
        {user_id && (
          <TextField
            id="old_password"
            label="Senha Atual"
            variant="outlined"
            name="old_password"
            type="password"
            autoComplete="current-password"
            margin="dense"
            className="max-w-[50%]"
            onChange={formik.handleChange}
            value={formik.values.old_password}
            error={
              formik.touched.old_password && Boolean(formik.errors.old_password)
            }
            helperText={
              formik.touched.old_password && formik.errors.old_password
            }
            disabled={!canEditPassword}
          />
        )}
        <TextField
          id="password"
          label={user_id ? 'Nova Senha' : 'Senha'}
          variant="outlined"
          name="password"
          margin="dense"
          type="password"
          autoComplete="new-password"
          className="max-w-[50%]"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          disabled={!canEditPassword}
        />
        <TextField
          id="confirm_password"
          variant="outlined"
          label={user_id ? 'Confirme Nova Senha' : 'Confirmação da Senha'}
          name="confirm_password"
          margin="dense"
          type="password"
          autoComplete="new-password"
          className="max-w-[50%]"
          onChange={formik.handleChange}
          value={formik.values.confirm_password}
          error={
            formik.touched.confirm_password &&
            Boolean(formik.errors.confirm_password)
          }
          helperText={
            formik.touched.confirm_password && formik.errors.confirm_password
          }
          disabled={!canEditPassword}
        />
      </div>
    </div>
  );
};

import { ICustomField } from 'src/interface/ICardFields';
import api from './api';

export interface IArea {
  id: string;
  name: string;
  description: string;
  phases: {
    id: string;
    name: string;
    order: number;
    isInactive: boolean;
    area_id: string;
    // o type abaixo é usado no taskModal, verificar se é necessário
    area: {
      id: string;
    };
    cardTasks: {
      id: string;
      name: string;
      order: number;
      deadLine: number;
      description: string;
      instructions: string;
      isInactive: boolean;
      isRequired: boolean;
      type: string;
      phase_id: string;
      weight: number;
    }[];
    customFields: ICustomField[];
  }[];
}

export interface IAreaAll {
  id: string;
  name: string;
  description: boolean;
}

export const areaApi = {
  getAreas: () => api.get<IArea[]>(`/areas`),
  getAreasAll: () => api.get<IAreaAll[]>(`/areas/all`),
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Card } from 'src/components/ui/card';
import { DataTableContext } from 'src/context/DataTableContext';
import { DataTable } from 'src/components/ui/data-table';
import Modal from 'src/components/Modal_cards';
import { DeleteAlertDialog } from 'src/components/DeleteAlertDialog';
import { useAuth } from 'src/context/AuthContext';
import { AccountHeader } from '../components/AccountHeader';
import Account from '..';
import { AccountColumns } from './AccountColumns';
import { useAccountList } from '../hooks/useAccountList';

const List: React.FC = () => {
  const { user } = useAuth();
  const useCanEditAccount = user?.permissions.ACCOUNT === 'READ_AND_WRITE';
  const {
    handleEdit,
    handleAddAccount,
    handleCloseModal,
    accounts,
    openModal,
    columnsToFilter,
    deleteAlertState,
    handleOpenDeleteAccountModal,
    handleDelete,
    handleDeleteAlertState,
  } = useAccountList();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingRight: 35,
        paddingBottom: 15,
      }}
    >
      <div className="max-w-6xl">
        <AccountHeader />
        <Card>
          <div className="w-full rounded-md p-1 overflow-hidden">
            <DataTableContext.Provider
              value={{
                handleOpenModal: handleEdit,
                handleDeleteItem: handleOpenDeleteAccountModal,
              }}
            >
              <DataTable
                columns={AccountColumns as any}
                data={accounts}
                showTableToolbar
                pageSize={15}
                onClickRow={handleEdit}
                showGlobalSearchInput
                showFilters
                columnsToFilter={columnsToFilter}
                handleClickAddNewButton={
                  useCanEditAccount ? handleAddAccount : undefined
                }
                fillEmptyRowsPerPage
              />
            </DataTableContext.Provider>
          </div>
        </Card>
        {openModal && (
          <Modal
            open
            closeModal={handleCloseModal}
            title="Adicionar Conta"
            titleLeft=""
          >
            <Account closeModal={handleCloseModal} />
          </Modal>
        )}
        {deleteAlertState.isOpen && deleteAlertState.accountId && (
          <DeleteAlertDialog
            open={deleteAlertState.isOpen}
            setOpen={handleDeleteAlertState}
            itemId={deleteAlertState.accountId}
            handleConfirmDelete={handleDelete}
          />
        )}
      </div>
    </div>
  );
};

export default List;

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import api from 'src/services/api';
import {
  dateTypes,
  formatCfToRichTextVariables,
  IRichTextVariable,
} from 'src/utils/customFieldUtils';
import { DocumentTableStyles, Table } from 'src/components/ModalTableStyles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  ICustomField,
  IFilter,
  IFormData,
  IIntegrationRule,
  IPhase,
} from '../CustomField.i';

interface HookProps {
  customField_id: string;
  phase_id: string;
  areaId?: string;
  closeModal: () => void;
}

interface HookReturn {
  // State variables
  typeCustomFieldSelected: string;
  integrationRules: any;
  loadingRules: boolean;
  phase: {
    name: string;
    areaId: string;
  } | null;
  areaPhases: IPhase[];
  loading: boolean;
  isTableModalOpen: boolean;
  isFillRulesOpen: boolean;
  cfTables: Table[];
  belongsToTable: boolean;
  selectedTable: any;
  newPredefinedOption: string;
  fetchFieldsCompleted: boolean;
  confirmationModalOpen: boolean;
  templateFieldOptions: IRichTextVariable[];
  template: string;
  header: string;
  footer: string;
  tableStyles: DocumentTableStyles;
  filters: IFilter[];
  selectedPhases: IPhase[];
  predefinedOptions: string[];
  nextLocation: string;
  initialValues: IFormData;
  customField: ICustomField;
  customFieldTables: ICustomField[];

  // Setters for state variables
  setIsTableModalOpen: (value: boolean) => void;
  setIsFillRuleOpens: (value: boolean) => void;
  setSelectedTable: (value: any) => void;
  setNewPredefinedOption: (value: string) => void;
  setTypeCustomFieldSelected: (value: string) => void;
  setUnsavedChanges: (value: boolean) => void;
  setConfirmationModalOpen: (value: boolean) => void;
  setTemplate: (value: string) => void;
  setHeader: (value: string) => void;
  setFooter: (value: string) => void;
  setTableStyles: (value: DocumentTableStyles) => void;
  setFilters: (value: IFilter[]) => void;
  setSelectedPhases: (value: IPhase[]) => void;
  setPredefinedOptions: (value: string[]) => void;

  // Functions
  formik: any;
  fetchPhaseAreaId: () => void;
  fetchCustomFieldFillRules: () => Promise<void>;
  fetchCustomField: () => void;
  handleToggleBelongsToTable: () => void;
  handleSubmitWrapper: () => Promise<void>;
}

export function useCustomField({
  customField_id,
  phase_id,
  areaId,
  closeModal,
}: HookProps): HookReturn {
  const history = useHistory();
  const area_id = areaId || history.location.state;

  const [filters, setFilters] = useState<IFilter[]>([]);
  const [customField, setCustomField] = useState<ICustomField>();
  const [typeCustomFieldSelected, setTypeCustomFieldSelected] = useState('');
  const [customFieldTables, setCustomFieldTables] = useState<ICustomField[]>(
    [],
  );
  const [integrationRules, setIntegrationRules] = useState<IIntegrationRule[]>(
    [],
  );
  const [loadingRules, setLoadingRules] = useState<boolean>(true);
  const [phase, setPhase] = useState<{
    name: string;
    areaId: string;
  } | null>(null);
  const [areaPhases, setAreaPhases] = useState<IPhase[]>([]);
  const [selectedPhases, setSelectedPhases] = useState<IPhase[]>([]);
  const [loading, setLoading] = useState(true);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);
  const [isFillRulesOpen, setIsFillRuleOpens] = useState(false);
  const [cfTables, setCfTables] = useState<Table[]>([]);
  const [belongsToTable, setBelongsToTable] = useState(false);
  const [selectedTable, setSelectedTable] = useState();
  const [predefinedOptions, setPredefinedOptions] = useState<string[]>([]);
  const [newPredefinedOption, setNewPredefinedOption] = useState('');
  const [fetchFieldsCompleted, setFetchFieldsCompleted] = useState(false);
  const [templateFieldOptions, setTemplateFieldOptions] = useState<
    IRichTextVariable[]
  >([] as IRichTextVariable[]);
  const [template, setTemplate] = useState<string>('');
  const [tableStyles, setTableStyles] = useState<DocumentTableStyles>({});
  const [header, setHeader] = useState<string>('');
  const [footer, setFooter] = useState<string>('');

  useEffect(() => {
    if (customField_id) {
      fetchCustomField();
      setUnsavedChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customField_id, integrationRules]);

  useEffect(() => {
    if (area_id) {
      const area = area_id;
      api.get(`/areas/${area}/customFields`).then(response => {
        const filteredFields: ICustomField[] = response.data.filter(
          (field: { slug: string; name: string; type: string }) =>
            field?.type !== 'DOCUMENT' && field?.type !== 'FILE',
        );
        const formattedSugestions = formatCfToRichTextVariables(filteredFields);
        setTemplateFieldOptions(formattedSugestions);
        setFetchFieldsCompleted(true);
        const filteredTables = response.data.filter(
          (field: { type: string }) => field.type === 'TABLE',
        );
        setCfTables(
          filteredTables.map((tableField: ICustomField) => {
            return {
              id: tableField.id,
              name: tableField.name,
              columns: response.data
                .filter(
                  (field: ICustomField) =>
                    field.table !== null && field.table.id === tableField.id,
                )
                .map((columnField: ICustomField) => columnField.name),
            };
          }),
        );
      });

      api
        .get(`areas/${area}/phases`)
        .then(response => {
          if (response.data) {
            const tempPhases = response.data
              .filter((resp: IPhase) => !resp.isInactive)
              .sort((a: IPhase, b: IPhase) => a.order < b.order);
            setAreaPhases(tempPhases);
            setUnsavedChanges(false);
          }
        })
        .catch(err => {
          toast.error(`Falha ao carregar fases. ${err.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area_id]);

  const handleToggleBelongsToTable = () => {
    if (belongsToTable) {
      formik.setFieldValue('customFieldTable_id', '');
    }

    setBelongsToTable(!belongsToTable);
  };

  const initialValues: IFormData = {
    phase_id: customField ? customField.phase_id : '',
    name: customField ? customField.name : '',
    slug: customField ? customField.slug : '',
    typeField: customField ? customField.type : null,
    isRequired: customField ? customField.isRequired : false,
    totalize: customField ? customField.totalize : false,
    isInactive: customField ? customField.isInactive : false,
    weight: customField ? customField.weight : 1,
    tableOrder:
      customField && customField.tableOrder ? customField.tableOrder : 1,
    integrationRule: customField ? customField.integrationRule : null,
    customFieldTable: customField ? customField.table : null,
    isBigString: customField ? customField.isBigString : false,
    predefinedValues: undefined,
    integratedFilterAssociations: customField
      ? customField.integratedFilterAssociations
      : [],
    documentTemplate:
      customField && customField?.document && template ? template : '',
    documentFooter:
      customField && customField?.document && footer ? footer : '',
    documentHeader:
      customField && customField?.document && header ? header : '',
    documentTableStyles:
      customField && customField?.document ? tableStyles : {},
    isEditable:
      customField && customField?.document
        ? customField.document.isEditable
        : false,
    isEncrypted:
      customField && customField?.document
        ? customField.document.isEncrypted
        : customField && customField?.file
        ? customField?.file.isEncrypted
        : false,
    isRequiredToSign:
      customField && customField?.document
        ? customField.document.isRequiredToSign
        : customField && customField?.file
        ? customField?.file.isRequiredToSign
        : false,
    fileAllowsMultiple:
      customField && customField?.file
        ? customField.file.allowsMultiple
        : false,
    phasesToTotalize:
      customField && customField.totalize ? customField.phasesToTotalize : [],
    dateType:
      customField && customField.dateType
        ? customField.dateType
        : dateTypes[0].id,
  };

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required('Nome é obrigatório')
      .min(2, 'O nome deve ter pelo menos 2 caracteres'),
    slug: Yup.string()
      .required('Apelido é obrigatório')
      .min(
        6,
        'O apelido deve ter pelo menos 6 caracteres e não deve contar caracteres especiais',
      ),
    weight: Yup.number().required('Peso é obrigatório'),
    typeField: Yup.object().required('Tipo do campo é obrigatório').nullable(),
    dateType:
      typeCustomFieldSelected === 'TIMESTAMP'
        ? Yup.string().required('É necessário informar ao menos um valor')
        : Yup.string().optional(),
    predefinedValues:
      typeCustomFieldSelected === 'PREDEFINED_STRINGS' &&
      !customField?.predefinedValues
        ? Yup.array().required('É necessário informar ao menos um valor').min(1)
        : Yup.array().optional(),
  });

  const onSubmit = async (
    values: IFormData,
    { setSubmitting }: any,
    showToast: undefined | boolean = true,
  ) => {
    setSubmitting(true);
    try {
      const reqBody: any = {
        name: values.name,
        slug: values.slug,
        isRequired: values.isRequired,
        isInactive: values.isInactive,
        weight: values.weight,
        predefinedValues: values.predefinedValues,
      };

      if (typeCustomFieldSelected !== 'TABLE') {
        if (belongsToTable) {
          reqBody.customFieldTable_id = values.customFieldTable.id;
        } else reqBody.customFieldTable_id = '';
        if (values.tableOrder) reqBody.tableOrder = values.tableOrder;
      }
      if (typeCustomFieldSelected === 'TIMESTAMP')
        reqBody.dateType = values.dateType;

      if (typeCustomFieldSelected === 'NUMERIC')
        reqBody.totalize = values.totalize;

      if (typeCustomFieldSelected === 'NUMERIC' && values.totalize)
        reqBody.phasesToTotalize = values.phasesToTotalize;

      if (typeCustomFieldSelected === 'STRING')
        reqBody.isBigString = values.isBigString;

      if (typeCustomFieldSelected === 'PREDEFINED_STRINGS')
        reqBody.predefinedValues = values.predefinedValues;
      else reqBody.predefinedValues = undefined;

      if (
        typeCustomFieldSelected === 'INTEGRATED' &&
        values.integrationRule.id !== '' &&
        filters.length > 0
      ) {
        reqBody.integratedFilterAssociations = filters
          .filter(filter => filter.isActive)
          .map(filter => {
            return {
              filterCode: filter.filterCode,
              associatedCustomField_id: filter.associatedCustomField_id
                ? filter.associatedCustomField_id
                : undefined,
              fixedValue: filter.fixedValue ? filter.fixedValue : undefined,
            };
          });
      }

      if (typeCustomFieldSelected === 'DOCUMENT') {
        reqBody.documentTemplate = template;
        reqBody.documentIsEncrypted = values.isEncrypted;
        reqBody.documentIsEditable = values.isEditable;
        reqBody.documentIsRequiredToSign = values.isRequiredToSign;
        reqBody.documentTableStyles = tableStyles;
        reqBody.documentHeader = header;
        reqBody.documentFooter = footer;
      }

      if (typeCustomFieldSelected === 'FILE') {
        reqBody.fileIsEncrypted = values.isEncrypted;
        reqBody.fileAllowsMultiple = values.fileAllowsMultiple;
        reqBody.fileIsRequiredToSign = values.isRequiredToSign;
      }

      if (customField_id) {
        await api.put(`/customFields/${customField_id}`, reqBody).then(() => {
          if (typeCustomFieldSelected === 'PREDEFINED_STRINGS') {
            setCustomField((oldValue: any) => {
              return {
                ...oldValue,
                predefinedValues:
                  values.predefinedValues ?? oldValue?.predefinedValues ?? [],
              };
            });
          }
        });

        if (showToast) {
          toast.success('Campo personalizado editado com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        }
      } else {
        reqBody.type_field = values.typeField.id;
        if (values.integrationRule) {
          reqBody.integrationRule_id = values.integrationRule.id;
        }
        await api.post(`phases/${phase_id}/customFields`, reqBody);
        toast.success('Campo personalizado salvo com sucesso.', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
        setTimeout(() => {
          closeModal();
        }, 2000);
      }
      setSubmitting(false);
    } catch (error: any) {
      setSubmitting(false);

      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        autoClose: 5000,
      });
    }
  };

  const handleSubmitWrapper = async (showToast: undefined | boolean = true) => {
    await onSubmit(
      formik.values,
      { setSubmitting: formik.setSubmitting },
      showToast,
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit,
    // enableReinitialize: !!customField_id,
  });

  // CONTROLE DE MUDANÇAS NÃO SALVAS
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState('');

  useEffect(() => {
    if (customField !== undefined && formik.dirty) {
      setUnsavedChanges(true);
    }
  }, [formik.values]);

  useEffect(() => {
    const unblock = history.block(location => {
      if (unsavedChanges) {
        setNextLocation(location.pathname);
        setConfirmationModalOpen(true);
        return false;
      }
      return undefined;
    });

    return () => {
      if (unblock) unblock();
    };
  }, [unsavedChanges]);

  useEffect(() => {
    if (!unsavedChanges && nextLocation) {
      history.push(nextLocation);
    }
  }, [unsavedChanges, nextLocation]);
  // FIM CONTROLE DE MUDANÇAS NÃO SALVAS

  const fetchPhaseAreaId = useCallback(async () => {
    if (phase_id !== undefined) {
      const response = await api.get(`phases/${phase_id}`);
      setPhase({
        name: response.data.name,
        areaId: response.data.area.id,
      });
    } else {
      setPhase(null);
    }
  }, [phase_id]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    fetchPhaseAreaId().catch(console.error);

    api.get('/integrationsrules/integrationtypes').then(response => {
      const newIntegrationRules: IIntegrationRule[] = response.data.filter(
        // eslint-disable-next-line no-return-assign
        (item: IIntegrationRule) =>
          item.integrationType.allowIntegratedCustomFields &&
          item.isActive &&
          (item.integrationType.areaRequired
            ? item.area_id === phase?.areaId
            : true),
      );

      setIntegrationRules(newIntegrationRules);
      setLoadingRules(false);
    });
  }, [fetchPhaseAreaId, phase?.areaId]);

  const fetchCustomFieldFillRules = useCallback(async () => {
    if (customField_id) {
      api
        .get(`customFields/${customField_id}`)
        .then(response => {
          const { fillRules } = response.data;
          setCustomField(oldValue => ({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...oldValue!,
            fillRules: fillRules || [],
          }));
        })
        .catch(err => {
          toast.error(
            `Falha atualizar regras de preenchimento. ${err.response.data.message}`,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 4000,
            },
          );
        });
    }
  }, [customField_id]);

  const fetchCustomField = useCallback(async () => {
    if (customField_id) {
      await api.get(`customFields/${customField_id}`).then(response => {
        setCustomField(response.data);
        if (
          response.data.predefinedValues !== undefined &&
          response.data?.predefinedValues?.length > 0
        ) {
          setPredefinedOptions(response.data.predefinedValues);
        }
        if (response.data.table) {
          setBelongsToTable(true);
        }
        if (response.data?.document) {
          setTemplate(response.data?.document.template);
          if (response.data?.document.tableStyles) {
            setTableStyles(response.data?.document.tableStyles);
          }
          if (response.data?.document.header) {
            setHeader(response.data?.document.header);
          }
          if (response.data?.document.footer) {
            setFooter(response.data?.document.footer);
          }
        }
        if (response.data?.phasesToTotalize) {
          const fieldTotalizePhases = response.data?.phasesToTotalize;
          const formattedPhases = areaPhases.filter(ap =>
            fieldTotalizePhases.includes(ap.id),
          );
          setSelectedPhases(formattedPhases);
        }

        if (
          response.data.integrationRule &&
          integrationRules &&
          response.data.integratedFilterAssociations
        ) {
          try {
            const selectedIntegrationType = integrationRules.find(
              rule => rule.id === response.data.integrationRule.id,
            );
            const selectedIntegrationRuleFilters =
              selectedIntegrationType &&
              selectedIntegrationType?.integrationType
                ?.hasDynamicFieldAssociations
                ? selectedIntegrationType.dynamicFieldAssociations || []
                : selectedIntegrationType?.integrationType?.filterFields || [];

            if (selectedIntegrationRuleFilters) {
              const allFilters = Object.keys(selectedIntegrationRuleFilters);
              const presetFilters = response.data.integratedFilterAssociations;
              const completeFilters = allFilters.map(filter => {
                const preset = presetFilters.find(
                  (presetFilter: { filterCode: string }) =>
                    presetFilter.filterCode === filter,
                );
                if (preset && preset.fixedValue) {
                  return {
                    isActive: true,
                    filterCode: filter,
                    fixedValue: preset.fixedValue,
                  };
                }
                if (preset && preset.associatedCustomField) {
                  return {
                    isActive: true,
                    filterCode: filter,
                    associatedCustomField_id: preset.associatedCustomField.id,
                  };
                }
                return {
                  isActive: false,
                  filterCode: filter,
                };
              });
              setFilters(completeFilters);
            }
          } catch (e) {
            console.error(e);
          }
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationRules, customField_id, areaPhases]);

  useEffect(() => {
    fetchCustomField();
  }, [fetchCustomField]);

  useEffect(() => {
    if (customField && customField?.type) {
      setTypeCustomFieldSelected(customField.type.id);
    }
  }, [customField]);

  useEffect(() => {
    if (phase_id) {
      const type = 'TABLE';
      const phaseId = phase_id;
      api.get(`customFields/type/${type}/phases/${phaseId}`).then(response => {
        const newIsInactive = response.data.filter(
          (item: ICustomField) => item.isInactive === false,
        );
        setCustomFieldTables(newIsInactive);
      });
    }
  }, [phase_id]);

  return {
    formik,
    typeCustomFieldSelected,
    integrationRules,
    loadingRules,
    phase,
    areaPhases,
    selectedPhases,
    loading,
    isTableModalOpen,
    isFillRulesOpen,
    cfTables,
    belongsToTable,
    selectedTable,
    predefinedOptions,
    newPredefinedOption,
    fetchFieldsCompleted,
    templateFieldOptions,
    template,
    tableStyles,
    header,
    footer,
    fetchPhaseAreaId,
    fetchCustomFieldFillRules,
    fetchCustomField,
    handleToggleBelongsToTable,
    handleSubmitWrapper,
    confirmationModalOpen,
    setIsTableModalOpen,
    setIsFillRuleOpens,
    setSelectedTable,
    setNewPredefinedOption,
    setTypeCustomFieldSelected,
    setUnsavedChanges,
    setConfirmationModalOpen,
    setTemplate,
    setHeader,
    setFooter,
    setTableStyles,
    setFilters,
    filters,
    setSelectedPhases,
    nextLocation,
    setPredefinedOptions,
    initialValues,
    customField,
    customFieldTables,
  } as HookReturn;
}

import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '../Button';
import { COLORS } from '../../styles/colors';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
  },
  margins: { marginTop: '20px', marginBottom: '20px' },
  title: { fontWeight: 'bold' },
  button: {
    color: COLORS.BLUE,
    margin: 0,
    marginLeft: 30,
  },
});

interface ITitleAndButtonProps {
  title: string;
  fontSize?: number;
  iconSize?: number;
  margin?: boolean;
  disabled?: boolean;
  handleAdd: () => void;
}

const TitleAndButton: React.FC<ITitleAndButtonProps> = ({
  title,
  fontSize = 25,
  iconSize = 20,
  handleAdd,
  margin = true,
  disabled = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={
        margin ? `${classes.container} ${classes.margins}` : classes.container
      }
    >
      <Typography className={classes.title} style={{ fontSize }}>
        {title}
      </Typography>
      <IconButton
        aria-label="add"
        onClick={handleAdd}
        className={classes.button}
        disabled={disabled}
      >
        <AddCircleIcon style={{ fontSize: `${iconSize}px` }} />
      </IconButton>
    </Box>
  );
};

export default TitleAndButton;

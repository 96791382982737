/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import {
  Button as ButtonMaterialUI,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
  Paper,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import {
  triggerDispatchTypes,
  triggerPhaseTypes,
} from 'src/utils/conditionTypes';
import { fixedAccountFields } from 'src/utils/fixedAccountFields';
import { TCondition, IConditionsProps, IGroupStatus } from './Condition.i';
import { styles } from './styles';
import { ExpandIcon } from '../ExpandIcon';
import { useCondition } from './hooks/useCondition';
import { OptionType } from './ConditionInputs/OptionType';
import { OptionList } from './ConditionInputs/OptionList';
import { OptionValue } from './ConditionInputs/OptionValue';
import { OuterLogicOperator } from './ConditionInputs/OuterLogicOperator';
import { OptionOperator } from './ConditionInputs/OptionOperator';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const Conditions: React.FC<IConditionsProps> = ({
  conditionsExpressionInput,
  setConditionsExpressionInput,
  customFields,
  phases,
  tasks,
  accountCustomFields,
  isTriggerCondition = false,
  triggerType,
}: IConditionsProps) => {
  const classes = useStyles();

  const {
    handleFillRuleConditionChange,
    handleTriggerConditionChange,
    handleAddCondition,
    handleAddConditionGroup,
    handleDeleteCondition,
    handleDeleteConditionGroup,
    toggleGroupBox,
    groupStatus,
  } = useCondition({
    setConditionsExpressionInput,
    conditionsExpressionInput,
  });

  const handleConditionChange = isTriggerCondition
    ? handleTriggerConditionChange
    : handleFillRuleConditionChange;

  const accountCustomFieldsWithPhase =
    accountCustomFields && accountCustomFields.length > 0
      ? accountCustomFields.map(field => ({
          ...field,
          phase: { name: 'Personalizado' },
        }))
      : [];

  const accountFixedAndCustomFields = [
    ...accountCustomFieldsWithPhase.sort((a, b) =>
      a.name.localeCompare(b.name),
    ),
    ...fixedAccountFields.sort((a, b) => a.name.localeCompare(b.name)),
  ];

  let groupCounter = 0;

  const renderCondition = (condition: TCondition[], groupIndex: number) => {
    return condition.map((item: TCondition, itemIndex: number) => {
      if (typeof item === 'object') {
        const filterSelectedField =
          item?.type === 'customField' && customFields && item?.customField_id
            ? customFields[
                customFields.findIndex(
                  (field: { id: any }) => field.id === item.customField_id,
                )
              ]
            : (item?.type === 'accountField' ||
                item?.type === 'accountFieldFixed') &&
              accountFixedAndCustomFields &&
              (item?.accountField_id || item?.accountFieldName)
            ? accountFixedAndCustomFields[
                accountFixedAndCustomFields.findIndex(
                  (field: { id: any }) =>
                    field.id ===
                    (item?.type === 'accountField'
                      ? item.accountField_id
                      : item.accountFieldName),
                )
              ]
            : undefined;

        return (
          <Box
            key={`Condition${groupCounter + groupIndex + itemIndex}`}
            className={classes.conditionItemRow}
          >
            <OptionType
              handleChange={handleConditionChange}
              groupIndex={groupIndex}
              itemIndex={itemIndex}
              itemId={
                item.type === 'accountFieldFixed'
                  ? 'accountField'
                  : item.type || ''
              }
              isInstantTrigger={isTriggerCondition && triggerType === 'instant'}
              isScheduledTrigger={
                isTriggerCondition && triggerType === 'scheduled'
              }
            />
            {(item?.type === 'accountField' ||
              item?.type === 'accountFieldFixed') && (
              <OptionList
                fieldType={
                  item?.type === 'accountField'
                    ? 'accountField'
                    : 'accountFieldFixed'
                }
                handleChange={handleConditionChange}
                optionsArray={
                  isTriggerCondition
                    ? accountFixedAndCustomFields.filter(
                        field =>
                          field.type !== 'TABLE' && field.type !== 'DOCUMENT',
                      )
                    : accountFixedAndCustomFields || []
                }
                groupIndex={groupIndex}
                itemIndex={itemIndex}
                itemId={
                  item.type === 'accountField'
                    ? item?.accountField_id || ''
                    : item?.accountFieldName || ''
                }
              />
            )}
            {item?.type === 'customField' && (
              <OptionList
                handleChange={handleConditionChange}
                optionsArray={
                  isTriggerCondition && customFields
                    ? customFields.filter(
                        field =>
                          field.type !== 'TABLE' &&
                          field.type !== 'DOCUMENT' &&
                          field.tableOrder === null,
                      )
                    : !isTriggerCondition && customFields
                    ? customFields.filter(field => field.type !== 'TABLE')
                    : []
                }
                groupIndex={groupIndex}
                itemIndex={itemIndex}
                itemId={item.customField_id || ''}
                fieldType="customField"
                isCardField
              />
            )}
            {(item?.type === 'task' || item?.type === 'overdueTask') && (
              <OptionList
                handleChange={handleConditionChange}
                optionsArray={tasks}
                groupIndex={groupIndex}
                itemIndex={itemIndex}
                itemId={item.task_id || ''}
                fieldType={item?.type === 'task' ? 'task' : 'overdueTask'}
              />
            )}
            {item?.type === 'phase' && triggerType === 'instant' && (
              <OptionList
                handleChange={handleConditionChange}
                optionsArray={phases}
                groupIndex={groupIndex}
                itemIndex={itemIndex}
                itemId={phases[0]?.id}
                fieldType="phase"
              />
            )}
            {item?.type &&
              item?.type !== 'overdueTask' &&
              item?.type !== 'staleCard' && (
                <OptionOperator
                  isTriggerCondition={isTriggerCondition}
                  customFields={
                    item.type === 'customField'
                      ? customFields || []
                      : item.type === 'accountField' ||
                        item.type === 'accountFieldFixed'
                      ? accountFixedAndCustomFields || []
                      : []
                  }
                  item={item}
                  handleConditionChange={handleConditionChange}
                  groupIndex={groupIndex}
                  itemIndex={itemIndex}
                />
              )}
            {item?.operator &&
              item?.type &&
              ((item.type === 'phase' && !isTriggerCondition) ||
                item.type === 'task' ||
                item.type === 'customField' ||
                item.type === 'accountField' ||
                item.type === 'accountFieldFixed') && (
                <OptionValue
                  handleChange={handleConditionChange}
                  customField={filterSelectedField}
                  phases={item?.type === 'phase' ? phases : undefined}
                  groupIndex={groupIndex}
                  itemIndex={itemIndex}
                  fieldValue={
                    item.type === 'phase'
                      ? item?.phase_ids
                      : item.type === 'task'
                      ? item?.taskDone
                      : isTriggerCondition &&
                        (item.type === 'customField' ||
                          item.type === 'accountField' ||
                          item.type === 'accountFieldFixed')
                      ? item?.customFieldValue
                      : item?.value
                  }
                  itemType={item?.type || ''}
                />
              )}

            {/* The options below are used only for trigger conditions */}
            {isTriggerCondition &&
              (item?.type === 'staleCard' || item?.type === 'progress') && (
                <OptionValue
                  handleChange={handleConditionChange}
                  customField={undefined}
                  phases={[]}
                  groupIndex={groupIndex}
                  itemIndex={itemIndex}
                  fieldValue={
                    item.type === 'progress'
                      ? item?.progressPercentage || 0
                      : item?.staleCardHours || 1
                  }
                  itemType={item?.type || ''}
                  isTriggerCondition={isTriggerCondition}
                />
              )}

            {isTriggerCondition &&
              (item?.type === 'phase' || item?.type === 'dispatch') && (
                <OptionValue
                  handleChange={handleConditionChange}
                  customField={undefined}
                  phases={[]}
                  groupIndex={groupIndex}
                  itemIndex={itemIndex}
                  fieldValue={
                    item?.type === 'phase'
                      ? triggerPhaseTypes[
                          triggerPhaseTypes.findIndex(
                            field => field.id === String(item.phaseEntering),
                          )
                        ] || null
                      : triggerDispatchTypes[
                          triggerDispatchTypes.findIndex(
                            field => field.id === item.dispatchType,
                          )
                        ] || null
                  }
                  itemType={item?.type || ''}
                  isTriggerCondition={isTriggerCondition}
                />
              )}

            <Tooltip title="Excluir condição">
              <IconButton
                aria-label="Deletar linha"
                size="small"
                onClick={() => handleDeleteCondition(groupIndex, itemIndex)}
              >
                <ClearRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }

      // retorno quando de condição E/OU
      return (
        <OuterLogicOperator
          key={`ConditionLogicOperator${groupCounter}${groupIndex}${itemIndex}`}
          item={item as any}
          groupIndex={groupIndex}
          itemIndex={itemIndex}
          handleConditionChange={handleConditionChange}
        />
      );
    });
  };

  return (
    <>
      <Box width="100%" className={classes.tableTitle}>
        Condições
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.conditionsBox}
        component={Paper}
      >
        {conditionsExpressionInput.length > 0 &&
          conditionsExpressionInput.map((condition: any, index: number) => {
            if (Array.isArray(condition)) {
              // eslint-disable-next-line no-plusplus
              groupCounter++;

              return (
                <Box
                  className={classes.conditionGroup}
                  key={groupCounter + index}
                >
                  <Box className={classes.conditionGroupHeader}>
                    <Box
                      width="100%"
                      height="100%"
                      display="flex"
                      flexDirection="row"
                      flexWrap="nowrap"
                      alignItems="center"
                    >
                      <Typography className={classes.conditionGroupTitle}>
                        Grupo {groupCounter}
                      </Typography>

                      <Tooltip title="Excluir grupo">
                        <IconButton
                          aria-label="Deletar grupo"
                          size="small"
                          onClick={() => handleDeleteConditionGroup(index)}
                        >
                          <ClearRoundedIcon />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        aria-label="Maximizar/minimizar grupo"
                        size="small"
                        disabled={!groupStatus || groupStatus.length <= 0}
                        // onClick={() => toggleGroupBox(index)}
                      >
                        <ExpandIcon
                          expanded={
                            groupStatus[
                              groupStatus.findIndex(
                                (group: IGroupStatus) => group.index === index,
                              )
                            ]?.isOpen
                          }
                          toggleBox={() => toggleGroupBox(index)}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  {/* CONDITION GROUP ROWS AND OPTIONS */}

                  {groupStatus[
                    groupStatus.findIndex(
                      (group: IGroupStatus) => group.index === index,
                    )
                  ]?.isOpen && (
                    <Box className={classes.conditionItems}>
                      {renderCondition(condition, index)}
                      <ButtonMaterialUI
                        onClick={() => handleAddCondition(index)}
                        size="small"
                        color="primary"
                        className={classes.addButton}
                      >
                        + Adicionar Condição
                      </ButtonMaterialUI>
                    </Box>
                  )}
                </Box>
              );
            }

            return (
              <OuterLogicOperator
                key={`groupConditionOperator${index}${groupCounter}`}
                item={condition as any}
                groupIndex={index}
                itemIndex={index}
                handleConditionChange={handleConditionChange}
                isGroupCondition
              />
            );
          })}
        <ButtonMaterialUI
          onClick={handleAddConditionGroup}
          size="small"
          color="primary"
          className={classes.addButton}
        >
          + Adicionar Grupo
        </ButtonMaterialUI>
      </Box>
    </>
  );
};

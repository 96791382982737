import React, { useState, useEffect, useCallback } from 'react';

import { toast } from 'react-toastify';
import api from 'src/services/api';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { IArea } from 'src/services/areaApi';
import { useAreas } from 'src/hooks/useAreas';
import { deepClone } from 'src/utils/deepCloneObject';
import {
  DataSourceListType,
  DataSourceParametersType,
} from 'src/services/dataSourceApi';
import { useDataSourceList } from 'src/hooks/useDataSourceList';
import { isNotNullish } from 'src/utils/comparisonUtils';

export interface DataSourceFormValues {
  id?: string;
  name: string;
  parameters: DataSourceParametersType;
}

const validationSchema = Yup.object({
  id: Yup.string().nullable(),
  name: Yup.string().required('Nome é obrigatório'),
  parameters: Yup.object()
    .shape({
      cards: Yup.array().of(Yup.string()).required(),
      tasks: Yup.array().of(Yup.string()).required(),
      accounts: Yup.array().of(Yup.string()).required(),
      areas: Yup.object()
        .shape({
          task_ids: Yup.array().of(Yup.string()).optional(),
          cardField_ids: Yup.array().of(Yup.string()).optional(),
        })
        .required(),
    })
    .optional(),
});

// type AreaOptions = Omit<IArea, 'description'>;
type AreaOptions = {
  id: string;
  name: string;
};

interface HookReturn {
  formik: FormikProps<DataSourceFormValues>;
  handleKeyDown: (event: React.KeyboardEvent) => void;
  handleEditDataSource: (dataSource: DataSourceFormValues | null) => void;
  handleDeleteDataSource: (profile_id: string) => void;
  handleRemoveArea: (area_id: string) => void;
  handleIncludeArea: () => void;
  handleUncheckAllOptions: (
    dataSource: 'accounts' | 'cards' | 'tasks' | 'areas',
  ) => void;
  handleCheckAllOptions: (
    dataSource: 'accounts' | 'cards' | 'tasks' | 'areas',
    listOfIds: string[],
  ) => void;
  handleSelectedSourceOption: (params: {
    dataSource: 'accounts' | 'cards' | 'tasks' | 'areas';
    sourceOptionId: string;
    areaId?: string;
    isCardField: boolean;
    isCardTask: boolean;
  }) => void;
  areaOptions: AreaOptions[];
  selectedAreas: AreaOptions[];
  setSelectedAreas: React.Dispatch<React.SetStateAction<AreaOptions[]>>;
  paramsAreas: string[];
  areas: IArea[];
  loadingAreas: boolean;
  dataSourceList: DataSourceListType[];
  loadingDataSources: boolean;
}

export function useDataSource(): HookReturn {
  const { areas, loadingAreas } = useAreas();
  const { dataSourceList, loadingDataSources, setRefreshDataSources } =
    useDataSourceList();
  const [selectedDataSource, setSelectedDataSource] =
    useState<DataSourceFormValues | null>(null);
  const [areaOptions, setAreaOptions] = useState<AreaOptions[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<AreaOptions[]>([]);
  const [paramsAreas, setParamsAreas] = useState<string[]>([]);

  useEffect(() => {
    if (areas && areas.length > 0) {
      const options: AreaOptions[] = areas.map(area => ({
        id: area.id,
        name: area.name,
      }));
      setAreaOptions(options);
    }
  }, [areas]);

  const handleEditDataSource = (dataSource: DataSourceFormValues | null) => {
    setSelectedDataSource(dataSource);
    setSelectedAreas([]);
    if (dataSource?.parameters.areas) {
      setParamsAreas(Object.keys(dataSource.parameters.areas));
    }
    if (dataSource === null) setParamsAreas([]);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const formik = useFormik<DataSourceFormValues>({
    initialValues: {
      id: selectedDataSource?.id || undefined,
      name: selectedDataSource?.name || '',
      parameters:
        selectedDataSource?.parameters &&
        isNotNullish(selectedDataSource?.parameters)
          ? selectedDataSource?.parameters
          : {
              cards: [],
              tasks: [],
              accounts: [],
              areas: {},
            },
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const dataSourceId = selectedDataSource?.id || values.id;
      const requestType = dataSourceId ? 'put' : 'post';
      const requestUrl = dataSourceId
        ? `/visions/dataSources/${dataSourceId}`
        : '/visions/dataSources';
      const formattedValues = {
        ...values,
        id: dataSourceId && undefined,
      };

      setSubmitting(true);
      api[requestType](requestUrl, formattedValues)
        .then(response => {
          const newId = response.data?.profile_id;
          if (requestType === 'post' && newId) {
            toast.success('Mineração de Dados criada com sucesso', {
              theme: 'colored',
              autoClose: 2000,
              closeOnClick: true,
            });
          } else {
            toast.success('Mineração de Dados editada com sucesso', {
              theme: 'colored',
            });
          }
          setSelectedDataSource(null);
          resetForm();
        })
        .catch(e => {
          const error = e.response?.data?.message || e?.message;
          const errMessage = `Erro ao salvar Mineração de Dados. ${error}`;
          toast.error(errMessage, {
            autoClose: 5000,
            theme: 'colored',
            closeOnClick: true,
          });
        })
        .finally(() => {
          setSubmitting(false);
          setRefreshDataSources(prev => !prev);
        });
    },
  });

  const handleDeleteDataSource = useCallback((dataSource_id: string) => {
    api
      .delete(`/visions/dataSources/${dataSource_id}`)
      .then(() => {
        toast.success('Mineração de Dados excluída com sucesso.', {
          theme: 'colored',
          closeOnClick: true,
        });
      })
      .catch(e => {
        const error = e.response?.data?.message || e?.message;
        const errMessage = `Erro ao excluir Mineração de Dados. ${error}`;
        toast.error(errMessage, {
          autoClose: 5000,
          theme: 'colored',
          closeOnClick: true,
        });
      })
      .finally(() => {
        setRefreshDataSources(prev => !prev);
      });
  }, []);

  const handleSelectedSourceOption = useCallback(
    ({
      dataSource,
      sourceOptionId,
      areaId,
      isCardField,
      isCardTask,
    }: {
      dataSource: 'accounts' | 'cards' | 'tasks' | 'areas';
      sourceOptionId: string;
      areaId?: string;
      isCardField?: boolean;
      isCardTask?: boolean;
    }) => {
      const currentValues = deepClone(formik.values.parameters);

      if (dataSource === 'areas' && areaId && sourceOptionId) {
        // Ensure area exists or initialize it
        const area = currentValues.areas[areaId] || {
          task_ids: [],
          cardField_ids: [],
        };
        const targetArray = isCardField
          ? area.cardField_ids
          : isCardTask
          ? area.task_ids
          : null;

        if (targetArray) {
          const idIndex = targetArray.indexOf(sourceOptionId);
          idIndex === -1
            ? targetArray.push(sourceOptionId)
            : targetArray.splice(idIndex, 1);
        }

        // Update the specific area in areas object
        currentValues.areas[areaId] = area;
      } else if (dataSource !== 'areas' && sourceOptionId) {
        const idArray = currentValues[dataSource];
        const idIndex = idArray.indexOf(sourceOptionId);
        idIndex === -1
          ? idArray.push(sourceOptionId)
          : idArray.splice(idIndex, 1);
      }

      formik.setFieldValue('parameters', currentValues);
    },
    [formik.values.id, formik.values.parameters],
  );

  const handleCheckAllOptions = useCallback(
    (
      dataSource: 'accounts' | 'cards' | 'tasks' | 'areas',
      listOfIds: string[],
    ) => {
      let currentParamsValues = deepClone(formik.values.parameters);
      if (dataSource !== 'areas') {
        currentParamsValues = {
          ...currentParamsValues,
          [dataSource]: listOfIds,
        };
      }
      formik.setFieldValue('parameters', currentParamsValues);
    },
    [formik.values.id, formik.values.parameters],
  );

  const handleUncheckAllOptions = useCallback(
    (dataSource: 'accounts' | 'cards' | 'tasks' | 'areas') => {
      let currentParamsValues = deepClone(formik.values.parameters);
      if (dataSource !== 'areas') {
        currentParamsValues = {
          ...currentParamsValues,
          [dataSource]: [],
        };
      }
      formik.setFieldValue('parameters', currentParamsValues);
    },
    [formik.values.id, formik.values.parameters],
  );

  const handleIncludeArea = useCallback(() => {
    setParamsAreas((oldValue: string[]) => {
      const oldAreas: string[] = [...oldValue];
      if (selectedAreas.length > 0 && !oldAreas.includes(selectedAreas[0].id)) {
        return [...oldAreas, selectedAreas[0].id];
      }
      return oldAreas;
    });
    setSelectedAreas([]);
  }, [selectedAreas]);

  const handleRemoveArea = (area_id: string) => {
    setParamsAreas((oldValue: string[]) => {
      return oldValue.filter(area => area !== area_id);
    });
    const currentParamsValues = deepClone(formik.values.parameters);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [area_id]: removed, ...newAreas } = currentParamsValues.areas;
    currentParamsValues.areas = newAreas;
    formik.setFieldValue('parameters', currentParamsValues);
  };

  return {
    formik,
    handleEditDataSource,
    handleDeleteDataSource,
    handleIncludeArea,
    areaOptions,
    selectedAreas,
    setSelectedAreas,
    paramsAreas,
    areas,
    loadingAreas,
    handleSelectedSourceOption,
    handleKeyDown,
    dataSourceList,
    loadingDataSources,
    handleRemoveArea,
    handleUncheckAllOptions,
    handleCheckAllOptions,
  } as HookReturn;
}

// hooks/useVisionList.ts
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import api from 'src/services/api';
import { TVision } from '../Visions.i';

interface HookReturn {
  isVisionDialogOpen: boolean;
  selectedVisionId: string | undefined;
  visions: TVision[];
  refreshVisions: boolean;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>; // Adicionado
  deleteAlertState: {
    isOpen: boolean;
    visionId: string;
  };
  executeVision: {
    isOpen: boolean;
    visionId: string;
    visionName: string;
    visionType: string;
  };
  handleDeleteVision: (vision_id: string) => void;
  handleOpenVisionDialog: (vision_id?: string) => void;
  handleOpenDeleteDialog: (vision_id: string) => void;
  handleCloseDeletionDialog: (value: boolean) => void;
  handleCloseVisionDialog: () => void;
  handleFilterVisions: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleExecuteVision: (vision_id: string, type: 'REPORT' | 'ANALYSIS') => void;
  handleCloseExecutionDialog: () => void;
}

export function useVisionList(): HookReturn {
  const [isVisionDialogOpen, setIsResponsibleDialogOpen] =
    React.useState(false);
  const [selectedVisionId, setSelectedVisionId] = React.useState<
    string | undefined
  >(undefined);
  const [visions, setVisions] = useState<TVision[]>([]);
  const [refreshVisions, setRefreshVisions] = useState(false);
  const [search, setSearch] = useState(''); // Já existente
  const [deleteAlertState, setDeleteAlertState] = useState({
    isOpen: false,
    visionId: '',
  });
  const [executeVision, setExecuteVision] = useState({
    isOpen: false,
    visionId: '',
    visionName: '',
    visionType: '',
  });

  useEffect(() => {
    api
      .get<{ visions: TVision[] }>('/visions')
      .then(response => {
        setVisions(response.data?.visions || []);
      })
      .catch(e => {
        const error = e.response?.data?.message || e?.message;
        const errMessage = `Erro ao buscar visões. ${error}`;
        toast.error(errMessage, {
          autoClose: 5000,
          theme: 'colored',
          closeOnClick: true,
        });
      });
  }, [refreshVisions]);

  const handleDeleteVision = useCallback((vision_id: string) => {
    api
      .delete(`/visions/${vision_id}`)
      .then(() => {
        toast.success('Visão excluída com sucesso.', {
          theme: 'colored',
          closeOnClick: true,
        });
      })
      .catch(e => {
        const error = e.response?.data?.message || e?.message;
        const errMessage = `Erro ao excluir visão. ${error}`;
        toast.error(errMessage, {
          autoClose: 5000,
          theme: 'colored',
          closeOnClick: true,
        });
      })
      .finally(() => {
        setRefreshVisions(oldValue => !oldValue);
      });
  }, []);

  const handleOpenVisionDialog = useCallback((vision_id?: string) => {
    setIsResponsibleDialogOpen(true);
    setSelectedVisionId(vision_id || undefined);
  }, []);

  const handleCloseVisionDialog = useCallback(() => {
    setIsResponsibleDialogOpen(false);
    setSelectedVisionId(undefined);
    setRefreshVisions(oldValue => !oldValue);
  }, []);

  const handleExecuteVision = useCallback(
    (vision_id: string, type: 'REPORT' | 'ANALYSIS') => {
      setExecuteVision({
        visionId: vision_id,
        visionName: visions.find(vision => vision.id === vision_id)?.name || '',
        visionType: type,
        isOpen: true,
      });
    },
    [visions],
  );

  const handleCloseExecutionDialog = useCallback(() => {
    setExecuteVision({
      visionId: '',
      visionName: '',
      visionType: '',
      isOpen: false,
    });
  }, []);

  const handleOpenDeleteDialog = useCallback((vision_id: string) => {
    setDeleteAlertState({ isOpen: true, visionId: vision_id });
  }, []);

  const handleFilterVisions = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value;
      if (searchValue.length === 0) {
        setSearch('');
      } else {
        setSearch(searchValue);
      }
    },
    [],
  );

  const handleCloseDeletionDialog = useCallback((value: boolean) => {
    setDeleteAlertState({ isOpen: value, visionId: '' });
  }, []);

  return {
    isVisionDialogOpen,
    selectedVisionId,
    visions,
    refreshVisions,
    search,
    setSearch,
    deleteAlertState,
    executeVision,
    handleDeleteVision,
    handleOpenVisionDialog,
    handleOpenDeleteDialog,
    handleCloseDeletionDialog,
    handleCloseVisionDialog,
    handleFilterVisions,
    handleExecuteVision,
    handleCloseExecutionDialog,
  } as HookReturn;
}

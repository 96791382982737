import React from 'react';
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { IArea } from 'src/services/areaApi';
import { DataSourceParametersType } from 'src/services/dataSourceApi';
import { useAuth } from 'src/context/AuthContext';
import { AccountParamsTabContent } from './AccountParamsTabContent';
import { CardParamsTabContent } from './CardParamsTabContent';
import { TaskParamsTabContent } from './TaskParamsTabContent';
import { AreasParamsTabContent } from './AreasParamsTabContent';

export interface ParametersProps {
  paramsAreas?: string[];
  areas: IArea[];
  loadingAreas: boolean;
  handleSelectedSourceOption: (params: {
    dataSource: 'accounts' | 'cards' | 'tasks' | 'areas';
    sourceOptionId: string;
    areaId?: string;
    isCardField: boolean;
    isCardTask: boolean;
  }) => void;
  handleRemoveArea: (area_id: string) => void;
  dataSourceParameters: DataSourceParametersType;
  handleUncheckAllOptions: (
    dataSource: 'accounts' | 'cards' | 'tasks' | 'areas',
  ) => void;
  handleCheckAllOptions: (
    dataSource: 'accounts' | 'cards' | 'tasks' | 'areas',
    listOfIds: string[],
  ) => void;
}

export const DataSourceParameters: React.FC<ParametersProps> = ({
  paramsAreas = [],
  areas,
  loadingAreas,
  handleSelectedSourceOption,
  dataSourceParameters,
  handleRemoveArea,
  handleUncheckAllOptions,
  handleCheckAllOptions,
}) => {
  const { user } = useAuth();
  const userCantEditDataSource =
    user.permissions.VISION_DATA_SOURCE !== 'READ_AND_WRITE';

  return (
    <Tabs
      defaultValue="accountParams"
      className="w-full h-[450px] border-2 rounded-sm flex flex-col overflow-hidden"
    >
      <TabsList className="w-full flex flex-row justify-start rounded-none">
        <TabsTrigger value="accountParams">Conta</TabsTrigger>
        <TabsTrigger value="cardParams">Card</TabsTrigger>
        <TabsTrigger value="taskParams">Tarefa</TabsTrigger>
        <TabsTrigger
          value="areasParams"
          disabled={loadingAreas || paramsAreas.length === 0}
        >
          Areas
        </TabsTrigger>
      </TabsList>
      <AccountParamsTabContent
        handleSelectedSourceOption={handleSelectedSourceOption}
        dataSourceParameters={dataSourceParameters}
        handleUncheckAllOptions={handleUncheckAllOptions}
        handleCheckAllOptions={handleCheckAllOptions}
        disableOptions={userCantEditDataSource}
      />
      <CardParamsTabContent
        handleSelectedSourceOption={handleSelectedSourceOption}
        dataSourceParameters={dataSourceParameters}
        handleUncheckAllOptions={handleUncheckAllOptions}
        handleCheckAllOptions={handleCheckAllOptions}
        disableOptions={userCantEditDataSource}
      />
      <TaskParamsTabContent
        handleSelectedSourceOption={handleSelectedSourceOption}
        dataSourceParameters={dataSourceParameters}
        handleUncheckAllOptions={handleUncheckAllOptions}
        handleCheckAllOptions={handleCheckAllOptions}
        disableOptions={userCantEditDataSource}
      />
      <AreasParamsTabContent
        paramsAreas={paramsAreas}
        areas={areas}
        handleSelectedSourceOption={handleSelectedSourceOption}
        dataSourceParameters={dataSourceParameters}
        handleRemoveArea={handleRemoveArea}
        disableOptions={userCantEditDataSource}
      />
    </Tabs>
  );
};

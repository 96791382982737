/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useHistory } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  TableContainer,
  Card,
  TextField,
  Typography,
  Collapse,
  Container,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { toast } from 'react-toastify';
import { useAuth } from 'src/context/AuthContext';
import api from '../../../services/api';
import Modal from '../../../components/Modal_cards';
import Area from '..';
import { IPhase } from '../../Phase/Phase.i';
import ListPhases from './phases';
import AreaFieldsAndTasks from '../AreaFieldsAndTasks';
import { IArea } from '../Area.i';
import { COLORS } from '../../../styles/colors';
import { EllipsisOptionsDropdown } from '../../../components/EllipsisOptionsDropdown/EllipsisOptionsDropdown';
import Phase from '../../Phase';
import TitleAndButton from '../../../components/TitleAndButton';

const useStyles = makeStyles(theme => ({
  boxTable: {
    width: '100%',
    overflow: 'auto',
    maxHeight: '600px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '400px',
    },
  },
  wrapper: {
    padding: 15,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 5,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'min-content',
      marginBottom: 15,
    },
  },
  title: { fontSize: '25px', fontWeight: 'bold' },
  card: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    minWidth: '49%',
    flex: 1,
    maxWidth: '650px',
    [theme.breakpoints.down('sm')]: {
      overflow: 'visible',
    },
  },
  columnActions: {
    width: '30px',
  },
  columnText: {
    width: '100px',
  },
  button: {
    color: COLORS.BLUE,
    margin: 0,
    marginLeft: 30,
  },
  swalAlert: {
    zIndex: 99999,
  },
}));

export interface IAreaWithPhases extends IArea {
  phases: IPhase[];
}

interface IOpenAreasMap {
  [key: string]: boolean;
}

const List: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuth();
  const userCantEditArea = user?.permissions.AREA !== 'READ_AND_WRITE';

  const [openAreaModal, setOpenAreaModal] = useState(false);
  const [openPhaseModal, setOpenPhaseModal] = useState(false);
  const [areas, setAreas] = useState<IAreaWithPhases[]>([]);
  const [areasFiltered, setAreasFiltered] = useState<IAreaWithPhases[]>([]);
  const [selectedArea, setSelectedArea] = useState<IAreaWithPhases>();
  const [selectedPhase, setSelectedPhase] = useState('');
  const [findName, setFindName] = useState('');
  const [openAreasMap, setOpenAreasMap] = useState<IOpenAreasMap>({});

  const handleRefreshArea = useCallback(() => {
    api.get('/areas').then(response => {
      setAreas(response.data);
      if (selectedArea) {
        const findArea = areas.find(area => area.id === selectedArea.id);
        setSelectedArea(findArea);
        setSelectedPhase('');
      }
    });
  }, [selectedArea]);

  useEffect(() => {
    api.get('/areas').then(response => {
      setAreas(response.data);
      updateOpenAreasFromUrl(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedArea) {
      const findArea = areas.find(area => area.id === selectedArea.id);
      setSelectedArea(findArea);
      // setSelectedPhase('');
    }
  }, [areas, selectedArea]);

  useEffect(() => {
    setAreasFiltered(areas);

    updateOpenAreasFromUrl(areas);
  }, [areas]);

  const handleEdit = useCallback(
    (area_id: string) => {
      history.push(`/areas/${area_id}`);
    },
    [history],
  );

  const handleDelete = useCallback((area_id: string) => {
    Swal.fire({
      title: 'Deseja excluir?',
      text: 'Essa opção não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
      customClass: {
        container: classes.swalAlert,
      },
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/areas/${area_id}`);
          handleRefreshArea();

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      }
    });
  }, []);

  const handleAddArea = useCallback(() => {
    setOpenAreaModal(true);
  }, []);

  const updateOpenAreasFromUrl = (fetchedAreas: IAreaWithPhases[]) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const areaIdFromUrl = urlSearchParams.get('selectedArea');

    const areasMap: { [key: string]: boolean } = {};
    fetchedAreas.forEach(area => {
      areasMap[area.id] = area.id === areaIdFromUrl;
    });
    setOpenAreasMap(areasMap);
  };

  const handleCloseAreaModal = () => {
    setOpenAreaModal(false);
    handleRefreshArea();
  };

  useEffect(() => {
    const areasF = areas.filter(
      account =>
        account.name
          .toUpperCase()
          .trim()
          .indexOf(findName.toUpperCase().trim()) >= 0,
    );
    setAreasFiltered(areasF);
  }, [findName, areas]);

  const handleClickRow = (area: IArea) => {
    const isCurrentlyOpen = openAreasMap[area.id];
    const updatedAreasMap = { ...openAreasMap };

    Object.keys(updatedAreasMap).forEach(key => {
      updatedAreasMap[key] = false;
    });

    updatedAreasMap[area.id] = !isCurrentlyOpen;
    setOpenAreasMap(updatedAreasMap);

    if (!isCurrentlyOpen) {
      history.push({ search: `?selectedArea=${area.id}` });
    } else {
      history.push({ search: '' });
    }
  };

  const handleAddPhase = useCallback(area => {
    setSelectedArea(area);
    setSelectedPhase('');

    setOpenPhaseModal(true);
  }, []);

  const handleEditPhase = useCallback(
    (area_id: string, phase_id: string) => {
      setSelectedArea(areas.find(area => area.id === area_id));
      setSelectedPhase(phase_id);
      setOpenPhaseModal(true);
    },
    [areas],
  );

  const handleClosePhaseModal = (shouldRefreshData: boolean) => {
    setOpenPhaseModal(false);
    if (shouldRefreshData) handleRefreshArea();
  };

  const handleClickPhaseRow = (phase_id: string) => {
    setSelectedPhase(phase_id);
  };

  return (
    <Container maxWidth={false} className={classes.wrapper}>
      <Card className={classes.card}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" component="h2" className={classes.title}>
            Processos
          </Typography>
          <IconButton
            aria-label="add"
            onClick={handleAddArea}
            className={classes.button}
            disabled={userCantEditArea}
          >
            <AddCircleIcon />
          </IconButton>
        </div>
        <TextField
          label="Pesquisar"
          name="find"
          margin="dense"
          variant="outlined"
          fullWidth
          value={findName}
          style={{ marginBottom: 15, maxWidth: '300px' }}
          onChange={value => {
            setFindName(value.target.value);
          }}
        />
        {/* Cabeçalho */}
        <TableContainer component={Paper} className={classes.boxTable}>
          <Table
            size="small"
            aria-label="lista de processos"
            padding="normal"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnText}>Processo</TableCell>
                <TableCell className={classes.columnActions}>Fases</TableCell>
                <TableCell className={classes.columnActions} />
                <TableCell className={classes.columnActions} />
              </TableRow>
            </TableHead>
            {/* Corpo da tabela */}
            <TableBody>
              {areas &&
                areasFiltered &&
                areasFiltered.map(area => (
                  <>
                    <TableRow
                      hover={area.phases.length > 0}
                      style={
                        openAreasMap[area.id] ? { backgroundColor: '#eee' } : {}
                      }
                      key={area.name}
                      onClick={e => {
                        e.stopPropagation();
                        handleClickRow(area);
                      }}
                      className="cursor-pointer"
                    >
                      <TableCell>{area.name}</TableCell>
                      <TableCell>{area.phases.length}</TableCell>
                      <TableCell>
                        <IconButton aria-label="expand row" size="small">
                          {openAreasMap[area.id] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <EllipsisOptionsDropdown
                          refreshDataAfterAction={() => {
                            api
                              .get('/areas')
                              .then(response => setAreas(response.data));
                          }}
                          options={{
                            'Editar processo': () => handleEdit(area.id),
                            ...(userCantEditArea
                              ? {}
                              : {
                                  'Adicionar fase': () => handleAddPhase(area),
                                  'Excluir processo': () =>
                                    handleDelete(area.id),
                                }),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      style={
                        openAreasMap[area.id] ? { backgroundColor: '#eee' } : {}
                      }
                    >
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={4}
                      >
                        <Collapse
                          in={openAreasMap[area.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={2}>
                            <TitleAndButton
                              title="Fases"
                              fontSize={16}
                              handleAdd={() => handleAddPhase(area)}
                              disabled={userCantEditArea}
                            />
                            {area.phases && (
                              <ListPhases
                                initialPhases={area.phases}
                                area_id={area.id}
                                handleEditPhase={handleEditPhase}
                                handleClickPhaseRow={handleClickPhaseRow}
                              />
                            )}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {areas &&
        areasFiltered &&
        areasFiltered.map(area => (
          <>
            {openAreasMap[area.id] && (
              <AreaFieldsAndTasks
                setAreas={setAreas}
                area={area}
                key={area.id}
                selectedPhase={selectedPhase}
              />
            )}
          </>
        ))}
      {openAreaModal && (
        <Modal
          open
          closeModal={handleCloseAreaModal}
          title="Adicionar Área"
          titleLeft=""
        >
          <Area closeModal={handleCloseAreaModal} />
        </Modal>
      )}
      {openPhaseModal && selectedArea && (
        <Modal
          open={openPhaseModal}
          closeModal={handleClosePhaseModal}
          title={selectedPhase ? 'Editar Fase' : 'Adicionar Fase'}
          titleLeft=""
          titleRight={selectedArea?.name || 'Sem Área'}
        >
          <Phase
            area_id_prop={selectedArea.id}
            phase_id_prop={selectedPhase}
            closeModal={handleClosePhaseModal}
          />
        </Modal>
      )}
    </Container>
  );
};

export default List;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button as ButtonMaterialUI,
  Container,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { toast } from 'react-toastify';
import ShowStorageData from '../../components/ShowStorageData';
import api from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import Button from '../../components/Button';

import { IBusiness, IFormData } from './Business.i';
import { styles } from './styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const Area: React.FC = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const userCantEditBusiness = user?.permissions.BUSINESS === 'READ_AND_WRITE';

  const history = useHistory();

  const goBack = () => {
    let path = '/';
    history.push(path);
  };

  const goToEmails = () => {
    let path = '/listEmails';
    history.push(path);
  };

  const [business, setBusiness] = useState<IBusiness>();

  const initialValues: IFormData = {
    name: business ? business.name : '',
    description: business ? business.description : '',
  };

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required('Nome é Obrigatório')
      .min(2, 'O nome deve ter pelo menos 2 caracteres'),
    description: Yup.string().required('Descrição é Obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      if (business) {
        try {
          await api.put(`/businesses`, {
            name: values.name,
            description: values.description,
          });
          setSubmitting(true);

          toast.success('Empresa salva com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          history.goBack();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          setSubmitting(false);

          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      }
    },
  });

  useEffect(() => {
    api.get(`/businesses/users`).then(response => setBusiness(response.data));
  }, [user.id]);

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        {business ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Editar Empresa
            </Typography>
            <Button
              variant="contained"
              disabled={formik.isSubmitting}
              onClick={goToEmails}
            >
              Emails
            </Button>
          </Box>
        ) : (
          <div />
        )}
        <Box>
          <form noValidate onSubmit={formik.handleSubmit}>
            <TextField
              id="name"
              label="Nome"
              name="name"
              margin="normal"
              autoFocus
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              id="description"
              label="Descrição"
              name="description"
              margin="normal"
              fullWidth
              multiline
              minRows={1}
              maxRows={4}
              onChange={formik.handleChange}
              value={formik.values.description}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            <ButtonMaterialUI
              className={classes.buttonSubmit}
              disabled={formik.isSubmitting}
              size="large"
              variant="contained"
              color="inherit"
              onClick={goBack}
            >
              Voltar
            </ButtonMaterialUI>
            <Button
              disabled={userCantEditBusiness || formik.isSubmitting}
              className={classes.buttonSubmit}
            >
              Salvar
            </Button>
          </form>
          <Box mt={3}>
            <ShowStorageData />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Area;

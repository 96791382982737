import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';

interface PermissionComponentProps {
  permissionName: string;
}

const PermissionComponent: React.FC<PermissionComponentProps> = ({
  permissionName,
  children,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user, signOut } = useAuth();

  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    if (
      permissionName &&
      permissionName !== 'FULL_ACCESS' &&
      user &&
      user?.permissions &&
      !(permissionName in user.permissions)
    ) {
      signOut();
    }

    if (permissionName === 'FULL_ACCESS') {
      setHasPermission(true);
    }

    if (
      permissionName !== 'FULL_ACCESS' &&
      permissionName &&
      user &&
      user.permissions &&
      user.permissions[permissionName]
    ) {
      const permissionValue =
        permissionName === 'SEARCH'
          ? user.permissions[permissionName] === 'READ_AND_WRITE'
          : user.permissions[permissionName] !== 'DISABLED';
      setHasPermission(permissionValue);
    }
  }, [permissionName, user]);

  return <>{hasPermission && children}</>;
};

export default PermissionComponent;

import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { permissionsApi, ProfileType } from 'src/services/permissionsApi';

interface HookReturn {
  profilesList: ProfileType[];
  loadingProfiles: boolean;
  setRefreshProfiles: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useProfilesList(): HookReturn {
  const [profilesList, setProfilesList] = useState<ProfileType[]>([]);
  const [loadingProfiles, setLoadingProfiles] = useState<boolean>(false);
  const [refreshProfiles, setRefreshProfiles] = useState<boolean>(false);

  useEffect(() => {
    setLoadingProfiles(true);
    permissionsApi
      .getProfilesList()
      .then((response: { data: { profiles: ProfileType[] } }) => {
        const { profiles } = response.data;
        setProfilesList(profiles);
      })
      .catch(error => {
        const errMessage = error.response?.data?.message || error.message;
        toast.error(`Falha ao buscar lista de perfis. ${errMessage || ''}`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      })
      .finally(() => {
        setLoadingProfiles(false);
      });
  }, [refreshProfiles]);

  return {
    profilesList,
    loadingProfiles,
    setRefreshProfiles,
  } as HookReturn;
}

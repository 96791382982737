import api from './api';

export interface PermissionProperties {
  [key: string]: {
    description: string;
    isExecutableOnly: boolean;
    isReadOnly: boolean;
  };
}

export type ProfileType = {
  id: string;
  name: string;
  description: string;
  category: {
    id: string;
    name: ProfileCategory;
  };
  activePermissions: {
    name: string;
    status: PermissionValues;
  }[];
};

export type ProfileCategory =
  | 'ADMINISTRATOR'
  | 'CONFIGURATOR'
  | 'SUPERVISOR'
  | 'USER';

export const ProfileCategoryNames: Record<ProfileCategory, string> = {
  ADMINISTRATOR: 'Administrador',
  CONFIGURATOR: 'Configurador',
  SUPERVISOR: 'Supervisor',
  USER: 'Básico',
};

export type PermissionValues =
  | 'TOTAL'
  | 'READ_AND_WRITE'
  | 'READ_ONLY'
  | 'DISABLED';

export type PermissionsByCategories = {
  [Category in ProfileCategory]: Record<string, PermissionValues>;
};

export interface IPermissionsCategories {
  permissionsByCategories: PermissionsByCategories;
  permissionProperties: PermissionProperties;
}

interface ProfilesListResponse {
  profiles: ProfileType[];
}

export const permissionsApi = {
  getPermissionsCategories: () =>
    api.get<IPermissionsCategories>('/permissions/categories'),
  getProfilesList: () => api.get<ProfilesListResponse>('/profiles'),
};

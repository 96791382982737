/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { addMinutes, isValid } from 'date-fns';
import { FormikHelpers } from 'formik';
import { ITriggersData } from 'src/context/TriggerContext';
import {
  CardFieldValueJSONTypes,
  CardFieldValueTypes,
} from 'src/utils/fieldTypes.constants';
import {
  checkIfRequiredItemsAreNotEmpty,
  isFieldValueEmpty,
} from 'src/utils/customFieldUtils';
import api from '../../services/api';
import { toastLoadingUpdate } from '../../utils/toastifyUtils';
import { CardDataProps, ITaskValues, InitialStateProps } from './TaskModal.i';
import { TaskTypesIds } from '../../utils/taskTypes.constants';

export function formikTaskValidation(
  updateTriggers: (triggers: ITriggersData) => void,
  task_id: string | null,
  task: ITaskValues | null,
  handleSetTaskIdAfterCreate: (id: string) => void,
  _closeModal: () => void,
  newTaskType: TaskTypesIds | null,
  isProcessTask: boolean,
  associateCard: boolean,
  initialState: InitialStateProps,
  setInitialState: (value: InitialStateProps) => void,
  shouldSendEmail: boolean,
  setShouldSendEmail: (value: boolean) => void,
  setCardFieldsErrorList: (value: { [key: string]: boolean }) => void,
  setWasInitiallyDone: (value: boolean) => void,
  card_id?: string,
  cardData?: CardDataProps,
) {
  const definedType =
    !task_id && newTaskType ? newTaskType : task?.type || 'ACTIVITY';
  const sortLogByDesc = task?.logs
    ? task.logs.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      )
    : [];
  let account;
  let contacts;

  if (associateCard && cardData !== undefined && !task_id && !task) {
    account = cardData.account || null;
    contacts = cardData.contact ? [cardData.contact] : null;
  } else {
    account = task?.account || null;
    contacts = task?.contacts || null;
  }

  if (initialState === undefined && task) {
    setInitialState({
      startsAt: task?.startsAt ? new Date(task.startsAt).toISOString() : null,
      endsAt: task?.endsAt ? new Date(task.endsAt).toISOString() : null,
      contacts: task?.contacts || [],
    });
  }

  const initialValues: ITaskValues = {
    id: task_id || task?.id || '',
    instructions: task?.instructions || '',
    wasCreatedByTrigger: task?.wasCreatedByTrigger || false,
    deadline: task?.deadline || 0,
    type: definedType,
    name: task?.name || '',
    completedAt: task?.completedAt || null,
    createdAt: task?.createdAt,
    description: task?.description || '',
    user: task?.user || null,
    startsAt: task?.startsAt || null,
    endsAt: task?.endsAt || null,
    account,
    contacts,
    area: task?.area || null,
    phase: task?.phase || null,
    card: task?.card || null,
    done: !!task?.completedAt || task?.done || false,
    guestUsers: task?.guestUsers || [],
    fieldValues: task?.fieldValues || [],
    logs: sortLogByDesc,
    linkedCardFieldValues: task?.linkedCardFieldValues || [],
    cumulativeDeadlineAt: task?.cumulativeDeadlineAt,
  };

  const requiredFieldMessage = 'Campo obrigatório';

  const formSchema = Yup.object().shape(
    {
      id: Yup.string().nullable().optional(),
      type: Yup.string().nullable().required(requiredFieldMessage),
      user: Yup.object().nullable().required(requiredFieldMessage),
      name: Yup.string().nullable().required(requiredFieldMessage),
      description: Yup.string().nullable().optional(),
      startsAt: Yup.date()
        .nullable()
        .test(
          'startsAt-required-if-endsAt',
          'Inicio é obrigatório quando fim está preenchido',
          function validateStartsAt(value) {
            const { endsAt } = this.parent;
            if (endsAt !== null && isValid(endsAt)) {
              return value !== null;
            }
            return true;
          },
        ),
      endsAt: Yup.date()
        .typeError('Data inválida')
        .nullable()
        .when('startsAt', {
          is: (startsAt: Date | null | undefined) =>
            startsAt !== null && isValid(startsAt),
          then: Yup.date()
            .nullable()
            .required('Fim é obrigatório quando início está preenchido')
            .test({
              name: 'dateComparison',
              exclusive: false,
              message: 'Fim deve ser igual ou posterior ao início',
              test(this: Yup.TestContext, endsAt: Date | null | undefined) {
                const startsAt = this.resolve(Yup.ref('startsAt')) as
                  | Date
                  | null
                  | undefined;
                if (!startsAt || !endsAt) return true;
                if (!isValid(startsAt) || !isValid(endsAt)) return true;
                return endsAt >= startsAt;
              },
            }),
        }),
      account: Yup.object().nullable().required(requiredFieldMessage),
      contacts: Yup.array().nullable().optional(),
      guestUsers: Yup.array().nullable().optional(),
      area: Yup.object().nullable().optional(),
      phase: Yup.object()
        .nullable()
        .optional()
        .when('card', {
          is: (card: null | object) =>
            card !== null && typeof card === 'object' && 'id' in card,
          then: Yup.object()
            .nullable()
            .required('Fase é obrigatório quando Card está preenchido'),
        }),
      card: Yup.object()
        .nullable()
        .optional()
        .when('phase', {
          is: (phase: null | object) =>
            phase !== null && typeof phase === 'object' && 'id' in phase,
          then: Yup.object()
            .nullable()
            .required('Card é obrigatório quando fase está preenchida'),
        }),
      done: Yup.boolean().optional(),
      fieldValues: Yup.array().nullable().optional(),
      log: Yup.array().optional(),
      linkedCardFieldValues: Yup.array().nullable().optional(),
    },
    [
      ['startsAt', 'endsAt'],
      ['card', 'phase'],
    ],
  );

  return {
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (
      values: ITaskValues,
      { setSubmitting, setFieldValue }: FormikHelpers<ITaskValues>,
    ) => {
      const currentDate = new Date();
      const currentDateString = currentDate.toISOString();
      const newStartDate = values?.startsAt
        ? new Date(values.startsAt).toISOString()
        : values.done
        ? currentDateString
        : null;
      const newEndDate = values?.endsAt
        ? new Date(values.endsAt).toISOString()
        : values.done
        ? currentDateString
        : null;

      setSubmitting(true);
      const currentTaskId = task_id || values.id;
      const successMessage = !currentTaskId
        ? 'Tarefa cadastrada com sucesso.'
        : 'Tarefa alterada com sucesso.';
      const savingTask = toast.loading(`Salvando tarefa`, {
        autoClose: 5000,
      });
      document.body.style.cursor = 'progress';

      if (
        values.done &&
        newStartDate &&
        newEndDate &&
        values.linkedCardFieldValues.length > 0 &&
        !checkIfRequiredItemsAreNotEmpty(values.linkedCardFieldValues)
      ) {
        toastLoadingUpdate(
          savingTask,
          'Não foi possível concluir a tarefa porque há campos vinculados e obrigatórios que não foram preenchidos.',
          'error',
          5000,
        );
        setSubmitting(false);
        document.body.style.cursor = 'auto';
        setFieldValue('done', false);

        const emptyRequiredFields: { [key: string]: boolean } = {};
        values.linkedCardFieldValues.forEach(field => {
          const isValue = CardFieldValueTypes.includes(field.type);
          const value = isValue ? field.value : field.valueJSON;
          if (
            (field.isRequired || field.isApprovalCriterion) &&
            isFieldValueEmpty(value)
          ) {
            emptyRequiredFields[field.id] = true;
          } else {
            emptyRequiredFields[field.id] = false;
          }
        });

        setCardFieldsErrorList(emptyRequiredFields);
        return;
      }

      const formattedLinkedCardFieldValues =
        values.linkedCardFieldValues && values.linkedCardFieldValues.length > 0
          ? values.linkedCardFieldValues
              .filter(v => v?.type !== 'FILE')
              .map(v => {
                const isValue = CardFieldValueTypes.includes(v.type);
                const isValueJSON = CardFieldValueJSONTypes.includes(v.type);
                return {
                  id: v.id,
                  value: isValue ? v.value || '' : undefined,
                  valueJSON: isValueJSON
                    ? v.valueJSON ||
                      (v.type === 'PREDEFINED_STRINGS' || v.type === 'FILE'
                        ? []
                        : null)
                    : undefined,
                };
              })
          : [];

      const newTask = {
        name: values.name || (currentTaskId ? null : undefined),
        type: !currentTaskId ? values.type : undefined,
        description: values.description || (currentTaskId ? null : undefined),
        user_id: values?.user?.id,
        account_id: values?.account?.id,
        contact_ids:
          values.contacts && values.contacts.length > 0
            ? values.contacts.map(c => c.id)
            : [],
        guestUser_ids:
          values.guestUsers && values.guestUsers.length > 0
            ? values.guestUsers.map(c => c.id)
            : [],
        phase_id: values?.phase?.id || (currentTaskId ? null : undefined),
        card_id: values?.card?.id || (currentTaskId ? null : undefined),
        startsAt: newStartDate || (currentTaskId ? null : undefined),
        endsAt: newEndDate || (currentTaskId ? null : undefined),
        done: values.done,
        fieldValues:
          values.fieldValues && values.fieldValues.length > 0
            ? values.fieldValues.map(v => ({ id: v.id, value: v.value }))
            : [],
        shouldSendEmail,
        linkedCardFieldValues:
          formattedLinkedCardFieldValues.length > 0
            ? formattedLinkedCardFieldValues
            : undefined,
      };

      const processTask = {
        type: !currentTaskId ? values.type : undefined,
        contact_ids:
          values.contacts && values.contacts.length > 0
            ? values.contacts.map(c => c.id)
            : [],
        guestUser_ids:
          values.guestUsers && values.guestUsers.length > 0
            ? values.guestUsers.map(c => c.id)
            : [],
        startsAt: newStartDate || (currentTaskId ? null : undefined),
        endsAt: newEndDate || (currentTaskId ? null : undefined),
        done: values.done,
        fieldValues:
          values.fieldValues && values.fieldValues.length > 0
            ? values.fieldValues.map(v => ({ id: v.id, value: v.value }))
            : [],
        shouldSendEmail,
        linkedCardFieldValues:
          formattedLinkedCardFieldValues.length > 0
            ? formattedLinkedCardFieldValues
            : undefined,
      };

      let request: Promise<any>;
      // const sendEmail = shouldSendEmail ? '?shouldSendEmail=true' : '';

      if (currentTaskId && isProcessTask && card_id) {
        request = api.put(
          `/cards/${card_id}/cardTasks/${currentTaskId}`,
          processTask,
        );
      } else {
        const url = currentTaskId
          ? `/singleTasks/${currentTaskId}`
          : `/singleTasks`;
        const method = currentTaskId ? 'put' : 'post';
        request = api[method](url, newTask);
      }

      request
        .then(response => {
          toastLoadingUpdate(savingTask, successMessage, 'success', 2000);
          if (!currentTaskId && response.data?.id !== undefined) {
            handleSetTaskIdAfterCreate(response.data.id);
          }

          if (response?.data !== undefined) {
            setInitialState({
              startsAt: newStartDate,
              endsAt: newEndDate,
              contacts: values.contacts,
            });

            if (values.done) {
              if (!values.startsAt) setFieldValue('startsAt', newStartDate);
              if (!values.endsAt) {
                setTimeout(() => {
                  const endDatePlusOneMinute = addMinutes(
                    currentDate,
                    1,
                  ).toISOString();
                  setFieldValue('endsAt', endDatePlusOneMinute);
                }, 500);
              }
              setWasInitiallyDone(true);
            }

            if (response.data?.triggers !== undefined) {
              const triggers = response.data?.triggers;
              const key_card = values.card?.keyCard;
              const cardId = card_id || values?.card?.id;
              const accountName = values?.account?.name || '';
              const triggerDateTime = new Date();

              if (
                triggers &&
                cardId &&
                key_card &&
                Object.keys(triggers).length > 0
              ) {
                updateTriggers({
                  card_id: cardId,
                  key_card,
                  triggers,
                  accountName,
                  triggerDateTime,
                });
              }
            }
          }
        })
        .catch(e => {
          toastLoadingUpdate(
            savingTask,
            `Ocorreu um erro. ${e.response.data.message}`,
            'error',
            5000,
          );
        })
        .finally(() => {
          document.body.style.cursor = 'auto';
          setShouldSendEmail(false);
        });

      setSubmitting(false);
    },
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ModalImportAccounts } from 'src/components/ModalImportAccounts';
import { useAccounts } from 'src/hooks/useAccounts';
import api from 'src/services/api';
import { Button } from 'src/components/ui/button';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { SettingsIcon } from 'lucide-react';
import { useAuth } from 'src/context/AuthContext';

interface AccountHeaderProps {}

export const AccountHeader: React.FC<AccountHeaderProps> = () => {
  const history = useHistory();
  const { user } = useAuth();
  const disableConfigOptions =
    user?.permissions.ACCOUNT_CONFIGURATION !== 'READ_AND_WRITE';
  const { setRefreshAccount } = useAccounts();
  const [openModalImportAccountsModal, setModalOpenImportAccountsModal] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const handleImportAccounts = useCallback(() => {
    setModalOpenImportAccountsModal(true);
  }, []);

  const handleCloseImportModal = () => {
    setModalOpenImportAccountsModal(false);
    setRefreshAccount(oldValue => !oldValue);
  };

  const handleIntegration = async () => {
    setLoading(true);
    try {
      await api.post('integrations/moments/load_accounts');
      setLoading(false);
      toast.success('Integração com o ERP efetuada com sucesso.', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setTimeout(() => {
        history.go(0);
      }, 50);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        autoClose: 5000,
      });
    }
  };

  const handleAccountConfiguration = () => {
    history.push('/accountFields');
  };

  return (
    <div className="w-full flex items-center my-5">
      <h1 className="text-3xl font-bold mr-8 mb-0">Contas</h1>

      <div className="w-fit p-0">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              className="p-2 m-0 rounded-full w-fit h-fit"
            >
              <span className="sr-only">Open menu</span>
              <SettingsIcon className="h-6 w-6" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuLabel>Configurações</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="hover:bg-gray-200 cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                handleAccountConfiguration();
              }}
              disabled={disableConfigOptions}
            >
              Campos personalizados
            </DropdownMenuItem>
            <DropdownMenuItem
              className="hover:bg-gray-200 cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                handleImportAccounts();
              }}
              disabled={disableConfigOptions}
            >
              Carregar arquivo CSV
            </DropdownMenuItem>
            <DropdownMenuItem
              className="hover:bg-gray-200 cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                handleIntegration();
              }}
              disabled={loading || disableConfigOptions}
            >
              Carregar integração
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {openModalImportAccountsModal && (
        <ModalImportAccounts
          openModal={openModalImportAccountsModal}
          closeModal={handleCloseImportModal}
        />
      )}
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  Box,
  CircularProgress,
  Button,
  makeStyles,
} from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Modal from '../Modal_cards';
import { useFilters } from '../../context/FilterContext';
import api from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import { IArea } from './Filter.i';
import { FilterIcon } from '../../assets/filterIcons/FilterIcon';
import { FilterIconOutline } from '../../assets/filterIcons/FilterIconOutline';
import { CreateCard } from '../CreateCard';
import { FilterModal } from './FilterModal';

const useStyles = makeStyles(() => ({
  root: {
    '& label.Mui-focused': {
      color: '#53a546',
    },
    '& .MuiInputLabel-shrink': {
      color: '#53a546',
    },
    '& fieldset': {
      borderColor: '#53a546',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#53a546',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#53a546',
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: '#53a546',
      },
    },
  },
  button: {
    height: '40px',
    background: 'linear-gradient(to left, #fafafa, #fafafa)',
    color: '#007dc6',
  },
}));

const FilterCards: React.FC = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const userCantCreateCard = user?.permissions.CREATE_CARD !== 'READ_AND_WRITE';
  const [openModal, setOpenModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [areas, setAreas] = useState<IArea[]>([]);
  const [selectedArea, setSelectedArea] = useState<IArea | null>(null);
  const [loadingArea, setLoadingArea] = useState(false);
  const [hasFilter, setHasFilter] = useState(false);

  const {
    handleFilters,
    storedAccountIds,
    storedContactIds,
    storedUserIds,
    storedCardKey,
    storedConditionsExpressionInput,
    setStoredConditionsExpressionInput,
  } = useFilters();

  const handleConditionsChange = (newConditions: any) => {
    setStoredConditionsExpressionInput(newConditions);
  };

  const checkHasFilter = () => {
    if (
      storedAccountIds.length > 0 ||
      storedContactIds.length > 0 ||
      storedUserIds.length > 0 ||
      storedCardKey.length > 0 ||
      (storedConditionsExpressionInput.length > 0 &&
        storedConditionsExpressionInput.some(
          group =>
            Array.isArray(group) &&
            group.some((condition: any) => condition.value),
        ))
    ) {
      setHasFilter(true);
    } else {
      setHasFilter(false);
    }
  };

  useEffect(() => {
    checkHasFilter();
  }, [
    storedAccountIds,
    storedContactIds,
    storedUserIds,
    storedCardKey,
    storedConditionsExpressionInput,
  ]);

  useEffect(() => {
    setLoadingArea(true);
    api
      .get('/areas')
      .then(response => {
        const findArea = response.data.find(
          (area: any) => area.id === loggedUser.areaId,
        );
        setAreas(response.data);
        if (findArea) {
          setSelectedArea(findArea);
          handleFilters({ area_id: findArea.id });
        } else {
          setSelectedArea(response.data[0]);
          handleFilters({ area_id: response.data[0]?.id });
        }
        setLoadingArea(false);
      })
      .catch(() => {
        setLoadingArea(false);
      });
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseFilterModal = () => {
    setOpenFilterModal(false);
  };

  const { user: loggedUser } = useAuth();

  return (
    <>
      <Box
        className={classes.root}
        display="flex"
        alignItems="center"
        marginTop="5px"
        width="auto"
      >
        <Button
          variant="contained"
          className={classes.button}
          style={{
            width: '95px',
            minWidth: '95px',
            marginRight: 15,
          }}
          onClick={() => setOpenModal(true)}
          disabled={userCantCreateCard}
          startIcon={<AddCircleOutlineIcon>add_circle</AddCircleOutlineIcon>}
        >
          Card
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          style={{ width: '95px', minWidth: '95px', marginRight: 15 }}
          onClick={() => setOpenFilterModal(true)}
        >
          {hasFilter ? <FilterIcon /> : <FilterIconOutline />}
          Filtros
        </Button>
        {selectedArea && (
          <Autocomplete
            id="area"
            getOptionLabel={area => area.name}
            getOptionSelected={(area, value) => area.id === value.id}
            loading={loadingArea}
            value={selectedArea ?? ''}
            options={areas}
            disableClearable
            style={{ maxWidth: '250px' }}
            onChange={(e, value) => {
              if (value) {
                setSelectedArea(value);
                setHasFilter(false);
                handleFilters({
                  area_id: value.id,
                  account_ids: [],
                  contact_ids: [],
                  user_ids: [],
                  card_key: '',
                  filters: [],
                  conditionsExpressionInput: [[{ name: '', type: '' }]],
                });
              }
            }}
            loadingText="Carregando"
            fullWidth
            renderInput={rest => (
              <TextField
                {...rest}
                id="area"
                label="Área"
                margin="dense"
                variant="outlined"
                InputProps={{
                  ...rest.InputProps,
                  endAdornment: (
                    <>
                      {loadingArea ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {rest.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      </Box>
      {openModal && (
        <Modal
          open
          closeModal={handleCloseModal}
          title="Adicionar CARD"
          titleLeft=""
          titleRight=""
        >
          <CreateCard closeModal={handleCloseModal} />
        </Modal>
      )}
      {openFilterModal && (
        <FilterModal
          open={openFilterModal}
          closeModal={handleCloseFilterModal}
          title="Filtros"
          titleLeft=""
          selectedArea={selectedArea}
          onConditionsChange={handleConditionsChange}
        />
      )}
    </>
  );
};

export default FilterCards;

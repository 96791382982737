import React from 'react';

import { TextField } from '@material-ui/core';
import { UserRestrictDataProps } from '../User.i';
import { UserRestrictData } from './UserRestrictData';

export const UserData: React.FC<UserRestrictDataProps> = ({
  formik,
  businesses,
  loadingBusiness,
  loadingArea,
  areas,
}) => {
  return (
    <div className="w-full flex flex-col gap-2 border-2 p-2 rounded-sm">
      <div className="w-fit text-1xl font-bold ml-1 px-1 -mt-5 bg-white">
        Dados Cadastrais
      </div>
      <div className="flex flex-row gap-2 items-start w-full p-0 m-0 max-md:flex-wrap">
        <TextField
          id="name"
          label="Nome"
          name="name"
          autoComplete="name"
          margin="dense"
          variant="outlined"
          fullWidth
          autoFocus
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          margin="dense"
          variant="outlined"
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </div>
      <UserRestrictData
        formik={formik}
        businesses={businesses}
        loadingBusiness={loadingBusiness}
        loadingArea={loadingArea}
        areas={areas}
      />
    </div>
  );
};

// src/components/Conditions/ConditionInputs/OuterLogicOperator.tsx

import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { conditionTypes } from 'src/utils/conditionOperators';
import { IOperatorProps, ITypeField } from '../Condition.i';

export const OuterLogicOperator: React.FC<IOperatorProps<string>> = ({
  item,
  groupIndex,
  itemIndex,
  handleConditionChange,
  isGroupCondition = false,
}) => {
  const selectedValue: ITypeField | null =
    conditionTypes.find(field => field.id === item) || null;

  return (
    <Autocomplete
      id="conditionItem"
      getOptionLabel={opt => opt.label}
      getOptionSelected={(opt, value) => opt.id === value.id}
      options={conditionTypes}
      value={selectedValue as ITypeField}
      onChange={(e, value) => {
        if (value) {
          handleConditionChange(
            isGroupCondition ? 'groupCondition' : 'condition',
            value.id,
            groupIndex,
            itemIndex,
          );
        }
      }}
      disableClearable
      size="small"
      loadingText="Carregando"
      renderInput={params => (
        <TextField
          {...params}
          id="conditionItem"
          label=""
          margin="dense"
          name={isGroupCondition ? 'groupConditionItem' : 'conditionItem'}
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            style: {
              margin: '0 0 0 5px',
              padding: '2px',
              fontSize: '14px',
              backgroundColor: 'white',
              width: '60px',
            },
          }}
        />
      )}
    />
  );
};

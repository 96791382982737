/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useEffect, useState, useCallback } from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  TextField,
  Checkbox,
  Theme,
  Typography,
  Tooltip,
  Divider,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import api from 'src/services/api';
import { styles } from '../styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

interface ICustomField {
  id: string;
  name: string;
  type: string;
  tableOrder: number;
  phaseOrder: number;
  table?: ICustomField | null;
}

interface IFilter {
  isActive: boolean;
  filterCode: string;
  associatedCustomField_id?: string;
  fixedValue?: string;
}

export const IntegrationFilters: React.FC<any> = ({
  phase,
  selectedTable,
  filters,
  setFilters,
  id,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [customFields, setCustomFields] = useState<ICustomField[]>([]);
  const loadCustomField = useCallback(() => {
    api
      .get(`/phases/${phase}/customFields`)
      .then(response => setCustomFields(response.data));
  }, [phase]);
  const classes = useStyles();
  useEffect(() => {
    loadCustomField();
  }, [loadCustomField, filters]);

  const handleIsActive = (filter: IFilter) => {
    const updatedFilters = filters.map(
      (obj: { filterCode: string; isActive: any }) => {
        if (obj.filterCode === filter.filterCode) {
          return { ...obj, isActive: !obj.isActive };
        }
        return obj;
      },
    );
    setFilters(updatedFilters);
  };

  const handleChangeFixValue = (event: any, filterCode: string) => {
    let value = '';
    if (event) value = event.target.value;
    const updatedFilters = filters.map(
      (obj: { filterCode: string }, index: number) => {
        if (obj.filterCode === filterCode) {
          clearSelectedField(index);
          return {
            ...obj,
            fixedValue: value,
          };
        }
        return obj;
      },
    );
    setFilters(updatedFilters);
  };
  const clearSelectedField = (index: number) => {
    setFilters((prevItems: any) => {
      const newItems = [...prevItems];
      newItems[index].associatedCustomField_id = '';
      return newItems;
    });
  };
  const handleChangeSeletedFields = (newValue: string, index: number) => {
    const chosen = customFields.find(field => field.name === newValue);
    setFilters((prevItems: any) => {
      const newItems = [...prevItems];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newItems[index].associatedCustomField_id = chosen!.id!;
      newItems[index].fixedValue = '';
      return newItems;
    });
  };

  return (
    <>
      <Box
        style={{
          // width: '100%',
          width: id ? '80%' : undefined,
          maxWidth: '550px',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '10px',
          marginBottom: '10px',
          marginRight: '30px',
          justifyContent: 'space-between',
        }}
      >
        <Typography>Editar Filtros</Typography>
        {isOpen ? (
          <ArrowDropUp onClick={() => setIsOpen(!isOpen)} />
        ) : (
          <ArrowDropDown onClick={() => setIsOpen(!isOpen)} />
        )}
      </Box>
      <Divider
        style={{
          width: id ? '80%' : undefined,
          maxWidth: '550px',
        }}
      />
      {isOpen ? (
        <Box marginTop="10px">
          <TableContainer style={{ maxWidth: '100%' }} component={Paper}>
            <Table size="small" aria-label="lista de campos">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '10%', overflow: 'hidden' }}>
                    Ativo
                  </TableCell>
                  <TableCell style={{ width: '30%', overflow: 'hidden' }}>
                    Filtro ERP
                  </TableCell>
                  <TableCell style={{ width: '40%', overflow: 'hidden' }}>
                    Campo BPM
                  </TableCell>
                  <TableCell style={{ width: '20%', overflow: 'hidden' }}>
                    Valor fixo
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filters.map((filter: IFilter, index: number) => (
                  <TableRow key={filter.filterCode}>
                    <TableCell
                      style={{ width: '10%', overflow: 'hidden' }}
                      component="th"
                      scope="row"
                    >
                      <Checkbox
                        name="isInactive"
                        onChange={() => handleIsActive(filter)}
                        checked={filter.isActive}
                      />
                    </TableCell>
                    <TableCell
                      style={{ width: '30%', overflow: 'hidden' }}
                      component="th"
                      scope="row"
                    >
                      <Tooltip
                        className={classes.tooltip}
                        title={filter.filterCode}
                        arrow
                      >
                        <span>{filter.filterCode}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      style={{ width: '40%', overflow: 'hidden' }}
                      component="th"
                      scope="row"
                    >
                      <Tooltip
                        className={classes.tooltip}
                        title={
                          customFields.find(
                            field =>
                              field.id ===
                              filters[index].associatedCustomField_id,
                          )?.name || ''
                        }
                        arrow
                      >
                        <Autocomplete
                          size="small"
                          key={filters[index].associatedCustomField_id}
                          disabled={!filter.isActive}
                          options={customFields
                            .filter(
                              field =>
                                (!field.table ||
                                  field.table?.id === selectedTable?.id) &&
                                field.type !== 'PREDEFINED_STRINGS' &&
                                field.type !== 'TABLE' &&
                                field.type !== 'DOCUMENT' &&
                                field.type !== 'FILE' &&
                                field.id !== id,
                            )
                            .map(field => field.name)}
                          value={
                            customFields.find(
                              element =>
                                element.id ===
                                filters[index].associatedCustomField_id,
                            )?.name || null
                          }
                          onChange={(event, newValue) => {
                            if (newValue) {
                              handleChangeSeletedFields(newValue, index);
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      className={classes.overflowCell}
                      style={{ width: '20%', overflow: 'hidden' }}
                    >
                      <Tooltip
                        className={classes.tooltip}
                        title={filter.fixedValue ? filter.fixedValue : ''}
                      >
                        <TextField
                          disabled={!filter.isActive}
                          value={filter.fixedValue || ''}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 10,
                          }}
                          onChange={event =>
                            handleChangeFixValue(event, filter.filterCode)
                          }
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

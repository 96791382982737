/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable camelcase */
import {
  Box,
  Button as ButtonMaterial,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'src/context/AuthContext';
import { CustomPaperComponent } from '../../components/AutoCompleteCustomComponents/CustomPaperComponent';
import { CustomRenderCheckboxOption } from '../../components/AutoCompleteCustomComponents/CustomRenderCheckboxOption';
import { CustomRenderInputTags } from '../../components/AutoCompleteCustomComponents/CustomRenderInputTags';
import {
  ListboxComponent,
  renderGroup,
  VirtualizationPopper,
} from '../../components/AutoCompleteVirtualization';
import Button from '../../components/Button';
import { InputNumberMask } from '../../components/InputNumberMask';
import { ModalIntegratedCustomFields } from '../../components/ModalIntegratedCustomFields';
import { useLoader } from '../../context/LoaderContext';
import api from '../../services/api';
import { IntegratedSearchInput } from './components/IntegratedSearchInput';
import { ResultsTable } from './ResultsTable';
import {
  IAccount,
  ICardFilterRepository,
  IContact,
  ICustomField,
  ICustomFieldFormatted,
  IModalData,
  IObject,
  IPhase,
  ITask,
  ITaskFormatted,
} from './Search.i';
import { styles } from './styles';
import { Card } from '../Card';
import { TimestampRangeInput } from './components/TimestampRangeInput';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const Search: React.FC = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const userCantSearch = user.permissions.SEARCH !== 'READ_AND_WRITE';
  const { loading, showLoading, hideLoading } = useLoader();

  const [showTasks, setShowTasks] = useState(false);
  const [showCustomFields, setShowCustomFields] = useState(false);
  const [showOthers, setShowOthers] = useState(false);

  const handleOpenIntegratedItemsModal = (
    customField: ICustomField,
    customFieldIndex: number,
  ) => {
    const newIntegratedModalData = {
      customField,
      customFieldIndex,
    };
    setIntegratedModalData(newIntegratedModalData);
    setOpenIntegratedItemsModal(true);
  };

  const toggleTasks = () => {
    setShowTasks(!showTasks);
  };

  const toggleCustomFields = () => {
    setShowCustomFields(!showCustomFields);
  };

  const toggleOthers = () => {
    setShowOthers(!showOthers);
  };

  const [integratedModalData, setIntegratedModalData] = useState<IModalData>();
  const [openCardModal, setOpenCardModal] = useState(false);
  const [openIntegratedItemsModal, setOpenIntegratedItemsModal] =
    useState(false);
  const [titleCard, setTitleCard] = useState('');
  const [titleLeftCard, setTitleLeftCard] = useState('');
  const [titleRightCard, setTitleRightCard] = useState('');
  const [cardIdSelected, setCardIdSelected] = useState('');

  const [cards, setCards] = useState<ICardFilterRepository[]>([]);
  const [users, setUsers] = useState<IObject[]>([]);
  const [areas, setAreas] = useState<IObject[]>([]);
  const [phases, setPhases] = useState<IPhase[]>([]);
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [customFields, setCustomFields] = useState<ICustomField[]>([]);
  const [accounts, setAccounts] = useState<IObject[]>([]);
  const [contacts, setContacts] = useState<IContact[]>([]);

  const [situations] = useState<{ id: string; label: string }[]>([
    { id: 'active', label: 'Ativos' },
    { id: 'suspended', label: 'Suspensos' },
    { id: 'finished', label: 'Finalizados' },
  ]);

  const [selectedUsers, setSelectedUsers] = useState<IObject[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<IObject[]>([]);
  const [selectedSituations, setSelectedSituations] = useState<
    { id: string; label: string }[]
  >([situations[0]]);
  const [selectedPhases, setSelectedPhases] = useState<IPhase[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<IObject[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<IContact[]>([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState<
    ICustomField[]
  >([]);
  const [selectedTasks, setSelectedTasks] = useState<ITask[]>([]);

  const [filteredContacts, setFilteredContacts] = useState<IContact[]>([]);
  const [filteredPhases, setFilteredPhases] = useState<IPhase[]>([]);
  const [filteredCustomFields, setFilteredCustomFields] = useState<
    ICustomField[]
  >([]);
  const [filteredTasks, setFilteredTasks] = useState<ITask[]>([]);

  const [selectedKey, setSelectedKey] = useState('');

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingAreas, setLoadingAreas] = useState(false);
  const [loadingPhases, setLoadingPhases] = useState(false);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState(false);

  const [isPinned, setIsPinned] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleCloseModal = useCallback(() => {
    setOpenCardModal(false);
  }, []);

  const loadTasks = useCallback(async (targetPhases: IPhase[]) => {
    const response = await api.post<ITask[]>(`/cardTasks/phases`, {
      phase_ids: targetPhases.map(phase => phase.id),
    });

    const formattedTasks = response.data.map(task => {
      return {
        selectedFinish: false,
        selectedExpired: false,
        id: task.id,
        name: task.name,
        finish: false,
        expired: false,
        phase: task.phase,
      };
    });

    return formattedTasks;
  }, []);

  const loadCustomFields = useCallback(async (targetPhases: IObject[]) => {
    const response = await api.post<ICustomField[]>(`/customFields/phases`, {
      phase_ids: targetPhases.map(phase => phase.id),
    });

    const formattedCustomFields = response.data
      .filter(customField => customField.type !== 'TABLE')
      .map(customField => {
        return {
          id: customField.id,
          name: customField.table
            ? `${customField.table.name} > ${customField.name}`
            : customField.name,
          type: customField.type,
          value: '',
          valueInitial: '',
          valueFinal: '',
          valueBool: null,
          valueJSON: customField.type === 'PREDEFINED_STRINGS' ? [] : {},
          format: customField.format || {},
          primaryKeyColumnName: customField.primaryKeyColumnName || '',
          displayColumnName: customField.displayColumnName || '',
          isRequired: customField.isRequired || false,
          table: customField.table,
          phase: customField.phase,
          predefinedValues: customField.predefinedValues || [],
          dateType: customField.dateType || '',
        };
      });

    return formattedCustomFields;
  }, []);

  const loadUsers = useCallback(async () => {
    setLoadingUsers(true);

    const usersResult = await api.get('/users');
    setLoadingUsers(false);
    return usersResult.data;
  }, []);

  const loadAreas = useCallback(async () => {
    setLoadingAreas(true);

    const areasResult = await api.get('/areas');

    setLoadingAreas(false);
    return areasResult.data;
  }, []);

  const loadPhases = useCallback(async (targetAreas: IObject[]) => {
    setLoadingPhases(true);
    const phasesResult = await api.post('/phases/areas', {
      area_ids: targetAreas.map(area => area.id),
      listInactive: true,
    });
    setLoadingPhases(false);
    return phasesResult.data;
  }, []);

  const loadAccounts = useCallback(async () => {
    setLoadingAccounts(true);
    const accountsResult = await api.get('/accounts');
    setLoadingAccounts(false);
    return accountsResult.data;
  }, []);

  const loadContacts = useCallback(async (targetAccounts: IObject[]) => {
    setLoadingContacts(true);

    const contactsResult = await api.post('/accounts/contacts', {
      account_ids: targetAccounts.map(account => account.id),
    });

    setLoadingContacts(false);
    return contactsResult.data;
  }, []);

  useEffect(() => {
    const loadUsersAreasAndPhases = async () => {
      const loadedUsers = await loadUsers();
      const loadedAreas = await loadAreas();
      const loadedPhases = await loadPhases(loadedAreas);

      const loadedTasks = await loadTasks(loadedPhases);
      const loadedCustomFields = await loadCustomFields(loadedPhases);
      setUsers(loadedUsers);
      setAreas(loadedAreas);
      setPhases(loadedPhases);
      setTasks(loadedTasks);
      setCustomFields(loadedCustomFields);
    };

    const loadAccountsAndContacts = async () => {
      const loadedAccounts = await loadAccounts();
      const loadedContacts = await loadContacts(loadedAccounts);

      setAccounts(loadedAccounts);
      setContacts(loadedContacts);
    };

    loadUsersAreasAndPhases();
    loadAccountsAndContacts();
  }, []);

  useEffect(() => {
    if (selectedAccounts.length > 0) {
      const newFilteredContacts = contacts.filter(
        contact =>
          selectedAccounts.findIndex(
            account => account.id === contact.account.id,
          ) !== -1,
      );
      setFilteredContacts(newFilteredContacts);
    } else {
      setFilteredContacts([]);
    }
  }, [selectedAccounts]);

  useEffect(() => {
    if (selectedAreas.length > 0) {
      const newFilteredPhases = phases.filter(
        phase =>
          selectedAreas.findIndex(area => area.id === phase.area.id) !== -1,
      );
      setFilteredPhases(newFilteredPhases);
    } else {
      setFilteredPhases([]);
    }
  }, [selectedAreas]);

  useEffect(() => {
    if (selectedPhases.length > 0) {
      const newFilteredCustomFields = customFields.filter(
        customField =>
          selectedPhases.findIndex(
            phase => phase.id === customField.phase.id,
          ) !== -1 &&
          customField.type !== 'FILE' &&
          customField.type !== 'DOCUMENT',
      );
      const newFilteredTasks = tasks.filter(
        task =>
          selectedPhases.findIndex(phase => phase.id === task.phase.id) !== -1,
      );

      setFilteredCustomFields(newFilteredCustomFields);
      setFilteredTasks(newFilteredTasks);
    } else {
      setFilteredCustomFields([]);
      setFilteredTasks([]);
    }
  }, [selectedPhases]);

  const handleSubmit = () => {
    if (loading) return;

    showLoading();

    const formattedUsers = selectedUsers.map(user => user.id);
    const formattedAreas = selectedAreas.map(area => area.id);
    const formattedPhases = selectedPhases.map(phase => phase.id);
    const formattedAccounts = selectedAccounts.map(account => account.id);
    const formattedContacts = selectedContacts.map(contact => contact.id);
    const formattedSituations = selectedSituations.map(
      situation => situation.id,
    );

    const formattedTasks: ITaskFormatted[] = filteredTasks.reduce(
      (
        filtered: Array<{ id: string; finally: boolean; expired: boolean }>,
        task,
      ) => {
        if (task.selectedFinish || task.selectedExpired) {
          filtered.push({
            id: task.id,
            finally: task.finish,
            expired: task.expired,
          });
        }
        return filtered;
      },
      [],
    );

    const formattedCustomFields: ICustomFieldFormatted[] =
      filteredCustomFields.reduce((filtered: ICustomFieldFormatted[], cf) => {
        if (
          cf.value ||
          cf.valueInitial ||
          cf.valueFinal ||
          cf.valueBool !== null ||
          (cf.type === 'INTEGRATED' && Object.keys(cf.valueJSON).length > 0) ||
          (cf.type === 'PREDEFINED_STRINGS' &&
            (cf.valueJSON as unknown as string[]).length > 0)
        ) {
          let newValueBool = cf.valueBool;
          if (newValueBool === null) newValueBool = false;

          filtered.push({
            id: cf.id,
            value: cf.value,
            valueInitial: cf.valueInitial,
            valueFinal: cf.valueFinal,
            valueBool: newValueBool,
            valueJSON: cf.valueJSON,
          });
        }
        return filtered;
      }, []);

    setCards([]);
    api
      .post(`/cards/search`, {
        key: selectedKey || undefined,
        users: formattedUsers,
        areas: formattedAreas,
        phases: formattedPhases,
        tasks: formattedTasks,
        customFields: formattedCustomFields,
        accounts: formattedAccounts,
        contacts: formattedContacts,
        situations: formattedSituations,
        isPinned: isPinned || undefined,
        isHighlighted: isHighlighted || undefined,
      })
      .then(response => {
        setCards(response.data);
        const resultsTableElement = document.querySelector('#resultsTable');
        if (resultsTableElement && response.data.length > 0)
          resultsTableElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        hideLoading();
      })
      .catch(() => hideLoading());
  };

  const handleSelectTask = (task: ITask, checked: boolean, filter: string) => {
    const taskIndex = filteredTasks.findIndex(f => f.id === task.id);

    if (taskIndex !== -1) {
      const newSelectedTask = { ...filteredTasks[taskIndex] };

      if (filter === 'finish') {
        newSelectedTask.selectedFinish =
          checked !== task.finish && task.selectedFinish
            ? true
            : !task.selectedFinish;
        newSelectedTask.finish = checked;
      }

      if (filter === 'expired') {
        newSelectedTask.selectedExpired =
          checked !== task.expired && task.selectedExpired
            ? true
            : !task.selectedExpired;
        newSelectedTask.expired = checked;
      }

      const newFilteredTasks = [...filteredTasks];
      newFilteredTasks[taskIndex] = newSelectedTask;

      setFilteredTasks(newFilteredTasks);
    }
  };

  const handleSetCustomField = (
    cf: ICustomField,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    isInitial = false,
  ) => {
    const cfIndex = filteredCustomFields.findIndex(f => f.id === cf.id);

    if (cfIndex !== -1) {
      const newCf = { ...filteredCustomFields[cfIndex] };

      switch (cf.type) {
        case 'BOOL':
          newCf.valueBool = value;
          break;
        case 'INTEGRATED':
        case 'PREDEFINED_STRINGS':
          newCf.valueJSON = value;
          break;
        case 'STRING':
          newCf.value = value;
          break;

        case 'NUMERIC':
        case 'TIMESTAMP':
          if (isInitial) {
            newCf.valueInitial = value;
          } else {
            newCf.valueFinal = value;
          }
          break;
        default:
          break;
      }

      const newFilteredCfs = [...filteredCustomFields];
      newFilteredCfs[cfIndex] = newCf;

      setFilteredCustomFields(newFilteredCfs);
    }
  };

  function handleChange(
    value: IAccount[] | IContact[],
    type: 'IAccount' | 'IContact',
  ) {
    if (value && type === 'IAccount') {
      setSelectedAccounts(value as IAccount[]);
      setSelectedContacts([]);
    }
    if (value && type === 'IContact') {
      setSelectedContacts(value as IContact[]);
    }
  }

  function resetInputs() {
    setSelectedUsers([]);
    setSelectedAreas([]);
    setSelectedSituations([situations[0]]);
    setSelectedAccounts([]);
    setSelectedContacts([]);
    setSelectedPhases([]);
    setSelectedKey('');
    setIsPinned(false);
    setIsHighlighted(false);
    setCards([]);
  }

  function handleChangeCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === 'isPinned') {
      setIsPinned(event.target.checked);
    } else {
      setIsHighlighted(event.target.checked);
    }
  }

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        <Box className={classes.boxFilters} component={Paper}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.title} noWrap>
              Pesquisa
            </Typography>
            <TextField
              id="key"
              name="key"
              label="Chave"
              margin="none"
              className={classes.fieldFilter}
              value={selectedKey}
              onChange={e => {
                setSelectedKey(e.target.value);
              }}
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="user"
                getOptionLabel={user => user.name}
                getOptionSelected={(user, value) => user.id === value.id}
                options={users}
                value={selectedUsers}
                loading={loadingUsers}
                onChange={(_, value) => {
                  setSelectedUsers(value);
                }}
                loadingText="Carregando"
                fullWidth
                multiple
                limitTags={1}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkBox}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                )}
                renderTags={(tagValue, getTagProps) => {
                  return (
                    <CustomRenderInputTags
                      tagValue={tagValue}
                      getTagProps={getTagProps}
                      size="medium"
                    />
                  );
                }}
                renderInput={rest => (
                  <TextField
                    {...rest}
                    id="user"
                    label="Usuários"
                    margin="normal"
                    InputProps={{
                      ...rest.InputProps,
                      style: {
                        display: 'flex',
                        flexWrap: 'nowrap',
                      },
                      endAdornment: (
                        <>
                          {loadingUsers ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {rest.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="area"
                getOptionLabel={area => area.name}
                getOptionSelected={(area, value) => area.id === value.id}
                options={areas}
                value={selectedAreas}
                loading={loadingAreas}
                onChange={(_, value) => {
                  setSelectedPhases([]);
                  setSelectedCustomFields([]);
                  setSelectedTasks([]);
                  setSelectedAreas(value);
                }}
                loadingText="Carregando"
                fullWidth
                multiple
                limitTags={1}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkBox}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                )}
                renderTags={(tagValue, getTagProps) => {
                  return (
                    <CustomRenderInputTags
                      tagValue={tagValue}
                      getTagProps={getTagProps}
                      size="medium"
                    />
                  );
                }}
                renderInput={rest => (
                  <TextField
                    {...rest}
                    id="area"
                    label="Áreas"
                    margin="normal"
                    InputProps={{
                      ...rest.InputProps,
                      style: {
                        display: 'flex',
                        flexWrap: 'nowrap',
                      },
                      endAdornment: (
                        <>
                          {loadingAreas ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {rest.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="situations"
                getOptionLabel={situation => situation.label}
                getOptionSelected={(situation, value) =>
                  situation.id === value.id
                }
                value={selectedSituations}
                onChange={(_, value) => {
                  setSelectedSituations(value);
                }}
                options={situations}
                loadingText="Carregando"
                fullWidth
                multiple
                limitTags={1}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkBox}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </>
                )}
                renderTags={(tagValue, getTagProps) => {
                  return (
                    <CustomRenderInputTags
                      tagValue={tagValue}
                      getTagProps={getTagProps}
                      size="medium"
                    />
                  );
                }}
                renderInput={rest => (
                  <TextField
                    {...rest}
                    id="situations"
                    label="Situação"
                    margin="normal"
                    InputProps={{
                      ...rest.InputProps,
                      style: {
                        display: 'flex',
                        flexWrap: 'nowrap',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="account"
                className={classes.listbox}
                getOptionLabel={account => account.name}
                getOptionSelected={(account, value) => account.id === value.id}
                options={accounts}
                value={selectedAccounts || []}
                loading={loadingAccounts}
                onChange={(_, value) => {
                  setSelectedContacts([]);
                  setSelectedAccounts(value);
                }}
                loadingText="Carregando"
                fullWidth
                multiple
                disableCloseOnSelect
                PopperComponent={VirtualizationPopper}
                PaperComponent={props => {
                  return (
                    <CustomPaperComponent
                      paperProps={props}
                      type="IAccount"
                      optionsValues={accounts}
                      selectedValues={selectedAccounts}
                      handleChange={() =>
                        handleChange(selectedAccounts, 'IAccount')
                      }
                    />
                  );
                }}
                disableListWrap
                ListboxComponent={
                  ListboxComponent as React.ComponentType<
                    React.HTMLAttributes<HTMLElement>
                  >
                }
                renderGroup={renderGroup}
                groupBy={option => option.name[0].toUpperCase()}
                renderOption={(option, { selected }) => (
                  <CustomRenderCheckboxOption
                    option={option.name}
                    selected={selected}
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  return (
                    <CustomRenderInputTags
                      tagValue={tagValue}
                      getTagProps={getTagProps}
                      size="medium"
                    />
                  );
                }}
                renderInput={rest => (
                  <TextField
                    {...rest}
                    id="account"
                    label="Contas"
                    margin="normal"
                    InputProps={{
                      ...rest.InputProps,
                      style: {
                        display: 'flex',
                        flexWrap: 'nowrap',
                      },
                      endAdornment: (
                        <>
                          {loadingAccounts ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {rest.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="contact"
                getOptionLabel={contact => contact.name}
                getOptionSelected={(con, value) => con.id === value.id}
                options={
                  selectedAccounts.length > 0 ? filteredContacts : contacts
                }
                value={selectedContacts}
                loading={loadingContacts}
                onChange={(_, value) => {
                  setSelectedContacts(value);
                }}
                loadingText="Carregando"
                fullWidth
                multiple
                disableCloseOnSelect
                PopperComponent={VirtualizationPopper}
                PaperComponent={props => {
                  return (
                    <CustomPaperComponent
                      paperProps={props}
                      type="IContact"
                      optionsValues={
                        selectedAccounts.length > 0
                          ? filteredContacts
                          : contacts
                      }
                      selectedValues={selectedContacts}
                      handleChange={() =>
                        handleChange(selectedContacts, 'IContact')
                      }
                    />
                  );
                }}
                disableListWrap
                ListboxComponent={
                  ListboxComponent as React.ComponentType<
                    React.HTMLAttributes<HTMLElement>
                  >
                }
                renderOption={(option, { selected }) => (
                  <CustomRenderCheckboxOption
                    option={option.name}
                    selected={selected}
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  return (
                    <CustomRenderInputTags
                      tagValue={tagValue}
                      getTagProps={getTagProps}
                      size="medium"
                    />
                  );
                }}
                renderInput={rest => (
                  <TextField
                    {...rest}
                    id="contact"
                    label="Contatos"
                    margin="normal"
                    InputProps={{
                      ...rest.InputProps,
                      style: {
                        display: 'flex',
                        flexWrap: 'nowrap',
                      },
                      endAdornment: (
                        <>
                          {loadingContacts ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {rest.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="phase"
                getOptionLabel={phase => phase.name}
                getOptionSelected={(phase, value) => phase.id === value.id}
                options={selectedAreas.length > 0 ? filteredPhases : phases}
                value={selectedPhases}
                loading={loadingPhases}
                onChange={(_, value) => {
                  setSelectedPhases(value);
                }}
                loadingText="Carregando"
                fullWidth
                multiple
                limitTags={1}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkBox}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                )}
                renderTags={(tagValue, getTagProps) => {
                  return (
                    <CustomRenderInputTags
                      tagValue={tagValue}
                      getTagProps={getTagProps}
                      size="medium"
                    />
                  );
                }}
                renderInput={rest => (
                  <TextField
                    {...rest}
                    id="phase"
                    label="Fases"
                    margin="normal"
                    InputProps={{
                      ...rest.InputProps,
                      style: {
                        display: 'flex',
                        flexWrap: 'nowrap',
                      },
                      endAdornment: (
                        <>
                          {loadingPhases ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {rest.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            width="100%"
            marginTop="1rem"
            flexDirection="column"
          >
            <Box width="100%">
              <Box className={classes.expandSearchBox}>
                <Typography
                  onClick={() => toggleTasks()}
                  className={classes.expandSearchText}
                >
                  Tarefas
                  {showTasks ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Typography>
              </Box>
              {showTasks && (
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table
                    className={classes.table}
                    aria-label="filtro tarefas"
                    size="small"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          Tarefa
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={2}
                          className={classes.tableCell}
                        >
                          Situação
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={2}
                          className={classes.tableCell}
                        >
                          Prazo
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredTasks.map(task => (
                        <TableRow key={task.id}>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            <Box
                              maxWidth="250px"
                              display="flex"
                              alignItems="center"
                              justifyContent="start"
                            >
                              <Typography
                                component="span"
                                className={classes.fieldFilter}
                                noWrap
                              >
                                {task.name}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            <Box
                              maxWidth="250px"
                              display="flex"
                              alignItems="center"
                              justifyContent="start"
                            >
                              <Checkbox
                                className={classes.checkBox}
                                checked={
                                  task.selectedFinish === true && task.finish
                                }
                                onClick={() =>
                                  handleSelectTask(task, true, 'finish')
                                }
                                inputProps={{
                                  'aria-labelledby': task.name,
                                }}
                              />

                              <Typography
                                component="span"
                                className={classes.fieldFilter}
                                noWrap
                              >
                                Concluída
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            <Box
                              maxWidth="250px"
                              display="flex"
                              alignItems="center"
                              justifyContent="start"
                            >
                              <Checkbox
                                className={classes.checkBox}
                                checked={
                                  task.selectedFinish === true && !task.finish
                                }
                                onClick={() =>
                                  handleSelectTask(task, false, 'finish')
                                }
                                inputProps={{
                                  'aria-labelledby': task.name,
                                }}
                              />

                              <Typography
                                component="span"
                                className={classes.fieldFilter}
                                noWrap
                              >
                                Não Concluída
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            <Box
                              maxWidth="250px"
                              display="flex"
                              alignItems="center"
                              justifyContent="start"
                            >
                              <Checkbox
                                className={classes.checkBox}
                                checked={
                                  task.selectedExpired === true && task.expired
                                }
                                onClick={() =>
                                  handleSelectTask(task, true, 'expired')
                                }
                                inputProps={{
                                  'aria-labelledby': task.name,
                                }}
                              />

                              <Typography
                                component="span"
                                className={classes.fieldFilter}
                                noWrap
                              >
                                Expirado
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            <Box
                              maxWidth="250px"
                              display="flex"
                              alignItems="center"
                              justifyContent="start"
                            >
                              <Checkbox
                                className={classes.checkBox}
                                checked={
                                  task.selectedExpired === true && !task.expired
                                }
                                onClick={() =>
                                  handleSelectTask(task, false, 'expired')
                                }
                                inputProps={{
                                  'aria-labelledby': task.name,
                                }}
                              />

                              <Typography
                                component="span"
                                className={classes.fieldFilter}
                                noWrap
                              >
                                Não Expirado
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
            <Divider style={{ marginTop: '0.5rem' }} />
            <Box width="100%" mt="0.5rem">
              <Box className={classes.expandSearchBox}>
                <Typography
                  onClick={() => toggleCustomFields()}
                  className={classes.expandSearchText}
                >
                  Dados
                  {showCustomFields ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </Typography>
              </Box>
              {showCustomFields && (
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table
                    className={classes.table}
                    aria-label="Filtro dos campos personalizados"
                    size="small"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Campo</TableCell>
                        <TableCell>Valor</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredCustomFields.map((customField, index) => (
                        <TableRow key={customField.id}>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCellFieldName}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="start"
                            >
                              <Typography
                                className={classes.fieldFilter}
                                noWrap
                              >
                                {customField.name}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCellFieldContent}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="start"
                            >
                              {customField.type === 'STRING' && (
                                <TextField
                                  id={customField.name}
                                  name={customField.name}
                                  margin="none"
                                  className={classes.stringInput}
                                  value={customField.value}
                                  fullWidth
                                  onChange={e => {
                                    handleSetCustomField(
                                      customField,
                                      e.target.value,
                                    );
                                  }}
                                />
                              )}
                              {customField.type === 'NUMERIC' && (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <TextField
                                    id={customField.name}
                                    name={customField.name}
                                    className={classes.fieldFilter}
                                    value={customField.valueInitial}
                                    label="De"
                                    margin="none"
                                    fullWidth
                                    onChange={e => {
                                      handleSetCustomField(
                                        customField,
                                        e.target.value,
                                        true,
                                      );
                                    }}
                                    InputProps={{
                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      inputComponent: InputNumberMask as any,
                                    }}
                                  />
                                  <Box width="2rem" />
                                  <TextField
                                    id={customField.name}
                                    name={customField.name}
                                    className={classes.fieldFilter}
                                    value={customField.valueFinal}
                                    label="Até"
                                    margin="none"
                                    fullWidth
                                    onChange={e => {
                                      handleSetCustomField(
                                        customField,
                                        e.target.value,
                                        false,
                                      );
                                    }}
                                    InputProps={{
                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      inputComponent: InputNumberMask as any,
                                    }}
                                  />
                                </Box>
                              )}
                              {customField.type === 'BOOL' && (
                                <>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="start"
                                  >
                                    <>
                                      <Checkbox
                                        className={classes.checkBox}
                                        checked={
                                          customField.valueBool !== null &&
                                          customField.valueBool === true
                                        }
                                        onChange={() => {
                                          if (customField.valueBool === true) {
                                            handleSetCustomField(
                                              customField,
                                              null,
                                            );
                                          } else {
                                            handleSetCustomField(
                                              customField,
                                              true,
                                            );
                                          }
                                        }}
                                        inputProps={{
                                          'aria-labelledby': customField.name,
                                        }}
                                      />
                                      <Typography
                                        className={classes.booleanFieldText}
                                        noWrap
                                      >
                                        Sim
                                      </Typography>
                                    </>
                                    <>
                                      <Checkbox
                                        className={classes.checkBox}
                                        checked={
                                          customField.valueBool !== null &&
                                          customField.valueBool === false
                                        }
                                        onChange={() => {
                                          if (customField.valueBool === false) {
                                            handleSetCustomField(
                                              customField,
                                              null,
                                            );
                                          } else {
                                            handleSetCustomField(
                                              customField,
                                              false,
                                            );
                                          }
                                        }}
                                        inputProps={{
                                          'aria-labelledby': customField.name,
                                        }}
                                      />
                                      <Typography
                                        className={classes.booleanFieldText}
                                        noWrap
                                      >
                                        Não
                                      </Typography>
                                    </>
                                  </Box>
                                </>
                              )}
                              {customField.type === 'INTEGRATED' && (
                                <IntegratedSearchInput
                                  customField={customField}
                                  customFieldIndex={index}
                                  handleOpenIntegratedItemsModal={
                                    handleOpenIntegratedItemsModal
                                  }
                                  handleSetCustomField={handleSetCustomField}
                                />
                              )}
                              {customField.type === 'TIMESTAMP' && (
                                <TimestampRangeInput
                                  customField={customField}
                                  handleSetCustomField={handleSetCustomField}
                                />
                              )}
                              {customField.type === 'PREDEFINED_STRINGS' &&
                                customField.predefinedValues !== undefined && (
                                  <Autocomplete
                                    multiple
                                    className={classes.predefinedInput}
                                    disableCloseOnSelect
                                    limitTags={1}
                                    options={
                                      customField.predefinedValues as string[]
                                    }
                                    fullWidth
                                    value={
                                      customField.valueJSON as unknown as string[]
                                    }
                                    getOptionLabel={(predefinedValue: string) =>
                                      predefinedValue
                                    }
                                    getOptionSelected={(
                                      predefinedValues,
                                      value,
                                    ) => predefinedValues === value}
                                    onChange={(_, value) => {
                                      handleSetCustomField(customField, value);
                                    }}
                                    renderOption={(option, { selected }) => (
                                      <>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          className={
                                            classes.predefinedOptionsCheckBox
                                          }
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option}
                                      </>
                                    )}
                                    loadingText="Carregando"
                                    renderTags={(
                                      value: string[],
                                      getTagProps,
                                    ) => {
                                      const numTags = value.length;
                                      const limitTags = 5;

                                      return (
                                        <>
                                          {value
                                            .slice(0, limitTags)
                                            // eslint-disable-next-line no-shadow
                                            .map(
                                              (
                                                option: string,
                                                index: number,
                                              ) => (
                                                <Chip
                                                  {...getTagProps({ index })}
                                                  // eslint-disable-next-line react/no-array-index-key
                                                  key={option}
                                                  label={option}
                                                  size="small"
                                                />
                                              ),
                                            )}

                                          {numTags > limitTags &&
                                            ` +${numTags - limitTags}`}
                                        </>
                                      );
                                    }}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        name={customField.name}
                                        InputProps={{
                                          ...params.InputProps,
                                          readOnly: true,
                                          disableUnderline: true,
                                          margin: 'none',
                                          style: {
                                            display: 'flex',
                                            flexWrap: 'nowrap',
                                            padding: 0,
                                            margin: 0,
                                            fontSize: '14px',
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
            <Divider style={{ marginTop: '0.5rem' }} />
            <Box width="100%" mt="0.5rem">
              <Box className={classes.expandSearchBox}>
                <Typography
                  onClick={() => toggleOthers()}
                  className={classes.expandSearchText}
                >
                  Outros
                  {showOthers ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Typography>
              </Box>
              {showOthers && (
                <Box className={classes.checkBoxesDiv}>
                  <FormControlLabel
                    // eslint-disable-next-line prettier/prettier
                    control={
                      <Checkbox
                        id="isHighlighted"
                        checked={isHighlighted}
                        onChange={handleChangeCheckbox}
                        name="isHighlighted"
                      />
                    }
                    label="Destaque"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="isPinned"
                        checked={isPinned}
                        onChange={handleChangeCheckbox}
                        name="isPinned"
                      />
                    }
                    label="Fixo"
                    labelPlacement="end"
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <ButtonMaterial
            style={{
              marginTop: 15,
              flexWrap: 'nowrap',
              fontSize: '14px',
              height: '40px',
              padding: 15,
            }}
            type="reset"
            variant="contained"
            onClick={resetInputs}
          >
            Limpar Filtro
          </ButtonMaterial>
          <Button
            style={{
              fontSize: '14px',
              height: '40px',
              marginTop: 15,
              marginLeft: 10,
              padding: 15,
            }}
            type="submit"
            disabled={
              userCantSearch ||
              loading ||
              loadingUsers ||
              loadingPhases ||
              loadingAreas ||
              loadingAccounts ||
              loadingContacts
            }
            onClick={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            Pesquisar
          </Button>
        </Box>
        <Box className={classes.boxTable} id="resultsTable">
          <ResultsTable
            cards={cards}
            setOpenModal={setOpenCardModal}
            setTitleRightCard={setTitleRightCard}
            setCardIdSelected={setCardIdSelected}
            setTitleCard={setTitleCard}
            setTitleLeftCard={setTitleLeftCard}
          />
        </Box>
      </Box>
      {openCardModal && (
        <Card
          cardId={cardIdSelected}
          openModal={openCardModal}
          handleCloseModal={handleCloseModal}
        />
      )}
      {openIntegratedItemsModal && integratedModalData && (
        <ModalIntegratedCustomFields
          customField_id={integratedModalData.customField.id}
          customField_name={integratedModalData.customField.name}
          openModal={openIntegratedItemsModal}
          format={integratedModalData.customField.format}
          dynamicFormat={integratedModalData.customField.dynamicFormat || null}
          primaryKeyColumnName={
            integratedModalData.customField.primaryKeyColumnName
          }
          handleCloseModal={() => setOpenIntegratedItemsModal(false)}
          onSelectedIntegratedItem={(
            value: Record<string, unknown> | undefined,
          ) => {
            if (value) {
              filteredCustomFields[
                integratedModalData.customFieldIndex
              ].valueJSON = value;
              setFilteredCustomFields([...filteredCustomFields]);
            }
            setOpenIntegratedItemsModal(false);
          }}
        />
      )}
    </Container>
  );
};

export default Search;

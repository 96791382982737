import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogOverlay,
} from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import ReactMarkdown from 'react-markdown';
import { useResponseChat } from '../hooks/useResponseChat';
import Table from './Table';
import LoadingMessage from './LoadingMessage';
import ConfirmDialogIA from './ConfirmDialogIA';
import { VisionIADialogProps } from '../Visions.i';
import '../styles/VisionAIDialogStyles.css';

export const VisionAIDialog: React.FC<VisionIADialogProps> = ({
  open,
  handleCloseDialog,
  vision_id,
  visionName,
}) => {
  const { combinedContent, loading, exportContentToPDF } =
    useResponseChat(vision_id);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogType, setConfirmDialogType] = useState<
    'closeAttempt' | 'downloadPDF' | null
  >(null);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [pdfDownloaded, setPdfDownloaded] = useState(false);
  const [autoStayOpen, setAutoStayOpen] = useState(false);

  useEffect(() => {
    if (!loading && showConfirmDialog && confirmDialogType === 'closeAttempt') {
      setShowConfirmDialog(false);
    }
  }, [loading, showConfirmDialog, confirmDialogType]);

  const handleCloseAttempt = () => {
    if (loading && !autoStayOpen) {
      setConfirmDialogType('closeAttempt');
      setConfirmMessage(
        'A integração com a IA foi executada e não será possível cancelar o token gerado',
      );
      setShowConfirmDialog(true);
    } else if (!pdfDownloaded) {
      setConfirmDialogType('downloadPDF');
      setConfirmMessage('Deseja baixar o PDF?');
      setShowConfirmDialog(true);
    } else {
      handleCloseDialog();
    }
  };

  const handleConfirmClose = () => {
    setShowConfirmDialog(false);
    handleCloseDialog();
  };

  const handleDownloadAndClose = () => {
    exportContentToPDF();
    setPdfDownloaded(true);
    setShowConfirmDialog(false);
    handleCloseDialog();
  };

  const handleCancelClose = () => {
    setShowConfirmDialog(false);
    if (loading) {
      setAutoStayOpen(true);
    }
  };

  const handleExportToPDF = () => {
    exportContentToPDF();
    setPdfDownloaded(true);
  };

  return (
    <>
      <Dialog
        open={open}
        onOpenChange={isOpen => {
          if (!isOpen) {
            handleCloseAttempt();
          }
        }}
      >
        <DialogOverlay className="z-[1400]" />
        <DialogContent
          className="z-[1500] overflow-auto max-h-[80vh] w-full min-w-fit max-w-5xl box-border p-6"
          onInteractOutside={event => event.preventDefault()}
          onEscapeKeyDown={event => event.preventDefault()}
        >
          <DialogHeader>
            <DialogTitle className="text-3xl font-bold text-black mb-4">
              {visionName}
            </DialogTitle>
          </DialogHeader>
          <div id="pdf-content" className="m-2 space-y-6">
            {loading ? (
              <LoadingMessage />
            ) : combinedContent.length > 0 ? (
              combinedContent.map(item => (
                <div key={item.content.id || Math.random()} className="mb-4">
                  {item.type === 'text' ? (
                    <div>
                      <h2 className="text-2xl font-bold text-black">
                        <ReactMarkdown>{item.content.title}</ReactMarkdown>
                      </h2>
                      <p className="italic mb-4 ml-1 text-gray-400">
                        Análise gerada por Inteligência Artificial
                      </p>
                      <ReactMarkdown>{item.content.description}</ReactMarkdown>
                      <ReactMarkdown>{item.content.resume}</ReactMarkdown>
                      <ReactMarkdown>
                        {item.content.important_points}
                      </ReactMarkdown>
                      <ReactMarkdown>{item.content.missing_data}</ReactMarkdown>
                      <ReactMarkdown>{item.content.notes}</ReactMarkdown>
                    </div>
                  ) : (
                    <Table
                      title={item.content.title || ''}
                      columns={item.content.jsonTable.columns || []}
                      data={item.content.jsonTable.data || []}
                    />
                  )}
                </div>
              ))
            ) : (
              <DialogDescription className="text-lg text-gray-600 mb-4">
                Ops, parece que tivemos um pequeno problema ao analisar a visão.
                Tente novamente.
              </DialogDescription>
            )}
          </div>

          {!loading && combinedContent.length > 0 && (
            <div className="flex justify-end mt-4">
              <Button
                className="px-4 py-2 text-white rounded hover:bg-blue-900"
                onClick={handleExportToPDF}
              >
                Baixar em PDF
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>

      {showConfirmDialog && confirmDialogType === 'closeAttempt' && (
        <ConfirmDialogIA
          title="Aviso"
          message={confirmMessage}
          onConfirm={handleConfirmClose}
          onCancel={handleCancelClose}
          confirmText="Fechar"
          cancelText="Aguardar"
          hideCloseButton
        />
      )}

      {showConfirmDialog && confirmDialogType === 'downloadPDF' && (
        <ConfirmDialogIA
          title="Baixar PDF"
          message={confirmMessage}
          onConfirm={handleDownloadAndClose}
          onCancel={handleConfirmClose}
          confirmText="Sim"
          cancelText="Não"
          hideCloseButton
        />
      )}
    </>
  );
};

export default VisionAIDialog;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  Chip,
  Popper,
  TextField,
  TextFieldProps,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { TaskHandleCardValueProps } from 'src/components/TaskModal/TaskModal.i';
import { predefinedStyles } from './predefinedStyles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PopperMy = (props: any) => {
  return (
    <Popper
      {...props}
      style={{ maxWidth: 'fit-content' }}
      placement="bottom-start"
    />
  );
};

const useStyles = makeStyles(() => createStyles(predefinedStyles()));

type InputType = string | Array<string>;

interface AutocompleteProps {
  fieldId: string;
  fieldName: string;
  fieldValue: InputType;
  isMultipleOptions?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  margin?: string;
  fieldOptions: Array<string>;
  predefinedActiveOptions: string[];
  error?: boolean | undefined;
  helperText?: string | boolean | undefined;
  fieldSize?: 'medium' | 'small' | undefined;
  showOptionsCheckbox?: boolean;
  fullWidth?: boolean;
  valueProperty: 'value' | 'valueJSON';
  handleChangeValue?: (params: TaskHandleCardValueProps) => void;
  handleFillTableValue?: (
    id: string,
    tableRowIndex: number,
    value: any,
    valueProperty: any,
  ) => void;
  tableRowIndex?: number;
}

export const PredefinedField: React.FC<AutocompleteProps> = ({
  fieldId,
  fieldName,
  fieldValue,
  fieldOptions,
  fieldSize = 'small',
  isMultipleOptions = true,
  isDisabled = false,
  isRequired = false,
  margin = 'normal',
  error = undefined,
  helperText = undefined,
  handleChangeValue = () => {},
  handleFillTableValue = () => {},
  fullWidth = true,
  valueProperty = 'valueJSON',
  tableRowIndex,
  predefinedActiveOptions,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...rest
}: AutocompleteProps) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<InputType>([]);
  const isTableColumn = tableRowIndex !== undefined;

  useEffect(() => {
    if (fieldValue) setInputValue(fieldValue);
  }, [fieldValue]);

  const handleInputChange = (value: InputType) => {
    setInputValue(value);
    if (isTableColumn) {
      handleFillTableValue(fieldId, tableRowIndex, value, valueProperty);
    } else {
      handleChangeValue({
        fieldId,
        newValue: value,
        valueProperty,
      });
    }
  };

  const handleBlur = () => {
    // if (isMultipleOptions) handleChangeValue(fieldId, inputValue, valueProperty);
  };

  return (
    <Autocomplete
      id={fieldId}
      multiple={isMultipleOptions}
      disableCloseOnSelect={isMultipleOptions}
      disableClearable={isTableColumn}
      className={!isTableColumn ? undefined : classes.tablePredefinedInput}
      limitTags={isTableColumn ? 1 : -1}
      disabled={isDisabled}
      options={fieldOptions || []}
      getOptionLabel={option => option}
      getOptionDisabled={option => !predefinedActiveOptions.includes(option)}
      getOptionSelected={(option, value) => option === value}
      value={inputValue || []}
      onChange={(_, value) => {
        handleInputChange(value as string | string[]);
      }}
      PopperComponent={!isTableColumn ? undefined : PopperMy}
      fullWidth
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            className={classes.optionsCheckBox}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option}`}
        </>
      )}
      loadingText="Carregando"
      noOptionsText="Sem opções"
      renderTags={(value: string[], getTagProps) => {
        const numTags = value.length;
        const limitTags = isTableColumn ? 1 : numTags;

        return (
          <>
            {value.slice(0, limitTags).map((option: string, index: number) => (
              <Chip
                {...getTagProps({ index })}
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}${option}`}
                label={option}
                size="small"
              />
            ))}
            {numTags > limitTags && ` +${numTags - limitTags}`}
          </>
        );
      }}
      renderInput={(rest: JSX.IntrinsicAttributes & TextFieldProps) => (
        <TextField
          {...rest}
          autoComplete="off"
          required={isRequired}
          disabled={isDisabled}
          name={fieldId}
          label={isTableColumn ? undefined : fieldName}
          size={fieldSize}
          margin={`${margin}` as any}
          onBlur={() => handleBlur()}
          error={error}
          helperText={helperText}
          fullWidth={fullWidth}
          InputProps={{
            ...rest.InputProps,
            ...(isTableColumn && { disableUnderline: true }),
            margin: isTableColumn ? 'none' : undefined,
            style: {
              display: 'flex',
              alignItems: 'center',
              flexWrap: isTableColumn ? 'nowrap' : undefined,
              padding: isTableColumn ? 0 : undefined,
              margin: isTableColumn ? 0 : undefined,
              fontSize: isTableColumn ? '14px' : undefined,
            },
          }}
        />
      )}
    />
  );
};

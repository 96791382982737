/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  FileSpreadsheetIcon,
  MoreHorizontalIcon,
  PlusCircleIcon,
  SettingsIcon,
  SparklesIcon,
  // ChevronsUpDownIcon,
} from 'lucide-react';
import { Button } from 'src/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { Input } from 'src/components/ui/input';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { useAuth } from 'src/context/AuthContext';
import { useHistory } from 'react-router-dom';
import { DeleteAlertDialog } from 'src/components/DeleteAlertDialog';
import { Vision } from '..';
import { useVisionList } from '../hooks/useVisionList';
import { VisionAIDialog } from '../components/VisionAIDialog';
import { VisionReportDialog } from '../components/VisionReportDialog';

export const ListVisions = () => {
  const { company } = useAuth();
  const history = useHistory();
  const envAllowedCompanies = process.env?.REACT_APP_ALLOWED_COMPANY_IDS || '';
  const allowedCompanies: string[] =
    envAllowedCompanies.length > 0 ? envAllowedCompanies.split(',') : [];
  const { user } = useAuth();
  const disableDataSourceAccess =
    user.permissions.VISION_DATA_SOURCE === 'DISABLED';
  const disableExecuteReport =
    user.permissions.VISION_REPORT !== 'READ_AND_WRITE';
  const disableExecuteAnalysis =
    user.permissions.VISION_ANALYSIS !== 'READ_AND_WRITE';
  const userCantEditVision = user.permissions.VISION !== 'READ_AND_WRITE';

  if (
    allowedCompanies.length > 0 &&
    company.id &&
    !allowedCompanies.includes(company.id)
  ) {
    history.push('/workspace');
  }

  const {
    isVisionDialogOpen,
    selectedVisionId,
    visions,
    search,
    setSearch,
    deleteAlertState,
    executeVision,
    handleDeleteVision,
    handleOpenVisionDialog,
    handleOpenDeleteDialog,
    handleCloseDeletionDialog,
    handleCloseVisionDialog,
    handleFilterVisions,
    handleExecuteVision,
    handleCloseExecutionDialog,
  } = useVisionList();

  const [selectedFilter, setSelectedFilter] = useState<
    'ALL' | 'REPORT' | 'ANALYSIS'
  >('ALL');

  const filteredVisions = visions.filter(vision => {
    let matchesSearch = true;
    if (search.length > 0) {
      const formattedSearch = search.toUpperCase().trim();
      matchesSearch =
        vision.name.toUpperCase().trim().includes(formattedSearch) ||
        vision.description.toUpperCase().trim().includes(formattedSearch);
    }

    let matchesFilter = true;
    if (selectedFilter !== 'ALL') {
      matchesFilter = vision.type === selectedFilter;
    }

    return matchesSearch && matchesFilter;
  });

  const handleResetFilters = () => {
    setSelectedFilter('ALL');
    setSearch('');
  };

  return (
    <div className="w-full h-full px-6 overflow-auto">
      <div className="space-y-1 my-6">
        <div className="flex flex-row items-center rounded-md mb-2 box-border">
          <h1 className="text-2xl font-bold leading-none mr-2">Visões</h1>
          <TooltipWrapper
            value="Nova visão"
            side="right"
            className="text-sm font-medium"
          >
            <Button
              onClick={() => {
                handleOpenVisionDialog();
              }}
              className="flex gap-1 items-center w-fit h-fit p-2 shadow-lg hover:shadow-md"
              disabled={userCantEditVision}
            >
              <PlusCircleIcon size={16} />
              Criar
            </Button>
          </TooltipWrapper>
          <div className="ml-4 w-fit p-0">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  className="p-2 m-0 rounded-full w-fit h-fit"
                >
                  <span className="sr-only">Open menu</span>
                  <SettingsIcon className="h-6 w-6" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start">
                <DropdownMenuLabel>Configurações</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="hover:bg-gray-200 cursor-pointer"
                  onClick={e => {
                    e.stopPropagation();
                    history.push('/dataSource');
                  }}
                  disabled={disableDataSourceAccess}
                >
                  Mineração de Dados
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <p className="text-sm text-muted-foreground">
          Cadastre visões que serão utilizadas para análise e extração de dados
          do processo BPM
        </p>
      </div>

      <div className="w-full max-w-[1200px] p-6 bg-[#ebf4eb] flex flex-wrap gap-4 mb-4 items-center rounded-md">
        <p
          className={`cursor-pointer text-base ${
            selectedFilter === 'ALL' ? 'text-blue-500 font-bold' : ''
          } text-lg`}
          onClick={() => setSelectedFilter('ALL')}
        >
          Todos
        </p>
        <p
          className={`cursor-pointer text-base ${
            selectedFilter === 'REPORT' ? 'text-blue-500 font-bold' : ''
          } text-lg`}
          onClick={() => setSelectedFilter('REPORT')}
        >
          Listagem
        </p>
        <p
          className={`cursor-pointer ${
            selectedFilter === 'ANALYSIS' ? 'text-blue-500 font-bold' : ''
          } text-lg`}
          onClick={() => setSelectedFilter('ANALYSIS')}
        >
          Análise
        </p>
        <Input
          id="search"
          name="search"
          required
          className="border border-gray-300 rounded-md w-[250px] max-[768px]:w-full"
          placeholder="Pesquise por nome ou descrição"
          onChange={handleFilterVisions}
          value={search}
          autoComplete="off"
        />
        <Button
          variant="secondary"
          onClick={handleResetFilters}
          className="ml-auto border border-gray-300 rounded-md w-[100px] max-[768px]:w-full"
        >
          Limpar
        </Button>
      </div>

      {filteredVisions.length > 0 ? (
        <Card className="flex flex-row flex-wrap justify-start max-xl:justify-around gap-4 w-full h-fit max-w-[1200px] p-3">
          {filteredVisions.map(vision => (
            <Card
              key={vision.id}
              className="overflow-hidden h-[100px] w-[380px] max-[768px]:w-full max-lg:w-[340px] bg-BPM_BGCOLORMENU shadow-zinc-600 border px-2 pb-1 hover:shadow-zinc-500 hover:border-2 hover:border-primary"
            >
              <CardHeader className="p-0">
                <CardTitle className="flex flex-row justify-between items-center w-full">
                  <span className="text-base truncate mr-2">{vision.name}</span>
                  <div className="w-fit p-0">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="ghost"
                          className="p-2 m-0 rounded-full w-fit h-fit"
                        >
                          <span className="sr-only">Open menu</span>
                          <MoreHorizontalIcon className="h-5 w-5" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="start" className="z-10">
                        <DropdownMenuLabel>Ações</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem
                          className="hover:bg-gray-200 cursor-pointer"
                          onClick={() => {
                            handleExecuteVision(
                              vision.id as string,
                              vision.type,
                            );
                          }}
                          disabled={
                            (vision.type === 'REPORT' &&
                              disableExecuteReport) ||
                            (vision.type === 'ANALYSIS' &&
                              disableExecuteAnalysis)
                          }
                        >
                          Executar
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="hover:bg-gray-200 cursor-pointer"
                          onClick={() => {
                            handleOpenVisionDialog(vision.id);
                          }}
                          disabled={userCantEditVision}
                        >
                          Editar
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="hover:bg-gray-200 cursor-pointer"
                          onClick={() => {
                            handleOpenDeleteDialog(vision.id as string);
                          }}
                          disabled={userCantEditVision}
                        >
                          Excluir
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardContent className="flex gap-2 rounded-md pb-2 w-full items-center">
                {vision.type === 'ANALYSIS' ? (
                  <SparklesIcon
                    size="30"
                    className="min-w-7 min-h-7 self-start text-BPM_GREY"
                    strokeWidth={1.5}
                  />
                ) : (
                  <FileSpreadsheetIcon
                    size="30"
                    className="min-w-7 min-h-7 self-start text-BPM_GREY"
                    strokeWidth={1.5}
                  />
                )}
                <p className="text-sm text-muted-foreground text-ellipsis line-clamp-2">
                  {vision.description}
                </p>
              </CardContent>
            </Card>
          ))}
        </Card>
      ) : (
        <div className="w-full max-w-[1200px] p-6 bg-gray-100 flex justify-center items-center rounded-md">
          <p className="text-lg text-gray-500">Nenhuma Visão encontrada.</p>
        </div>
      )}

      {isVisionDialogOpen && (
        <Vision
          open={isVisionDialogOpen}
          vision_id={selectedVisionId}
          handleCloseDialog={handleCloseVisionDialog}
        />
      )}
      {deleteAlertState.isOpen && deleteAlertState.visionId && (
        <DeleteAlertDialog
          open={deleteAlertState.isOpen}
          setOpen={handleCloseDeletionDialog}
          itemId={deleteAlertState.visionId}
          handleConfirmDelete={handleDeleteVision}
        />
      )}
      {executeVision.isOpen &&
        executeVision.visionId &&
        executeVision.visionType === 'ANALYSIS' && (
          <VisionAIDialog
            open={executeVision.isOpen}
            handleCloseDialog={handleCloseExecutionDialog}
            vision_id={executeVision.visionId}
            visionName={executeVision.visionName}
          />
        )}
      {executeVision.isOpen &&
        executeVision.visionId &&
        executeVision.visionType === 'REPORT' && (
          <VisionReportDialog
            open={executeVision.isOpen}
            handleCloseDialog={handleCloseExecutionDialog}
            vision_id={executeVision.visionId}
            visionName={executeVision.visionName}
          />
        )}
    </div>
  );
};

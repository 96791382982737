import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {
  Box,
  makeStyles,
  createStyles,
  TableFooter,
  IconButton,
  Typography,
} from '@material-ui/core';

import { AddCircleRounded, Clear } from '@material-ui/icons';

import { IValueJSON } from 'src/interface/ICardData';
import { ICustomField } from 'src/interface/ICardFields';
import { TableInputProps } from 'src/pages/Card/Card.i';
import { evaluateTableColumnsRules } from 'src/utils/fillRules/evaluateRules';
import { IntegratedTableInput } from './IntegratedTableInput';
import { styles } from '../../styles';
import { StringOrNumericField } from '../StringOrNumericField';
import { BooleanField } from '../BooleanField';
import { TimestampField } from '../TimestampField';
import { PredefinedField } from '../PredefinedField';
import { useCardDataContext } from '../../../../../context/CardDataContext';

const useStyles = makeStyles(() => createStyles(styles()));

export const TableField: React.FC<TableInputProps> = ({
  customField,
  phaseIndex,
  customFieldIndex,
  isInCurrentCardPhase,
}: TableInputProps) => {
  const classes = useStyles();
  const {
    handleFillCustomField,
    cardCustomFieldPhases,
    accountFields,
    fillRuleDictionary,
    cardCurrentPhase,
  } = useCardDataContext();

  const handleFillTableValue = (
    columnCustomFieldId: string,
    tableRowIndex: number,
    value?: string,
    valueJSON?: string[] | Record<string, unknown>,
  ) => {
    const newValueJSON = [...customField.valueJSON] as IValueJSON[];
    if (!newValueJSON[tableRowIndex][columnCustomFieldId]) {
      newValueJSON[tableRowIndex][columnCustomFieldId] = {};
    }

    if (typeof value !== 'undefined') {
      newValueJSON[tableRowIndex][columnCustomFieldId].value = value;
    } else {
      newValueJSON[tableRowIndex][columnCustomFieldId].valueJSON = valueJSON;
    }

    handleFillCustomField({
      phaseIndex,
      customFieldIndex,
      valueJSON: newValueJSON as unknown as Record<string, unknown>,
      customFieldId: columnCustomFieldId,
    });
  };

  const handleAddTableRow = () => {
    if (customField.tableColumns) {
      const newTableRow = customField.tableColumns.reduce(
        (row, currentColumn) => {
          return {
            ...row,
            [currentColumn.id]:
              currentColumn.type === 'INTEGRATED'
                ? {
                    valueJSON: {},
                  }
                : currentColumn.type === 'PREDEFINED_STRINGS'
                ? {
                    valueJSON: [],
                  }
                : {
                    value: '',
                  },
          };
        },
        {},
      );
      let newValueJSON: IValueJSON[];
      if (!customField.valueJSON) {
        newValueJSON = [] as IValueJSON[];
      } else {
        newValueJSON = [...customField.valueJSON] as IValueJSON[];
      }
      newValueJSON.push(newTableRow);
      let newTableColumns: ICustomField[] = customField.tableColumns || [];

      if (
        fillRuleDictionary &&
        cardCustomFieldPhases &&
        newTableColumns.length > 0
      ) {
        const newPhaseFields = cardCustomFieldPhases.map(phase => {
          const editedFields = phase.customFields.map(field => {
            if (field.id === customField.id) {
              return {
                ...field,
                valueJSON: newValueJSON,
              };
            }

            return field;
          });
          return {
            ...phase,
            customFields: editedFields,
          };
        });

        const evaluateResult = evaluateTableColumnsRules(
          customField.id,
          newPhaseFields,
          accountFields,
          fillRuleDictionary,
          customField.tableColumns,
          cardCurrentPhase?.id || '',
        );

        if (evaluateResult && evaluateResult?.tableColumns)
          newTableColumns = evaluateResult.tableColumns;

        if (evaluateResult && evaluateResult?.valueJSON)
          newValueJSON = evaluateResult.valueJSON;
      }

      handleFillCustomField({
        phaseIndex,
        customFieldIndex,
        valueJSON: newValueJSON as unknown as Record<string, unknown>,
        newTableColumns: newTableColumns ?? undefined,
      });
    }
  };

  const handleDeleteRow = (tableRowIndex: number) => {
    const newValueJSON = [...customField.valueJSON] as IValueJSON[];
    newValueJSON.splice(tableRowIndex, 1);
    handleFillCustomField({
      phaseIndex,
      customFieldIndex,
      valueJSON: newValueJSON as unknown as Record<string, unknown>,
    });
  };

  const activeColumns = customField?.tableColumns
    ? customField.tableColumns.filter(field => field?.isHidden !== true)
    : [];
  const tableColumns = customField?.tableColumns ?? [];

  return (
    <Box className={classes.tableContent}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="sticky table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {tableColumns.length !== 0 && (
                <TableCell
                  component="th"
                  scope="col"
                  align="center"
                  className={classes.deleteIconColumn}
                >
                  <Typography variant="inherit" noWrap>
                    Excluir
                  </Typography>
                </TableCell>
              )}
              {activeColumns &&
                activeColumns.map(tableColumn => (
                  <TableCell
                    component="th"
                    scope="col"
                    align={
                      tableColumn.type === 'NUMERIC'
                        ? 'right'
                        : // eslint-disable-next-line no-constant-condition
                        tableColumn.type === 'BOOL' ||
                          tableColumn.type === 'DATE' ||
                          tableColumn.type === 'TIME'
                        ? 'center'
                        : 'left'
                    }
                    key={tableColumn.id}
                  >
                    <Typography variant="inherit" noWrap>
                      {tableColumn.name}{' '}
                      {tableColumn.isRequired && isInCurrentCardPhase
                        ? '*'
                        : ''}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customField.valueJSON &&
              customField.valueJSON.map(
                (tableRow: IValueJSON, tableRowIndex: number) => (
                  <TableRow
                    className={classes.tableRowSelected}
                    // eslint-disable-next-line react/no-array-index-key
                    key={tableRowIndex}
                    hover
                  >
                    <TableCell component="td" scope="row" align="center">
                      <IconButton
                        edge="start"
                        color="inherit"
                        size="small"
                        aria-label="Remover linha"
                        onClick={() => handleDeleteRow(tableRowIndex)}
                        disabled={
                          customField.isInactive || customField.isDisabled
                        }
                      >
                        <Clear />
                      </IconButton>
                    </TableCell>
                    {tableRow &&
                      activeColumns &&
                      activeColumns.map(tableColumn => (
                        <TableCell
                          component="td"
                          key={`${tableColumn.id}.${tableColumn.tableOrder}`}
                          scope="row"
                          // align="center"
                          align={
                            tableColumn.type === 'NUMERIC'
                              ? 'right'
                              : tableColumn.type === 'BOOL'
                              ? 'center'
                              : 'left'
                          }
                        >
                          {tableColumn.type === 'NUMERIC' ||
                          tableColumn.type === 'STRING' ? (
                            <Box className={classes.notIngratedField}>
                              <StringOrNumericField
                                customField={tableColumn}
                                customFieldIndex={customFieldIndex}
                                phaseIndex={phaseIndex}
                                cardCustomFieldPhaseId=""
                                handleFillTableValue={handleFillTableValue}
                                tableColumn={{
                                  value:
                                    tableRow[tableColumn.id] &&
                                    tableRow[tableColumn.id].value
                                      ? (tableRow[tableColumn.id]
                                          .value as string)
                                      : '',
                                  tableRowIndex,
                                  tableId: customField.id,
                                }}
                                isInCurrentCardPhase={isInCurrentCardPhase}
                              />
                            </Box>
                          ) : tableColumn.type === 'BOOL' ? (
                            <Box key={customField.id}>
                              <BooleanField
                                customField={tableColumn}
                                customFieldIndex={customFieldIndex}
                                cardCustomFieldPhaseId=""
                                phaseIndex={phaseIndex}
                                handleFillTableValue={handleFillTableValue}
                                tableColumn={{
                                  value:
                                    tableRow[tableColumn.id] &&
                                    tableRow[tableColumn.id].value
                                      ? (tableRow[tableColumn.id]
                                          .value as string)
                                      : '',
                                  tableRowIndex,
                                  tableId: customField.id,
                                }}
                                isInCurrentCardPhase={isInCurrentCardPhase}
                              />
                            </Box>
                          ) : tableColumn.type === 'INTEGRATED' ? (
                            <IntegratedTableInput
                              customField={tableColumn}
                              phaseIndex={phaseIndex}
                              customFieldIndex={customFieldIndex}
                              tableRowIndex={tableRowIndex}
                              selectedIntegratedItem={
                                typeof tableRow[tableColumn.id]?.valueJSON ===
                                'undefined'
                                  ? undefined
                                  : (tableRow[tableColumn.id]
                                      .valueJSON as Record<string, unknown>)
                              }
                              handleFillTableValue={handleFillTableValue}
                              isInCurrentCardPhase={isInCurrentCardPhase}
                            />
                          ) : tableColumn.type === 'TIMESTAMP' ? (
                            <TimestampField
                              customField={tableColumn}
                              customFieldIndex={customFieldIndex}
                              cardCustomFieldPhaseId=""
                              phaseIndex={phaseIndex}
                              handleFillTableValue={handleFillTableValue}
                              tableColumn={{
                                value:
                                  tableRow[tableColumn.id] &&
                                  tableRow[tableColumn.id].value
                                    ? (tableRow[tableColumn.id].value as string)
                                    : '',
                                tableRowIndex,
                                tableId: customField.id,
                              }}
                              isInCurrentCardPhase={isInCurrentCardPhase}
                            />
                          ) : tableColumn.type === 'PREDEFINED_STRINGS' ? (
                            <PredefinedField
                              customField={tableColumn}
                              customFieldIndex={customFieldIndex}
                              cardCustomFieldPhaseId=""
                              phaseIndex={phaseIndex}
                              handleFillTableValue={handleFillTableValue}
                              tableColumn={{
                                value:
                                  tableRow[tableColumn.id] &&
                                  !!tableRow[tableColumn.id].valueJSON
                                    ? (tableRow[tableColumn.id]
                                        .valueJSON as string[])
                                    : '',
                                tableRowIndex,
                                tableId: customField.id,
                              }}
                              isInCurrentCardPhase={isInCurrentCardPhase}
                            />
                          ) : null}
                        </TableCell>
                      ))}
                  </TableRow>
                ),
              )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                align="left"
                colSpan={activeColumns ? activeColumns.length + 1 : 1}
              >
                <IconButton
                  className={classes.addButton}
                  edge="start"
                  color="inherit"
                  size="small"
                  aria-label="Adicionar linha"
                  disabled={customField.isInactive || customField.isDisabled}
                  onClick={handleAddTableRow}
                >
                  <AddCircleRounded />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

import React, { useCallback, useState } from 'react';
import { ChevronsUpDown } from 'lucide-react';
import { CheckboxWithText } from 'src/components/CheckboxWithText';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'src/components/ui/collapsible';
import { Input } from 'src/components/ui/input';
import { TabsContent } from 'src/components/ui/tabs';
import { IArea } from 'src/services/areaApi';
import { DataSourceParametersType } from 'src/services/dataSourceApi';
import Select from 'src/components/Select';
import { DeleteForever } from '@material-ui/icons';
import { TooltipWrapper } from 'src/components/ui/tooltip';

export interface AreasParamsTabContentProps {
  paramsAreas?: string[];
  areas: IArea[];
  handleSelectedSourceOption: (params: {
    dataSource: 'accounts' | 'cards' | 'tasks' | 'areas';
    sourceOptionId: string;
    areaId?: string;
    isCardField: boolean;
    isCardTask: boolean;
  }) => void;
  handleRemoveArea: (area_id: string) => void;
  dataSourceParameters: DataSourceParametersType;
  disableOptions?: boolean;
}

const filterOptions = [
  {
    id: 'all',
    name: 'Campos e Tarefas',
  },
  {
    id: 'customFields',
    name: 'Campos Personalizados',
  },
  {
    id: 'cardTasks',
    name: 'Tarefas',
  },
];

export const AreasParamsTabContent: React.FC<AreasParamsTabContentProps> = ({
  paramsAreas = [],
  areas,
  handleSelectedSourceOption,
  dataSourceParameters,
  handleRemoveArea,
  disableOptions = false,
}) => {
  const selectedAreas = areas.filter(area => paramsAreas.includes(area.id));
  const [search, setSearch] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState<
    typeof filterOptions
  >([
    {
      id: 'all',
      name: 'Campos e Tarefas',
    },
  ]);

  const handleFilterOptions = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value;
      if (searchValue.length === 0) {
        setSearch('');
      } else {
        setSearch(searchValue);
      }
    },
    [],
  );

  const filteredAreas: IArea[] =
    search.length > 0
      ? selectedAreas
          .map(area => {
            const filteredPhases = area.phases
              .map(phase => {
                const filteredCustomFields = phase.customFields.filter(field =>
                  field.name
                    .toUpperCase()
                    .includes(search.toUpperCase().trim()),
                );
                const filteredCardTasks = phase.cardTasks.filter(task =>
                  task.name.toUpperCase().includes(search.toUpperCase().trim()),
                );

                return filteredCustomFields.length > 0 ||
                  filteredCardTasks.length > 0
                  ? {
                      ...phase,
                      customFields: filteredCustomFields,
                      cardTasks: filteredCardTasks,
                    }
                  : null;
              })
              .filter(
                (phase): phase is NonNullable<typeof phase> => phase !== null,
              );

            return filteredPhases.length > 0
              ? { ...area, phases: filteredPhases }
              : null;
          })
          .filter((area): area is NonNullable<typeof area> => area !== null) ||
        []
      : selectedAreas;

  return (
    <TabsContent
      value="areasParams"
      className="flex-1 overflow-y-auto box-border"
    >
      <div className="sticky top-0 z-10 bg-white w-full py-2 flex flex-nowrap">
        <Select
          items={filterOptions}
          selectedItems={selectedFilterOption}
          setSelectedItems={setSelectedFilterOption}
          placeholder="Filtar por Campo e Tarefa"
          maxItems={1}
          btnClassName="min-w-48 m-0 mr-2 p-0"
          disabledSearch
        />
        <Input
          id="search"
          name="search"
          required
          className="border border-gray-300 rounded-md w-full"
          placeholder="Pesquise por nome de Tarefa e Campos Personalizados"
          onChange={handleFilterOptions}
          autoComplete="off"
        />
      </div>
      <div>
        {filteredAreas.map(area => (
          <Collapsible key={area.id} className="w-full mb-4">
            <CollapsibleTrigger className="w-full text-left p-2 my-2 font-medium bg-gray-100 hover:bg-gray-200 rounded flex items-center justify-between">
              <span className="flex items-center">
                {!disableOptions && (
                  <TooltipWrapper value="Remover área" align="start" side="top">
                    <DeleteForever
                      className="mr-4 text-red-600 hover:text-red-700 cursor-pointer"
                      onClick={event => {
                        event.stopPropagation();
                        handleRemoveArea(area.id);
                      }}
                      aria-label="Delete area"
                    />
                  </TooltipWrapper>
                )}
                {area.name}
              </span>
              <ChevronsUpDown className="h-4 w-4" />
              <span className="sr-only">Toggle</span>
            </CollapsibleTrigger>
            <CollapsibleContent className="w-full">
              <Accordion type="single" collapsible className="w-full">
                {area.phases.map(phase => (
                  <AccordionItem key={phase.id} value={phase.id}>
                    <AccordionTrigger className="text-left font-medium p-2">
                      {phase.name}
                    </AccordionTrigger>
                    <AccordionContent className="p-2">
                      <div className="flex flex-row flex-wrap gap-2 items-center">
                        {selectedFilterOption?.[0]?.id !== 'cardTasks' &&
                          phase.customFields.map(field => (
                            <CheckboxWithText
                              key={field.id}
                              checked={(
                                dataSourceParameters?.areas?.[area.id]
                                  ?.cardField_ids || []
                              ).includes(field.id)}
                              labelText={field.name}
                              id={field.id}
                              onCheckedChange={() =>
                                handleSelectedSourceOption({
                                  dataSource: 'areas',
                                  areaId: area.id,
                                  sourceOptionId: field.id,
                                  isCardField: true,
                                  isCardTask: false,
                                })
                              }
                              className="w-80 truncate"
                              disabled={disableOptions}
                            />
                          ))}
                        {selectedFilterOption?.[0]?.id !== 'customFields' &&
                          phase.cardTasks.map(task => (
                            <CheckboxWithText
                              key={task.id}
                              checked={(
                                dataSourceParameters?.areas?.[area.id]
                                  ?.task_ids || []
                              ).includes(task.id)}
                              labelText={`Tarefa - ${task.name}`}
                              id={task.id}
                              onCheckedChange={() =>
                                handleSelectedSourceOption({
                                  dataSource: 'areas',
                                  areaId: area.id,
                                  sourceOptionId: task.id,
                                  isCardField: false,
                                  isCardTask: true,
                                })
                              }
                              className="w-80 truncate"
                              disabled={disableOptions}
                            />
                          ))}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </CollapsibleContent>
          </Collapsible>
        ))}
      </div>
    </TabsContent>
  );
};

import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import {
  accountCustomFieldApi,
  IGetAccountCustomFieldApi,
} from 'src/services/accountCustomFieldApi';

interface HookReturn {
  accountFields: IGetAccountCustomFieldApi[];
  setAccountFields: React.Dispatch<
    React.SetStateAction<IGetAccountCustomFieldApi[]>
  >;
  loadingAccountFields: boolean;
  setRefreshAccountFields: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useAccountFields(): HookReturn {
  const [accountFields, setAccountFields] = useState<
    IGetAccountCustomFieldApi[]
  >([]);
  const [loadingAccountFields, setLoadingAccountFields] =
    useState<boolean>(false);
  const [refreshAccountFields, setRefreshAccountFields] =
    useState<boolean>(false);

  useEffect(() => {
    setLoadingAccountFields(true);
    accountCustomFieldApi
      .getCustomFields()
      .then((response: { data: IGetAccountCustomFieldApi[] }) => {
        const sorted =
          response.data?.length > 0
            ? response.data.sort(
                (a: IGetAccountCustomFieldApi, b: IGetAccountCustomFieldApi) =>
                  -b.name.localeCompare(a.name),
              )
            : [];
        setAccountFields(sorted);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        const errMessage = error.response?.data?.message || error.message;
        toast.error(
          `Falha ao buscar campos personalizados de conta. ${errMessage || ''}`,
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          },
        );
      })
      .finally(() => {
        setLoadingAccountFields(false);
      });
  }, [refreshAccountFields]);

  return {
    accountFields,
    setAccountFields,
    loadingAccountFields,
    setRefreshAccountFields,
  } as HookReturn;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from 'src/components/ui/dialog';
import api from 'src/services/api';
import { toast } from 'react-toastify';
import { ScrollArea, ScrollBar } from 'src/components/ui/scroll-area';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import { Button } from 'src/components/ui/button';
import { FileSpreadsheetIcon } from 'lucide-react';
import { PdfIcon } from 'src/assets/PdfIcon';
import {
  ExtractedVisionDataResponse,
  VisionReportDialogProps,
} from '../../Visions.i';
import { DynamicTable } from './ReportTable';
import { exportToXLSX } from '../../utils/exportToXLSX';
import { exportListToPDF } from '../../utils/exportListToPDF';

export const VisionReportDialog: React.FC<VisionReportDialogProps> = ({
  open,
  handleCloseDialog,
  vision_id,
  visionName,
}) => {
  const [reportData, setReportData] =
    useState<ExtractedVisionDataResponse | null>(null);

  useEffect(() => {
    if (vision_id) {
      api
        .post(`/visions/${vision_id}/execute`)
        .then(response => {
          if (
            response.data.message !== undefined &&
            JSON.parse(response.data.message)
          ) {
            const data: ExtractedVisionDataResponse = JSON.parse(
              response.data.message,
            );
            setReportData(data);
          }
        })
        .catch(e => {
          const error = e.response?.data?.message || e?.message;
          const errMessage = `Erro ao buscar visões. ${error}`;
          toast.error(errMessage, {
            autoClose: 5000,
            theme: 'colored',
            closeOnClick: true,
          });
        });
    }
  }, [vision_id]);

  const handleExportToCSV = () => {
    if (reportData) {
      Object.keys(reportData).forEach(key => {
        const value = reportData[key as keyof ExtractedVisionDataResponse];
        if (Array.isArray(value)) {
          exportToXLSX(value, key);
        } else {
          toast.error('Dados não compatíveis para exportação em CSV', {
            autoClose: 5000,
            theme: 'colored',
            closeOnClick: true,
          });
        }
      });
    } else {
      toast.warning('Sem dados para exportar para CSV', {
        autoClose: 5000,
        theme: 'colored',
        closeOnClick: true,
      });
    }
  };

  const handleExportToPDF = () => {
    if (reportData) {
      Object.entries(reportData).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          exportListToPDF(
            value,
            {
              margin: { top: 15, bottom: 15, left: 10, right: 10 },
              orientation: 'landscape',
              format: 'a4',
            },
            `${key.replace(/\s+/g, '_')}_report.pdf`,
          );
        } else {
          toast.error(`Dados inválidos para exportar: ${key}`, {
            autoClose: 5000,
            theme: 'colored',
            closeOnClick: true,
          });
        }
      });
    } else {
      toast.error('Sem dados para exportar para PDF', {
        autoClose: 5000,
        theme: 'colored',
        closeOnClick: true,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={isOpen => {
        if (!isOpen) handleCloseDialog();
      }}
    >
      <DialogOverlay className="z-[1400]" />
      <div className="fixed inset-0 flex items-center justify-center">
        <DialogContent
          autoFocus
          className="z-[1500] overflow-y-auto max-h-screen min-w-fit max-w-4xl box-border"
        >
          <DialogHeader>
            <DialogTitle className="flex flex-row items-center">
              {`${visionName}`}
            </DialogTitle>
            <DialogDescription>
              Nessa tela você pode visualizar os dados extraídos da visão
            </DialogDescription>
          </DialogHeader>
          {reportData === null ? (
            <p>Carregando...</p>
          ) : (
            <div className="overflow-auto">
              {Object.entries(reportData).map(([key, value]) => (
                <Accordion
                  key={key} // Use key as a unique identifier instead of index
                  type="single"
                  collapsible
                  className="w-full"
                >
                  <AccordionItem value={`item-${key}`}>
                    <AccordionTrigger>{key}</AccordionTrigger>
                    <AccordionContent>
                      <ScrollArea className="w-full h-[400px] box-border border relative">
                        <DynamicTable data={value} tableId={`table-${key}`} />
                        <ScrollBar orientation="horizontal" />
                        <ScrollBar orientation="vertical" />
                      </ScrollArea>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              ))}
            </div>
          )}
          <DialogFooter className="flex gap-2">
            <Button
              onClick={handleExportToCSV}
              className="flex flex-row flex-nowrap items-center gap-2"
              disabled={reportData === null}
            >
              <FileSpreadsheetIcon strokeWidth={1.5} />
              Gerar em XLSX
            </Button>
            <Button
              onClick={handleExportToPDF}
              className="flex flex-row flex-nowrap items-center gap-2"
              disabled={reportData === null}
            >
              <PdfIcon strokeWidth={1.5} />
              Gerar em PDF
            </Button>
          </DialogFooter>
        </DialogContent>
      </div>
    </Dialog>
  );
};
